/**
 * Component for adding a shortcut widget to the dashboard.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.addWidget - Function to add the widget.
 * @param {number} props.addwidgetRow - The row number where the widget will be added.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <AddShortcutWidget addWidget={addWidgetFunction} addwidgetRow={1} />
 *
 * @remarks
 * This component allows users to add a shortcut widget to their dashboard. It includes a form for selecting the widget's path, a color picker for customizing the widget's background color, and a button to add the widget. The component also handles the expansion and collapse of the widget settings.
 *
 * @internal
 * The component uses the `useThemeState` hook to access the global theme and applies various styles based on the theme.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ButtonBase } from "@mui/material";
import ColorPicker from "./ColorPicker";
import { useThemeState } from "../../../components/utils/globalStates";
import { pageEnums } from "../../../components/utils/enums";
import "./DashboardAddWidget.css";

export default function AddShortcutWidget({
  addWidget,
  addwidgetRow,
}: {
  addWidget: Function;
  addwidgetRow: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedWidth, setIsExpandedWidth] = useState(false);
  const [widgetTitle, setWidgetTitle] = useState("explore");
  const [widgetPath, setWidgetPath] = useState("/explore");
  const [widgetLabel, setWidgetLabel] = useState("Explore");
  const [pickedColor, setPickedColor] = useState(
    globalTheme.figmaBeigeDark.color
  );
  const [widgetIcon, setWidgetIcon] = useState("explore");
  const [isUidRequired, setIsUidRequired] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setWidgetTitle(event.target.value as string);
    setWidgetPath(
      pageEnums.find((page) => page.value === event.target.value)!.path
    );
    setWidgetLabel(
      pageEnums.find((page) => page.value === event.target.value)!.label
    );
    setWidgetIcon(
      pageEnums.find((page) => page.value === event.target.value)!.icon
    );
    setIsUidRequired(
      pageEnums.find((page) => page.value === event.target.value)!.uidRequired
    );
  };

  const onWidgetExpand = () => {
    setIsExpandedWidth(true);
    setTimeout(() => {
      setIsExpanded(true);
    }, 350);
  };

  const onWidgetCollapse = () => {
    setIsExpanded(false);
    setTimeout(() => {
      setIsExpandedWidth(false);
    }, 350);
  };

  const onWidgetPress = () => {
    if (isExpanded) {
      onWidgetCollapse();
    } else {
      onWidgetExpand();
    }
  };

  const onAddWidgetPress = () => {
    const widgetData = {
      icon: widgetIcon,
      type: "shortcut",
      rowLength: 1,
      uidRequired: isUidRequired,
      path: widgetPath,
      title: widgetLabel,
      value: widgetTitle,
      bgColorCode: pickedColor,
    };
    addWidget(widgetData, addwidgetRow);
  };

  const styles = {
    formOuter: {
      marginTop: "15px",
      backgroundColor: "transparent",
      color: globalTheme.figmaPrimaryText.color,
      borderColor: globalTheme.figmaPrimaryText.color + " !important",
      "& .MuiInputLabel-root": {
        color: globalTheme.figmaPrimaryText.color + " !important",
        flexDirection: "row",
        "& .Mui-focused ": {
          color: globalTheme.figmaPrimaryText.color,
        },
      },
    },
    select: {
      backgroundColor: "transparent",
      color: globalTheme.figmaPrimaryText.color,
      "& span": { fontSize: "13px" },
      borderColor: globalTheme.figmaPrimaryText.color + " !important",
      "& .MuiSelect-root": {
        "& fieldset": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
        "&:after": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
      },
      "&.Mui-focused fieldset": {
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color + " !important",
      },
      "&.Mui-focused ": {
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color + " !important",
      },
    },
    selectMenu: {
      zIndex: 9999,
      color: globalTheme.figmaPrimaryText.color,
      "& span": { fontSize: "13px" },
      "& .MuiPaper-root": {
        backgroundColor: "#fff",
        color: globalTheme.figmaPrimaryText.color,
        "& .MuiMenuItem-root": {
          "& .Mui-selected": {
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.altTextColor.color,
          },
        },
        "& .Mui-selected": {
          color: globalTheme.figmaPrimaryText.color,
          backgroundColor: globalTheme.background.backgroundColor,
        },
      },
    },
  };

  return (
    <div
      className="AddWidget"
      style={{
        color: globalTheme.figmaPrimaryText.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        backgroundColor: globalTheme.figmaWhite.color,
        width: isExpandedWidth ? "100%" : "25%",
        alignItems: "flex-start",
      }}
    >
      <ButtonBase
        onClick={onWidgetPress}
        className="ListingsWidget"
        sx={{
          color: globalTheme.figmaPrimaryText.color,
          borderRadius: "8px",
          backgroundColor: "transparent",
          width: "100%",
          marginTop: "-1px",
          marginBottom: "-1px",
        }}
      >
        <div
          className="ShortCutWidget"
          style={{
            color: globalTheme.figmaPrimaryText.color,
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            borderRadius: "8px",
            width: "100%",
            backgroundColor: pickedColor,
          }}
        >
          <span
            style={{
              marginTop: "10px",
              fontSize: "30px",
            }}
            className="material-symbols-outlined"
          >
            {widgetIcon}
          </span>
          <span
            style={{
              marginBottom: "10px",
              fontSize: "10px",
            }}
            className="ShortCutWidgetTitle"
          >
            {widgetLabel}
          </span>
        </div>
      </ButtonBase>
      <div
        className="AddWidgetSettingsOuter"
        style={{
          maxHeight: isExpanded ? "500px" : "0px",
        }}
      >
        <div className="AddWidgetSettings">
          <div className="AddWidgetSettingsInner">
            <FormControl fullWidth size="small" sx={styles.formOuter}>
              <InputLabel>Path</InputLabel>
              <Select
                value={widgetTitle}
                size="small"
                label="Path"
                onChange={handleChange}
                sx={{ ...styles.select }}
                MenuProps={{
                  sx: styles.selectMenu,
                }}
                renderValue={(selected) =>
                  pageEnums.find((page) => page.value === selected)!.label
                }
              >
                {pageEnums.map((page) => (
                  <MenuItem value={page.value} key={page.value}>
                    <ListItemIcon>
                      <span className="material-symbols-outlined">
                        {page.icon}
                      </span>
                    </ListItemIcon>
                    <ListItemText>{page.label}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <ColorPicker
            setPickedColor={setPickedColor}
            pickedColor={pickedColor}
          />
        </div>
        <ButtonBase
          disabled={widgetTitle === ""}
          onClick={onAddWidgetPress}
          className="AddWidgetButton"
          style={{
            backgroundColor: globalTheme.figmaPrimaryText.color,
            color: globalTheme.figmaWhite.color,
            width: "100%",
            padding: "2px",
            borderRadius: "0px 0px 6px 6px",
            justifySelf: "flex-end",
          }}
        >
          <p>ADD WIDGET</p>
        </ButtonBase>
      </div>
    </div>
  );
}
