/**
 * The `TokensStart` component sets up the routing for the tokens section of the application.
 * It uses `react-router-dom` to define two routes:
 * - The root path ("/") which renders the `TokensList` component.
 * - The "/page/:tokenId" path which renders the `TokensPage` component, where `:tokenId` is a dynamic parameter.
 *
 * @component
 * @example
 * return (
 *   <TokensStart />
 * )
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { Routes, Route } from "react-router-dom";

import TokensList from './list/TokensList';
import TokensPage from './page/TokensPage';
import './TokensStart.css';

export default function TokensStart() {

  return (
    <div className="TokensStart" >
      <Routes>
        <Route path="/" element={<TokensList />} />
        <Route path="/page/:tokenId" element={<TokensPage />} />
      </Routes>
    </div>
  );
}
