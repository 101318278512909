/**
 * The `Listeners` component is responsible for handling various event listeners and state updates
 * related to device orientation, GDPR compliance, scroll events, and view size card settings.
 * 
 * @component
 * 
 * @example
 * return (
 *   <Listeners />
 * )
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @remarks
 * This component uses several `useEffect` hooks to set up event listeners and initialize states:
 * - `checkIfMobile`: Checks if the device is mobile based on window dimensions and updates the device state.
 * - `gdprCheck`: Checks if the GDPR consent is stored in localStorage and opens a Snackbar if not.
 * - `initializeAppCheck`: Initializes Firebase App Check with ReCaptcha Enterprise.
 * - Scroll listener: Updates the scroll state when scrolling starts and stops.
 * - View size card check: Checks localStorage for the view size card setting and updates the device state.
 * 
 * @hook
 * - `useDeviceState`: Custom hook to access and update the device state.
 * - `useScrollStates`: Custom hook to access and update the scroll state.
 * 
 * @dependencies
 * - `Snackbar` and `Button` from `@mui/material` for displaying the GDPR consent message.
 * - `firebaseApp`, `initializeAppCheck`, and `ReCaptchaEnterpriseProvider` from `firebase/app-check` for Firebase App Check initialization.
 * 
 * @state
 * - `gdprOpen` (boolean): State to control the visibility of the GDPR consent Snackbar.
 * 
 * @event
 * - `resize`: Event listener to check if the device is mobile on window resize.
 * - `scroll`: Event listener to update the scroll state on window scroll.
 * 
 * @localStorage
 * - `gdpr`: Stores the user's GDPR consent status.
 * - `viewSizeCard`: Stores the user's view size card preference.
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */

import React, { useEffect, useState } from "react";
import { useDeviceState, useScrollStates } from "../utils/globalStates";
import Snackbar from "@mui/material/Snackbar";
import { Button } from "@mui/material";
import { firebaseApp } from "../firebase/firebase";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { recaptchaKey } from "../../.config/config";

export default function Listeners() {
  const [gdprOpen, setGdprOpen] = useState(false);
  const deviceState = useDeviceState((state) => state);
  const scrollState = useScrollStates((state) => state);

  const checkIfMobile = () => {
    if (window.innerWidth > window.innerHeight) {
      deviceState.setMobile(false);
    } else {
      deviceState.setMobile(true);
    }
    deviceState.setDeviceWidth(window.innerWidth);
  };

  useEffect(() => {
    const onResize = () => checkIfMobile();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const gdprCheck = () => {
    if (
      localStorage.getItem("gdpr") === null ||
      localStorage.getItem("gdpr") === undefined ||
      localStorage.getItem("gdpr") === "false" ||
      localStorage.getItem("gdpr") === ""
    ) {
      setGdprOpen(true);
    }
  };

  const onGdprClose = () => {
    localStorage.setItem("gdpr", "true");
    setGdprOpen(false);
  };

  useEffect(() => {
    checkIfMobile();
  }, []);

  useEffect(() => {
    gdprCheck();
  }, []);

  useEffect(() => {
    initializeAppCheck(firebaseApp, {
      provider: new ReCaptchaEnterpriseProvider(recaptchaKey),
      isTokenAutoRefreshEnabled: true,
    });
  }, []);

  // scroll listener that updates the scroll state when scroll start
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const onStop = () => {
      scrollState.setIsScrolling(false);
      if (window.scrollY > window.innerHeight && !scrollState.toTop) {
        scrollState.setToTop(true);
      } else if (window.scrollY < window.innerHeight && scrollState.toTop) {
        scrollState.setToTop(false);
      }
    };

    /*const onScrollY = () => {
      if (window.scrollY > window.innerHeight && !scrollState.toTop) {
        scrollState.setToTop(true);
      } else if (window.scrollY < window.innerHeight && scrollState.toTop) {
        scrollState.setToTop(false);
      }
    };*/

    const onScroll = () => {
      clearTimeout(timeoutId);
      if (!scrollState.isScrolling) {
        scrollState.setIsScrolling(true);
      }
      timeoutId = setTimeout(onStop, 300);
      //onScrollY();
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [scrollState.toTop]);

  // Check if local stortage includes viewSizeCard and set it to the device state
  useEffect(() => {
    if (localStorage.getItem("viewSizeCard") !== null) {
      const viewSizeCard = localStorage.getItem("viewSizeCard");
      if (
        viewSizeCard === "large" ||
        viewSizeCard === "medium" ||
        viewSizeCard === "small"
      ) {
        deviceState.setViewSizeBox(viewSizeCard);
      }
    }
  }, []);

  return (
    <>
      <Snackbar
        open={gdprOpen}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#fffbf3",
            color: "#39393c",
            fontSize: "14px",
            fontWeight: 600,
            textAlign: "left",
            border: "2px solid #ffffff",
            "@media (min-width: 600px)": {
              marginRight: "24px !important",
              maxWidth: "360px",
            },
            "@media (min-width: 900px)": {
              maxWidth: "500px",
            },
          },
        }}
        message="We use cookies to improve your experience on our site. By using our site, you agree to our use of cookies."
        action={
          <React.Fragment>
            <Button variant="text" onClick={onGdprClose} color="inherit">
              Accept
            </Button>
          </React.Fragment>
        }
      />
    </>
  );
}
