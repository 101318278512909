/**
 * Component representing a wallet trade item in a timeline view.
 *
 * @param {Object} props - The properties object.
 * @param {Object} props.tradeItem - The trade item data.
 * @param {number} props.index - The index of the trade item in the list.
 *
 * @returns {JSX.Element} The WalletTradeItem component.
 *
 * This component displays information about a trade item, including:
 * - Whether the item was bought or sold.
 * - The marketplace where the trade occurred.
 * - The Ethereum block number of the trade.
 * - Metadata about the traded NFT, such as collection name, image, name, description, and price.
 * - Links to social media profiles and marketplaces related to the NFT.
 *
 * The component uses the global theme state to style various elements.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";

import moment from "moment";

import XLogo from "../../../components/ui/img/socials/Xlogo";
import DiscordLogo from "../../../components/ui/img/socials/DiscordLogo";
import OpenseaLogo from "../../../components/ui/img/socials/OpenseaLogo";

import { LazyLoadImg } from "../../../components/ui/misc/LazyLoadImg";
import { removeUrlPrefix } from "../../../components/utils/parsers";
import { useThemeState } from "../../../components/utils/globalStates";
import "./WalletComp.css";

moment().format();

export default function WalletTradeItem({
  tradeItem,
  index,
}: {
  tradeItem: any;
  index: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);

  const calculatePrice = () => {
    const tradeInfo = tradeItem.sellerFee;
    let tempDecimals = 1;
    for (let i = 0; i < tradeInfo.decimals; i++) {
      tempDecimals *= 10;
    }

    return (
      (tradeInfo.amount / tempDecimals).toFixed(5) + " " + tradeInfo.symbol
    );
  };

  return (
    <div
      className="TimelineItem"
      style={{ flexDirection: index % 2 === 0 ? "row" : "row-reverse" }}
    >
      <div className="TimelineItemSide">
        <div
          className="WalletItemTitleOuter"
          style={{ alignItems: index % 2 === 0 ? "flex-end" : "flex-start" }}
        >
          <p
            style={{
              color: globalTheme.figmaPrimaryText.color,
              textAlign: index % 2 === 0 ? "right" : "left",
            }}
            className="WalletItemTitle"
          >
            {tradeItem.seller ? "Sold" : "Bought"} a NFT on{" "}
            {tradeItem.marketplace.charAt(0).toUpperCase() +
              tradeItem.marketplace.slice(1)}
          </p>
          <p
            style={{
              color: globalTheme.figmaSecondaryText.color,
              textAlign: index % 2 === 0 ? "right" : "left",
            }}
            className="WalletItemSub"
          >
            Ethereum - Block #{tradeItem.blockNumber}
          </p>
        </div>
      </div>
      <div className="TimelineItemSeparator">
        <div
          className="TimelineItemSeparatorLine"
          style={{ backgroundColor: globalTheme.figmaGOrange.color }}
        ></div>
        <div
          className="TimelineItemSeparatorCircle"
          style={{ backgroundColor: globalTheme.figmaGOrange.color }}
        >
          <StorefrontOutlinedIcon
            sx={{ color: globalTheme.figmaLightBG.color, fontSize: "26px" }}
          />
        </div>
        <div
          className="TimelineItemSeparatorLine"
          style={{ backgroundColor: globalTheme.figmaGOrange.color }}
        ></div>
      </div>
      <div className="TimelineItemSide">
        <div
          className="WalletItemDiv"
          style={{
            backgroundColor: globalTheme.figmaWhite.color,
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            boxShadow: globalTheme.boxShadow.boxShadow,
          }}
        >
          <>
            <p
              className="WalletItemInnerTitle"
              style={{ color: globalTheme.figmaPrimaryText.color }}
            >
              {tradeItem.metadata.collection.name}
            </p>
            <LazyLoadImg imgUrl={tradeItem.metadata.image.cachedUrl} />
            <p
              className="WalletItemInnerSub"
              style={{ color: globalTheme.figmaPrimaryText.color }}
            >
              {tradeItem.metadata.name}
            </p>
            <p
              className="WalletItemInnerDesc"
              style={{ color: globalTheme.figmaPrimaryText.color }}
            >
              {tradeItem.metadata.description !== undefined
                ? tradeItem.metadata.description
                : tradeItem.metadata.contract.openSeaMetadata.description !==
                    undefined &&
                  tradeItem.metadata.contract.openSeaMetadata.description}
            </p>
            <p
              className="WalletItemInnerTitle"
              style={{ color: globalTheme.figmaPrimaryText.color }}
            >
              {calculatePrice()}
            </p>
            {tradeItem.metadata.contract.openSeaMetadata.floorPrice !==
              undefined && (
              <p
                className="WalletItemInnerFloorPrice"
                style={{ color: globalTheme.figmaPrimaryText.color }}
              >
                <b>Floor price: </b>
                {tradeItem.metadata.contract.openSeaMetadata.floorPrice} ETH
              </p>
            )}
          </>
          <div className="WalletItemLinks">
            {tradeItem.metadata.contract.openSeaMetadata.twitterUsername !==
              undefined && (
              <a
                href={`https://twitter.com/${tradeItem.metadata.contract.openSeaMetadata.twitterUsername}`}
                target="_blank"
                rel="noopener noreferrer"
                className="WalletItemLinksSvg"
              >
                <XLogo />
              </a>
            )}
            {tradeItem.metadata.contract.openSeaMetadata.discordUrl !==
              undefined && (
              <a
                href={tradeItem.metadata.contract.openSeaMetadata.discordUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="WalletItemLinksSvg"
              >
                <DiscordLogo />
              </a>
            )}
            {tradeItem.metadata.contract.address !== undefined &&
              tradeItem.tokenId !== undefined && (
                <a
                  href={`https://opensea.io/assets/${tradeItem.metadata.contract.address}/${tradeItem.tokenId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="WalletItemLinksSvg"
                >
                  <OpenseaLogo />
                </a>
              )}
          </div>
          {tradeItem.metadata.contract.openSeaMetadata.externalUrl !==
            undefined && (
            <a
              href={tradeItem.metadata.contract.openSeaMetadata.externalUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="WalletItemLinksText"
            >
              {`${removeUrlPrefix(
                tradeItem.metadata.contract.openSeaMetadata.externalUrl
              )
                .charAt(0)
                .toUpperCase()}${removeUrlPrefix(
                tradeItem.metadata.contract.openSeaMetadata.externalUrl
              ).slice(1)}`}
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
