
/**
 * Header component that renders the application's header bar with various interactive elements.
 * 
 * @component
 * 
 * @example
 * return (
 *   <Header />
 * )
 * 
 * @returns {JSX.Element} The rendered header component.
 * 
 * @remarks
 * This component includes:
 * - A drawer button to toggle the side menu.
 * - Conditional rendering of back, settings, and profile buttons.
 * - A search input field.
 * - A logo that links to an external site.
 * 
 * @requires useState
 * @requires useEffect
 * @requires useNavigate
 * @requires useLocation
 * @requires useAuthState
 * 
 * @param {Object} props - The component props.
 * 
 * @property {boolean} isDrawerOpen - State to manage the drawer's open/close status.
 * @property {boolean} authModal - State to manage the authentication modal visibility.
 * @property {Object} settingsStates - Global settings state.
 * @property {Object} scrollStates - Global scroll state.
 * @property {Object} headerSettingsState - Global header settings state.
 * @property {Object} exploreSortState - Global explore sort state.
 * @property {Object} tabSettingsState - Global tab settings state.
 * @property {Object} deviceState - Global device state.
 * @property {Object} globalTheme - Global theme state.
 * 
 * @function setIsDrawerOpen - Toggles the drawer's open/close status.
 * @function setAuthModal - Toggles the authentication modal visibility.
 * 
 * @event onClick - Handles click events for buttons.
 * @event onClose - Handles the drawer close event.
 * 
 * @author 
 * @name jimmybengtsson (itchy-fingers)
 * 
 */
import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MainIcon from "../img/MainIcon";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../firebase/auth";
import SearchInput from "../../search/SearchInput";
import {
  useDeviceState,
  useSettingsStates,
  useScrollStates,
  useExploreSortState,
  useHeaderSettingsState,
  useTabSettingsState,
  useThemeState
} from "../../utils/globalStates";

import MenuDrawer from "../drawer/MenuDrawer";

import "./Header.css";

function Header() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [authModal, setAuthModal] = useState(false);
  const settingsStates = useSettingsStates((state) => state);
  const scrollStates = useScrollStates((state) => state);
  const headerSettingsState = useHeaderSettingsState();
  const exploreSortState = useExploreSortState();
  const tabSettingsState = useTabSettingsState();
  const deviceState = useDeviceState();
  const globalTheme = useThemeState(state => state.globalTheme);

  useEffect(() => {
    if (user !== null) {
      setAuthModal(false);
    }
  }, [user, authModal]);


const styles = {
  drawerButton: {
    color: globalTheme.backgroundMainOffYellow.color,
    backgroundColor: 'transparent',
    position: "fixed",
    zIndex: 9999,
    top: "30px",
    right: "calc(5% - 1px)",
    border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
    transition: "transform 0.3s ease",
    borderRadius: "8px",
    padding: "3px",
    height: "35px",
    width: "35px",
    "@media (min-width: 900px)": {
      right: "20px",
    },
  },
  backButton: {
    color: globalTheme.backgroundMainOffYellow.color,
    backgroundColor: 'transparent',
    position: "fixed",
    zIndex: 9998,
    top: "30px",
    left: "calc(5% - 1px)",
    border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
    borderRadius: "8px",
    padding: "3px",
    height: "35px",
    width: "35px",
    transition: "transform 0.3s ease",
    "@media (min-width: 900px)": {
      left: "20px",
    },
  },
};

  return (
    <>
      <IconButton
        sx={{
          ...styles.drawerButton,
          transform: deviceState.isNotificationOpen
            ? "translateY(90px)"
            : "translateY(0px)",
          backgroundColor: 'transparent',
          "&:hover": {
            backgroundColor: 'transparent'
          },
        }}
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
      >
        {isDrawerOpen ? (
          <ArrowForwardIosOutlinedIcon color="inherit" />
        ) : (
          <MenuIcon color="inherit" />
        )}
      </IconButton>
      {headerSettingsState.isBackButtonOpen && (
        <IconButton
          sx={{
            ...styles.backButton,
            transform: deviceState.isNotificationOpen
              ? "translateY(90px)"
              : "translateY(0px)",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackOutlinedIcon color="inherit" />
        </IconButton>
      )}
      {headerSettingsState.isSettingsButtonOpen && (
        <IconButton
          sx={{
            ...styles.backButton,
            transform: deviceState.isNotificationOpen
              ? "translateY(90px)"
              : "translateY(0px)",
          }}
          onClick={() => navigate("/profile/settings")}
        >
          <SettingsOutlinedIcon color="inherit" />
        </IconButton>
      )}
      {headerSettingsState.isProfileButtonOpen && (
        <IconButton
          sx={{
            ...styles.backButton,
            transform: deviceState.isNotificationOpen
              ? "translateY(90px)"
              : "translateY(0px)",
          }}
          onClick={() => navigate("/profile")}
        >
          <AccountCircleOutlinedIcon color="inherit" />
        </IconButton>
      )}
      {headerSettingsState.isSearchbarOpen && <SearchInput />}
      {headerSettingsState.isLogoOpen && (
        <div
          className="HeaderLogo"
          style={{
            backgroundColor: headerSettingsState.logoBGColor,
          }}
        >
          <div className="HeaderLogoInner" onClick={() => {
            window.open("https://gath3r.co", "_blank");
          }}>
            <MainIcon fillColor={globalTheme.backgroundMainOffYellow.color} />
          </div>
        </div>
      )}
      <Drawer
        anchor={"right"}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        sx={{
          zIndex: 9998,
          transform: deviceState.isNotificationOpen
            ? "translateY(90px)"
            : "translateY(0px)",
          transition: "transform 0.3s ease",
          "& .MuiDrawer-paper": {
            backgroundColor: globalTheme.dashboardBackground.color,
          },
        }}
      >
        <div className="appMenu">
          <MenuDrawer setIsDrawerOpen={setIsDrawerOpen} />
        </div>
      </Drawer>
    </>
  );
}

export default Header;
