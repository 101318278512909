/**
 * WalletsStart component sets up the routing for the wallets section of the application.
 * It uses react-router-dom for routing and react-helmet-async for managing the document head.
 *
 * @component
 * @example
 * return (
 *   <WalletsStart />
 * )
 *
 * Routes:
 * - "/" renders the WalletsLatest component.
 * - "/list" renders the WalletsList component.
 * - "/page/:walletId" renders the WalletPage component with a dynamic walletId parameter.
 * - "*" renders the WalletsLatest component for any unmatched routes.
 *
 * @returns {JSX.Element} The WalletsStart component.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import WalletPage from './routes/WalletPage';
import WalletsList from './routes/WalletsList';
import WalletsLatest from './routes/WalletsLatest';
import './WalletsStart.css';

export default function WalletsStart() {

  return (
    <div className="WalletsStart" >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Wallets</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<WalletsLatest />} />
        <Route path="/list" element={<WalletsList />} />
        <Route path="/page/:walletId" element={<WalletPage />} />
        <Route path="*" element={<WalletsLatest />} />
      </Routes>
    </div>
  );
}
