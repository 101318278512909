/**
 * The `UserFollowStart` component sets up the routing for user follow functionality.
 * It uses `react-router-dom` to define routes for displaying the users that a specific user is following
 * and the users that are following a specific user.
 *
 * @component
 * @example
 * return (
 *   <UserFollowStart />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @remarks
 * This component uses the `Routes` and `Route` components from `react-router-dom` to define two routes:
 * - `/out/:userId` which renders the `UserFollowing` component.
 * - `/in/:userId` which renders the `UserFollowers` component.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { Routes, Route } from "react-router-dom";

import UserFollowers from './UserFollowers';
import UserFollowing from './UserFollowing';
import './UserFollow.css';

export default function UserFollowStart() {

  return (
    <div className="UserFollowStart" >
      <Routes>
        <Route path="/out/:userId" element={<UserFollowing />} />
        <Route path="/in/:userId" element={<UserFollowers />} />
      </Routes>
    </div>
  );
}
