/**
 * AdminErrorListings component is responsible for displaying a list of error listings
 * in the admin panel. It allows sorting and pagination of the listings.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 * @component
 * @example
 * return (
 *   <AdminErrorListings />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @remarks
 * This component uses Firebase Firestore to fetch the listings and react-waypoint
 * for infinite scrolling. It also uses Material-UI components for styling and UI elements.
 *
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */

import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import { Waypoint } from "react-waypoint";
import { CircularProgress } from "@mui/material";
import {
  query,
  orderBy,
  limit,
  collection,
  onSnapshot,
  OrderByDirection,
} from "firebase/firestore";
import { firestoreDb } from "../../components/firebase/firestore";
import AdminListingItem from "./items/AdminListingItem";
import AdminErrorListingDialog from "./dialogs/AdminErrorListingDialog";
import { useThemeState } from "../../components/utils/globalStates";
import "./Admin.css";

let outerLoading = false;
const count = 12;
export default function AdminErrorListings() {
  const [sort, setSort] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState<
    OrderByDirection | undefined
  >("desc");
  const [listings, setListings] = useState([]);
  const [listingsLoading, setListingsLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [lastVisible, setLastVisible] = useState(count);
  const [lastCount, setLastCount] = useState(0);
  const [activeListing, setActiveListing] = useState<any>(null);
  const [listingDialogOpen, setListingDialogOpen] = useState(false);
  const [newListingDialogOpen, setNewListingDialogOpen] = useState(false);

  const globalTheme = useThemeState((state) => state.globalTheme);

  const [lastUpdatedAt, setLastUpdatedAt] = useState(0);

  const handleChange = (event: SelectChangeEvent) => {
    setListingsLoading(true);
    setListings([]);
    setLastCount(0);
    setLastVisible(count);
    setSort(event.target.value as string);
  };

  const handleSortDirection = () => {
    setListingsLoading(true);
    setListings([]);
    setLastCount(0);
    setLastVisible(count);
    if (sortDirection === "desc") {
      setSortDirection("asc");
    } else {
      setSortDirection("desc");
    }
  };

  // Listener for listings snapshot
  useEffect(() => {
    if (outerLoading === true) {
      return;
    }
    let q = query(
      collection(firestoreDb, "errorListings"),
      orderBy(sort, sortDirection),
      limit(lastVisible)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      outerLoading = true;
      if (lastVisible === count) {
        setListingsLoading(true);
      }
      setIsFetchingMore(true);
      let tempListings: any = [];
      querySnapshot.forEach((doc) => {
        let tempObj = doc.data();
        //@ts-ignore
        let pushObj = {
          ...tempObj,
          id: doc.id,
          name:
            tempObj.name !== null &&
            tempObj.name !== undefined &&
            tempObj.name !== ""
              ? tempObj.name
              : "",
          description:
            tempObj.description !== null &&
            tempObj.description !== undefined &&
            tempObj.description !== ""
              ? tempObj.description
              : "",
          category:
            tempObj.category !== null &&
            tempObj.category !== undefined &&
            tempObj.category !== ""
              ? tempObj.category
              : "",
          website:
            tempObj.website !== null &&
            tempObj.website !== undefined &&
            tempObj.website !== ""
              ? tempObj.website
              : "",
          xHandle:
            tempObj.xHandle !== null &&
            tempObj.xHandle !== undefined &&
            tempObj.xHandle !== ""
              ? tempObj.xHandle
              : "",
          instagramHandle:
            tempObj.instagramHandle !== null &&
            tempObj.instagramHandle !== undefined &&
            tempObj.instagramHandle !== ""
              ? tempObj.instagramHandle
              : "",
          telegramHandle:
            tempObj.telegramHandle !== null &&
            tempObj.telegramHandle !== undefined &&
            tempObj.telegramHandle !== ""
              ? tempObj.telegramHandle
              : "",
          mediumHandle:
            tempObj.mediumHandle !== null &&
            tempObj.mediumHandle !== undefined &&
            tempObj.mediumHandle !== ""
              ? tempObj.mediumHandle
              : "",
          substackHandle:
            tempObj.substackHandle !== null &&
            tempObj.substackHandle !== undefined &&
            tempObj.substackHandle !== ""
              ? tempObj.substackHandle
              : "",
          facebookUrl:
            tempObj.facebookUrl !== null &&
            tempObj.facebookUrl !== undefined &&
            tempObj.facebookUrl !== ""
              ? tempObj.facebookUrl
              : "",
          logo:
            tempObj.logo !== null &&
            tempObj.logo !== undefined &&
            tempObj.logo !== ""
              ? tempObj.logo
              : "",
          tags:
            tempObj.tags !== null &&
            tempObj.tags !== undefined &&
            tempObj.tags !== ""
              ? tempObj.tags.join(", ")
              : "",
        };
        tempListings.push(pushObj);
      });
      setListings(tempListings);
      setLastCount(lastCount + count);
      setIsFetchingMore(false);
      setListingsLoading(false);
      setLastUpdatedAt(Date.now());
      outerLoading = false;
    });

    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe();
  }, [sort, sortDirection, lastVisible]);

  const handleEndReached = () => {
    if (lastVisible !== lastCount) {
      return;
    }
    if (lastVisible === lastCount && listings.length < lastVisible) {
      return;
    }
    if (isFetchingMore === true) {
      return;
    }
    if (listingsLoading === true) {
      return;
    }
    setIsFetchingMore(true);
    setLastVisible(lastVisible + count);
  };

  const handleListingDialogClose = () => {
    setListingDialogOpen(false);
    setActiveListing(null);
  };

  const handleListingDialogOpen = (listing: any) => {
    setActiveListing(listing);
    setListingDialogOpen(true);
  };


const styles = {
  input: {
      backgroundColor: "transparent",
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      "& .MuiInputLabel-root": {
        color: globalTheme.backgroundMainOffBeige.color + " !important",
        "& .Mui-focused ": {
          color: globalTheme.backgroundMainOffBeige.color,
        },
      },
    },
    select: {
      backgroundColor: "transparent",
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      "& .MuiSelect-root": {
        "& fieldset": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
        "&:after": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
      },
      "&.Mui-focused fieldset": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      },
      "&.Mui-focused ": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      },
    },
    selectMenu: {
      color: globalTheme.backgroundMainOffBeige.color,
      "& .MuiPaper-root": {
        backgroundColor: globalTheme.backgroundMainBeige.color,
        color: globalTheme.backgroundMainOffBeige.color,
        "& .MuiMenuItem-root": {
          opacity: 0.6,
          "& .Mui-selected": {
            color: globalTheme.backgroundMainOffBeige.color,
            backgroundColor: globalTheme.backgroundMainBeige.color,
            opacity: 1,
          },
        },
        "& .Mui-selected": {
          color: globalTheme.backgroundMainOffBeige.color,
          backgroundColor: globalTheme.backgroundMainBeige.color,
            opacity: 1,
        },
      },
    },
};


  return (
    <div className="AdminInner">
      <p
        className="AdminDescription"
        style={{ ...globalTheme.backgroundMainOffBeige }}
      >
        Quarantine for listings that are not ready to be
        published or needs to be fixed.
      </p>
      <div
        className="AdminListingSortOuter"
        style={{ ...globalTheme.backgroundMainOffBeige }}
      >
        <div className="AdminListingSortAddOuter"></div>
        <div className="AdminListingSortInner">
          <FormControl fullWidth sx={{ ...styles.input }}>
            <InputLabel>Sort</InputLabel>
            <Select
              size="small"
              value={sort}
              label="Sort"
              onChange={handleChange}
              sx={{ ...styles.select }}
              MenuProps={{
                sx: styles.selectMenu,
              }}
            >
              <MenuItem value={"createdAt"}>Latest</MenuItem>
              <MenuItem value={"name"}>Name</MenuItem>
              <MenuItem value={"category"}>Category</MenuItem>
            </Select>
          </FormControl>
          <IconButton
            color="inherit"
            size="small"
            sx={{ marginLeft: "2px" }}
            onClick={handleSortDirection}
          >
            {sortDirection === "desc" ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )}
          </IconButton>
        </div>
      </div>
      {listingsLoading ? (
        <div
          className="AdminListingsLoading"
          style={{ ...globalTheme.backgroundMainOffBeige }}
        >
          <CircularProgress size={30} color="inherit" />
        </div>
      ) : listings.length > 0 ? (
        <div className="AdminListingsOuter">
          {listings.map((listing: any, index: number) => {
            return (
              <div
                onClick={() => handleListingDialogOpen(listing)}
                className="AdminListItemOuter"
                key={index}
              >
                <AdminListingItem listItem={listing} />
              </div>
            );
          })}
          <div className="LoadMoreOuter">
            {listings.length > 0 && (
              <Waypoint scrollableAncestor={window} onEnter={handleEndReached}>
                {isFetchingMore ? (
                  <div
                    className="LoadMoreOuter"
                    style={{ ...globalTheme.backgroundMainOffBeige }}
                  >
                    <CircularProgress size={30} color="inherit" />
                  </div>
                ) : (
                  <div className="LoadMoreOuter">
                    <p
                      className="AdminDescription"
                      style={{ ...globalTheme.backgroundMainOffBeige }}
                    >
                      LOAD MORE
                    </p>
                  </div>
                )}
              </Waypoint>
            )}
          </div>
          <Dialog
            sx={{
              zIndex: 9997,
              "& .MuiPaper-root": {
                width: "90vw",
                maxWidth: "600px",
                height: "70vh",
                backgroundColor: globalTheme.backgroundMainBeige.color,
              },
            }}
            onClose={handleListingDialogClose}
            open={listingDialogOpen}
          >
            {activeListing !== null && (
              <AdminErrorListingDialog
                listing={activeListing}
                lastUpdatedAt={lastUpdatedAt}
                setActiveListing={handleListingDialogClose}
              />
            )}
          </Dialog>
        </div>
      ) : (
        <div className="AdminListingsLoading">
          <p
            className="AdminDescription"
            style={{ ...globalTheme.backgroundMainBeige }}
          >
            No listings found
          </p>
        </div>
      )}
    </div>
  );
}