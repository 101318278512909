/**
 * DeviceState interface represents the state of the device.
 * @property {boolean} isMobile - Indicates if the device is mobile.
 * @property {(bool: boolean) => void} setMobile - Sets the mobile state.
 * @property {boolean} isNotificationOpen - Indicates if the notification is open.
 * @property {(bool: boolean) => void} setIsNotificationOpen - Sets the notification open state.
 * @property {number} deviceWidth - The width of the device.
 * @property {(width: number) => void} setDeviceWidth - Sets the device width.
 * @property {'small' | 'medium' | 'large'} viewSizeBox - The size of the view box.
 * @property {(size: 'small' | 'medium' | 'large') => void} setViewSizeBox - Sets the view size box.
 */

/**
 * AdminState interface represents the state of the admin.
 * @property {string} activeListing - The active listing.
 * @property {(listing: string) => void} setActiveListing - Sets the active listing.
 * @property {string} activeUser - The active user.
 * @property {(user: string) => void} setActiveUser - Sets the active user.
 * @property {string} activeListingName - The active listing name.
 * @property {(listing: string) => void} setActiveListingName - Sets the active listing name.
 * @property {string} activeReview - The active review.
 * @property {(review: string) => void} setActiveReview - Sets the active review.
 * @property {string} activeReviewName - The active review name.
 * @property {(review: string) => void} setActiveReviewName - Sets the active review name.
 * @property {string} activeReviewUser - The active review user.
 * @property {(review: string) => void} setActiveReviewUser - Sets the active review user.
 */

/**
 * SnackbarState interface represents the state of the snackbar.
 * @property {boolean} isOpen - Indicates if the snackbar is open.
 * @property {(bool: boolean) => void} setIsOpen - Sets the snackbar open state.
 * @property {string} message - The message of the snackbar.
 * @property {(message: string) => void} setMessage - Sets the snackbar message.
 * @property {string} actionText - The action text of the snackbar.
 * @property {(text: string) => void} setActionText - Sets the snackbar action text.
 * @property {number | null} openLength - The open length of the snackbar.
 * @property {(length: number | null) => void} setOpenLength - Sets the snackbar open length.
 * @property {boolean} isActionLoading - Indicates if the action is loading.
 * @property {(bool: boolean) => void} setIsActionLoading - Sets the action loading state.
 */

/**
 * SnackbarErrorState interface represents the state of the snackbar error.
 * @property {boolean} isOpen - Indicates if the snackbar error is open.
 * @property {(bool: boolean) => void} setIsOpen - Sets the snackbar error open state.
 * @property {string} message - The message of the snackbar error.
 * @property {(message: string) => void} setMessage - Sets the snackbar error message.
 * @property {string} actionText - The action text of the snackbar error.
 * @property {(text: string) => void} setActionText - Sets the snackbar error action text.
 * @property {number | null} openLength - The open length of the snackbar error.
 * @property {(length: number | null) => void} setOpenLength - Sets the snackbar error open length.
 */

/**
 * AuthStates interface represents the authentication states.
 * @property {string} role - The role of the user.
 * @property {(role: string) => void} setRole - Sets the role of the user.
 * @property {any | null} userData - The user data.
 * @property {(userData: any) => void} setUserData - Sets the user data.
 * @property {boolean} userDataLoaded - Indicates if the user data is loaded.
 * @property {(bool: boolean) => void} setUserDataLoaded - Sets the user data loaded state.
 */

/**
 * SettingsStates interface represents the settings states.
 * @property {string[]} tab - The tabs.
 * @property {(tab: string[]) => void} setTab - Sets the tabs.
 * @property {string} tabHighlight - The highlighted tab.
 * @property {(tab: string) => void} setTabHighlight - Sets the highlighted tab.
 * @property {any[]} dashboard - The dashboard data.
 * @property {(dashboard: any[]) => void} setDashboard - Sets the dashboard data.
 * @property {boolean} dashboardInitialLoaded - Indicates if the dashboard is initially loaded.
 * @property {(bool: boolean) => void} setDashboardInitialLoaded - Sets the dashboard initial loaded state.
 */

/**
 * ScrollStates interface represents the scroll states.
 * @property {boolean} isScrolling - Indicates if scrolling is happening.
 * @property {(bool: boolean) => void} setIsScrolling - Sets the scrolling state.
 * @property {boolean} toTop - Indicates if scrolling to top.
 * @property {(bool: boolean) => void} setToTop - Sets the scrolling to top state.
 * @property {boolean} isScrollingDown - Indicates if scrolling down.
 * @property {(bool: boolean) => void} setIsScrollingDown - Sets the scrolling down state.
 */

/**
 * NewsState interface represents the news state.
 * @property {any[]} listings - The news listings.
 * @property {(listings: any[]) => void} setListings - Sets the news listings.
 * @property {boolean} isFetched - Indicates if the news is fetched.
 * @property {(bool: boolean) => void} setIsFetched - Sets the news fetched state.
 */

/**
 * ExploreSortState interface represents the explore sort state.
 * @property {string} search - The search query.
 * @property {(search: string) => void} setSearch - Sets the search query.
 * @property {string} sortType - The sort type.
 * @property {(sort: string) => void} setSortType - Sets the sort type.
 * @property {string} sortOrder - The sort order.
 * @property {(order: string) => void} setSortOrder - Sets the sort order.
 * @property {number} hitsPerPage - The number of hits per page.
 * @property {(number: number) => void} setHitsPerPage - Sets the number of hits per page.
 * @property {number} page - The current page.
 * @property {(number: number) => void} setPage - Sets the current page.
 * @property {number} listingCount - The listing count.
 * @property {(number: number) => void} setListingCount - Sets the listing count.
 * @property {number} listingPages - The number of listing pages.
 * @property {(number: number) => void} setListingPages - Sets the number of listing pages.
 * @property {any[]} results - The search results.
 * @property {(results: any[]) => void} setResults - Sets the search results.
 */

/**
 * HeaderSettingsState interface represents the header settings state.
 * @property {boolean} isBackButtonOpen - Indicates if the back button is open.
 * @property {(bool: boolean) => void} setIsBackButtonOpen - Sets the back button open state.
 * @property {boolean} isSearchbarOpen - Indicates if the search bar is open.
 * @property {(bool: boolean) => void} setIsSearchbarOpen - Sets the search bar open state.
 * @property {boolean} isLogoOpen - Indicates if the logo is open.
 * @property {(bool: boolean) => void} setIsLogoOpen - Sets the logo open state.
 * @property {string} logoBGColor - The background color of the logo.
 * @property {(color: string) => void} setLogoBGColor - Sets the background color of the logo.
 * @property {string} logoColor - The color of the logo.
 * @property {(color: string) => void} setLogoColor - Sets the color of the logo.
 * @property {boolean} isSettingsButtonOpen - Indicates if the settings button is open.
 * @property {(bool: boolean) => void} setIsSettingsButtonOpen - Sets the settings button open state.
 * @property {boolean} isProfileButtonOpen - Indicates if the profile button is open.
 * @property {(bool: boolean) => void} setIsProfileButtonOpen - Sets the profile button open state.
 */

/**
 * TabSettingsState interface represents the tab settings state.
 * @property {string} color - The color of the tab.
 * @property {(color: string) => void} setColor - Sets the color of the tab.
 * @property {string} iconColor - The color of the tab icon.
 * @property {(color: string) => void} setIconColor - Sets the color of the tab icon.
 */

/**
 * AchievementsState interface represents the achievements state.
 * @property {any[]} earnedAchievements - The earned achievements.
 * @property {(achievements: any[]) => void} setEarnedAchievements - Sets the earned achievements.
 * @property {number} earnedAchievementsLength - The length of earned achievements.
 * @property {(number: number) => void} setEarnedAchievementsLength - Sets the length of earned achievements.
 * @property {boolean} earnedAchievementsLoaded - Indicates if the earned achievements are loaded.
 * @property {(bool: boolean) => void} setEarnedAchievementsLoaded - Sets the earned achievements loaded state.
 * @property {boolean} hasNewAchievements - Indicates if there are new achievements.
 * @property {(bool: boolean) => void} setHasNewAchievements - Sets the new achievements state.
 * @property {number} newAchievementsLength - The length of new achievements.
 * @property {(number: number) => void} setNewAchievementsLength - Sets the length of new achievements.
 * @property {any[]} allAchievements - All achievements.
 * @property {(achievements: any[]) => void} setAllAchievements - Sets all achievements.
 * @property {boolean} allAchievementsLoaded - Indicates if all achievements are loaded.
 * @property {(bool: boolean) => void} setAllAchievementsLoaded - Sets the all achievements loaded state.
 * @property {any[]} progressAchievements - The progress achievements.
 * @property {(achievements: any[]) => void} setProgressAchievements - Sets the progress achievements.
 * @property {boolean} progressAchievementsLoaded - Indicates if the progress achievements are loaded.
 * @property {(bool: boolean) => void} setProgressAchievementsLoaded - Sets the progress achievements loaded state.
 */

/**
 * TokensListState interface represents the tokens list state.
 * @property {any[]} tokens - The tokens.
 * @property {(tokens: any[]) => void} setTokens - Sets the tokens.
 * @property {number} tokensTotalSupply - The total supply of tokens.
 * @property {(number: number) => void} setTokensTotalSupply - Sets the total supply of tokens.
 * @property {any[]} tokenList - The token list.
 * @property {(tokenList: any[]) => void} setTokenList - Sets the token list.
 * @property {number} tokenPage - The current token page.
 * @property {(number: number) => void} setTokenPage - Sets the current token page.
 * @property {number} tokenListPage - The current token list page.
 * @property {(number: number) => void} setTokenListPage - Sets the current token list page.
 * @property {number} pageSize - The page size.
 * @property {(number: number) => void} setPageSize - Sets the page size.
 */

/**
 * ThemeState interface represents the theme state.
 * @property {boolean} isDark - Indicates if the theme is dark.
 * @property {(bool: boolean) => void} setIsDark - Sets the dark theme state.
 * @property {any} globalTheme - The global theme.
 * @property {(theme: any) => void} setGlobalTheme - Sets the global theme.
 */
import { create } from "zustand";
import { globalThemeLight } from "../theme/globalTheme";

interface DeviceState {
  isMobile: boolean;
  setMobile: (bool: boolean) => void;
  isNotificationOpen: boolean;
  setIsNotificationOpen: (bool: boolean) => void;
  deviceWidth: number;
  setDeviceWidth: (width: number) => void;
  viewSizeBox: 'small' | 'medium' | 'large';
  setViewSizeBox: (size: 'small' | 'medium' | 'large') => void;
}

interface AdminState {
  activeListing: string;
  setActiveListing: (listing: string) => void;
  activeUser: string;
  setActiveUser: (user: string) => void;
  activeListingName: string;
  setActiveListingName: (listing: string) => void;
  activeReview: string;
  setActiveReview: (review: string) => void;
  activeReviewName: string;
  setActiveReviewName: (review: string) => void;
  activeReviewUser: string;
  setActiveReviewUser: (review: string) => void;
}

interface SnackbarState {
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
  message: string;
  setMessage: (message: string) => void;
  actionText: string;
  setActionText: (text: string) => void;
  openLength: number | null;
  setOpenLength: (length: number | null) => void;
  isActionLoading: boolean;
  setIsActionLoading: (bool: boolean) => void;
}

interface SnackbarErrorState {
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
  message: string;
  setMessage: (message: string) => void;
  actionText: string;
  setActionText: (text: string) => void;
  openLength: number | null;
  setOpenLength: (length: number | null) => void;
}

interface AuthStates {
  role: string;
  setRole: (role: string) => void;
  userData: any | null;
  setUserData: (userData: any) => void;
  userDataLoaded: boolean;
  setUserDataLoaded: (bool: boolean) => void;
}

interface SettingsStates {
  tab: string[];
  setTab: (tab: string[]) => void;
  tabHighlight: string;
  setTabHighlight: (tab: string) => void;
  dashboard: any[];
  setDashboard: (dashboard: any[]) => void;
  dashboardInitialLoaded: boolean;
  setDashboardInitialLoaded: (bool: boolean) => void;
}

interface ScrollStates {
  isScrolling: boolean;
  setIsScrolling: (bool: boolean) => void;
  toTop: boolean;
  setToTop: (bool: boolean) => void;
  isScrollingDown: boolean;
  setIsScrollingDown: (bool: boolean) => void;
}

interface NewsState {
  listings: any[];
  setListings: (listings: any[]) => void;
  isFetched: boolean;
  setIsFetched: (bool: boolean) => void;
}

interface ExploreSortState {
  search: string;
  setSearch: (search: string) => void;
  sortType: string;
  setSortType: (sort: string) => void;
  sortOrder: string;
  setSortOrder: (order: string) => void;
  hitsPerPage: number;
  setHitsPerPage: (number: number) => void;
  page: number;
  setPage: (number: number) => void;
  listingCount: number;
  setListingCount: (number: number) => void;
  listingPages: number;
  setListingPages: (number: number) => void;
  results: any[];
  setResults: (results: any[]) => void;
}

interface HeaderSettingsState {
  isBackButtonOpen: boolean;
  setIsBackButtonOpen: (bool: boolean) => void;
  isSearchbarOpen: boolean;
  setIsSearchbarOpen: (bool: boolean) => void;
  isLogoOpen: boolean;
  setIsLogoOpen: (bool: boolean) => void;
  logoBGColor: string;
  setLogoBGColor: (color: string) => void;
  logoColor: string;
  setLogoColor: (color: string) => void;
  isSettingsButtonOpen: boolean;
  setIsSettingsButtonOpen: (bool: boolean) => void;
  isProfileButtonOpen: boolean;
  setIsProfileButtonOpen: (bool: boolean) => void;
}

interface TabSettingsState {
  color: string;
  setColor: (color: string) => void;
  iconColor: string;
  setIconColor: (color: string) => void;
}

interface AchievementsState {
  earnedAchievements: any[];
  setEarnedAchievements: (achievements: any[]) => void;
  earnedAchievementsLength: number;
  setEarnedAchievementsLength: (number: number) => void;
  earnedAchievementsLoaded: boolean;
  setEarnedAchievementsLoaded: (bool: boolean) => void;
  hasNewAchievements: boolean;
  setHasNewAchievements: (bool: boolean) => void;
  newAchievementsLength: number;
  setNewAchievementsLength: (number: number) => void;
  allAchievements: any[];
  setAllAchievements: (achievements: any[]) => void;
  allAchievementsLoaded: boolean;
  setAllAchievementsLoaded: (bool: boolean) => void;
  progressAchievements: any[];
  setProgressAchievements: (achievements: any[]) => void;
  progressAchievementsLoaded: boolean;
  setProgressAchievementsLoaded: (bool: boolean) => void;
}

interface TokensListState {
  tokens: any[];
  setTokens: (tokens: any[]) => void;
  tokensTotalSupply: number;
  setTokensTotalSupply: (number: number) => void;
  tokenList: any[];
  setTokenList: (tokenList: any[]) => void;
  tokenPage: number;
  setTokenPage: (number: number) => void;
  tokenListPage: number;
  setTokenListPage: (number: number) => void;
  pageSize: number;
  setPageSize: (number: number) => void;
}

interface ThemeState {
  isDark: boolean;
  setIsDark: (bool: boolean) => void;
  globalTheme: any;
  setGlobalTheme: (theme: any) => void;
}

export const useDeviceState = create<DeviceState>((set) => ({
  isMobile: true,
  setMobile: (newState) => set((state) => ({ isMobile: newState })),
  isNotificationOpen: false,
  setIsNotificationOpen: (newState) =>
    set((state) => ({ isNotificationOpen: newState })),
  deviceWidth: 600,
  setDeviceWidth: (width) => set((state) => ({ deviceWidth: width })),
  viewSizeBox: 'medium',
  setViewSizeBox: (size) => set((state) => ({ viewSizeBox: size })),
}));

export const useAdminState = create<AdminState>((set) => ({
  activeListing: "",
  setActiveListing: (listing) => set((state) => ({ activeListing: listing })),
  activeUser: "",
  setActiveUser: (user) => set((state) => ({ activeUser: user })),
  activeListingName: "",
  setActiveListingName: (listing) =>
    set((state) => ({ activeListingName: listing })),
  activeReview: "",
  setActiveReview: (review) => set((state) => ({ activeReview: review})),
  activeReviewName: "",
  setActiveReviewName: (review) =>
    set((state) => ({ activeReviewName: review })),
  activeReviewUser: "",
  setActiveReviewUser: (review) =>
    set((state) => ({ activeReviewUser: review })),
}));

export const useSnackbarState = create<SnackbarState>((set) => ({
  isOpen: false,
  setIsOpen: (newState) => set((state) => ({ isOpen: newState })),
  message: "",
  setMessage: (message) => set((state) => ({ message: message })),
  actionText: "",
  setActionText: (text) => set((state) => ({ actionText: text })),
  openLength: 5000,
  setOpenLength: (length) => set((state) => ({ openLength: length })),
  isActionLoading: false,
  setIsActionLoading: (bool) => set((state) => ({ isActionLoading: bool })),
}));

export const useSnackbarErrorState = create<SnackbarErrorState>((set) => ({
  isOpen: false,
  setIsOpen: (newState) => set((state) => ({ isOpen: newState })),
  message: "",
  setMessage: (message) => set((state) => ({ message: message })),
  actionText: "",
  setActionText: (text) => set((state) => ({ actionText: text })),
  openLength: 5000,
  setOpenLength: (length) => set((state) => ({ openLength: length })),
}));

export const useAuthStates = create<AuthStates>((set) => ({
  role: "",
  setRole: (role) => set((state) => ({ role: role })),
  userData: null,
  setUserData: (userData) => set((state) => ({ userData: userData })),
  userDataLoaded: false,
  setUserDataLoaded: (bool) => set((state) => ({ userDataLoaded: bool })),
}));

export const useSettingsStates = create<SettingsStates>((set) => ({
  tab: ["/explore", "/users", "/profile", "/profile/settings"],
  setTab: (tab) => set((state) => ({ tab: tab })),
  tabHighlight: "",
  setTabHighlight: (tab) => set((state) => ({ tabHighlight: tab })),
  dashboard: [],
  setDashboard: (dashboard) => {
    set((state) => ({ dashboard: dashboard }));
  },
  dashboardInitialLoaded: false,
  setDashboardInitialLoaded: (bool) =>
    set((state) => ({ dashboardInitialLoaded: bool })),
}));

export const useScrollStates = create<ScrollStates>((set) => ({
  isScrolling: false,
  setIsScrolling: (bool) => set((state) => ({ isScrolling: bool })),
  toTop: false,
  setToTop: (number) => set((state) => ({ toTop: number })),
  isScrollingDown: false,
  setIsScrollingDown: (bool) => set((state) => ({ isScrollingDown: bool })),
}));

export const useMediumState = create<NewsState>((set) => ({
  listings: [],
  setListings: (newState) => set((state) => ({ listings: newState })),
  isFetched: false,
  setIsFetched: (newState) => set((state) => ({ isFetched: newState })),
}));

export const useRssState = create<NewsState>((set) => ({
  listings: [],
  setListings: (newState) => set((state) => ({ listings: newState })),
  isFetched: false,
  setIsFetched: (newState) => set((state) => ({ isFetched: newState })),
}));

export const useRss3State = create<NewsState>((set) => ({
  listings: [],
  setListings: (newState) => set((state) => ({ listings: newState })),
  isFetched: false,
  setIsFetched: (newState) => set((state) => ({ isFetched: newState })),
}));

export const useExploreSortState = create<ExploreSortState>((set) => ({
  search: "",
  setSearch: (search) => set((state) => ({ search: search })),
  sortType: "createdAt",
  setSortType: (sort) => set((state) => ({ sortType: sort })),
  sortOrder: "desc",
  setSortOrder: (order) => set((state) => ({ sortOrder: order })),
  hitsPerPage: 24,
  setHitsPerPage: (number) => set((state) => ({ hitsPerPage: number })),
  page: 0,
  setPage: (number) => set((state) => ({ page: number })),
  listingCount: 0,
  setListingCount: (number) => set((state) => ({ listingCount: number })),
  listingPages: 0,
  setListingPages: (number) => set((state) => ({ listingPages: number })),
  results: [],
  setResults: (results) => set((state) => ({ results: results })),
}));

export const useUserListingsSortState = create<ExploreSortState>((set) => ({
  search: "",
  setSearch: (search) => set((state) => ({ search: search })),
  sortType: "createdAt",
  setSortType: (sort) => set((state) => ({ sortType: sort })),
  sortOrder: "desc",
  setSortOrder: (order) => set((state) => ({ sortOrder: order })),
  hitsPerPage: 24,
  setHitsPerPage: (number) => set((state) => ({ hitsPerPage: number })),
  page: 0,
  setPage: (number) => set((state) => ({ page: number })),
  listingCount: 0,
  setListingCount: (number) => set((state) => ({ listingCount: number })),
  listingPages: 0,
  setListingPages: (number) => set((state) => ({ listingPages: number })),
  results: [],
  setResults: (results) => set((state) => ({ results: results })),
}));

export const useUserSearchSortState = create<ExploreSortState>((set) => ({
  search: "",
  setSearch: (search) => set((state) => ({ search: search })),
  sortType: "relevance",
  setSortType: (sort) => set((state) => ({ sortType: sort })),
  sortOrder: "desc",
  setSortOrder: (order) => set((state) => ({ sortOrder: order })),
  hitsPerPage: 24,
  setHitsPerPage: (number) => set((state) => ({ hitsPerPage: number })),
  page: 0,
  setPage: (number) => set((state) => ({ page: number })),
  listingCount: 0,
  setListingCount: (number) => set((state) => ({ listingCount: number })),
  listingPages: 0,
  setListingPages: (number) => set((state) => ({ listingPages: number })),
  results: [],
  setResults: (results) => set((state) => ({ results: results })),
}));

export const useReviewSearchSortState = create<ExploreSortState>((set) => ({
  search: "",
  setSearch: (search) => set((state) => ({ search: search })),
  sortType: "relevance",
  setSortType: (sort) => set((state) => ({ sortType: sort })),
  sortOrder: "desc",
  setSortOrder: (order) => set((state) => ({ sortOrder: order })),
  hitsPerPage: 24,
  setHitsPerPage: (number) => set((state) => ({ hitsPerPage: number })),
  page: 0,
  setPage: (number) => set((state) => ({ page: number })),
  listingCount: 0,
  setListingCount: (number) => set((state) => ({ listingCount: number })),
  listingPages: 0,
  setListingPages: (number) => set((state) => ({ listingPages: number })),
  results: [],
  setResults: (results) => set((state) => ({ results: results })),
}));

export const useHeaderSettingsState = create<HeaderSettingsState>((set) => ({
  isBackButtonOpen: false,
  setIsBackButtonOpen: (bool) => set((state) => ({ isBackButtonOpen: bool })),
  isSearchbarOpen: false,
  setIsSearchbarOpen: (bool) => set((state) => ({ isSearchbarOpen: bool })),
  isLogoOpen: false,
  setIsLogoOpen: (bool) => set((state) => ({ isLogoOpen: bool })),
  isSettingsButtonOpen: false,
  setIsSettingsButtonOpen: (bool) =>
    set((state) => ({ isSettingsButtonOpen: bool })),
  isProfileButtonOpen: false,
  setIsProfileButtonOpen: (bool) =>
    set((state) => ({ isProfileButtonOpen: bool })),
  logoBGColor: globalThemeLight.dashboardBackground.color,
  setLogoBGColor: (color) => set((state) => ({ logoBGColor: color })),
  logoColor: globalThemeLight.backgroundMainOffYellow.color,
  setLogoColor: (color) => set((state) => ({ logoColor: color })),
}));

export const useTabSettingsState = create<TabSettingsState>((set) => ({
  color: globalThemeLight.backgroundMainYellow.color,
  setColor: (color) => set((state) => ({ color: color })),
  iconColor: globalThemeLight.backgroundMainOffYellow.color,
  setIconColor: (color) => set((state) => ({ iconColor: color })),
}));

export const useAchievementsState = create<AchievementsState>((set) => ({
  earnedAchievements: [],
  setEarnedAchievements: (achievements) =>
    set((state) => ({ earnedAchievements: achievements })),
  earnedAchievementsLength: 0,
  setEarnedAchievementsLength: (number) =>
    set((state) => ({ earnedAchievementsLength: number })),
  earnedAchievementsLoaded: false,
  setEarnedAchievementsLoaded: (bool) =>
    set((state) => ({ earnedAchievementsLoaded: bool })),
  hasNewAchievements: false,
  setHasNewAchievements: (bool) =>
    set((state) => ({ hasNewAchievements: bool })),
  newAchievementsLength: 0,
  setNewAchievementsLength: (number) =>
    set((state) => ({ newAchievementsLength: number })),
  allAchievements: [],
  setAllAchievements: (achievements) =>
    set((state) => ({ allAchievements: achievements })),
  allAchievementsLoaded: false,
  setAllAchievementsLoaded: (bool) =>
    set((state) => ({ allAchievementsLoaded: bool })),
  progressAchievements: [],
  setProgressAchievements: (achievements) =>
    set((state) => ({ progressAchievements: achievements })),
  progressAchievementsLoaded: false,
  setProgressAchievementsLoaded: (bool) =>
    set((state) => ({ progressAchievementsLoaded: bool })),
}));

export const useTokensListState = create<TokensListState>((set) => ({
  tokens: [],
  setTokens: (tokens) => set((state) => ({ tokens: tokens })),
  tokenList: [],
  setTokenList: (tokenList) => set((state) => ({ tokenList: tokenList })),
  tokenPage: 1,
  setTokenPage: (number) => set((state) => ({ tokenPage: number })),
  tokenListPage: 1,
  setTokenListPage: (number) => set((state) => ({ tokenListPage: number })),
  pageSize: 100,
  setPageSize: (number) => set((state) => ({ pageSize: number })),
  tokensTotalSupply: 1000,
  setTokensTotalSupply: (number) =>
    set((state) => ({ tokensTotalSupply: number })),
}));

export const useThemeState = create<ThemeState>((set) => ({
  isDark: false,
  setIsDark: (bool) => set((state) => ({ isDark: bool })),
  globalTheme: globalThemeLight,
  setGlobalTheme: (theme) => set((state) => ({ globalTheme: theme })),
}));
