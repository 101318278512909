/**
 * SearchInputTokens component provides a search input field with autocomplete functionality
 * for searching tokens from a list. It uses Material-UI components and match-sorter for filtering.
 *
 * @component
 * @example
 * return (
 *   <SearchInputTokens />
 * )
 *
 * @returns {JSX.Element} The rendered SearchInputTokens component.
 *
 * @remarks
 * - The component uses `useTokensListState` and `useThemeState` hooks to access global state.
 * - It navigates to a specific token's page on result click using `useNavigate` from react-router-dom.
 * - The search input is styled based on the global theme.
 *
 * @dependencies
 * - React
 * - Material-UI (TextField, Autocomplete, ButtonBase, CircularProgress)
 * - react-router-dom (useNavigate)
 * - match-sorter
 * - moment
 * - Custom hooks: useTokensListState, useThemeState
 *
 * @state
 * - `query` (string): The current search query.
 * - `results` (any[]): The filtered search results.
 * - `loading` (boolean): Indicates if the search is in progress.
 *
 * @styles
 * - `autoComplete`: Styles for the Autocomplete component based on the global theme.
 *
 * @function
 * - `onResultClick(id: string)`: Navigates to the token's page with the given id.
 *
 * @effect
 * - Updates the search results based on the query and tokens list state.
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 * 
 */
import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonBase from "@mui/material/ButtonBase";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import {matchSorter} from 'match-sorter'
import moment from "moment";
import { useTokensListState, useThemeState } from "../utils/globalStates";
import "./Search.css";

moment().format();

export default function SearchInputTokens() {
  const tokensListState = useTokensListState();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const globalTheme = useThemeState((state) => state.globalTheme);

  useEffect(() => {
    if (query.length < 2) {
        return setResults([]);
    }
    let tempArray: any[] = [];
    if (query !== "" && query.length > 1 && tokensListState.tokens.length > 0) {
      setLoading(true);
        tempArray = matchSorter(tokensListState.tokens, query, {
            keys: ["name"],
        });
      
      setResults(tempArray);
        setLoading(false);
    }
  }, [query]);

  const onResultClick = (id: string) => {
    navigate(`/cryptos/page/${id}`);
  };

  const styles = {
  autoComplete: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      color: globalTheme.figmaPrimaryText.color,
      borderColor: globalTheme.figmaPrimaryText.color,
      borderWidth: "1px",
      backgroundColor: globalTheme.figmaWhite.color,
      boxShadow: "none",
      borderRadius: "8px",
      padding: "6px",
      paddingLeft: "14px",
      paddingRight: "14px",
      "& fieldset": {
        borderColor: globalTheme.figmaPrimaryText.color,
        borderWidth: "1px",
        color: globalTheme.figmaPrimaryText.color,
      },
      "&:hover fieldset": {
        borderColor: globalTheme.figmaPrimaryText.color,
        borderWidth: "1px",
        color: globalTheme.figmaPrimaryText.color,
      },
      "&.Mui-focused fieldset": {
        borderColor: globalTheme.figmaPrimaryText.color,
        color: globalTheme.figmaPrimaryText.color,
      },
    },
    "& label.Mui-focused": {
      color: globalTheme.figmaPrimaryText.color,
    },
    "& label": {
      color: globalTheme.figmaPrimaryText.color,
    },
  },
  
};


  return (
    <Autocomplete
      freeSolo
      fullWidth
      disabled={tokensListState.tokens.length === 0}
      sx={{
        ...styles.autoComplete,
      }}
      size="small"
      filterOptions={(x) => x}
      loading={loading}
      getOptionLabel={(option: any) => {
        return option.name;
      }
        }
      renderOption={(props, option: any) => {
        return (
          <ButtonBase
            key={option.id}
            onClick={() => {
              onResultClick(option.id);
            }}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "space-between",
              width: "100%",
              padding: "10px",
              backgroundColor: "#fff",
            }}
          >
            <p className="AutocompleteResultTitle">{option.name}</p>
          </ButtonBase>
        );
      }}
      options={results}
      renderInput={(params) => (
        <TextField
          {...params}
          value={query}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          placeholder="Search for..."
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <React.Fragment>
                <SearchIcon
                  color="inherit"
                  sx={{ fontSize: "20px", marginLeft: "5px", opacity: 0.6 }}
                />
              </React.Fragment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={"20px"} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}