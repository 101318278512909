/**
 * Component for rendering a card view picker with toggle buttons.
 * 
 * @param {object} props - The component props.
 * @param {string} props.iconColor - The color of the icons.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * This component uses the following icons from Material UI:
 * - Crop54OutlinedIcon
 * - Crop32OutlinedIcon
 * - Crop169OutlinedIcon
 * 
 * It also uses the following components from Material UI:
 * - ToggleButton
 * - ToggleButtonGroup
 * 
 * The component interacts with the global state using the following hooks:
 * - useDeviceState
 * - useThemeState
 * 
 * The selected view size is stored in local storage under the key "viewSizeCard".
 * 
 * @component
 * 
 * @author 
 * @name jimmybengtsson (itchy-fingers)
 */
import Crop54OutlinedIcon from "@mui/icons-material/Crop54Outlined";
import Crop32OutlinedIcon from "@mui/icons-material/Crop32Outlined";
import Crop169OutlinedIcon from "@mui/icons-material/Crop169Outlined";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  useDeviceState,
  useThemeState,
} from "../../../components/utils/globalStates";
import "./Pickers.css";

function PickersCardView({iconColor}:any) {
  const deviceState = useDeviceState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const handleItemSizeChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: "large" | "medium" | "small"
  ) => {
    deviceState.setViewSizeBox(nextView);
    // Set in local storage
    localStorage.setItem("viewSizeCard", nextView);
  };

  const styles = {
    toggleIcon: {
      fontSize: "18px",
    },
  };
  return (
    <ToggleButtonGroup
      size="small"
      orientation="horizontal"
      value={deviceState.viewSizeBox}
      exclusive
      onChange={handleItemSizeChange}
    >
      <ToggleButton value="large" aria-label="large">
        <Crop54OutlinedIcon
          sx={{
            ...styles.toggleIcon,
            color:
              iconColor !== null ? iconColor : globalTheme.iconNeutral.color,
          }}
        />
      </ToggleButton>
      <ToggleButton value="medium" aria-label="medium">
        <Crop32OutlinedIcon
          sx={{
            ...styles.toggleIcon,
            color:
              iconColor !== null ? iconColor : globalTheme.iconNeutral.color,
          }}
        />
      </ToggleButton>
      <ToggleButton value="small" aria-label="small">
        <Crop169OutlinedIcon
          sx={{
            ...styles.toggleIcon,
            color:
              iconColor !== null ? iconColor : globalTheme.iconNeutral.color,
          }}
        />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default PickersCardView;
