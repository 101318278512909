/**
 * The `TokensPage` component is responsible for displaying detailed information about a specific cryptocurrency token.
 * It fetches token data from the CoinGecko API and displays various details including the token's name, description, 
 * categories, social media links, and price data.
 *
 * @component
 * @example
 * ```tsx
 * <TokensPage />
 * ```
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @remarks
 * - The component uses several global state hooks to manage header settings, tab settings, and snackbar error states.
 * - It also uses the `useParams` hook from `react-router-dom` to get the `tokenId` from the URL.
 * - The `useEffect` hooks are used to fetch token data and update the header and tab settings based on the global theme.
 * - The component displays a loading skeleton while fetching data and handles errors by showing a snackbar message.
 *
 * @dependencies
 * - `useState`, `useEffect` from `react`
 * - `useParams`, `useNavigate` from `react-router-dom`
 * - `useSnackbarErrorState`, `useHeaderSettingsState`, `useTabSettingsState`, `useThemeState` from `../../../components/utils/globalStates`
 * - `removeUrlPrefix`, `removeTwitterPrefix` from `../../../components/utils/parsers`
 * - `copy-to-clipboard`
 * - `DOMPurify`
 * - `XLogo`, `InstagramLogo`, `MediumLogo`, `TelegramLogo` from `../../../components/ui/img/socials`
 * - `IconButton`, `Avatar`, `Skeleton` from `@mui/material`
 * - `ShareOutlinedIcon` from `@mui/icons-material`
 * - `TokenPagePrices` from `./TokenPagePrices`
 * - `LazyLoadImg` from `../../../components/ui/misc/LazyLoadImg`
 * - `coinGeckoKey` from `../../../.config/config`
 * - `Helmet` from `react-helmet-async`
 *
 * @param {Object} props - The component props.
 * @param {string} props.tokenId - The ID of the token to fetch and display.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";
import {
  useSnackbarErrorState,
  useHeaderSettingsState,
  useTabSettingsState,
  useThemeState,
} from "../../../components/utils/globalStates";
import {
  removeUrlPrefix,
  removeTwitterPrefix,
} from "../../../components/utils/parsers";
import copy from "copy-to-clipboard";

import DOMPurify from "dompurify";
import XLogo from "../../../components/ui/img/socials/Xlogo";
import InstagramLogo from "../../../components/ui/img/socials/InstagramLogo";
import MediumLogo from "../../../components/ui/img/socials/MediumLogo";
import TelegramLogo from "../../../components/ui/img/socials/TelegramLogo";

import IconButton from "@mui/material/IconButton";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import { useParams, useNavigate } from "react-router-dom";
import TokenPagePrices from "./TokenPagePrices";
import { LazyLoadImg } from "../../../components/ui/misc/LazyLoadImg";
import { coinGeckoKey } from "../../../.config/config";
import { Helmet } from "react-helmet-async";

import "./TokensPage.css";

export default function TokensPage() {
  const { tokenId } = useParams();
  const headerSettingsState = useHeaderSettingsState();
  const tabSettingsState = useTabSettingsState();
  const snackbarError = useSnackbarErrorState();
  const snackbarStates = useSnackbarErrorState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const navigate = useNavigate();

  const [tokenData, setTokenData] = useState<any>(null);
  const [tokenPriceData, setTokenPriceData] = useState<any>(null);
  const [isTopExpanded, setIsTopExpanded] = useState(false);

  useEffect(() => {
    headerSettingsState.setIsBackButtonOpen(true);
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffBeige.color);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.backgroundMainBeige.color);
    return () => {
      headerSettingsState.setIsBackButtonOpen(false);
      headerSettingsState.setIsLogoOpen(false);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      tabSettingsState.setColor(globalTheme.backgroundMainYellow.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  // Fetch token data from listings collection
  useEffect(() => {
    console.log("Fetching token data");
    console.log(tokenId);
    if (tokenId === undefined || tokenId === "" || tokenId === null) {
      snackbarError.setMessage("Cryptocurrency not found");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return navigate("/cryptos");
    }

    const options = {
      method: "GET",
      headers: { "x-cg-demo-api-key": coinGeckoKey },
    };
    fetch(`https://api.coingecko.com/api/v3/coins/${tokenId}`, options)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.error !== undefined && data.error !== null) {
          snackbarError.setMessage("Cryptocurrency not found");
          snackbarError.setOpenLength(4000);
          snackbarError.setIsOpen(true);
          return navigate("/cryptos");
        }
        setTokenData(data);
        if (data.market_data !== undefined && data.market_data !== null) {
          setTokenPriceData(data.market_data);
        }
      })
      .catch((err) => {
        console.error(err);
        snackbarError.setMessage("Cryptocurrency not found");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        return navigate("/cryptos");
      });
  }, [tokenId]);

  const onShareClick = () => {
    copy(`https://app.gath3r.co/cryptos/page/${tokenId}`);
    snackbarStates.setMessage("Link copied to clipboard");
    snackbarStates.setOpenLength(3000);
    snackbarStates.setIsOpen(true);
  };

  return (
    <div
      className="TokensPage"
      style={{ backgroundColor: globalTheme.backgroundMainBeige.color, paddingTop: "90px" }}
    >
      {tokenData !== null && (
        <Helmet prioritizeSeoTags>
          <title>GATH3R - {tokenData.name}</title>
        </Helmet>
      )}
      <div
        className="ListingsPageTop"
        style={{
          backgroundColor: globalTheme.boxBackgroundWhite.color,
          boxShadow: globalTheme.boxShadow.boxShadow,
          border: `1px solid ${globalTheme.solidMainBlack.color}`,
          padding: "20px",
          width: "calc(90% - 40px)",
          maxWidth: "760px",
        }}
      >
        <div className="ListingsPageTopInner">
          <div className="ListingsPageTopInnerAvatar">
            {tokenData === null ? (
              <Skeleton
                variant="circular"
                sx={{
                  width: "65px",
                  height: "65px",
                  borderRadius: "50%",
                  "@media (min-width: 350px)": {
                    width: "75px",
                    height: "75px",
                  },
                  "@media (min-width: 550px)": {
                    width: "85px",
                    height: "85px",
                  },
                  "@media (min-width: 700px)": {
                    width: "95px",
                    height: "95px",
                  },
                }}
              />
            ) : tokenData.image !== undefined &&
              tokenData.image !== null &&
              tokenData.image.large !== undefined &&
              tokenData.image.large !== null &&
              tokenData.image.large !== "" ? (
              <div className="ListingItemAvatar">
                <LazyLoadImg
                  // @ts-ignore
                  imgUrl={tokenData.image.large}
                />
              </div>
            ) : (
              <Avatar
                sx={{
                  bgcolor: globalTheme.boxBackgroundWhite.color,
                  color: globalTheme.textMainBlack.color,
                  width: "65px",
                  height: "65px",
                  borderRadius: "50%",
                  fontSize: "16px",
                  "@media (min-width: 350px)": {
                    width: "75px",
                    height: "75px",
                    fontSize: "17px",
                  },
                  "@media (min-width: 550px)": {
                    width: "85px",
                    height: "85px",
                    fontSize: "18px",
                  },
                  "@media (min-width: 700px)": {
                    width: "95px",
                    height: "95px",
                    fontSize: "20px",
                  },
                }}
                alt={tokenData.name}
              >
                {tokenData.name.substr(0, 2)}
              </Avatar>
            )}
          </div>
          <div className="ListingsPageTopInnerText">
            {tokenData === null ? (
              <Skeleton
                variant="text"
                sx={{
                  width: "60%",
                  fontSize: "20px",
                  marginLeft: "15px",
                  "@media (min-width: 350px)": {
                    width: "80px",
                    fontSize: "22px",
                  },
                  "@media (min-width: 550px)": {
                    width: "100px",
                    fontSize: "24px",
                  },
                }}
              />
            ) : (
              <p
                className="ListingsPageHeaderTitle"
                style={{ color: globalTheme.textMainBlack.color }}
              >
                <span style={{ fontSize: "95%" }}>{tokenData.name}</span>
              </p>
            )}
            {tokenData === null ? (
              <Skeleton
                variant="text"
                sx={{
                  width: "50px",
                  fontSize: "12px",
                  marginLeft: "15px",
                  marginTop: "5px",
                  "@media (min-width: 350px)": {
                    width: "60px",
                    fontSize: "15px",
                  },
                  "@media (min-width: 550px)": {
                    width: "70px",
                    fontSize: "16px",
                  },
                }}
              />
            ) : (
              tokenData.categories !== null &&
              tokenData.categories !== undefined &&
              tokenData.categories.length > 0 && (
                <p
                  className="ListingsPageHeaderSub"
                  style={{ color: globalTheme.figmaAlt1.color, width: "95%" }}
                >
                  <span style={{ fontSize: "80%" }}>
                    {tokenData.categories.join(" - ")}
                  </span>
                </p>
              )
            )}
          </div>
        </div>
        {tokenData === null ? (
          <>
            <Skeleton
              variant="text"
              sx={{
                width: "92%",
                fontSize: "14px",
                "@media (min-width: 350px)": {
                  fontSize: "15px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "16px",
                },
              }}
            />
            <Skeleton
              variant="text"
              sx={{
                width: "92%",
                fontSize: "14px",
                "@media (min-width: 350px)": {
                  fontSize: "15px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "16px",
                },
              }}
            />
            <Skeleton
              variant="text"
              sx={{
                width: "92%",
                fontSize: "14px",
                "@media (min-width: 350px)": {
                  fontSize: "15px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "16px",
                },
              }}
            />
          </>
        ) : (
          tokenData.description !== null &&
          tokenData.description !== undefined &&
          tokenData.description.en !== undefined &&
          tokenData.description.en !== null && (
            <div
              className="ListingsPageHeaderDesc"
              style={{ color: globalTheme.textMainBlack.color }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(tokenData.description.en, {
                  FORBID_ATTR: ["style"],
                  FORBID_TAGS: ["style", "a"],
                }),
              }}
            ></div>
          )
        )}
        <div className="ListingsPageDescSocial">
          {tokenData !== null &&
            tokenData.links !== null &&
            tokenData.links !== undefined &&
            tokenData.links.twitter_screen_name !== undefined &&
            tokenData.links.twitter_screen_name !== null &&
            tokenData.links.twitter_screen_name !== "" && (
              <a
                href={`https://x.com/${removeTwitterPrefix(
                  tokenData.links.twitter_screen_name
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="ListingsPageHeaderSocialLink"
              >
                <XLogo customColor={globalTheme.textMainGrey.color} />
              </a>
            )}
          {tokenData !== null &&
            tokenData.instagramHandle !== "" &&
            tokenData.instagramHandle !== undefined &&
            tokenData.instagramHandle !== null && (
              <a
                href={`https://instagram.com/${tokenData.instagramHandle}`}
                target="_blank"
                rel="noopener noreferrer"
                className="ListingsPageHeaderSocialLink"
              >
                <InstagramLogo customColor={globalTheme.textMainGrey.color} />
              </a>
            )}
          {tokenData !== null &&
            tokenData.links !== null &&
            tokenData.links !== undefined &&
            tokenData.links.telegram_channel_identifier !== undefined &&
            tokenData.links.telegram_channel_identifier !== null &&
            tokenData.links.telegram_channel_identifier !== "" && (
              <a
                href={`https://t.me/${tokenData.links.telegram_channel_identifier}`}
                target="_blank"
                rel="noopener noreferrer"
                className="ListingsPageHeaderSocialLink"
              >
                <TelegramLogo customColor={globalTheme.textMainGrey.color} />
              </a>
            )}
          {tokenData !== null &&
            tokenData.mediumHandle !== "" &&
            tokenData.mediumHandle !== undefined &&
            tokenData.mediumHandle !== null && (
              <a
                href={`https://medium.com/@${removeTwitterPrefix(
                  tokenData.mediumHandle
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="ListingsPageHeaderSocialLink"
              >
                <MediumLogo />
              </a>
            )}
          <div className="ListingsPageTopInnerIcons">
            <IconButton
              sx={{
                margin: 0,
                color: globalTheme.textMainGrey.color,
                opacity: tokenData !== null ? 1 : 0,
              }}
              size="small"
              onClick={onShareClick}
            >
              <ShareOutlinedIcon
                sx={{
                  fontSize: "16px",
                  "@media (min-width: 350px)": { fontSize: "17px" },
                  "@media (min-width: 550px)": { fontSize: "18px" },
                  "@media (min-width: 701px)": { fontSize: "20px" },
                }}
              />
            </IconButton>
          </div>
        </div>
        <div
          className="ListingsPageTopInnerDivider"
          style={{ backgroundColor: globalTheme.figmaPrimaryText.color }}
        ></div>
        <div className="ListingsPageTopFooter">
          <div className="ListingsPageTopTagsOuter">
            <div className="ListingsPageTopTags">
              {tokenData !== null &&
                tokenData.links !== null &&
                tokenData.links !== undefined &&
                tokenData.links.homepage !== undefined &&
                tokenData.links.homepage !== null &&
                tokenData.links.homepage.length > 0 && (
                  <a
                    href={tokenData.website}
                    className="ListingsPageWebsiteLink"
                    style={{ color: globalTheme.figmaAlt1.color }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{`${removeUrlPrefix(tokenData.links.homepage[0])
                    .charAt(0)
                    .toUpperCase()}${removeUrlPrefix(
                    tokenData.links.homepage[0]
                  ).slice(1)}`}</a>
                )}
            </div>
          </div>
          {tokenPriceData !== null ? (
            <TokenPagePrices
              priceData={tokenPriceData}
              tokenId={tokenData.id}
            />
          ) : (
            <Skeleton
              variant="rectangular"
              sx={{
                width: "92%",
                height: "100px",
                "@media (min-width: 350px)": {
                  height: "150px",
                },
                "@media (min-width: 550px)": {
                  height: "200px",
                },
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
