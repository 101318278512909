/**
 * Displays and manages verified listings in the admin panel.
 *
 * @remarks
 * This component integrates with Firestore Realtime Database and Algolia
 * to fetch and manage listings. It includes functionality for searching,
 * sorting, pagination, and the addition or editing of listings. Dialogs
 * are used for creating new listings or editing existing ones.
 *
 * @returns A React component that renders the admin listings interface.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers) 
 */
import React, { useEffect, useState } from "react";
//import AddIcon from "@mui/icons-material/Add";
import Pagination from "@mui/material/Pagination";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import ButtonBase from "@mui/material/ButtonBase";
import { CircularProgress } from "@mui/material";
import { ref, onValue } from "firebase/database";
import { firebaseRlDb } from "../../components/firebase/database";

// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Radio from "@mui/material/Radio";

import IconButton from "@mui/material/IconButton";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

import {
  algoliaListingsRelevance,
  algoliaListingsCreatedAtDesc,
  algoliaListingsRatingDesc,
  //algoliaListingsTrendingHourDesc,
  //algoliaListingsViewsTotalDesc,
} from "../../components/search/algoliaSearch";
import AdminListingSearch from "./components/AdminListingSearch";
import ProjectListItem from "../../components/ui/listItems/ProjectListItem";
import AdminListingDialog from "./dialogs/AdminListingDialog";
import AdminNewListingDialog from "./dialogs/AdminNewListingDialog";
import {
  standardDialogPaper,
  standardDialogRoot,
} from "../../components/theme/globalMuiTheme";
import {
  useThemeState,
  useExploreSortState,
  useAdminState,
  useDeviceState,
} from "../../components/utils/globalStates";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../components/firebase/auth";
import PickersCardView from "../../components/ui/pickers/PickerCardView";
import AdminListingsStats from "./components/AdminListingsStats";
import "./Admin.css";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AdminListings() {
  const [user] = useAuthState(firebaseAuth);
  const exploreState = useExploreSortState((state) => state);
  const adminState = useAdminState((state) => state);
  const deviceState = useDeviceState((state) => state);
  const [sortDialogOpen, setSortDialogOpen] = useState(false);

  const [listingsLoading, setListingsLoading] = useState(true);
  const [listingDialogOpen, setListingDialogOpen] = useState(false);
  const [newListingDialogOpen, setNewListingDialogOpen] = useState(false);

  const [lastUpdatedAt, setLastUpdatedAt] = useState(0);

  const [listingCount, setListingCount] = useState(0);
  const [listingCountLoading, setListingCountLoading] = useState(true);

  const globalTheme = useThemeState((state) => state.globalTheme);

  const handleItemSizeChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: "large" | "medium" | "small"
  ) => {
    deviceState.setViewSizeBox(nextView);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    exploreState.setPage(value - 1);
  };

  useEffect(() => {
    if (adminState.activeListing !== "") {
      setListingDialogOpen(true);
    } else {
      setListingDialogOpen(false);
    }
  }, [adminState.activeListing]);

  /*const fetchTrendingHourDesc = () => {
    setListingsLoading(true);
    const fromDate = Date.now() - 60 * 60 * 1000 * 200;
    algoliaListingsTrendingHourDesc
      .search(exploreState.search, {
        hitsPerPage: exploreState.hitsPerPage,
        page: exploreState.page,
        numericFilters: [`trendingHourUpdated > ${fromDate}`],
        clickAnalytics: true,
        userToken: user?.uid,
      })
      .then((res: any) => {
        exploreState.setListingPages(res.nbPages);
        exploreState.setListingCount(res.nbHits);
        let tempArray: any[] = [];
        for (let i = 0; i < res.hits.length; i++) {
          const tempListing = res.hits[i];
          tempArray.push({ ...tempListing, searchId: res.queryID });
        }
        exploreState.setResults(tempArray);
        setListingsLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setListingsLoading(false);
      });
  };*/

  const fetchTemplate = (fetchObj: any) => {
    setListingsLoading(true);
    fetchObj
      .search(exploreState.search, {
        hitsPerPage: exploreState.hitsPerPage,
        page: exploreState.page,
        clickAnalytics: true,
        userToken: user?.uid,
      })
      .then((res: any) => {
        exploreState.setListingPages(res.nbPages);
        exploreState.setListingCount(res.nbHits);
        let tempArray: any[] = [];
        for (let i = 0; i < res.hits.length; i++) {
          const tempListing = res.hits[i];
          tempArray.push({ ...tempListing, searchId: res.queryID });
        }
        exploreState.setResults(tempArray);
        setListingsLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setListingsLoading(false);
      });
  };

  useEffect(() => {
    if (exploreState.sortType === "rating") {
      fetchTemplate(algoliaListingsRatingDesc);
    } /*else if (exploreState.sortType === "trendingHour") {
      fetchTrendingHourDesc();
    }*/ /*else if (exploreState.sortType === "viewsTotal") {
      fetchTemplate(algoliaListingsViewsTotalDesc);
    }*/ else if (exploreState.sortType === "relevance") {
      fetchTemplate(algoliaListingsRelevance);
    } else {
      fetchTemplate(algoliaListingsCreatedAtDesc);
    }
  }, [
    exploreState.sortOrder,
    exploreState.sortType,
    exploreState.hitsPerPage,
    exploreState.page,
    exploreState.search,
  ]);

  // Listener for firebase realtime database for listing count /public/app/count/verified
  useEffect(() => {
    const dbRef = ref(firebaseRlDb, "public/app/count/verified");
    const unsubscribe = onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        console.log(snapshot.val());
        setListingCount(snapshot.val());
        setListingCountLoading(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const styles = {
    input: {
      backgroundColor: "transparent",
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      "& .MuiInputLabel-root": {
        color: globalTheme.backgroundMainOffBeige.color + " !important",
        "& .Mui-focused ": {
          color: globalTheme.backgroundMainOffBeige.color,
        },
      },
    },
    select: {
      backgroundColor: "transparent",
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      "& .MuiSelect-root": {
        "& fieldset": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
        "&:after": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
      },
      "&.Mui-focused fieldset": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      },
      "&.Mui-focused ": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      },
    },
    selectMenu: {
      color: globalTheme.backgroundMainOffBeige.color,
      "& .MuiPaper-root": {
        backgroundColor: globalTheme.backgroundMainBeige.color,
        color: globalTheme.backgroundMainOffBeige.color,
        "& .MuiMenuItem-root": {
          opacity: 0.6,
          "& .Mui-selected": {
            color: globalTheme.backgroundMainOffBeige.color,
            backgroundColor: globalTheme.backgroundMainBeige.color,
            opacity: 1,
          },
        },
        "& .Mui-selected": {
          color: globalTheme.backgroundMainOffBeige.color,
          backgroundColor: globalTheme.backgroundMainBeige.color,
          opacity: 1,
        },
      },
    },
    dialogRoot: {
      zIndex: 9999,
      backgroundColor: "rgba(253, 247, 234, 0.5)",
      "& .MuiDialog-paper": {
        backgroundColor: globalTheme.backgroundMainBeige.color,
        color: globalTheme.backgroundMainOffBeige.color,
        width: "94%",
        maxWidth: "600px",
        margin: 0,
        paddingBottom: "70px",
        paddingTop: "20px",
        position: "absolute",
        bottom: "-10px",
      },
    },
    dialogTitle: {
      color: globalTheme.backgroundMainOffBeige.color,
      textAlign: "center",
      fontSize: "16px",
      marginTop: "20px",
      fontWeight: 600,
    },
    radio: {
      color: globalTheme.backgroundMainOffBeige.color,
      "&.Mui-checked": {
        color: globalTheme.backgroundMainOffBeige.color,
      },
    },
  };

  return (
    <div className="AdminInner">
      <p
          className="AdminDescription"
          style={{
            ...globalTheme.backgroundMainOffBeige,
          }}
        >
          All verified listings shown in the app and on landing page. New can be
          added and existing ones can be edited, deleted and moved to error
          listings
        </p>
      <AdminListingsStats />
      <AdminListingSearch />
      <div
        className="AdminListingSortOuter"
        style={{ ...globalTheme.backgroundMainOffBeige }}
      >
        <ButtonBase
          onClick={() => {
            setSortDialogOpen(true);
          }}
        >
          <div className="ExploreSortInner">
            <SwapVertOutlinedIcon
              sx={{
                color: globalTheme.backgroundMainOffYellow.color,
                fontSize: "17px",
                "@media (min-width: 350px)": {
                  fontSize: "18px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "19px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "20px",
                },
              }}
            />
            <p
              className="ExploreSortTitle"
              style={{
                color: globalTheme.backgroundMainOffYellow.color,
                marginLeft: "5px",
                fontWeight: 500,
              }}
            >
              {exploreState.sortType === "createdAt"
                ? "Latest"
                : exploreState.sortType === "rating"
                ? "Rating"
                : exploreState.sortType === "trendingHour"
                ? "Trending"
                : exploreState.sortType === "relevance"
                ? "Relevance"
                : "Views"}
            </p>
          </div>
        </ButtonBase>

        <PickersCardView
          iconColor={globalTheme.backgroundMainOffYellow.color}
        />
      </div>
      {listingsLoading ? (
        <div
          className="AdminListingsLoading"
          style={{ ...globalTheme.backgroundMainOffBeige }}
        >
          <CircularProgress size={30} color="inherit" />
        </div>
      ) : exploreState.results.length > 0 ? (
        <div className="AdminListingsOuter">
          {exploreState.results.map((listing: any, index: number) => {
            return (
              <ProjectListItem
                listItem={listing}
                indexKey={index}
                itemSize={deviceState.viewSizeBox}
                clickAdmin={true}
              />
            );
          })}
          {!listingsLoading &&
            exploreState.results.length > 0 &&
            exploreState.listingPages !== 0 && (
              <Pagination
                count={exploreState.listingPages}
                page={exploreState.page + 1}
                onChange={handlePageChange}
                sx={{
                  marginTop: "20px",
                  marginBottom: "0px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  "& button": {
                    color: globalTheme.backgroundMainOffYellow.color,
                  },

                  "& .MuiPaginationItem-root": {
                    color: globalTheme.backgroundMainOffYellow.color,
                  },
                  "& .MuiPaginationItem-page.Mui-selected": {
                    backgroundColor: globalTheme.backgroundMainOffYellow.color,
                    color: globalTheme.backgroundMainYellow.color,
                  },
                }}
                size="small"
              />
            )}
          <Dialog
            sx={{
              ...standardDialogRoot,
              "& .MuiDialog-paper": {
                ...standardDialogPaper,
                backgroundColor: globalTheme.backgroundMainBeige.color,
                color: globalTheme.backgroundMainOffBeige.color,
                border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
              },
            }}
            onClose={() => adminState.setActiveListing("")}
            open={listingDialogOpen}
          >
            <IconButton
              sx={{
                color: globalTheme.textMainGrey.color,
                width: "30px",
                height: "30px",
                position: "absolute",
                top: "25px",
                right: "15px",
              }}
              size="small"
              onClick={() => adminState.setActiveListing("")}
            >
              <CloseOutlined />
            </IconButton>
            <DialogContent
              sx={{
                marginTop: "0px",
                paddingTop: "0px",
                paddingBottom: "50px",
              }}
            >
              {adminState.activeListing !== "" && (
                <AdminListingDialog
                  lastUpdatedAt={lastUpdatedAt}
                  listingId={adminState.activeListing}
                />
              )}
            </DialogContent>
          </Dialog>
          <Dialog
            sx={{
              zIndex: 9997,
              "& .MuiPaper-root": {
                width: "90vw",
                maxWidth: "600px",
                height: "70vh",
                backgroundColor: globalTheme.backgroundMainBeige.color,
              },
            }}
            onClose={() => setNewListingDialogOpen(false)}
            open={newListingDialogOpen}
          >
            <AdminNewListingDialog closeDialog={setNewListingDialogOpen} />
          </Dialog>
        </div>
      ) : (
        <div className="AdminListingsLoading">
          <p
            className="AdminDescription"
            style={{ ...globalTheme.altTextColorDarker }}
          >
            No listings found
          </p>
        </div>
      )}
      <Dialog
        open={sortDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSortDialogOpen(false)}
        sx={styles.dialogRoot}
      >
        <DialogTitle sx={styles.dialogTitle}>{"Sort by"}</DialogTitle>
        <DialogContent>
          <div className="UserListSortDialog">
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                exploreState.setSortType("relevance");
                exploreState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffYellow.color }}
                >
                  Relevance
                </p>
                <Radio
                  checked={exploreState.sortType === "relevance"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                exploreState.setSortType("createdAt");
                exploreState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffYellow.color }}
                >
                  Latest
                </p>
                <Radio
                  checked={exploreState.sortType === "createdAt"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                exploreState.setSortType("rating");
                exploreState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffYellow.color }}
                >
                  Rating
                </p>
                <Radio
                  checked={exploreState.sortType === "rating"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            {/*<ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                exploreState.setSortType("trendingHour");
                exploreState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffYellow.color }}
                >
                  Trending
                </p>
                <Radio
                  checked={exploreState.sortType === "trendingHour"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                exploreState.setSortType("viewsTotal");
                exploreState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffYellow.color }}
                >
                  Views
                </p>
                <Radio
                  checked={exploreState.sortType === "viewsTotal"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>*/}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

/*
<div
                onClick={async () => {
                  adminState.setActiveListing(listing.id);
                  try {
                    await aa("clickedObjectIDsAfterSearch", {
                      index: "gath3r_listings",
                      eventName: "Listing Clicked after Search",
                      queryID: listing.searchId,
                      objectIDs: [listing.id],
                      positions: [index + 1],
                    });
                  } catch (error) {
                    console.error(error);
                    console.log(error);
                  }
                }}
                className="AdminListItemOuter"
                key={index}
              >
                <ProjectListItem
                    listItem={listing}
                    indexKey={index}
                    itemSize="small"
                    />
              </div>
*/
