
/**
 * UserVerifiedBadge component renders an SVG badge indicating user verification.
 *
 * The badge is styled using the "StandardBadge" class and includes a checkmark
 * icon within a shield-like shape. The SVG is designed to be scalable and 
 * responsive.
 *
 * @returns {JSX.Element} The SVG element representing the user verified badge.
 * 
 * @author 
 * @name jimmybengtsson (itchy-fingers)
 * 
 */
import React from "react";
import "./Badges.css";

function UserVerifiedBadge() {
  return (
    <svg
      className="StandardBadge"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 41 40"
    >
      <g clipPath="url(#clip0_210_9975)">
        <path
          fill="#48A8D1"
          d="M40.048 21.27l-3.105-3.678a2.134 2.134 0 01-.371-2.108l1.659-4.518a2.13 2.13 0 00-1.636-2.832l-4.742-.823a2.132 2.132 0 01-1.64-1.376L28.58 1.409a2.13 2.13 0 00-3.074-1.12l-4.161 2.418a2.13 2.13 0 01-2.141 0L15.043.29a2.13 2.13 0 00-3.074 1.119l-1.634 4.526a2.133 2.133 0 01-1.64 1.376l-4.74.823a2.13 2.13 0 00-1.637 2.832l1.66 4.518c.263.72.122 1.523-.372 2.108L.5 21.27a2.134 2.134 0 00.568 3.224l4.177 2.394a2.13 2.13 0 011.069 1.853l-.013 4.814a2.13 2.13 0 002.506 2.102l4.736-.85a2.126 2.126 0 012.011.733l3.085 3.694a2.128 2.128 0 003.27 0l3.084-3.694a2.127 2.127 0 012.011-.733l4.738.85a2.132 2.132 0 002.506-2.102l-.015-4.814a2.128 2.128 0 011.072-1.853l4.173-2.394a2.131 2.131 0 00.569-3.224zm-10.92-8.681l-10.944 15.28-.114.158c-.722 1.05-2.355 1.041-3.075 0l-3.521-4.921c-.752-1.072.555-2.368 1.616-1.631l3.126 2.201 11.55-12.208c.758-.778 1.985.222 1.363 1.12z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_210_9975">
          <path fill="#fff" d="M0 0H40.551V40H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default UserVerifiedBadge;
