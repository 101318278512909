/**
 * The `UserStart` component serves as the main entry point for user-related routes in the application.
 * It utilizes `react-router-dom` to define various routes and their corresponding components.
 *
 * Routes:
 * - `/` renders the `UserList` component.
 * - `/page/:userId` renders the `UserPageV2` component, where `userId` is a dynamic parameter.
 * - `/listings/:userId` renders the `UserListings` component, where `userId` is a dynamic parameter.
 * - `/follow/*` renders the `UserFollowStart` component, handling any sub-routes under `/follow`.
 * - `*` (wildcard) renders the `UserList` component for any undefined routes.
 *
 * @returns {JSX.Element} The rendered component with defined routes.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { Routes, Route } from "react-router-dom";

import UserList from './list/UserList';
import UserPageV2 from './page/UserPageV2';
import UserListings from './listings/UserListings';
import UserFollowStart from './follow/UserFollowStart';
import './UserStart.css';

export default function UserStart() {

  return (
    <div className="UserStart" >
      <Routes>
        <Route path="/" element={<UserList />} />
        <Route path="/page/:userId" element={<UserPageV2 />} />
        <Route path="/listings/:userId" element={<UserListings />} />
        <Route path="/follow/*" element={<UserFollowStart />} />
        <Route path="*" element={<UserList />} />
      </Routes>
    </div>
  );
}
