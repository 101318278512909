/**
 * AdminNewsLinkItem component renders an item representing an RSS link with delete functionality.
 * 
 * @param {Object} props - The component props.
 * @param {any} props.rssLink - The RSS link object containing the link and key.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @component
 * 
 * @example
 * // Example usage:
 * <AdminNewsLinkItem rssLink={{ key: '123', link: 'https://example.com/rss' }} />
 * 
 * @remarks
 * This component uses Firebase Realtime Database to remove the RSS link.
 * It also uses Material-UI components for styling and theming.
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState } from "react";
import { ref, remove } from "firebase/database";
import { firebaseRlDb } from "../../../components/firebase/database";
import { useThemeState } from "../../../components/utils/globalStates";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import "./ListingItems.css";

export default function AdminNewsLinkItem({ rssLink }: any) {
  const [verifyDelete, setVerifyDelete] = useState(false);
  const globalTheme = useThemeState((state) => state.globalTheme);

  const onDelete = async () => {
    setVerifyDelete(false);
    const linkRef = ref(firebaseRlDb, "private/rssLinks/" + rssLink.key);
    await remove(linkRef);
  };
  return !verifyDelete ? (
    <div
      className="AdminNewsLinkItem"
      style={{
        borderBottom: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
      }}
    >
      <div className="AdminUserPreRegItemInner" style={{
        color: globalTheme.textMainBlack.color,
      }}>
        <p className="AdminUserPreRegItemText">
          {rssLink.link}
        </p>
      </div>
      <IconButton
        aria-label="delete"
        color="inherit"
        sx={{ marginRight: "3%", color: globalTheme.colorPaletteRed.color  }}
        size="small"
        onClick={() => setVerifyDelete(true)}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  ) : (
    <div
      className="AdminNewsLinkItem"
      style={{
        borderBottom: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
      }}
    >
      <div className="AdminUserPreRegItemInner" style={{
        color: globalTheme.textMainBlack.color,
      }}>
        <p className="AdminUserPreRegItemText">
          Delete link?
        </p>
      </div>
      <>
        <Button
          size="small"
          sx={{
            color: globalTheme.backgroundMainOffBeige.color,
            marginRight: "5px",
            fontSize: "13px",
            "&:hover": {
              opacity: 0.9,
            },
          }}
          color="inherit"
          onClick={() => setVerifyDelete(false)}
        >
          Cancel
        </Button>
        <Button
          size="small"
          sx={{
            color: globalTheme.colorPaletteRed.color,
            marginRight: "10px",
            fontSize: "13px",
            "&:hover": {
              opacity: 0.9,
            },
          }}
          color="inherit"
          onClick={onDelete}
        >
          Delete
        </Button>
      </>
    </div>
  );
}