/**
 * A React functional component that renders a list of social media icons with links.
 * Each icon is wrapped in an anchor tag that opens the corresponding social media page in a new tab.
 * 
 * @param {Object} props - The component props.
 * @param {string} [props.customColor] - An optional custom color to apply to the social media icons.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @author 
 * @name jimmybengtsson (itchy-fingers)
 */
import InstagramLogo from "../img/socials/InstagramLogo";
import FacebookLogo from "../img/socials/FacebookLogo";
import XLogo from "../img/socials/Xlogo";
import TelegramLogo from "../img/socials/TelegramLogo";
import SubstackLogo from "../img/socials/SubstackLogo";
import './Misc.css';

export default function Socials({customColor}: {customColor?: string;}) {
  return (
      <div className="socials">
        <a
          href="https://instagram.com/gath3rco"
          target="_blank"
          rel="noopener noreferrer"
          className="socialLink"
        >
          <InstagramLogo customColor={customColor}/>
        </a>
        <a
          href="https://facebook.com/gath3rco"
          target="_blank"
          rel="noopener noreferrer"
          className="socialLink"
        >
          <FacebookLogo customColor={customColor}/>
        </a>
        <a
          href="https://twitter.com/gath3rco"
          target="_blank"
          rel="noopener noreferrer"
          className="socialLink"
        >
          <XLogo customColor={customColor}/>
        </a>
        <a href="https://T.me/gath3rco "
          target="_blank"
          rel="noopener noreferrer"
          className="socialLink"
        >
          <TelegramLogo customColor={customColor}/>
        </a>
        <a href="https://substack.com/@gath3rco"
          target="_blank"
          rel="noopener noreferrer"
          className="socialLink"
        >
          <SubstackLogo customColor={customColor}/>
        </a>
      </div>
  );
}
