/**
 * UsersWidget component displays a list of users based on various filters.
 *
 * @param {string} bgColorCode - The background color code for the widget.
 * @param {string} listingsFilter - The filter applied to the listings.
 * @param {boolean} isEditing - Flag indicating if the widget is in editing mode.
 * @param {Function} deleteWidget - Function to delete the widget.
 * @param {any} fulldata - Full data object containing filter information.
 * @param {number} elementIndex - Index of the element in the row.
 * @param {number} rowIndex - Index of the row.
 *
 * @returns {JSX.Element} The UsersWidget component.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";

import { IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import UsersWidgetItem from "./components/UsersWidgetItem";
import { useThemeState } from "../../../components/utils/globalStates";
import {
  algoliaUsersCreatedAtDesc,
  algoliaUsersLastActiveDesc,
  algoliaUsersPointsDayDesc,
  algoliaUsersPointsDesc,
  algoliaUsersPointsHourDesc,
  algoliaUsersPointsWeekDesc,
} from "../../../components/search/algoliaSearch";
import "./DashWidgets.css";

export default function UsersWidget({
  bgColorCode,
  listingsFilter,
  isEditing,
  deleteWidget,
  fulldata,
  elementIndex,
  rowIndex,
}: {
  bgColorCode: string;
  listingsFilter: string;
  isEditing: boolean;
  deleteWidget: Function;
  fulldata: any;
  elementIndex: number;
  rowIndex: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [widgetTitle, setWidgetTitle] = useState("Users");
  const [isDeleteing, setIsDeleting] = useState(false);
  const [widgetList, setWidgetList] = useState([null, null, null, null, null]);
  // On delete
  const onDelete = () => {
    setIsDeleting(true);
    deleteWidget(rowIndex, elementIndex);
  };

  useEffect(() => {
    if (fulldata.filter === "createdAt") {
      setWidgetTitle("New Users");
    } else if (fulldata.filter === "points") {
      setWidgetTitle("Top Users");
    } else if (fulldata.filter === "lastActive") {
      setWidgetTitle("Active Users");
    } else if (fulldata.filter === "latestHourPoints") {
      setWidgetTitle("Top Users (Last Hour)");
    } else if (fulldata.filter === "latestDayPoints") {
      setWidgetTitle("Top Users (Last Day)");
    } else if (fulldata.filter === "latestWeekPoints") {
      setWidgetTitle("Top Users (Last Week)");
    } else {
      setWidgetTitle("Users");
    }
  }, [fulldata.filter]);

  const fetchPointsTemplate = (fetchObj: any, fromDate: number) => {
    fetchObj
      .search("", {
        hitsPerPage: 5,
        numericFilters: [`pointsUpdated > ${fromDate}`],
      })
      .then((res: any) => {
        let tempArray: any[] = [];
        for (let i = 0; i < res.hits.length; i++) {
          const tempDoc = {
            pfpString: res.hits[i].pfpString,
            displayName: res.hits[i].displayName,
            createdAt: res.hits[i].createdAt,
            lastActive: res.hits[i].lastActive,
            id: res.hits[i].id,
            ratingCount: res.hits[i].ratingCount,
            reviewsCount: res.hits[i].reviewsCount,
            type: fulldata.filter,
            points: res.hits[i].points,
            pointsHour: res.hits[i].latestHourPoints,
            pointsDay: res.hits[i].latestDayPoints,
            pointsWeek: res.hits[i].latestWeekPoints,
          };
          tempArray.push(tempDoc);
        }
        setWidgetList(tempArray);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };
  const fetchTemplate = (fetchObj: any) => {
    fetchObj
      .search("", {
        hitsPerPage: 5,
      })
      .then((res: any) => {
        let tempArray: any[] = [];
        for (let i = 0; i < res.hits.length; i++) {
          const tempDoc = {
            pfpString: res.hits[i].pfpString,
            displayName: res.hits[i].displayName,
            createdAt: res.hits[i].createdAt,
            lastActive: res.hits[i].lastActive,
            id: res.hits[i].id,
            ratingCount: res.hits[i].ratingCount,
            reviewsCount: res.hits[i].reviewsCount,
            type: fulldata.filter,
            points: res.hits[i].points,
            pointsHour: res.hits[i].latestHourPoints,
            pointsDay: res.hits[i].latestDayPoints,
            pointsWeek: res.hits[i].latestWeekPoints,
            listingCount: res.hits[i].listingCount,
          };
          tempArray.push(tempDoc);
        }
        setWidgetList(tempArray);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (fulldata.filter === "lastActive") {
      fetchTemplate(algoliaUsersLastActiveDesc);
    } else if (fulldata.filter === "points") {
      fetchTemplate(algoliaUsersPointsDesc);
    } else if (fulldata.filter === "latestHourPoints") {
      fetchPointsTemplate(algoliaUsersPointsHourDesc, Date.now() - 3600000);
    } else if (fulldata.filter === "latestDayPoints") {
      fetchPointsTemplate(algoliaUsersPointsDayDesc, Date.now() - 86400000);
    } else if (fulldata.filter === "latestWeekPoints") {
      fetchPointsTemplate(algoliaUsersPointsWeekDesc, Date.now() - 604800000);
    } else {
      fetchTemplate(algoliaUsersCreatedAtDesc);
    }
  }, [fulldata.filter]);

  return (
    <div
      className="ListingsWidget"
      style={{
        color: globalTheme.figmaPrimaryText.color,
        border: isEditing
          ? `1px dashed ${globalTheme.backgroundMainOffYellow.color}`
          : `1px solid ${globalTheme.figmaPrimaryText.color}`,
        borderRadius: isEditing ? "0px" : "8px",
        backgroundColor: bgColorCode,
      }}
    >
      <div
        className="ListingsWidgetHeader"
        style={{
          color: globalTheme.figmaPrimaryText.color,
          borderBottom: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        }}
      >
        <p className="ListingsWidgetTitle">{widgetTitle}</p>
      </div>
      <div className="ListingsWidgetInner">
        {widgetList.map((item, index) => (
          <UsersWidgetItem isLink={true} dataToRender={item} key={index} />
        ))}
      </div>
      {isEditing && (
        <IconButton
          size="small"
          sx={{
            position: "absolute",
            right: "-1px",
            top: "-1px",
            padding: "2px",
            borderRadius: "0px 0px 0px 5px",
            width: "20%",
            color: globalTheme.backgroundMainOffYellow.color,
            backgroundColor: globalTheme.backgroundMainYellow.color,
            border: `1px dashed ${globalTheme.backgroundMainOffYellow.color}`,
            "&:hover": {
              backgroundColor: globalTheme.backgroundMainYellow.color,
            },
          }}
          onClick={onDelete}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      )}
    </div>
  );
}
