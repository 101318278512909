/**
 * Component for picking a display name during the authentication process.
 * 
 * This component allows users to enter a display name, checks the validity and availability of the display name,
 * and updates the user's profile with the chosen display name.
 * 
 * @component
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <PickDisplayname />
 * 
 * @remarks
 * - The component uses Firebase for authentication and Firestore for checking display name availability.
 * - It also uses Algolia for searching existing display names.
 * - The component is styled using Material-UI components and custom styles.
 * 
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import TextField from "@mui/material/TextField";

import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../components/firebase/auth";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { isValidUsername } from "../../components/utils/parsers";
import LogoMask from "../../components/ui/img/LogoMask";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../components/firebase/functions";

import Tooltip from "@mui/material/Tooltip";

import {
  useThemeState,
  useAuthStates,
} from "../../components/utils/globalStates";
import { algoliaUsedDisplayNames } from "../../components/search/algoliaSearch";
import "./Auth.css";

export default function PickDisplayname() {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [user] = useAuthState(firebaseAuth);
  const authStates = useAuthStates((state) => state);
  const navigate = useNavigate();
  const [displayNameInput, setDisplayNameInput] = useState("");
  const [messageDisplayName, setMessageDisplayName] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);
  const [isDisplayNameTaken, setIsDisplayNameTaken] = useState(false);
  const [isDisplayNameTakenLoading, setIsDisplayNameTakenLoading] =
    useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [isLoginInit, setIsLoginInit] = useState(false);

  useEffect(() => {
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.backgroundMainYellow.color);
    return () => {
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, []);
  useEffect(() => {
    if (
      authStates.userData !== null &&
      authStates.userData !== undefined &&
      authStates.userData.displayName !== null &&
      authStates.userData.displayName !== ""
    ) {
      return navigate("/");
    }
  }, []);

  useEffect(() => {
    if (
      displayNameInput !== "" &&
      !isValidUsername(displayNameInput.toLocaleLowerCase())
    ) {
      setMessageDisplayName(true);
      setIsDisplayNameTaken(true);
      setInputMessage("Please enter a valid display name (4-20 characters)");
    } else if (displayNameInput === '') {
        setMessageDisplayName(true);
        setIsDisplayNameTaken(true);
        setInputMessage("Please enter a display name");
    } else {
      // Search in algoliaUsedDisplaynames and check if displayname is taken
      setIsDisplayNameTakenLoading(true);
      algoliaUsedDisplayNames
        .search(displayNameInput)
        .then((res) => {
          console.log(res);
          if (res.hits.length > 0) {
            const resultArray: any[] = res.hits;
            // Check if any of the hits are the same as the input in lowercase
            for (let i = 0; i < resultArray.length; i++) {
              if (
                resultArray[i].displayName.toLowerCase() ===
                displayNameInput.toLowerCase()
              ) {
                setIsDisplayNameTaken(true);
                setMessageDisplayName(true);
                setInputMessage("Display name is already taken");
                break;
              } else {
                setIsDisplayNameTaken(false);
                setMessageDisplayName(false);
                setInputMessage(" ");
              }
            }
          } else {
            setIsDisplayNameTaken(false);
            setMessageDisplayName(false);
            setInputMessage(" ");
          }
          setIsDisplayNameTakenLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsDisplayNameTakenLoading(false);
        });
    }
  }, [displayNameInput]);

  //  set displayname on init
  useEffect(() => {
    if (user === null || user === undefined) return;
    setDisplayNameInput(user.displayName || "");
  }, [user]);

  const handleDisplayName = async () => {
    if (user === null || user === undefined) {
      return;
    }
    if (messageDisplayName) return;
    if (displayNameInput === "") {
      setErrorMessage("Please enter a display name");
      return;
    }
    if (authStates.userData === null || authStates.userData === undefined)
      return;
    setAuthLoading(true);
    try {
      const updateName = httpsCallable(
        firebaseFunctions,
        "auth-social_init"
      );
      const tokenCheck = await user.getIdToken();
      await updateName({
        name: displayNameInput,
        token: tokenCheck,
      });
      setAuthLoading(false);
      return navigate("/");
    } catch (error) {
      setErrorMessage("Error setting display name");
      console.error(error);
      setAuthLoading(false);
    }
  };

  const styles = {
    input: {
      backgroundColor: "transparent",
      color: globalTheme.textMainBlack.color + " !important",
      borderColor: globalTheme.textMainBlack.color + " !important",
      WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
      "& .MuiInput-root": {
        color: globalTheme.textMainBlack.color + " !important",
        borderColor: globalTheme.textMainBlack.color + " !important",
        WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        "& fieldset": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&:after": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&.Mui-focused fieldset": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
      },
      "& .MuiInputLabel-root": {
        color: globalTheme.textMainBlack.color + " !important",
      },
    },
    inputOTP: {
      width: "90%",
      backgroundColor: "transparent",
      color: globalTheme.textMainBlack.color + " !important",
      borderColor: globalTheme.textMainBlack.color + " !important",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "5px",
          color: globalTheme.textMainBlack.color + " !important",
          border: `1px solid ${globalTheme.textMainBlack.color}`,
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "& input": {
          textAlign: "center !important",
          fontSize: "20px",
          letterSpacing: "4px",
          fontWeight: 600,
          color: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&:after": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&.Mui-focused fieldset": {
          color: globalTheme.textMainBlack.color + " !important",
          border: `1px solid ${globalTheme.textMainBlack.color}`,
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
      },
    },
    button: {
      width: "100%",
      borderRadius: "10px",
      marginTop: "20px",
      backgroundColor: globalTheme.coreOrange.color,
      color: globalTheme.coreOrangeOff.color,
      textTransform: "none",
      boxShadow: globalTheme.boxShadow.boxShadow,
      border: `1px solid ${globalTheme.coreOrangeOff.color}`,
      "&.Mui-disabled": {
        borderRadius: "10px",
        backgroundColor: globalTheme.coreOrange.color,
        color: globalTheme.coreOrangeOff.color,
        opacity: 0.96,
        border: `1px solid ${globalTheme.coreOrangeOff.color}`,
      },
      "&:hover": {
        backgroundColor: globalTheme.coreOrange.color,
        color: globalTheme.coreOrangeOff.color,
        opacity: 0.96,
        border: `1px solid ${globalTheme.coreOrangeOff.color}`,
      },
    },
    buttonError: {
      marginTop: "34px",
      textTransform: "none",
      color: globalTheme.textColor.color,
      "&:hover": {
        color: globalTheme.textColor.color,
      },
    },

    iconButton: {
      borderRadius: "10px",
      backgroundColor: globalTheme.boxBackgroundWhite.color,
      color: globalTheme.textMainBlack.color,
      marginBottom: "25px",
      border: `1px solid ${globalTheme.textMainBlack.color}`,
      "&:hover": {
        backgroundColor: globalTheme.boxBackgroundWhite.color,
        color: globalTheme.textMainBlack.color,
        opacity: 0.96,
        border: `1px solid ${globalTheme.textMainBlack.color}`,
      },
    },
    iconButtonGoogle: {
      marginTop: "25px",
      width: "86%",
      height: "44px",
      borderRadius: "10px",
      textTransform: "none",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      border: `1px solid ${globalTheme.textMainBlack.color}`,
    },
  };

  return (
    <div
      className="LoginViewOuter"
      style={{ backgroundColor: globalTheme.backgroundMainYellow.color, zIndex: 9999}}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Pick Displayname</title>
      </Helmet>
      <div className="LoginViewAlt">
        <div className="AuthIconStart">
          <div className="AuthIcon">
            <LogoMask
              fillColor={globalTheme.backgroundMainYellow.color}
              strokeColor={globalTheme.backgroundMainOffYellow.color}
            />
          </div>
        </div>
        <div className="AuthStartButtons">
          <div
            className="AuthStartButtonsInner"
            style={{
              boxShadow: globalTheme.boxShadow.boxShadow,
              backgroundColor: globalTheme.boxBackgroundWhite.color,
              border: `1px solid ${globalTheme.loginBoxBorder.color}`,
              transform: isLoginInit ? "translateX(-100vw)" : "translateX(0vw)",
            }}
          >
            <p
              className="AuthText"
              style={{
                color: globalTheme.textMainBlack.color,
                flex: "unset",
                marginBottom: "20px",
                maxWidth: "80%",
              }}
            >
              Please enter a Display Name to continue
            </p>
            <div className="textfieldOuter">
              <TextField
                sx={styles.input}
                label="Display Name"
                placeholder={user?.displayName || "Display Name"}
                value={displayNameInput}
                type="name"
                fullWidth
                variant="standard"
                disabled={authLoading}
                onChange={(e) => setDisplayNameInput(e.target.value)}
                InputProps={{
                  endAdornment: isDisplayNameTakenLoading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : isDisplayNameTaken ? (
                    <Tooltip title={inputMessage} arrow>
                      <div
                        style={{
                          height: "10px",
                          width: "10px",
                          borderRadius: "50%",
                          backgroundColor: "#FF0000",
                          opacity: 0.5,
                        }}
                      ></div>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Display name is available" arrow>
                      <div
                        style={{
                          height: "10px",
                          width: "10px",
                          borderRadius: "50%",
                          backgroundColor: "#00FF00",
                          opacity: 0.5,
                        }}
                      ></div>
                    </Tooltip>
                  ),
                }}
              />
              <p
                className="InputText"
                style={{ color: globalTheme.textMainBlack.color }}
              >
                {inputMessage}
              </p>
            </div>
          </div>
          <Button
            sx={styles.button}
            size="large"
            color="inherit"
            variant="outlined"
            disabled={messageDisplayName || authLoading}
            onClick={handleDisplayName}
          >
            {authLoading ? (
              <CircularProgress
                color="inherit"
                size={20}
                sx={{ paddingTop: "3px", paddingBottom: "3px" }}
              />
            ) : (
              "Submit"
            )}
          </Button>
          <p
            onClick={() => setErrorMessage("")}
            style={{
              marginTop: "20px",
              cursor: "pointer",
              color: globalTheme.textMainBlack.color,
            }}
            className="ErrorText"
          >
            {errorMessage}
          </p>
        </div>
        <div className="AuthStartFooter">
          <IconButton
            sx={styles.iconButton}
            color="inherit"
            href="https://gath3r.co"
            target="_blank"
            rel="noopener noreferrer"
          >
            <HomeOutlinedIcon fontSize="medium" />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
