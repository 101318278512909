/**
 * AdminUserSearch component allows administrators to search for users.
 * It provides a search input field that dynamically updates the search results
 * based on the user's input. The component adapts its layout for mobile devices
 * by using an Autocomplete component, while on larger screens it uses a simple
 * TextField component.
 *
 * @component
 * @example
 * return (
 *   <AdminUserSearch />
 * )
 *
 * @returns {JSX.Element} The rendered AdminUserSearch component.
 *
 * @remarks
 * The component uses several global states:
 * - `useUserSearchSortState` to manage the search query and sorting type.
 * - `useAdminState` to manage the active user.
 * - `useDeviceState` to determine if the device is mobile.
 * - `useThemeState` to apply theme styles.
 *
 * The search input field is styled according to the global theme and includes
 * icons for search and clear actions. The search results are displayed as a list
 * of buttons, which when clicked, set the active user in the admin state.
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { ButtonBase, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  useDeviceState,
  useThemeState,
  useAdminState,
  useUserSearchSortState,
} from "../../../components/utils/globalStates";
import "../../../components/ui/topBoxes/TopBoxes.css";

export default function AdminUserSearch() {
  const [query, setQuery] = useState("");
  const userListingsSortState = useUserSearchSortState();
  const adminState = useAdminState();
  const deviceState = useDeviceState();
  const globalTheme = useThemeState((state) => state.globalTheme);

  useEffect(() => {
    setQuery(userListingsSortState.search);
  }, [userListingsSortState.search]);

  const styles = {
    exploreSearchForm: {
      "& .MuiOutlinedInput-root": {
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color,
        borderWidth: "1px",
        backgroundColor: globalTheme.figmaWhite.color,
        boxShadow: "none",
        borderRadius: "8px",
        padding: "6px",
        paddingLeft: "14px",
        paddingRight: "14px !important",
        marginTop: "10px",
        "& fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          borderWidth: "1px",
          color: globalTheme.figmaPrimaryText.color,
        },
        "&:hover fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          borderWidth: "1px",
          color: globalTheme.figmaPrimaryText.color,
        },
        "&.Mui-focused fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          color: globalTheme.figmaPrimaryText.color,
        },
      },
      "& label.Mui-focused": {
        color: globalTheme.figmaPrimaryText.color,
      },
      "& label": {
        color: globalTheme.figmaPrimaryText.color,
      },
    },
  };

 return deviceState.isMobile ? (
        <Autocomplete
          freeSolo
          fullWidth
          inputValue={query}
          sx={{
            ...styles.exploreSearchForm,
          }}
          filterOptions={(x) => x}
          getOptionLabel={(option: any) => {
            return option.name;
          }}
          renderOption={(props, option: any) => {
            return (
              query !== "" && (
                <ButtonBase
                  key={option.id}
                  onClick={() => {
                    adminState.setActiveUser(option.id);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "10px",
                    backgroundColor: "#fff",
                  }}
                >
                  <p className="AutocompleteResultTitle">{option.displayName}</p>
                </ButtonBase>
              )
            );
          }}
          options={userListingsSortState.results}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search users"
              onChange={(event) => {
                userListingsSortState.setSortType("relevance");
                userListingsSortState.setSearch(event.target.value);
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon
                      style={{ color: globalTheme.figmaExploreSearch.color }}
                    />
                  </InputAdornment>
                ),
                endAdornment: userListingsSortState.search !== "" && (
                  <InputAdornment position="end">
                    <CloseOutlinedIcon
                      style={{
                        color: globalTheme.figmaExploreSearch.color,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        userListingsSortState.setSortType("createdAt");
                        userListingsSortState.setSearch("");
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      ) : (
        <TextField
          id="outlined-basic"
          variant="outlined"
          fullWidth
          size="small"
          placeholder="Search Users"
          value={userListingsSortState.search}
          sx={styles.exploreSearchForm}
          onChange={(e) => {
            userListingsSortState.setSortType("relevance");
            userListingsSortState.setSearch(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon
                  style={{ color: globalTheme.figmaExploreSearch.color }}
                />
              </InputAdornment>
            ),
            endAdornment: userListingsSortState.search !== "" && (
              <InputAdornment position="end">
                <CloseOutlinedIcon
                  style={{
                    color: globalTheme.figmaExploreSearch.color,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    userListingsSortState.setSortType("createdAt");
                    userListingsSortState.setSearch("");
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      )
}
