/**
 * The `ReviewStart` component is the entry point for the reviews section of the application.
 * It sets up the routes for different review-related views and handles the display of an admin review dialog.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <ReviewStart />
 *
 * @remarks
 * This component uses React Router for routing and Material-UI for the dialog.
 * It also utilizes global state hooks for admin, theme, and authentication states.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";

import { Routes, Route} from "react-router-dom";
import Reviews from "./routes/Reviews";
import ReviewsListing from "./routes/ReviewsListing";
import ReviewsUser from "./routes/ReviewsUser";
import RatingsUser from "./routes/RatingsUser";
import RatingsListing from "./routes/RatingsListing";
import AdminReviewDialog from "../admin/dialogs/AdminReviewDialog";

// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import IconButton from "@mui/material/IconButton";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

import {
  useAdminState,
  useThemeState,
  useAuthStates,
} from "../../components/utils/globalStates";

import {
  standardDialogPaper,
  standardDialogRoot,
} from "../../components/theme/globalMuiTheme";

import "./ReviewStart.css";

export default function ReviewStart() {
  const adminState = useAdminState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const authStates = useAuthStates();
  const [listingDialogOpen, setListingDialogOpen] = useState(false);

  useEffect(() => {
    if (adminState.activeReview !== "") {
      setListingDialogOpen(true);
    } else {
      setListingDialogOpen(false);
    }
  }, [adminState.activeReview]);

  return (
    <div className="ReviewStart">
      <Routes>
        <Route path="/" element={<Reviews />} />
        <Route path="/listing/:listingId" element={<ReviewsListing />} />
        <Route path="/listing/rating/:listingId" element={<RatingsListing />} />
        <Route path="/user/:userId" element={<ReviewsUser />} />
        <Route path="/user/rating/:userId" element={<RatingsUser />} />
        <Route path="*" element={<Reviews />} />
      </Routes>
      {authStates.role === "admin" || authStates.role === "owner" ? (
        <Dialog
          sx={{
            ...standardDialogRoot,
            "& .MuiDialog-paper": {
              ...standardDialogPaper,
              backgroundColor: globalTheme.backgroundMainBeige.color,
              color: globalTheme.backgroundMainOffBeige.color,
              border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
            },
          }}
          onClose={() => adminState.setActiveReview("")}
          open={listingDialogOpen}
        >
          <IconButton
            sx={{
              color: globalTheme.textMainGrey.color,
              width: "30px",
              height: "30px",
              position: "absolute",
              top: "25px",
              right: "15px",
            }}
            size="small"
            onClick={() => adminState.setActiveReview("")}
          >
            <CloseOutlined />
          </IconButton>
          <DialogContent
            sx={{
              marginTop: "0px",
              paddingTop: "0px",
              paddingBottom: "50px",
            }}
          >
            {adminState.activeReview !== "" && (
              <AdminReviewDialog
                reviewId={adminState.activeReview}
                listingName={adminState.activeReviewName}
                userName={adminState.activeReviewUser}
              />
            )}
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  );
}
