/**
 * Component for adding a user widget to the dashboard.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.addWidget - Function to add the widget.
 * @param {number} props.addwidgetRow - The row number to add the widget to.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <AddUserWidget addWidget={addWidgetFunction} addwidgetRow={1} />
 *
 * @remarks
 * This component allows users to add a user widget to the dashboard with various customization options such as widget type and background color.
 *
 * @description
 * The `AddUserWidget` component provides a UI for selecting different types of user widgets (e.g., New Users, Top Users) and a color picker for customizing the widget's background color. It uses Material-UI components for the form controls and styling.
 *
 * @see {@link https://mui.com/} for more information on Material-UI components.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import UsersWidgetItem from "../widgets/components/UsersWidgetItem";
import { ButtonBase } from "@mui/material";
import ColorPicker from "./ColorPicker";
import { useThemeState } from "../../../components/utils/globalStates";
import "./DashboardAddWidget.css";

export default function AddUserWidget({
  addWidget,
  addwidgetRow,
}: {
  addWidget: Function;
  addwidgetRow: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [isExpanded, setIsExpanded] = useState(false);
  const [widgetTitle, setWidgetTitle] = useState("Users");
  const [pickedColor, setPickedColor] = useState(
    globalTheme.figmaPurplePastel.color
  );
  const [widgetType, setWidgetType] = useState("createdAt");

  const handleChange = (event: SelectChangeEvent) => {
    setWidgetType(event.target.value as string);
  };

  // Update widget title on widgetType change
  useEffect(() => {
    if (widgetType === "createdAt") {
      setWidgetTitle("New Users");
    } else if (widgetType === "points") {
      setWidgetTitle("Top Users");
    } else if (widgetType === "lastActive") {
      setWidgetTitle("Active Users");
    } else if (widgetType === "latestHourPoints") {
      setWidgetTitle("Top Users (Last Hour)");
    } else if (widgetType === "latestDayPoints") {
      setWidgetTitle("Top Users (Last Day)");
    } else if (widgetType === "latestWeekPoints") {
      setWidgetTitle("Top Users (Last Week)");
    } else {
      setWidgetTitle("Users");
    }
  }, [widgetType]);

  const onAddWidgetPress = () => {
    const widgetData = {
      filter: widgetType,
      bgColorCode: pickedColor,
      type: "users",
      rowLength: 4,
      uidRequired: true,
    };
    addWidget(widgetData, addwidgetRow);
  };

  const styles = {
    formOuter: {
      marginTop: "15px",
      backgroundColor: "transparent",
      color: globalTheme.figmaPrimaryText.color,
      borderColor: globalTheme.figmaPrimaryText.color + " !important",
      "& .MuiInputLabel-root": {
        color: globalTheme.figmaPrimaryText.color + " !important",
        "& .Mui-focused ": {
          color: globalTheme.figmaPrimaryText.color,
        },
      },
    },
    select: {
      backgroundColor: "transparent",
      color: globalTheme.figmaPrimaryText.color,
      borderColor: globalTheme.figmaPrimaryText.color + " !important",
      "& .MuiSelect-root": {
        "& fieldset": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
        "&:after": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
      },
      "&.Mui-focused fieldset": {
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color + " !important",
      },
      "&.Mui-focused ": {
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color + " !important",
      },
    },
    selectMenu: {
      zIndex: 9999,
      color: globalTheme.figmaPrimaryText.color,
      "& .MuiPaper-root": {
        backgroundColor: "#fff",
        color: globalTheme.figmaPrimaryText.color,
        "& .MuiMenuItem-root": {
          "& .Mui-selected": {
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.altTextColor.color,
          },
        },
        "& .Mui-selected": {
          color: globalTheme.figmaPrimaryText.color,
          backgroundColor: globalTheme.background.backgroundColor,
        },
      },
    },
  };

  return (
    <div
      className="AddWidget"
      style={{
        color: globalTheme.figmaPrimaryText.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        backgroundColor: globalTheme.figmaWhite.color,
      }}
    >
      <ButtonBase
        onClick={() => setIsExpanded(!isExpanded)}
        className="ListingsWidget"
        style={{
          color: globalTheme.figmaPrimaryText.color,
          border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          borderRadius: "8px",
          backgroundColor: pickedColor,
          width: "calc(100% + 2px)",
          marginTop: "-1px",
          marginBottom: "-1px",
        }}
      >
        <div
          className="ListingsWidgetHeader"
          style={{
            color: globalTheme.figmaPrimaryText.color,
            borderBottom: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          }}
        >
          <p className="ListingsWidgetTitle">{widgetTitle}</p>
        </div>
        <div className="ListingsWidgetInner">
          <UsersWidgetItem
            isLink={false}
            dataToRender={{
              photoUrl: "",
              displayName: "GATH3R",
              createdAt: Date.now() - 1000 * 60 * 60 * 24 * 2,
              lastActive: Date.now() - 1000 * 18,
              id: "",
              ratingCount: 123,
              reviewsCount: 92,
              type: widgetType,
              points: 545,
              pointsHour: 12,
              pointsDay: 45,
              pointsWeek: 123,
            }}
          />
        </div>
      </ButtonBase>
      <div
        className="AddWidgetSettingsOuter"
        style={{ maxHeight: isExpanded ? "500px" : "0px" }}
      >
        <div className="AddWidgetSettings">
          <div className="AddWidgetSettingsInner">
            <FormControl fullWidth size="small" sx={styles.formOuter}>
              <InputLabel>Type</InputLabel>
              <Select
                value={widgetType}
                label="Type"
                onChange={handleChange}
                sx={{ ...styles.select }}
                MenuProps={{
                  sx: styles.selectMenu,
                }}
              >
                <MenuItem value={"createdAt"}>New Users</MenuItem>
                <MenuItem value={"lastActive"}>Active Users</MenuItem>
                <MenuItem value={"points"}>Top Users</MenuItem>
                <MenuItem value={"latestHourPoints"}>
                  Top Users (Last Hour)
                </MenuItem>
                <MenuItem value={"latestDayPoints"}>
                  Top Users (Last Day)
                </MenuItem>
                <MenuItem value={"latestWeekPoints"}>
                  Top Users (Last Week)
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <ColorPicker
            setPickedColor={setPickedColor}
            pickedColor={pickedColor}
          />
        </div>
        <ButtonBase
          disabled={widgetType === ""}
          onClick={onAddWidgetPress}
          className="AddWidgetButton"
          style={{
            backgroundColor: globalTheme.figmaPrimaryText.color,
            color: globalTheme.figmaWhite.color,
            width: "100%",
            padding: "2px",
            borderRadius: "0px 0px 6px 6px",
            justifySelf: "flex-end",
          }}
        >
          <p>ADD WIDGET</p>
        </ButtonBase>
      </div>
    </div>
  );
}
