/**
 * Component that displays a rating gauge for a project listing.
 * 
 * @param {Object} props - The component props.
 * @param {any} props.listing - The listing object containing rating information.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * ```tsx
 * <ProjectPageRatingGauge listing={listing} />
 * ```
 * 
 * @remarks
 * This component uses the `react-gauge-component` to display a semicircular gauge
 * representing the rating of a project. The gauge is color-coded based on the rating value.
 * It also displays a textual representation of the rating and additional information such as
 * the number of ratings and reviews.
 * 
 * The component relies on a global theme state to style its elements.
 * 
 * @see {@link https://www.npmjs.com/package/react-gauge-component|react-gauge-component}
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { GaugeComponent } from "react-gauge-component";
import { useThemeState } from "../../../../components/utils/globalStates";
import StarIcon from "@mui/icons-material/Star";
import "./ProjectPageComp.css";

export default function ProjectPageRatingGauge({ listing }: { listing: any }) {
  const globalTheme = useThemeState((state) => state.globalTheme);

  return (
    <div
      className="ProjectPageRatingGauge"
      style={{
        backgroundColor: globalTheme.primarySurface.color,
        border: `1px solid #F8F4EA`,
      }}
    >
      {listing.rating !== undefined &&
      listing.rating !== null &&
      listing.rating > 0 ? (
        <div className="ProjectPageRatingGaugeContainer">
          <GaugeComponent
            className="ProjectPageRatingGaugeContainerGauge"
            arc={{
              padding: 0,
              emptyColor: "#EDEDED",
              gradient: true,
              subArcs: [
                {
                  limit: 0,
                  color: "#FFF5BB",
                },
                {
                  limit: 1,
                  color: "#F7F3BB",
                },
                {
                  limit: 2,
                  color: "#C9EBBE",
                },
                {
                  limit: 3,
                  color: "#73DBC4",
                },
                {
                  limit: 4,
                  color: "#53D5C7",
                },
                {
                  limit: 5,
                  color: "#34CFC8",
                },
              ],
            }}
            value={listing.rating}
            maxValue={5}
            minValue={0}
            pointer={{
              type: "arrow",
              elastic: false,
              color: globalTheme.textMainGrey.color,
              animationDuration: 1000,
              width: 15,
            }}
            labels={{
              tickLabels: { hideMinMax: true },
              valueLabel: {
                matchColorWithArc: true,
                maxDecimalDigits: 1,
                formatTextValue: (value) => {
                  return listing.rating < 1.5
                    ? "Negative"
                    : listing.rating < 3.5
                    ? "Neutral"
                    : "Positive";
                },
                style: {
                  textShadow:
                    "transparent 0px 0px 0px, transparent 0px 0px 0em, transparent 0px 0px 0em",
                  fontSize: "30px",
                  backgroundColor: globalTheme.secondarySurface.color,
                },
              },
            }}
            type="semicircle"
          />
          {/*<svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 180 95"
          >
            <rect
              width="49.924"
              height="49.924"
              x="63.797"
              y="44.982"
              fill="#34CFC8"
              rx="24.962"
            ></rect>
            <path
              fill="#1A1A1A"
              d="M73.702 74.253v-1.805l7.054-10.12h2.897v9.847h1.953v2.078h-1.953v3.191h-2.351v-3.19h-7.6zm7.705-9.489l-4.955 7.41h4.955v-7.41zm7.463 12.827c-.434 0-.798-.147-1.092-.44a1.483 1.483 0 01-.441-1.092c0-.434.147-.798.44-1.092.295-.294.659-.44 1.093-.44.42 0 .776.146 1.07.44.294.294.441.658.441 1.092 0 .434-.147.798-.44 1.091-.295.294-.651.441-1.071.441zm6.365-8.145c-1.455-.728-2.183-1.876-2.183-3.443 0-.756.189-1.449.567-2.079.378-.63.944-1.127 1.7-1.49.756-.378 1.68-.567 2.771-.567 1.078 0 1.995.189 2.751.567.769.364 1.343.86 1.721 1.49.378.63.567 1.323.567 2.079 0 .783-.203 1.476-.609 2.078a3.932 3.932 0 01-1.574 1.365 3.844 3.844 0 011.889 1.448c.462.658.693 1.435.693 2.33 0 .91-.231 1.715-.693 2.415-.462.7-1.106 1.239-1.931 1.617-.826.377-1.764.566-2.814.566s-1.987-.189-2.813-.567c-.812-.377-1.448-.916-1.91-1.616-.462-.7-.693-1.505-.693-2.414 0-.91.23-1.694.693-2.352a3.894 3.894 0 011.868-1.427zm5.606-3.15c0-.783-.245-1.385-.735-1.805-.49-.42-1.162-.63-2.016-.63-.84 0-1.504.21-1.994.63-.49.42-.735 1.029-.735 1.827 0 .7.252 1.273.756 1.721.518.434 1.176.65 1.973.65.798 0 1.456-.223 1.974-.67.518-.449.777-1.023.777-1.722zm-2.75 4.2c-.924 0-1.68.23-2.268.692-.574.448-.86 1.099-.86 1.953 0 .797.28 1.448.84 1.952.559.49 1.322.735 2.287.735.952 0 1.7-.252 2.247-.756.56-.504.84-1.148.84-1.931 0-.84-.287-1.49-.861-1.953-.56-.462-1.302-.693-2.226-.693z"
            ></path>
            <path
              stroke="#EDEDED"
              strokeLinecap="round"
              strokeWidth="9.237"
              d="M175.076 89.92c0-46.849-37.978-84.827-84.826-84.827-46.848 0-84.826 37.978-84.826 84.826"
            ></path>
            <path
              stroke="url(#paint0_linear_274_11171)"
              strokeLinecap="round"
              strokeWidth="9.237"
              d="M175.076 89.92c0-46.849-37.978-84.827-84.826-84.827-46.848 0-84.826 37.978-84.826 84.826"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear_274_11171"
                x1="5.424"
                x2="166.25"
                y1="47.506"
                y2="47.506"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFF4BA"></stop>
                <stop offset="1" stopColor="#34CFC8"></stop>
              </linearGradient>
            </defs>
          </svg>*/}
          <p className="ProjectPageRatingGaugeContainerTitle" style={{color: globalTheme.textMainGrey.color}}>
            {listing.rating < 1.5
              ? "Very negative feedback from the community"
              : listing.rating < 2
              ? "Mostly negative feedback from the community"
              : listing.rating < 3.5
              ? "Neutral feedback from the community"
              : listing.rating < 4.5
              ? "Mostly positive feedback from the community"
              : "Very positive feedback from the community"}
          </p>
          <div className="ProjectPageRatingGaugeContainerFooter" style={{color: globalTheme.textMainBlack.color}}>
            <div className="ProjectPageRatingGaugeContainerFooterItem">
              <p>{Number(listing.rating).toFixed(1)}</p>
              <StarIcon style={{ color: "#F7D100" }} />
            </div>
            <div className="ProjectPageRatingGaugeContainerFooterItem">
              <p>{listing.ratingCount} Ratings</p>
            </div>
            <div className="ProjectPageRatingGaugeContainerFooterItem">
              <p>{listing.reviewsCount} Reviews</p>
            </div>
          </div>
        </div>
      ) : (
        <p
          className="ProjectPageRatingGaugeErrorText"
          style={{ color: globalTheme.textMainGrey.color }}
        >
          No rating available
        </p>
      )}
    </div>
  );
}
