/**
 * WalletPage component displays the details of a specific wallet including its balance, activity feed, and NFT gallery.
 * It fetches and displays wallet information such as wallet name, balance, and activity feed (mints, trades, donations, posts, comments).
 * It also allows filtering the activity feed based on different types of activities.
 *
 * @component
 * @example
 * return (
 *   <WalletPage />
 * )
 *
 * @returns {JSX.Element} The WalletPage component.
 *
 * @remarks
 * This component uses several hooks and external libraries to fetch and display wallet data:
 * - `useParams` and `useNavigate` from `react-router-dom` for routing.
 * - `useSnackbarErrorState`, `useTabSettingsState`, `useHeaderSettingsState`, `useThemeState` from global state management.
 * - `useState` and `useEffect` from React for state management and side effects.
 * - `moment` for date formatting.
 * - `alchemyEth` and `web3Provider` for interacting with the Ethereum blockchain.
 *
 * @todo
 * - Improve error handling and user feedback.
 * - Optimize performance for large data sets.
 * - Add more detailed documentation for each function and state variable.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import React, { useState, useEffect } from "react";

import ButtonBase from "@mui/material/ButtonBase";
// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Skeleton from "@mui/material/Skeleton";
import { TransitionProps } from "@mui/material/transitions";
import Radio from "@mui/material/Radio";
import { CircularProgress, Avatar } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  useSnackbarErrorState,
  useTabSettingsState,
  useHeaderSettingsState,
  useThemeState,
} from "../../../components/utils/globalStates";
import { LazyLoadImg } from "../../../components/ui/misc/LazyLoadImg";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import WalletTradeItem from "../components/WalletTradeItem";
import WalletMintItem from "../components/WalletMintItem";
import WalletGallery from "../components/WalletGallery";
import { alchemyEth } from "../../../components/web3/alchemy";
import { web3Provider } from "../../../components/web3/web3js";
import { isValidEthAddress } from "../../../components/web3/web3js";
import "./Wallets.css";
import "../../user/list/UserList.css";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

moment().format();
const ensContractAddress = "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85";

export default function WalletPage() {
  const { walletId } = useParams();
  const navigate = useNavigate();
  const snackbarError = useSnackbarErrorState((state) => state);
  const tabSettingsState = useTabSettingsState();
  const headerSettingsState = useHeaderSettingsState();
  const globalTheme = useThemeState((state) => state.globalTheme);

  const [isLoading, setIsLoading] = useState(true);
  const [combinedFeed, setCombinedFeed] = useState<any[]>([]);
  const [rss3SocialFeed, setRss3SocialFeed] = useState<any[]>([]);
  const [nftMintFeed, setNftMintFeed] = useState<any[]>([]);
  const [nftBuyFeed, setNftBuyFeed] = useState<any[]>([]);
  const [nftSellFeed, setNftSellFeed] = useState<any[]>([]);

  const [filterString, setFilterString] = useState("mint,trade");
  const [filterStringSend, setFilterStringSend] = useState("mint,trade");
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);

  const [walletName, setWalletName] = useState("");
  const [isWalletNameLoading, setIsWalletNameLoading] = useState(true);
  const [walletBalance, setWalletBalance] = useState<number | null>(null);
  const [isWallelBalanceLoading, setIsWalletBalanceLoading] = useState(true);
  const [verifiedAddress, setVerifiedAddress] = useState("");
  const [isVerifiedAddressLoading, setIsVerifiedAddressLoading] =
    useState(true);

  const [isWalletUrlLoading, setIsWalletUrlLoading] = useState(true);
  const [walletUrl, setWalletUrl] = useState("");
  const [tabValue, setTabValue] = useState(0);

  const checkWallet = async () => {
    if (walletId === undefined || walletId === null || walletId === "") {
      return;
    }
    if (isValidEthAddress(walletId) === false) {
      try {
        const isEns = await web3Provider.eth.ens.getAddress(walletId);
        setWalletName(walletId);
        setIsWalletNameLoading(false);
        setVerifiedAddress(isEns.toString());
        setIsVerifiedAddressLoading(false);
      } catch (error) {
        console.error(error);
        snackbarError.setMessage("Wallet not found");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        return navigate("/wallets/list");
      }
    } else {
      setIsWalletNameLoading(false);
      setVerifiedAddress(walletId);
      setIsVerifiedAddressLoading(false);
    }
  };

  const checkWalletBalance = async () => {
    if (
      verifiedAddress === undefined ||
      verifiedAddress === null ||
      verifiedAddress === ""
    ) {
      return;
    }

    try {
      const balance = await web3Provider.eth.getBalance(verifiedAddress);
      setWalletBalance(Number(web3Provider.utils.fromWei(balance, "ether")));
      setIsWalletBalanceLoading(false);
    } catch (error) {
      console.error(error);
      snackbarError.setMessage("Error fetching wallet balance");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      setIsWalletBalanceLoading(false);
    }
  };

  // Randomize the nft for wallet url from nftMintFeed length when mint feed is fetched

  useEffect(() => {
    if (nftMintFeed.length > 0) {
      let randomNft = Math.floor(Math.random() * nftMintFeed.length);
      let nft = nftMintFeed[randomNft];
      if (nft.image !== undefined && nft.image !== "" && nft.image !== null) {
        setWalletUrl(nft.image);
        setIsWalletUrlLoading(false);
      } else {
        setIsWalletUrlLoading(false);
      }
    }
  }, [nftMintFeed]);

  useEffect(() => {
    checkWallet();
  }, [walletId]);

  useEffect(() => {
    checkWalletBalance();
  }, [verifiedAddress]);

  const filterNames = () => {
    const filterArr = filterString.split(",");
    if (
      filterArr.includes("mint") &&
      filterArr.includes("donate") &&
      filterArr.includes("post") &&
      filterArr.includes("comment") &&
      filterArr.includes("trade")
    ) {
      return "All";
    }

    if (filterArr.length === 1) {
      return filterArr[0].charAt(0).toUpperCase() + filterArr[0].slice(1);
    }
    let returnString = "";
    for (let i = 0; i < filterArr.length; i++) {
      if (i === filterArr.length - 1) {
        returnString +=
          " & " + filterArr[i].charAt(0).toUpperCase() + filterArr[i].slice(1);
      } else if (i === filterArr.length - 2) {
        returnString +=
          filterArr[i].charAt(0).toUpperCase() + filterArr[i].slice(1);
      } else {
        returnString +=
          filterArr[i].charAt(0).toUpperCase() + filterArr[i].slice(1) + ", ";
      }
    }
    return returnString;
  };

  const onFilterClick = (filter: string) => {
    const filterArr = filterString.split(",");
    if (filterArr.length === 1 && filterArr[0] === filter) {
      return;
    }
    if (filterArr.includes(filter)) {
      const newFilterArr = filterArr.filter((item) => item !== filter);
      setFilterString(newFilterArr.join(","));
    } else {
      filterArr.push(filter);
      setFilterString(filterArr.join(","));
    }
  };

  const onFilterSend = () => {
    setFilterStringSend(filterString);
    setFilterDialogOpen(false);
  };

  const fetchRss3SocialFeed = async () => {
    if (walletId === undefined || walletId === null || walletId === "") {
      return [];
    }

    let tempTypeArray: any[] = [];
    let tempTypeString = filterStringSend.split(",");
    for (let i = 0; i < tempTypeString.length; i++) {
      if (
        tempTypeString[i] === "donate" ||
        tempTypeString[i] === "post" ||
        tempTypeString[i] === "comment"
      ) {
        tempTypeArray.push(tempTypeString[i]);
      }
    }
    if (tempTypeArray.length === 0) {
      return [];
    }

    try {
      const options = {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          action_limit: 10,
          limit: 15,
          account: [walletId],
          direction: "out",
          type: tempTypeArray,
          tag: ["donation", "social"],
        }),
      };

      const response = await fetch(
        "https://testnet.rss3.io/data/accounts/activities",
        options
      );
      const data = await response.json();
      let tempArray: any[] = [];
      for (let i = 0; i < data.data.length; i++) {
        for (let j = 0; j < data.data[i].actions.length; j++) {
          if (data.data[i].actions[j].type === "donate") {
            tempArray.push({
              type: "donate",
              timestamp: data.data[i].timestamp * 1000,
              metadata: data.data[i].actions[j].metadata,
              relatedUrls: data.data[i].actions[j].related_urls,
              network: data.data[i].network,
              tag: data.data[i].tag,
              platform: data.data[i].actions[j].platform,
            });
          } else if (data.data[i].actions[j].type === "post") {
            tempArray.push({
              type: "post",
              timestamp: data.data[i].timestamp * 1000,
              metadata: data.data[i].actions[j].metadata,
              relatedUrls: data.data[i].actions[j].related_urls,
              network: data.data[i].network,
              tag: data.data[i].tag,
              platform: data.data[i].actions[j].platform,
            });
          } else if (data.data[i].actions[j].type === "comment") {
            tempArray.push({
              type: "comment",
              timestamp: data.data[i].timestamp * 1000,
              metadata: data.data[i].actions[j].metadata,
              relatedUrls: data.data[i].actions[j].related_urls,
              network: data.data[i].network,
              tag: data.data[i].tag,
              platform: data.data[i].actions[j].platform,
            });
          }
        }
      }
      setRss3SocialFeed(tempArray);
      return tempArray;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchNftMints = async () => {
    if (walletId === undefined || walletId === null || walletId === "") {
      return [];
    }

    if (filterStringSend.includes("mint") === false) {
      return [];
    }

    if (nftMintFeed.length > 0) {
      return nftMintFeed;
    }

    try {
      const options = {
        tokenType: "ERC721",
      };
      // @ts-ignore
      const mintedNfts = await alchemyEth.nft.getMintedNfts(walletId, options);
      const tempArray: any[] = [];
      for (let i = 0; i < mintedNfts.nfts.length; i++) {
        if (
          mintedNfts.nfts[i].image.cachedUrl !== undefined &&
          mintedNfts.nfts[i].contract.isSpam !== true &&
          mintedNfts.nfts[i].raw.metadata !== undefined &&
          mintedNfts.nfts[i].raw.metadata.name !== undefined &&
          mintedNfts.nfts[i].blockNumber !== undefined
        ) {
          let tempDate = new Date(mintedNfts.nfts[i].timeLastUpdated).getTime();
          tempArray.push({
            type: "mint",
            metadata: mintedNfts.nfts[i].raw.metadata,
            collection: mintedNfts.nfts[i].collection,
            contract: mintedNfts.nfts[i].contract,
            image: mintedNfts.nfts[i].image.cachedUrl,
            tokenUri: mintedNfts.nfts[i].tokenUri,
            name: mintedNfts.nfts[i].name,
            description: mintedNfts.nfts[i].description,
            blockNumber: Number(mintedNfts.nfts[i].blockNumber),
            timestamp: tempDate,
            tokenId: mintedNfts.nfts[i].tokenId,
          });
        }
      }

      setNftMintFeed(tempArray);
      return tempArray;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchNftBuy = async () => {
    if (walletId === undefined || walletId === null || walletId === "") {
      return [];
    }

    if (filterStringSend.includes("trade") === false) {
      return [];
    }

    if (nftBuyFeed.length > 0) {
      return nftBuyFeed;
    }

    try {
      // @ts-ignore
      const boughtNft = await alchemyEth.nft.getNftSales({
        buyerAddress: walletId,
        limit: 20,
        // @ts-ignore
        order: "desc",
      });
      let tempArray: any[] = [];
      let contractArray: any[] = [];
      for (let i = 0; i < boughtNft.nftSales.length; i++) {
        contractArray.push({
          contractAddress: boughtNft.nftSales[i].contractAddress,
          tokenId: boughtNft.nftSales[i].tokenId,
        });
      }
      const nftMetadata = await alchemyEth.nft.getNftMetadataBatch(
        contractArray
      );
      for (let i = 0; i < boughtNft.nftSales.length; i++) {
        let tempDate = new Date(nftMetadata.nfts[i].timeLastUpdated).getTime();
        tempArray.push({
          ...boughtNft.nftSales[i],
          type: "trade",
          metadata: nftMetadata.nfts[i],
          seller: false,
          blockNumber: Number(boughtNft.nftSales[i].blockNumber),
          timestamp: tempDate,
        });
      }
      setNftBuyFeed(tempArray);
      return tempArray;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchNftSales = async () => {
    if (walletId === undefined || walletId === null || walletId === "") {
      return [];
    }

    if (filterStringSend.includes("trade") === false) {
      return [];
    }

    if (nftSellFeed.length > 0) {
      return nftSellFeed;
    }

    try {
      // @ts-ignore
      const soldNfts = await alchemyEth.nft.getNftSales({
        sellerAddress: walletId,
        limit: 20,
        // @ts-ignore
        order: "desc",
      });
      let tempArray: any[] = [];
      let contractArray: any[] = [];
      for (let i = 0; i < soldNfts.nftSales.length; i++) {
        contractArray.push({
          contractAddress: soldNfts.nftSales[i].contractAddress,
          tokenId: soldNfts.nftSales[i].tokenId,
        });
      }
      const nftMetadata = await alchemyEth.nft.getNftMetadataBatch(
        contractArray
      );
      for (let i = 0; i < soldNfts.nftSales.length; i++) {
        tempArray.push({
          ...soldNfts.nftSales[i],
          type: "trade",
          metadata: nftMetadata.nfts[i],
          seller: true,
          blockNumber: Number(soldNfts.nftSales[i].blockNumber),
        });
      }

      setNftSellFeed(tempArray);
      return tempArray;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    if (walletId === undefined || walletId === null || walletId === "") {
      snackbarError.setMessage("Wallet not found");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return navigate("/wallets/list");
    }
    setIsLoading(true);
    Promise.all([
      fetchRss3SocialFeed(),
      fetchNftMints(),
      fetchNftSales(),
      fetchNftBuy(),
    ])
      .then((res) => {
        setCombinedFeed(
          [...res[0], ...res[1], ...res[2], ...res[3]].sort(
            (a, b) => b.timestamp - a.timestamp
          )
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        snackbarError.setMessage(
          "Error fetching wallet data, please reload the page!"
        );
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        setIsLoading(false);
      });
  }, [filterStringSend]);

  useEffect(() => {
    headerSettingsState.setIsBackButtonOpen(true);
    tabSettingsState.setColor(globalTheme.figmaBeigeDark.color);
    return () => {
      headerSettingsState.setIsBackButtonOpen(false);
      tabSettingsState.setColor(globalTheme.figmaBeigeLight.color);
    };
  }, []);

  const styles = {
    dialogRoot: {
      zIndex: 9999,
      backgroundColor: "rgba(253, 247, 234, 0.5)",
      "& .MuiDialog-paper": {
        backgroundColor: globalTheme.figmaBeigeBG.color,
        color: globalTheme.figmaPrimaryText.color,
        width: "94%",
        maxWidth: "600px",
        margin: 0,
        paddingBottom: "70px",
        paddingTop: "20px",
        position: "absolute",
        bottom: "-10px",
      },
    },
    dialogTitle: {
      color: globalTheme.figmaPrimaryText.color,
      textAlign: "center",
      fontSize: "16px",
      marginTop: "20px",
      fontWeight: 600,
    },
    radio: {
      color: globalTheme.figmaPrimaryText.color,
      "&.Mui-checked": {
        color: globalTheme.figmaPrimaryText.color,
      },
    },
    timelineOuter: {
      width: "104%",
      padding: "0px",
      margin: "0px",
    },
  };

  return (
    <div
      className="WalletOuter"
      style={{
        backgroundColor: globalTheme.figmaBeigeDark.color,
      }}
    >
      <div
        className="ProfileHeader"
        style={{
          backgroundColor: globalTheme.figmaWhite.color,
          border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          ...globalTheme.boxShadow,
          marginTop: "80px",
        }}
      >
        {isWalletUrlLoading ? (
          <Skeleton
            variant="rectangular"
            className="ProfileAvatarImg"
            sx={{
              height: "70px",
              width: "70px",
              marginBottom: "10px",
              borderRadius: "8px",
              marginTop: "10px",
            }}
          />
        ) : walletUrl === "" ? (
          <div className="ListingItemAvatar">
            <Avatar
              sx={{
                bgcolor: globalTheme.figmaWhite.color,
                color: globalTheme.figmaLinkTextSub.color,
                border: `0.5px solid ${globalTheme.figmaLinkTextSub.color}`,
                width: "100%",
                height: "100%",
                borderRadius: "8px",
                fontSize: "16px",
                "@media (min-width: 350px)": {
                  fontSize: "17px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "18px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "20px",
                },
              }}
              alt={walletName}
            >
              {walletId?.substr(0, 2)}
            </Avatar>
          </div>
        ) : (
          <div className="ListingItemAvatar">
            <LazyLoadImg imgUrl={walletUrl} />
          </div>
        )}
        {isWalletNameLoading ? (
          <Skeleton
            variant="text"
            style={{ fontSize: "20px", marginBottom: "5px", width: "90px" }}
          />
        ) : walletName === "" ? null : (
          <p
            className="UserPageUsername"
            style={{
              color: globalTheme.figmaPrimaryText.color,
              textOverflow: "ellipsis",
              overflow: "hidden",
              wordWrap: "break-word",
              width: "80%",
            }}
          >
            {walletName}
          </p>
        )}
        {isVerifiedAddressLoading ? (
          <Skeleton
            variant="text"
            style={{ fontSize: "12px", marginTop: "-5px", width: "60px" }}
          />
        ) : walletName === "" && isWalletNameLoading === false ? (
          <p
            className="UserPageUsername"
            style={{
              color: globalTheme.figmaPrimaryText.color,
              textOverflow: "ellipsis",
              overflow: "hidden",
              wordWrap: "break-word",
              width: "80%",
            }}
          >
            {verifiedAddress}
          </p>
        ) : (
          <p
            className="UserPageLastActive"
            style={{
              color: globalTheme.figmaPrimaryText.color,
              textOverflow: "ellipsis",
              overflow: "hidden",
              wordWrap: "break-word",
              width: "70%",
            }}
          >
            {verifiedAddress}
          </p>
        )}
        {walletBalance === null ? (
          <Skeleton
            variant="text"
            style={{
              fontSize: "22px",
              width: "50%",
            }}
          />
        ) : (
          <p
            className="WalletPageHeaderSub"
            style={{ color: globalTheme.figmaSecondaryText.color }}
          >
            Balance: {walletBalance.toFixed(6)} ETH
          </p>
        )}
      </div>
      <div className="WalletHeaderLinks">
        <ButtonBase
          onClick={() => setTabValue(0)}
          sx={{
            textDecoration: "none",
            width: "50%",
            paddingTop: "0px",
            paddingBottom: "0px",
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            borderRightWidth: "0.5px",
            borderRadius: "8px 0px 0px 8px",
            backgroundColor:
              tabValue === 0
                ? globalTheme.figmaPurplePastel.color
                : "transparent",
          }}
        >
          <div className="WalletHeaderLinksInner">
            <NewspaperOutlinedIcon
              color="inherit"
              sx={{
                fontSize: "16px",
                marginRight: "5px",
                "@media (min-width: 350px)": {
                  fontSize: "17px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "18px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "19px",
                },
              }}
            />
            <p className="WalletHeaderLinksText">Timeline</p>
          </div>
        </ButtonBase>
        <ButtonBase
          onClick={() => setTabValue(1)}
          sx={{
            textDecoration: "none",
            width: "50%",
            paddingTop: "0px",
            paddingBottom: "0px",
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            borderLeftWidth: "0.5px",
            borderRadius: "0px 8px 8px 0px",
            backgroundColor:
              tabValue === 1
                ? globalTheme.figmaPurplePastel.color
                : "transparent",
          }}
        >
          <div className="WalletHeaderLinksInner">
            <CollectionsOutlinedIcon
              color="inherit"
              sx={{
                fontSize: "16px",
                marginRight: "5px",
                "@media (min-width: 350px)": {
                  fontSize: "17px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "18px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "19px",
                },
              }}
            />
            <p className="WalletHeaderLinksText">NFT Gallery</p>
          </div>
        </ButtonBase>
      </div>
      {tabValue === 0 ? (
        <>
          <div className="UserListSort">
            <ButtonBase
              className="UserListSortButton"
              onClick={() => {
                setFilterDialogOpen(true);
              }}
            >
              <div className="UserListSortInner">
                <p
                  className="UserListSortTitle"
                  style={{ color: globalTheme.figmaSecondaryText.color }}
                >
                  Filter by
                </p>
                <p
                  className="UserListSortTitle"
                  style={{
                    color: globalTheme.figmaDashboardIcon.color,
                    marginLeft: "5px",
                    fontWeight: 500,
                  }}
                >
                  {filterNames()}
                </p>
              </div>
            </ButtonBase>
          </div>
          {isLoading ? (
            <div className="WalletBodyLoading">
              <CircularProgress
                sx={{ color: globalTheme.figmaSecondaryText.color }}
              />
              <p
                className="WalletListItemTitle"
                style={{
                  color: globalTheme.figmaSecondaryText.color,
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                Fetching Wallet Activity from Blockchain
              </p>
            </div>
          ) : combinedFeed.length === 0 ? (
            <div className="WalletBodyLoading">
              <p
                className="WalletListItemTitle"
                style={{
                  color: globalTheme.figmaSecondaryText.color,
                  textAlign: "center",
                }}
              >
                No activity found
              </p>
            </div>
          ) : (
            <div className="WalletBody">
              {combinedFeed.map((item, index) => {
                return item.type === "mint" ? (
                  <div key={index} className="WalletItemOuter">
                    <WalletMintItem mintItem={item} index={index} />
                  </div>
                ) : item.type === "trade" ? (
                  <div key={index} className="WalletItemOuter">
                    <WalletTradeItem tradeItem={item} index={index} />
                  </div>
                ) : null;
              })}
            </div>
          )}
        </>
      ) : (
        <WalletGallery walletId={walletId} />
      )}

      <Dialog
        open={filterDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={onFilterSend}
        sx={styles.dialogRoot}
      >
        <DialogTitle sx={styles.dialogTitle}>{"Filter by"}</DialogTitle>
        <DialogContent>
          <div className="UserListSortDialog">
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => onFilterClick("mint")}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  Mints
                </p>
                <Radio
                  checked={filterString.includes("mint")}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => onFilterClick("trade")}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  Trades
                </p>
                <Radio
                  checked={filterString.includes("trade")}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => onFilterClick("donate")}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  Donations
                </p>
                <Radio
                  checked={filterString.includes("donate")}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => onFilterClick("post")}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  Posts
                </p>
                <Radio
                  checked={filterString.includes("post")}
                  onChange={() => {}}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => onFilterClick("comment")}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  Comments
                </p>
                <Radio
                  checked={filterString.includes("comment")}
                  onChange={() => {}}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
