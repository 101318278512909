/**
 * The `ReviewsListing` component is responsible for displaying a list of reviews.
 * It includes sorting functionality, pagination, and loading states.
 * 
 * @component
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <ReviewsListing />
 * 
 * @remarks
 * This component uses various global states for managing theme, header settings, tab settings, and review search sort state.
 * It fetches review data from Algolia based on the current sort type and search parameters.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import { Helmet } from "react-helmet-async";
import Pagination from "@mui/material/Pagination";
import moment from "moment";

// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Radio from "@mui/material/Radio";

import {
  useReviewSearchSortState,
  useTabSettingsState,
  useHeaderSettingsState,
  useThemeState,
} from "../../../components/utils/globalStates";

import {
  algoliaReviewsRelevance,
  algoliaReviewsCreatedAtDesc,
} from "../../../components/search/algoliaSearch";
import ReviewListItem from "../../../components/ui/listItems/ReviewListItem";
import ReviewSearchInfo from "../../../components/ui/topBoxes/ReviewSearchInfo";

import "./Reviews.css";
import "../../user/list/UserList.css";

moment().format();

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReviewsListing() {
  const headerSettingsState = useHeaderSettingsState();
  const tabSettingsState = useTabSettingsState();
  const reviewSearchSortState = useReviewSearchSortState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [loading, setLoading] = useState(true);
  const [listings, setListings] = useState<any[]>([]);
  const [sortDialogOpen, setSortDialogOpen] = useState(false);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    reviewSearchSortState.setPage(value - 1);
  };

  const fetchTemplate = (fetchObj: any) => {
    setLoading(true);
    fetchObj
      .search(reviewSearchSortState.search, {
        hitsPerPage: reviewSearchSortState.hitsPerPage,
        page: reviewSearchSortState.page,
        filters: `review:true`,
      })
      .then((res: any) => {
        reviewSearchSortState.setListingPages(res.nbPages);
        reviewSearchSortState.setListingCount(res.nbHits);
        setListings(res.hits);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (reviewSearchSortState.sortType === "relevance") {
      fetchTemplate(algoliaReviewsRelevance);
    } else {
      fetchTemplate(algoliaReviewsCreatedAtDesc);
    }
  }, [
    reviewSearchSortState.sortOrder,
    reviewSearchSortState.sortType,
    reviewSearchSortState.hitsPerPage,
    reviewSearchSortState.page,
    reviewSearchSortState.search,
  ]);

  useEffect(() => {
    headerSettingsState.setIsLogoOpen(true);
    headerSettingsState.setLogoBGColor(globalTheme.dashboardBackground.color);
    headerSettingsState.setLogoColor(globalTheme.backgroundMainOffYellow.color);
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.dashboardBackground.color);
    return () => {
      headerSettingsState.setIsLogoOpen(false);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  const styles = {
    dialogRoot: {
      zIndex: 9999,
      backgroundColor: "rgba(253, 247, 234, 0.5)",
      "& .MuiDialog-paper": {
        backgroundColor: globalTheme.backgroundMainYellow.color,
        color: globalTheme.backgroundMainOffYellow.color,
        width: "94%",
        maxWidth: "600px",
        margin: 0,
        paddingBottom: "70px",
        paddingTop: "20px",
        position: "absolute",
        bottom: "-10px",
      },
    },
    dialogTitle: {
      color: globalTheme.backgroundMainOffYellow.color,
      textAlign: "center",
      fontSize: "16px",
      marginTop: "20px",
      fontWeight: 600,
    },
    radio: {
      color: globalTheme.backgroundMainOffYellow.color,
      "&.Mui-checked": {
        color: globalTheme.backgroundMainOffYellow.color,
      },
    },
  };

  return (
    <div
      className="Reviews"
      style={{
        backgroundColor: globalTheme.dashboardBackground.color,
        marginTop: "-10px",
      }}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Reviews</title>
      </Helmet>
      <ReviewSearchInfo />
      <div className="UserListSort">
        <ButtonBase
          className="UserListSortButton"
          onClick={() => {
            setSortDialogOpen(true);
          }}
        >
          <div className="UserListSortInner" style={{ marginTop: "-10px" }}>
            <p
              className="UserListSortTitle"
              style={{ color: globalTheme.backgroundMainOffYellow.color }}
            >
              Sort by
            </p>
            <p
              className="UserListSortTitle"
              style={{
                color: globalTheme.backgroundMainOffYellow.color,
                marginLeft: "5px",
                fontWeight: 600,
              }}
            >
              {reviewSearchSortState.sortType === "createdAt"
                ? "Latest"
                : "Relevance"}
            </p>
          </div>
        </ButtonBase>
      </div>
      {loading ? (
        <div className="UserListLoading">
          <CircularProgress
            sx={{ color: globalTheme.backgroundMainOffYellow.color }}
          />
        </div>
      ) : listings.length > 0 ? (
        <div className="ReviewsOuter">
          {listings.map((reviewItem, index) => {
            return (
                <ReviewListItem
                key={index}
                  listItem={reviewItem}
                  isListing={true}
                />
              );
          })}
        </div>
      ) : (
        <div className="UserListLoading">
          <p
            className="UserListSortTitle"
            style={{ color: globalTheme.backgroundMainOffYellow.color }}
          >
            No reviews yet
          </p>
        </div>
      )}
      {!loading &&
        listings.length > 0 &&
        reviewSearchSortState.listingPages !== 0 && (
          <Pagination
            count={reviewSearchSortState.listingPages}
            page={reviewSearchSortState.page + 1}
            onChange={handlePageChange}
            sx={{
              marginTop: "20px",
              marginBottom: "0px",
              "& button": {
                color: globalTheme.backgroundMainOffYellow.color,
              },

              "& .MuiPaginationItem-root": {
                color: globalTheme.backgroundMainOffYellow.color,
              },
              "& .MuiPaginationItem-page.Mui-selected": {
                backgroundColor: globalTheme.backgroundMainOffYellow.color,
                color: globalTheme.backgroundMainYellow.color,
              },
            }}
            size="small"
          />
        )}
      <Dialog
        open={sortDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSortDialogOpen(false)}
        sx={styles.dialogRoot}
      >
        <DialogTitle sx={styles.dialogTitle}>{"Sort by"}</DialogTitle>
        <DialogContent>
          <div className="UserListSortDialog">
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                reviewSearchSortState.setSortType("relevance");
                reviewSearchSortState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffYellow.color }}
                >
                  Relevance
                </p>
                <Radio
                  checked={reviewSearchSortState.sortType === "relevance"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                reviewSearchSortState.setSortType("createdAt");
                reviewSearchSortState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffYellow.color }}
                >
                  Latest
                </p>
                <Radio
                  checked={reviewSearchSortState.sortType === "createdAt"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
