/**
 * Entry point for the React application.
 * 
 * This file sets up the root of the React application and renders the main App component.
 * It uses the following libraries:
 * - `react-dom/client` for creating the root of the React application.
 * - `react-router-dom` for handling routing within the application.
 * - `react-helmet-async` for managing changes to the document head.
 * 
 * The application is wrapped with `BrowserRouter` to enable routing and `HelmetProvider` to manage the document head.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </BrowserRouter>
);
