/**
 * Renders a component displaying various user statistics for an admin view.
 * It fetches counts of total users, newly signed-up users, users active within
 * different time frames (now, today, weekly, monthly), and inactive users. All
 * data is retrieved from Firestore using queries based on timestamps.
 *
 * @returns A JSX element containing counters with tooltips, each representing
 *          a distinct user count statistic.
 * @example
 * return (
 *  <AdminUserStats />
 * )
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */

import { useEffect, useState } from "react";

import {
  useThemeState,
} from "../../../components/utils/globalStates";
import { Skeleton, Tooltip } from "@mui/material";

import {
  getCountFromServer,
  collection,
  query,
  where,
} from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";

import "../Admin.css";

export default function AdminUserStats() {
  const globalTheme = useThemeState((state) => state.globalTheme);

  const [listingCount, setListingCount] = useState(0);
  const [listingCountLoading, setListingCountLoading] = useState(true);

  const [listingCountWeek, setListingCountWeek] = useState(0);
  const [listingCountLoadingWeek, setListingCountLoadingWeek] = useState(true);

  const [listingCountMonth, setListingCountMonth] = useState(0);
  const [listingCountLoadingMonth, setListingCountLoadingMonth] =
    useState(true);

  const [activeDailyCount, setActiveDailyCount] = useState(0);
  const [activeDailyCountLoading, setActiveDailyCountLoading] = useState(true);

  const [activeWeeklyCount, setActiveWeeklyCount] = useState(0);
  const [activeWeeklyCountLoading, setActiveWeeklyCountLoading] =
    useState(true);

  const [activeCountMonthly, setActiveCountMontly] = useState(0);
  const [activeCountLoadingMontly, setActiveCountLoadingMontly] =
    useState(true);

  const [inactiveCount, setInactiveCount] = useState(0);
  const [inactiveCountLoading, setInactiveCountLoading] = useState(true);

  const [activeNowCount, setActiveNowCount] = useState(0);
  const [activeNowCountLoading, setActiveNowCountLoading] = useState(true);

  // Get total user count from privateUserInfo collection
  useEffect(() => {
    const fetchListingCount = async () => {
      const q = query(collection(firestoreDb, "privateUserInfo"));
      const snapshot = await getCountFromServer(q);
      setListingCount(snapshot.data().count);
      setListingCountLoading(false);
    };
    fetchListingCount();
  }, []);

  // Get total user count from privateUserInfo collection where createdAtTimestamp field is within the last week
  useEffect(() => {
    const fetchListingCountWeek = async () => {
      const timeNow = Date.now();
      const timeLastWeek = timeNow - 604800000;
      const q = query(
        collection(firestoreDb, "privateUserInfo"),
        where("createdAtTimestamp", ">", timeLastWeek)
      );
      const snapshot = await getCountFromServer(q);
      setListingCountWeek(snapshot.data().count);
      setListingCountLoadingWeek(false);
    };
    fetchListingCountWeek();
  }, []);

  // Get total user count from privateUserInfo collection where createdAtTimestamp field is within the last month
  useEffect(() => {
    const fetchListingCountMonth = async () => {
      const timeNow = Date.now();
      const timeLastMonth = timeNow - 2628000000;
      const q = query(
        collection(firestoreDb, "privateUserInfo"),
        where("createdAtTimestamp", ">", timeLastMonth)
      );
      const snapshot = await getCountFromServer(q);
      setListingCountMonth(snapshot.data().count);
      setListingCountLoadingMonth(false);
    };
    fetchListingCountMonth();
  }, []);

  // Get total user count from privateUserInfo collection where lastActive field is within the last day

  useEffect(() => {
    const fetchActiveDailyCount = async () => {
      const timeNow = Date.now();
      const timeLastDay = timeNow - 86400000;
      const q = query(
        collection(firestoreDb, "privateUserInfo"),
        where("lastActive", ">", timeLastDay)
      );
      const snapshot = await getCountFromServer(q);
      setActiveDailyCount(snapshot.data().count);
      setActiveDailyCountLoading(false);
    };
    fetchActiveDailyCount();
  }, []);

  // Get total user count from privateUserInfo collection where lastActive field is within the last week

  useEffect(() => {
    const fetchActiveWeeklyCount = async () => {
      const timeNow = Date.now();
      const timeLastWeek = timeNow - 604800000;
      const q = query(
        collection(firestoreDb, "privateUserInfo"),
        where("lastActive", ">", timeLastWeek)
      );
      const snapshot = await getCountFromServer(q);
      setActiveWeeklyCount(snapshot.data().count);
      setActiveWeeklyCountLoading(false);
    };
    fetchActiveWeeklyCount();
  }, []);

  // Get total user count from privateUserInfo collection where lastActive field is within the last month

  useEffect(() => {
    const fetchActiveCountMonthly = async () => {
      const timeNow = Date.now();
      const timeLastMonth = timeNow - 2628000000;
      const q = query(
        collection(firestoreDb, "privateUserInfo"),
        where("lastActive", ">", timeLastMonth)
      );
      const snapshot = await getCountFromServer(q);
      setActiveCountMontly(snapshot.data().count);
      setActiveCountLoadingMontly(false);
    };
    fetchActiveCountMonthly();
  }, []);

  // Get total user count from privateUserInfo collection where lastActive field is more than 3 month ago

  useEffect(() => {
    const fetchInactiveCount = async () => {
      const timeNow = Date.now();
      const timeLastMonth = timeNow - 7884000000;
      const q = query(
        collection(firestoreDb, "privateUserInfo"),
        where("lastActive", "<", timeLastMonth)
      );
      const snapshot = await getCountFromServer(q);
      setInactiveCount(snapshot.data().count);
      setInactiveCountLoading(false);
    };
    fetchInactiveCount();
  }, []);

  // Get total user count from privateUserInfo collection where lastActive field is within the last hour

  useEffect(() => {
    const fetchActiveNowCount = async () => {
      const timeNow = Date.now();
      const timeLastHour = timeNow - 3600000;
      const q = query(
        collection(firestoreDb, "privateUserInfo"),
        where("lastActive", ">", timeLastHour)
      );
      const snapshot = await getCountFromServer(q);
      setActiveNowCount(snapshot.data().count);
      setActiveNowCountLoading(false);
    };
    fetchActiveNowCount();
  }, []);

  return (
    <div className="AdminInnerTopRow">
      <Tooltip
        title="Total number of users in the system"
        placement="top"
        arrow
      >
        <div className="AdminInnerTopRowItem">
          <p
            className="AdminDescription"
            style={{
              ...globalTheme.backgroundMainOffBeige,
              width: "unset",
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "0px",
            }}
          >
            Total
          </p>
          {listingCountLoading ? (
            <Skeleton
              variant="text"
              sx={{ borderRadius: "5px", width: "80px", height: "40px" }}
            />
          ) : (
            <p
              className="AdminInnerTopRowCounter"
              style={{ ...globalTheme.backgroundMainOffBeige }}
            >
              {new Intl.NumberFormat().format(listingCount)}
            </p>
          )}
        </div>
      </Tooltip>
      <Tooltip
        title="Number of users that have signed up in the last week"
        placement="top"
        arrow
      >
        <div className="AdminInnerTopRowItem">
          <p
            className="AdminDescription"
            style={{
              ...globalTheme.backgroundMainOffBeige,
              width: "unset",
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "0px",
            }}
          >
            Last Week
          </p>
          {listingCountLoadingWeek ? (
            <Skeleton
              variant="text"
              sx={{ borderRadius: "5px", width: "80px", height: "40px" }}
            />
          ) : (
            <p
              className="AdminInnerTopRowCounter"
              style={{ ...globalTheme.backgroundMainOffBeige }}
            >
              {new Intl.NumberFormat().format(listingCountWeek)}
            </p>
          )}
        </div>
      </Tooltip>
      <Tooltip
        title="Number of users that have signed up in the last month"
        placement="top"
        arrow
      >
        <div className="AdminInnerTopRowItem">
          <p
            className="AdminDescription"
            style={{
              ...globalTheme.backgroundMainOffBeige,
              width: "unset",
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "0px",
            }}
          >
            Last Month
          </p>
          {listingCountLoadingMonth ? (
            <Skeleton
              variant="text"
              sx={{ borderRadius: "5px", width: "80px", height: "40px" }}
            />
          ) : (
            <p
              className="AdminInnerTopRowCounter"
              style={{ ...globalTheme.backgroundMainOffBeige }}
            >
              {new Intl.NumberFormat().format(listingCountMonth)}
            </p>
          )}
        </div>
      </Tooltip>
      <Tooltip
        title="Number of users that are currently active"
        placement="top"
        arrow
      >
        <div className="AdminInnerTopRowItem">
          <p
            className="AdminDescription"
            style={{
              ...globalTheme.backgroundMainOffBeige,
              width: "unset",
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "0px",
            }}
          >
            Active Now
          </p>
          {activeNowCountLoading ? (
            <Skeleton
              variant="text"
              sx={{ borderRadius: "5px", width: "80px", height: "40px" }}
            />
          ) : (
            <p
              className="AdminInnerTopRowCounter"
              style={{ ...globalTheme.backgroundMainOffBeige }}
            >
              {new Intl.NumberFormat().format(activeNowCount)}
            </p>
          )}
        </div>
      </Tooltip>
      <Tooltip
        title="Number of users that have been active in the last day"
        placement="top"
        arrow
      >
        <div className="AdminInnerTopRowItem">
          <p
            className="AdminDescription"
            style={{
              ...globalTheme.backgroundMainOffBeige,
              width: "unset",
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "0px",
            }}
          >
            Active Today
          </p>
          {activeDailyCountLoading ? (
            <Skeleton
              variant="text"
              sx={{ borderRadius: "5px", width: "80px", height: "40px" }}
            />
          ) : (
            <p
              className="AdminInnerTopRowCounter"
              style={{ ...globalTheme.backgroundMainOffBeige }}
            >
              {new Intl.NumberFormat().format(activeDailyCount)}
            </p>
          )}
        </div>
      </Tooltip>
      <Tooltip
        title="Number of users that have been active in the last week"
        placement="top"
        arrow
      >
        <div className="AdminInnerTopRowItem">
          <p
            className="AdminDescription"
            style={{
              ...globalTheme.backgroundMainOffBeige,
              width: "unset",
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "0px",
            }}
          >
            Active This Week
          </p>
          {activeWeeklyCountLoading ? (
            <Skeleton
              variant="text"
              sx={{ borderRadius: "5px", width: "80px", height: "40px" }}
            />
          ) : (
            <p
              className="AdminInnerTopRowCounter"
              style={{ ...globalTheme.backgroundMainOffBeige }}
            >
              {new Intl.NumberFormat().format(activeWeeklyCount)}
            </p>
          )}
        </div>
      </Tooltip>
      <Tooltip
        title="Number of users that have been active in the last month"
        placement="top"
        arrow
      >
        <div className="AdminInnerTopRowItem">
          <p
            className="AdminDescription"
            style={{
              ...globalTheme.backgroundMainOffBeige,
              width: "unset",
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "0px",
            }}
          >
            Active This Month
          </p>
          {activeCountLoadingMontly ? (
            <Skeleton
              variant="text"
              sx={{ borderRadius: "5px", width: "80px", height: "40px" }}
            />
          ) : (
            <p
              className="AdminInnerTopRowCounter"
              style={{ ...globalTheme.backgroundMainOffBeige }}
            >
              {new Intl.NumberFormat().format(activeCountMonthly)}
            </p>
          )}
        </div>
      </Tooltip>
      <Tooltip
        title="Number of users that have been inactive for more than 3 months"
        placement="top"
        arrow
      >
        <div className="AdminInnerTopRowItem">
          <p
            className="AdminDescription"
            style={{
              ...globalTheme.backgroundMainOffBeige,
              width: "unset",
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "0px",
            }}
          >
            Inactive
          </p>
          {inactiveCountLoading ? (
            <Skeleton
              variant="text"
              sx={{ borderRadius: "5px", width: "80px", height: "40px" }}
            />
          ) : (
            <p
              className="AdminInnerTopRowCounter"
              style={{ ...globalTheme.backgroundMainOffBeige }}
            >
              {new Intl.NumberFormat().format(inactiveCount)}
            </p>
          )}
        </div>
      </Tooltip>
    </div>
  );
}
