/**
 * FirebaseListeners component sets up various Firebase listeners to manage
 * authentication states, user settings, and user private information.
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 * 
 * @component
 * @example
 * return (
 *   <FirebaseListeners />
 * )
 *
 * @remarks
 * This component uses multiple `useEffect` hooks to listen for changes in Firebase
 * Realtime Database and Firestore, and updates the global state accordingly.
 *
 * @returns {JSX.Element} An empty JSX element as this component does not render any UI.
 *
 * @function
 * @name FirebaseListeners
 *
 * @description
 * - Listens for changes in user authentication state and updates the role.
 * - Sets the authenticated user token for search insights.
 * - Listens for changes in user-specific settings in Firebase Realtime Database and updates the global state.
 * - Listens for updates on user private information in Firestore and updates the global state.
 * - Initializes user data if not present in Firestore.
 *
 * @requires
 * - `react`
 * - `search-insights`
 * - `firebase/database`
 * - `firebase/auth`
 * - `firebase/functions`
 * - `firebase/firestore`
 * - `react-firebase-hooks/auth`
 * - `../utils/globalStates`
 * - `../firebase/database`
 * - `../../views/dashboard/dashEnums`
 * - `../firebase/auth`
 * - `../firebase/functions`
 * - `../firebase/firestore`
 *
 * @global
 * - `useAuthStates`
 * - `useSettingsStates`
 *
 * @state
 * - `isLoadingRoles` - A boolean flag to prevent multiple role loading.
 * - `isLoadingSettings` - A boolean flag to prevent multiple settings loading.
 *
 * @hook
 * - `useAuthState` - To get the current user authentication state.
 * - `useEffect` - To set up listeners for Firebase changes.
 *
 * @async
 * @function
 * @name initUserData
 * @description Initializes user data by calling a Firebase function.
 * @returns {Promise<void>}
 */

import React, { useEffect } from "react";
import aa from "search-insights";
import { useAuthStates, useSettingsStates } from "../utils/globalStates";
import { ref, onValue } from "firebase/database";
import { firebaseRlDb } from "../firebase/database";
import { initialDashSettings } from "../../views/dashboard/dashEnums";
import { getUserRole, firebaseAuth } from "../firebase/auth";

import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../firebase/functions";
import { doc, onSnapshot } from "firebase/firestore";
import { firestoreDb } from "../firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
let isLoadingRoles = false;
let isLoadingSettings = false;

export default function FirebaseListeners() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const authStates = useAuthStates((state) => state);
  const settingsStates = useSettingsStates((state) => state);

  useEffect(() => {
    if (isLoadingRoles) return;
    isLoadingRoles = true;
    if (user !== null) {
      getUserRole().then((role: any) => {
        authStates.setRole(role);
      });
    }
    isLoadingRoles = false;
  }, [user]);

  useEffect(() => {
    if (user === null || user === undefined) return;
    // @ts-ignore
    aa("setAuthenticatedUserToken", user.uid);
  }, [user]);

  useEffect(() => {
    if (user === null) return;
    isLoadingSettings = true;
    const dbRef = ref(firebaseRlDb, `public/settings/user/${user?.uid}`);
    const unsubscribe = onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        if (data.tab !== null && data.tab !== undefined && data.tab !== "") {
          settingsStates.setTab(data.tab.split(","));
        } else {
          settingsStates.setTab(
            "/explore,/search,/users,/profile".split(",")
          );
        }
        if (data.dashboard !== null && data.dashboard !== undefined) {
          settingsStates.setDashboard(Object.values(data.dashboard));
          settingsStates.setDashboardInitialLoaded(true);
        } else {
          settingsStates.setDashboard(initialDashSettings);
          settingsStates.setDashboardInitialLoaded(true);
        }
      } else {
        console.log("No data available");
        settingsStates.setTab(
          "/explore,/search,/users,/profile".split(",")
        );
        settingsStates.setDashboard(initialDashSettings);
        settingsStates.setDashboardInitialLoaded(true);
      }
      isLoadingSettings = false;
    });

    return () => unsubscribe();
  }, [user]);

  // Listen for updates on userPrivateInfo and update global state
  useEffect(() => {
    if (user === null || user === undefined) return;
    const userRef = doc(firestoreDb, "privateUserInfo", user.uid);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        authStates.setUserData(data);
        authStates.setUserDataLoaded(true);
      } else {
        console.log("No such document!");
        initUserData();
      }
    });

    return () => unsubscribe();
  }, [user]);

  const initUserData = async () => {
    if (user === null || user === undefined) return;
    try {
      const initData = httpsCallable(firebaseFunctions, "user-init_data");
      const tokenCheck = await user.getIdToken();
      const res = await initData({ token: tokenCheck });
      console.log("Data initialized");
      console.log(res);
      authStates.setUserData(res.data);
      authStates.setUserDataLoaded(true);
    } catch (error) {
      console.error(error);
      authStates.setUserData(null);
      authStates.setUserDataLoaded(true);
    }
  };

  return <></>;
}
