/**
 * AdminLogoUpload component allows the admin to upload a logo image for a specific listing.
 * It handles image selection, resizing, uploading to Firebase storage, and updating the Firestore database with the new logo URL.
 *
 * @param {Object} props - The component props.
 * @param {string} props.listingId - The ID of the listing for which the logo is being uploaded.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @component
 * @example
 * // Usage example:
 * <AdminLogoUpload listingId="exampleListingId" />
 *
 * @remarks
 * This component uses the following libraries:
 * - react-image-file-resizer for resizing the image before upload.
 * - @mui/material for UI components.
 * - firebase/storage and firebase/firestore for interacting with Firebase services.
 *
 * The component maintains the following state:
 * - logoObj: The selected image file object.
 * - isSending: A boolean indicating if the upload process is ongoing.
 * - isSent: A boolean indicating if the upload process has completed successfully.
 * - chosenImgError: A string to display error or status messages related to the image selection and upload process.
 *
 * The component provides the following functionality:
 * - updateLogoObj: Updates the selected image file object and sets an error message to prompt the user to upload or clear the image.
 * - onClear: Clears the selected image file object and resets the state.
 * - resizeFile: Resizes the selected image file to a 240x240 jpeg blob.
 * - checkIfLogoExists: Checks if a logo already exists in Firebase storage for the given listing ID.
 * - onUpload: Handles the image upload process, including resizing the image, uploading it to Firebase storage, and updating the Firestore database with the new logo URL.
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState } from "react";
import Resizer from "react-image-file-resizer";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import {
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
} from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { firebaseStorage } from "../../../components/firebase/storage";
import { firestoreDb } from "../../../components/firebase/firestore";

import { useThemeState } from "../../../components/utils/globalStates";
import "./AdminComponents.css";

export default function AdminLogoUpload({ listingId }: any) {
  const [logoObj, setLogoObj] = useState<any>(null);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [chosenImgError, setChosenImgError] = useState("");

  const globalTheme = useThemeState((state) => state.globalTheme);

  const updateLogoObj = (e: any) => {
    setLogoObj(e.target.files[0]);
    setChosenImgError("Upload chosen image or clear");
  };

  const onClear = () => {
    setLogoObj(null);
    setChosenImgError("Pick a logo to upload");
    setIsSent(false);
  };

  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        240,
        240,
        "jpeg",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  const checkIfLogoExists = async () => {
    try {
      const imageRef = ref(firebaseStorage, `public/logos/${listingId}.jpeg`);
      const image = await getDownloadURL(imageRef);
      return true;
    } catch (err) {
      return false;
    }
  };
  const onUpload = async () => {
    if (logoObj === null) {
      setChosenImgError("Please pick a logo to upload.");
      return;
    }
    setIsSending(true);
    setChosenImgError("Uploading logo to storage...");
    try {
      let imageObj: any = null;
      const file = logoObj;
      const image = await resizeFile(file);
      imageObj = image;

      const imageRef = ref(firebaseStorage, `public/logos/${listingId}.jpeg`);

      const hasFile = await checkIfLogoExists();

      if (hasFile) {
        await deleteObject(imageRef);
      }

      const uplodedImage = await uploadBytes(imageRef, imageObj);
      const uploadedImageUrl = await getDownloadURL(uplodedImage.ref);

      setChosenImgError("Set logo in database...");
      // update listing with new logo
      const listingRef = doc(firestoreDb, "listings", listingId);
      await updateDoc(listingRef, {
        logo: uploadedImageUrl,
      });
      setIsSent(true);
      setIsSending(false);
      setChosenImgError("Logo uploaded successfully.");
    } catch (err) {
      console.error(err);
      setIsSending(false);
      setChosenImgError("Something went wrong. Please try again.");
    }
  };

  return (
    <div
      className="AdminLogoUpload"
      style={{
        border: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
        color: globalTheme.backgroundMainOffBeige.color,
      }}
    >
      {chosenImgError !== "" ? (
        <p
          className="AdminLogoUploadTitle"
          style={{ ...globalTheme.backgroundMainOffBeige }}
        >
          {chosenImgError}
        </p>
      ) : (
        <p
          className="AdminLogoUploadTitle"
          style={{ ...globalTheme.backgroundMainOffBeige }}
        >
          Pick a logo to upload
        </p>
      )}
      {isSending ? (
        <CircularProgress
          sx={{ marginRight: "25px" }}
          color="inherit"
          size={20}
        />
      ) : isSent ? (
        <Button
          sx={{
            marginRight: "5px",
            fontSize: "15px",
            "&:hover": {
              opacity: 0.9,
            },
          }}
          color="inherit"
          disabled={isSending}
          onClick={onClear}
        >
          Ok
        </Button>
      ) : logoObj !== null ? (
        <>
          <Button
            sx={{
              marginRight: "5px",
              fontSize: "15px",
              "&:hover": {
                opacity: 0.9,
              },
            }}
            color="inherit"
            disabled={isSending}
            onClick={onClear}
          >
            Clear
          </Button>
          <Button
            sx={{
              marginRight: "5px",
              fontSize: "15px",
              "&:hover": {
                opacity: 0.9,
              },
            }}
            color="inherit"
            disabled={isSending}
            onClick={onUpload}
          >
            Upload
          </Button>
        </>
      ) : (
        <Button
          sx={{
            marginRight: "5px",
            fontSize: "15px",
            "&:hover": {
              opacity: 0.9,
            },
          }}
          color="inherit"
          component="label"
          disabled={isSending}
        >
          Pick Image
          <input
            type="file"
            hidden
            accept="image/png, image/jpeg, image/jpg"
            onChange={updateLogoObj}
          />
        </Button>
      )}
    </div>
  );
}
