/**
 * Component for displaying a slider of user achievements.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.userId - The ID of the user whose achievements are to be displayed
 *
 * @returns {JSX.Element} The rendered component
 *
 * @example
 * <ProfileAchievementsSlider userId="user123" />
 *
 * @remarks
 * This component fetches user achievements from Firestore and displays them in a slider.
 * It uses Firebase authentication to get the current user and fetches achievements based on the provided userId.
 * The component also handles loading states and displays skeleton loaders while fetching data.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../../components/firebase/auth";
import { query, collection, where, getDocs, limit } from "firebase/firestore";
import { firestoreDb } from "../../../../components/firebase/firestore";

import AchievementsSliderItem from "./AchievementsSliderItem";
import {
  useThemeState,
  useDeviceState,
} from "../../../../components/utils/globalStates";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProfileStartComp.css";

let initLoading = false;

export default function ProfileAchievementsSlider({
  userId,
}: {
  userId: string;
}) {
  const [user] = useAuthState(firebaseAuth);
  const [isLoading, setIsLoading] = useState(true);
  const [achievements, setAchievements] = useState<any[]>([]);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const deviceState = useDeviceState((state) => state);

  useEffect(() => {
    if (userId === null || userId === undefined) {
      return;
    }
    if (initLoading) return;
    initLoading = true;
    const getData = async () => {
      try {
        const achievementsQ = query(
          collection(firestoreDb, "userAchievements"),
          where("uid", "==", userId),
          limit(30)
        );
        const achievementsSnap = await getDocs(achievementsQ);
        const achievementsData: any[] = [];
        achievementsSnap.forEach((doc) => {
          achievementsData.push(doc.data());
        });
        setAchievements(achievementsData);
        setIsLoading(false);
        initLoading = false;
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        initLoading = false;
      }
    };
    getData();
  }, [userId]);
  return (
    <div className="ProfileAchivements">
      {isLoading ? (
        <div className="ProfileAchivementsLoading">
          <Skeleton
            variant="rounded"
            sx={{
              width: "20% !important",
              height: "100px !important",
            }}
          />
          <Skeleton
            variant="rounded"
            sx={{
              width: "20% !important",
              height: "100px !important",
            }}
          />
          <Skeleton
            variant="rounded"
            sx={{
              width: "20% !important",
              height: "100px !important",
            }}
          />
          <Skeleton
            variant="rounded"
            sx={{
              width: "20% !important",
              height: "100px !important",
            }}
          />
        </div>
      ) : achievements.length > 0 ? (
        <div className="ProfileAchivementsSlider" style={{justifyContent: deviceState.deviceWidth >= 700 ? achievements.length >= 8 ? 'space-between':'space-evenly': deviceState.deviceWidth >= 400 ? achievements.length >= 6 ? 'space-between':'space-evenly': achievements.length >= 4 ? 'space-between':'space-evenly'}}>
          {achievements.map((achievement, index) => {
            if (deviceState.deviceWidth < 400 && index < 4) {
              return (
                <AchievementsSliderItem achievement={achievement} key={index} />
              );
            } else if (deviceState.deviceWidth >= 400 && deviceState.deviceWidth < 700 && index < 6) {
              return (
                <AchievementsSliderItem achievement={achievement} key={index} />
              );
            } else if (deviceState.deviceWidth >= 700 && index < 8) {
              return (
                <AchievementsSliderItem achievement={achievement} key={index} />
              );
            }
          })}
        </div>
      ) : (
        userId === user?.uid && (
          <div className="ProfileAchivementsInner">
            <p
              className="ProfileCountSub"
              style={{ color: globalTheme.figmaSecondaryText.color }}
            >
              No achievements yet
            </p>
          </div>
        )
      )}
    </div>
  );
}
