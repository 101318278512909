/**
 * AdminListingItem component renders a listing item for the admin view.
 * It displays the item's logo or an avatar with the item's initials if the logo is not available.
 * It also shows the item's name, category, and description.
 * If the item is manually verified, a verified icon is displayed.
 *
 * @param {Object} props - The component props.
 * @param {any} props.listItem - The listing item data.
 * @returns {JSX.Element} The rendered component.
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useThemeState } from "../../../components/utils/globalStates";

import Tooltip from "@mui/material/Tooltip";

import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import moment from "moment";
import { LazyLoadImg } from "../../../components/ui/misc/LazyLoadImg";
import "../../listings/item/ListingsItem.css";

moment().format();

export default function AdminListingItem({ listItem }: { listItem: any }) {
  const globalTheme = useThemeState((state) => state.globalTheme);

  const styles = {
    title: {
      color: globalTheme.textMainBlack.color,
      textAlign: "left",
      fontSize: "12px",
      margin: "0px",
      fontWeight: 600,
    },
  };

  return (
    <div
      className="ListingsItem"
      style={{
        backgroundColor: globalTheme.boxBackgroundWhite.color,
        border: `1px solid ${globalTheme.solidMainBlack.color}`,
        cursor: "pointer",
        ...globalTheme.boxShadow,
      }}
    >
      {listItem.logo !== "" &&
      listItem.logo !== undefined &&
      listItem.logo !== null ? (
        <div
          className="ListingItemAvatar"
          style={{ width: "60px", height: "60px" }}
        >
          <LazyLoadImg imgUrl={listItem.logo} />
        </div>
      ) : (
        <div style={{ textDecoration: "none" }}>
          <Avatar
            sx={{
              bgcolor: globalTheme.boxBackgroundWhite.color,
              color: globalTheme.solidMainBlack.color,
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              fontSize: "16px",
              textDecoration: "none",
            }}
            alt={listItem.name}
          >
            {listItem.name.substr(0, 2)}
          </Avatar>
        </div>
      )}
      <div className="ListingsItemInner">
        <div className="ListingsItemInnerHeader">
          <Typography
            noWrap
            sx={{
              ...styles.title,
              color: globalTheme.textMainBlack.color,
              textDecoration: "none",
            }}
          >
            {listItem.name}
            <span
              className="ListingsItemTitleSub"
              style={{ color: globalTheme.figmaLinks.color }}
            >
              {listItem.category}
            </span>
          </Typography>
        </div>
        <p
          style={{ color: globalTheme.textMainBlack.color }}
          className="ListingsItemDesc"
        >
          {listItem.description}
        </p>
      </div>
      {listItem.manualVerified !== null &&
              listItem.manualVerified !== undefined &&
              listItem.manualVerified === true && (
                <Tooltip title="Verified by GATH3R">
                  <VerifiedUserOutlinedIcon
                    sx={{
                      fontSize: "20px",
                      color: globalTheme.figmaLinks.color,
                      marginLeft: "5px",
                      "@media (min-width: 350px)": {
                        fontSize: "22px",
                      },
                      "@media (min-width: 550px)": {
                        fontSize: "24px",
                      },
                      "@media (min-width: 700px)": {
                        fontSize: "26px",
                      },
                    }}
                  />
                </Tooltip>
              )}
    </div>
  );
}
