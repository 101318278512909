/**
 * WalletsList component displays a list of wallets that the user follows.
 * It allows the user to add new wallets, delete existing ones, and edit the list.
 * 
 * @component
 * 
 * @returns {JSX.Element} The WalletsList component.
 * 
 * @example
 * return (
 *   <WalletsList />
 * )
 * 
 * @remarks
 * This component uses Firebase Firestore to fetch and manage the list of wallets.
 * It also uses various Material-UI components for the UI and react-firebase-hooks for authentication.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import React, { useState, useEffect } from "react";
import {
  getDocs,
  where,
  limit,
  collection,
  doc,
  query,
  addDoc,
  deleteDoc,
} from "firebase/firestore";

// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";

import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";

import {
  useSnackbarErrorState,
  useSnackbarState,
  useTabSettingsState,
  useHeaderSettingsState,
  useThemeState,
} from "../../../components/utils/globalStates";

import WalletListItem from "../components/WalletListItem";
import WalletsListInfo from "../../../components/ui/topBoxes/WalletsListInfo";
import { materialListingFormStyles } from "../../../components/theme/globalMuiTheme";
import { firestoreDb } from "../../../components/firebase/firestore";
import { isValidEthAddress } from "../../../components/web3/web3js";
import { web3Provider } from "../../../components/web3/web3js";
import "./Wallets.css";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

let externaLLoaded = false;
const initWallets = [
  {
    wallet: "vitalik.eth",
    createdAt: Date.now(),
    name: "Vitalik Buterin",
  },
  {
    wallet: "0x679d5162BaD71990ABCA0f18095948c12a2756B0",
    createdAt: Date.now(),
    name: "AriZonaNFTea",
  },
];

export default function WalletsList() {
  const snackbarError = useSnackbarErrorState();
  const headerSettingsState = useHeaderSettingsState();
  const tabSettingsState = useTabSettingsState();
  const snackbarState = useSnackbarState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [user, loading, error] = useAuthState(firebaseAuth);
  const [isLoading, seIsLoading] = useState(true);
  const [listings, setListings] = useState<any[]>([]);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [deletingId, setDeletingId] = useState("");
  const [addNewDialogOpen, setAddNewDialogOpen] = useState(false);
  const [newWalletName, setNewWalletName] = useState("");
  const [newWallet, setNewWallet] = useState("");
  const [isSendingNewWallet, setIsSendingNewWallet] = useState(false);

  useEffect(() => {
    if (externaLLoaded) {
      return;
    }
    if (user === null || user === undefined) {
      return;
    }
    const loadListings = async () => {
      try {
        externaLLoaded = true;
        seIsLoading(true);
        const q = query(
          collection(firestoreDb, "follow", user.uid, "wallets"),
          limit(100)
        );
        const querySnapshot = await getDocs(q);
        let tempArray: any[] = [];
        querySnapshot.forEach((tempDoc) => {
          tempArray.push(tempDoc.data());
        });
        if (tempArray.length === 0) {
          const docRef = collection(firestoreDb, "follow", user.uid, "wallets");
          for (let i = 0; i < initWallets.length; i++) {
            await addDoc(docRef, initWallets[i]);
          }
          setListings(initWallets);
        } else {
          setListings(tempArray);
        }
        seIsLoading(false);
        externaLLoaded = false;
      } catch (error) {
        console.error("Error getting documents: ", error);
        seIsLoading(false);
        externaLLoaded = false;
      }
    };
    loadListings();
  }, []);

  /**
   * Deletes a listing with the specified ID.
   * @param idToDelete - The ID of the listing to delete.
   */

  const deleteListing = async (idToDelete: string) => {
    if (user === null || user === undefined) {
      return;
    }
    setDeletingId(idToDelete);
    try {
      const q = query(
        collection(firestoreDb, "follow", user.uid, "wallets"),
        where("wallet", "==", idToDelete)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (tempDoc) => {
        await deleteDoc(
          doc(firestoreDb, "follow", user.uid, "wallets", tempDoc.id)
        );
      });
      let tempArr: any[] = [...listings];
      tempArr = tempArr.filter(function (obj) {
        return obj.wallet !== idToDelete;
      });
      setDeletingId("");
      setListings(tempArr);
      snackbarState.setMessage("Wallet deleted");
      snackbarState.setOpenLength(4000);
      snackbarState.setIsOpen(true);
    } catch (error) {
      console.error(error);
      setDeletingId("");
      snackbarError.setMessage("Error: Something went wrong, please try again");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
    }
  };

  const onAddNewCancel = () => {
    if (isSendingNewWallet) return;
    setNewWallet("");
    setNewWalletName("");
    setAddNewDialogOpen(false);
  };

  const onAddNewSubmit = async () => {
    if (user === null || user === undefined) {
      return;
    }
    if (isSendingNewWallet) return;
    setIsSendingNewWallet(true);
    try {
      if (!isValidEthAddress(newWallet)) {
        try {
          const isEns = await web3Provider.eth.ens.getAddress(newWallet);
          if (!isValidEthAddress(isEns.toString())) {
            setIsSendingNewWallet(false);
            snackbarError.setMessage("Error: Invalid address or ENS");
            snackbarError.setOpenLength(4000);
            snackbarError.setIsOpen(true);
            return;
          }
        } catch (error) {
          console.error("Invalid ENS");
          console.error(error);
          setIsSendingNewWallet(false);
          snackbarError.setMessage("Error: Invalid address or ENS");
          snackbarError.setOpenLength(4000);
          snackbarError.setIsOpen(true);
          return;
        }
      }
      const q = query(
        collection(firestoreDb, "follow", user.uid, "wallets"),
        where("wallet", "==", newWallet)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (tempDoc) => {
        setIsSendingNewWallet(false);
        snackbarError.setMessage("Error: Wallet already exists");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        return;
      });
      const docRef = collection(firestoreDb, "follow", user.uid, "wallets");
      const newWalletObj = {
        wallet: newWallet,
        createdAt: Date.now(),
        name: newWalletName,
      };
      await addDoc(docRef, newWalletObj);
      setListings([...listings, newWalletObj]);
      setNewWallet("");
      setNewWalletName("");
      setAddNewDialogOpen(false);
      setIsSendingNewWallet(false);
      snackbarState.setMessage("Wallet added");
      snackbarState.setOpenLength(4000);
      snackbarState.setIsOpen(true);
      return;
    } catch (error) {
      console.error(error);
      setIsSendingNewWallet(false);
      snackbarError.setMessage("Error: Something went wrong, please try again");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }
  };

  useEffect(() => {
    headerSettingsState.setIsLogoOpen(true);
    tabSettingsState.setColor(globalTheme.figmaBeigeDark.color);
    return () => {
      headerSettingsState.setIsLogoOpen(false);
      tabSettingsState.setColor(globalTheme.figmaBeigeLight.color);
    };
  }, []);

  const styles = {
    dialogRoot: {
      zIndex: 9990,
      backgroundColor: "rgba(253, 247, 234, 0.5)",
      "& .MuiDialog-paper": {
        backgroundColor: globalTheme.figmaBeigeBG.color,
        color: globalTheme.figmaPrimaryText.color,
        width: "94%",
        maxWidth: "600px",
        margin: 0,
        paddingBottom: "30px",
        paddingTop: "100px",
        position: "absolute",
        top: "-10px",
        zIndex: 9990,
      },
    },
    dialogTitle: {
      color: globalTheme.figmaPrimaryText.color,
      textAlign: "center",
      fontSize: "16px",
      marginTop: "20px",
      fontWeight: 600,
    },
  };

  return (
    <div
      className="WalletOuter"
      style={{
        backgroundColor: globalTheme.figmaBeigeDark.color,
        marginTop: "-10px",
      }}
    >
      <WalletsListInfo />
      <div className="WalletHeaderLinks">
        <Link
          to="/wallets"
          className="ListingsPageTabsInner"
          style={{
            textDecoration: "none",
            width: "50%",
            paddingTop: "0px",
            paddingBottom: "0px",
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            borderRightWidth: "0.5px",
            borderRadius: "8px 0px 0px 8px",
          }}
        >
          <NewspaperOutlinedIcon
            color="inherit"
            sx={{
              color: globalTheme.figmaPrimaryText.color,
              fontSize: "16px",
              marginRight: "5px",
              "@media (min-width: 350px)": {
                fontSize: "17px",
              },
              "@media (min-width: 550px)": {
                fontSize: "18px",
              },
              "@media (min-width: 700px)": {
                fontSize: "19px",
              },
            }}
          />
          <p
            className="WalletHeaderLinksText"
            style={{ color: globalTheme.figmaPrimaryText.color }}
          >
            Latest
          </p>
        </Link>

        <div
          className="ListingsPageTabsInner"
          style={{
            width: "50%",
            backgroundColor: globalTheme.figmaPurplePastel.color,
            paddingTop: "0px",
            paddingBottom: "0px",
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            borderLeftWidth: "0.5px",
            borderRadius: "0px 8px 8px 0px",
          }}
        >
          <TokenOutlinedIcon
            color="inherit"
            sx={{
              color: globalTheme.figmaPrimaryText.color,
              fontSize: "16px",
              marginRight: "5px",
              "@media (min-width: 350px)": {
                fontSize: "17px",
              },
              "@media (min-width: 550px)": {
                fontSize: "18px",
              },
              "@media (min-width: 700px)": {
                fontSize: "19px",
              },
            }}
          />
          <p
            className="WalletHeaderLinksText"
            style={{ color: globalTheme.figmaPrimaryText.color }}
          >
            Wallets
          </p>
        </div>
      </div>
      {isLoading ? (
        <div className="WalletBodyLoading">
          <CircularProgress
            sx={{ color: globalTheme.figmaSecondaryText.color }}
          />
        </div>
      ) : (
        <div className="WalletBody">
          <div className="WalletBodyEditHeader">
            <IconButton
              size="small"
              sx={{
                marginBottom: "-7px",
              }}
              onClick={() => {
                setEditIsOpen(!editIsOpen);
              }}
            >
              <EditOutlinedIcon
                sx={{
                  color: globalTheme.figmaSecondaryText.color,
                  fontSize: "18px",
                }}
              />
            </IconButton>
          </div>
          {listings.map((walletItem, index) => {
            return (
              <div className="WalletListItemLinkOuter" key={index}>
                <WalletListItem
                  walletItem={walletItem}
                  editIsOpen={editIsOpen}
                />
                <div
                  className="WalletListItemDeleteOuter"
                  style={{
                    pointerEvents: editIsOpen ? "auto" : "none",
                    opacity: editIsOpen ? 1 : 0,
                    width: editIsOpen ? "50px" : "0px",
                  }}
                >
                  <IconButton
                    size="small"
                    disabled={deletingId !== ""}
                    sx={{
                      marginTop: "10px",
                    }}
                    onClick={() => {
                      deleteListing(walletItem.wallet);
                    }}
                  >
                    {deletingId === walletItem.wallet ? (
                      <CircularProgress
                        size={"20px"}
                        sx={{
                          color: globalTheme.figmaSecondaryText.color,
                        }}
                      />
                    ) : (
                      <DeleteOutlinedIcon
                        sx={{
                          color: globalTheme.figmaSecondaryText.color,
                          fontSize: "22px",
                        }}
                      />
                    )}
                  </IconButton>
                </div>
              </div>
            );
          })}
          {editIsOpen && (
            <Fab
              size="small"
              sx={{
                backgroundColor: globalTheme.figmaLightBG.color,
                position: "fixed",
                zIndex: 9985,
                right: "10px",
                bottom: "56px",
                transition: "all 0.25s ease-in-out",
                color: globalTheme.figmaDashboardIcon.color,
                "&:hover": {
                  backgroundColor: globalTheme.figmaLightBG.color,
                },
                "&:focus": {
                  backgroundColor: globalTheme.figmaLightBG.color,
                },
              }}
              onClick={() => setAddNewDialogOpen(true)}
            >
              <AddIcon />
            </Fab>
          )}

          <Dialog
            open={addNewDialogOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={onAddNewCancel}
            sx={styles.dialogRoot}
          >
            <DialogTitle sx={styles.dialogTitle}>
              {"Add new wallet to follow"}
            </DialogTitle>
            <DialogContent>
              <div className="NewWalletDialog">
                <TextField
                  label="Wallet Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={materialListingFormStyles}
                  value={newWalletName}
                  placeholder="Name for wallet"
                  onChange={(e) => setNewWalletName(e.target.value)}
                  disabled={isSendingNewWallet}
                />
                <TextField
                  label="Wallet address"
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{
                    ...materialListingFormStyles,
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  value={newWallet}
                  placeholder="Address of wallet"
                  onChange={(e) => setNewWallet(e.target.value)}
                  disabled={isSendingNewWallet}
                />
                <div className="WalletHeaderLinks">
                  <ButtonBase sx={{ width: "30%" }} onClick={onAddNewCancel}>
                    <div
                      className="ListingsPageTabsInner"
                      style={{
                        color: globalTheme.figmaPrimaryText.color,
                        textDecoration: "none",
                      }}
                    >
                      <CancelOutlinedIcon
                        color="inherit"
                        sx={{
                          fontSize: "16px",
                          marginRight: "5px",
                          "@media (min-width: 350px)": {
                            fontSize: "17px",
                          },
                          "@media (min-width: 550px)": {
                            fontSize: "18px",
                          },
                          "@media (min-width: 700px)": {
                            fontSize: "19px",
                          },
                        }}
                      />
                      <p className="WalletHeaderLinksText">Cancel</p>
                    </div>
                  </ButtonBase>

                  <ButtonBase sx={{ width: "30%" }} onClick={onAddNewSubmit}>
                    <div
                      className="ListingsPageTabsInner"
                      style={{
                        color: globalTheme.figmaPrimaryText.color,
                        textDecoration: "none",
                      }}
                    >
                      {isSendingNewWallet ? (
                        <CircularProgress
                          size={"15px"}
                          sx={{
                            color: globalTheme.figmaSecondaryText.color,
                            marginRight: "6px",
                          }}
                        />
                      ) : (
                        <TokenOutlinedIcon
                          color="inherit"
                          sx={{
                            fontSize: "16px",
                            marginRight: "5px",
                            "@media (min-width: 350px)": {
                              fontSize: "17px",
                            },
                            "@media (min-width: 550px)": {
                              fontSize: "18px",
                            },
                            "@media (min-width: 700px)": {
                              fontSize: "19px",
                            },
                          }}
                        />
                      )}
                      <p className="WalletHeaderLinksText">Add</p>
                    </div>
                  </ButtonBase>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </div>
  );
}
