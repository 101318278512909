/**
 * Component for rendering a user list item with different sizes and functionalities.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.listItem - The user list item data.
 * @param {number} props.indexKey - The index key of the list item.
 * @param {string} props.itemSize - The size of the list item ("small", "medium", "large").
 * @param {boolean} props.clickAdmin - Flag indicating if the admin click functionality is enabled.
 * 
 * @returns {JSX.Element} The rendered user list item component.
 * 
 * @author 
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { Tooltip, IconButton, ButtonBase } from "@mui/material";
import copy from "copy-to-clipboard";
import { useThemeState } from "../../utils/globalStates";
import {
  useSnackbarErrorState,
  useSnackbarState,
  useExploreSortState,
  useAdminState,
} from "../../utils/globalStates";
import { figmaColorVariables } from "../../theme/globalTheme";
import moment from "moment";
import { doc, onSnapshot, setDoc, deleteDoc } from "firebase/firestore";
import UserListItemNumber from "./components/UserListItemNumbers";
import UserVerifiedBadge from "../img/badges/UserVerifiedBadge";
import { firestoreDb } from "../../firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../firebase/auth";
import { PfpViewer } from "../img/PfpViewer";
import XLogo from "../img/socials/Xlogo";
import InstagramLogo from "../img/socials/InstagramLogo";
import FacebookLogo from "../img/socials/FacebookLogo";
import TelegramLogo from "../img/socials/TelegramLogo";
import "./ListItems.css";

moment().format();

export default function UserListItemNew({
  listItem,
  indexKey,
  itemSize,
  clickAdmin,
}: any) {
  const [user] = useAuthState(firebaseAuth);
  const navigate = useNavigate();
  const exploreSortState = useExploreSortState();
  const snackbarStates = useSnackbarState((state) => state);
  const snackbarError = useSnackbarErrorState((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const adminState = useAdminState((state) => state);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isFollowingLoading, setIsFollowingLoading] = useState(false);
  const [itemSizeState, setItemSizeState] = useState("large");

  useEffect(() => {
    if (itemSize === "small") {
      setItemSizeState("small");
    } else if (itemSize === "medium") {
      setItemSizeState("medium");
    } else if (itemSize === "large") {
      setItemSizeState("large");
    }
  }, [itemSize]);

  const onProjectClick = async () => {
    if (clickAdmin === true) {
      adminState.setActiveUser(listItem.id);
    }
  };

  // Set following or unfollowing
  const onFollowClick = async () => {
    if (user?.uid === undefined) return;
    if (isFollowingLoading === true) return;
    setIsFollowingLoading(true);
    if (isFollowing === true) {
      const followingRef = doc(
        firestoreDb,
        "follow",
        user?.uid,
        "userFollows",
        user?.uid + "-" + listItem.id
      );
      try {
        await deleteDoc(followingRef);
        setIsFollowingLoading(false);
        snackbarStates.setMessage("Unfollowed");
        snackbarStates.setOpenLength(3000);
        snackbarStates.setIsOpen(true);
      } catch (e) {
        console.error("Error updating document: ", e);
        setIsFollowingLoading(false);
        snackbarError.setMessage("Error unfollowing, please try again");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
      }
    } else {
      const followingRef = doc(
        firestoreDb,
        "follow",
        user?.uid,
        "userFollows",
        user?.uid + "-" + listItem.id
      );
      try {
        await setDoc(followingRef, {
          following: listItem.id,
          follower: user?.uid,
          followedAt: Date.now(),
        });
        setIsFollowingLoading(false);
        snackbarStates.setMessage("Followed");
        snackbarStates.setOpenLength(3000);
        snackbarStates.setIsOpen(true);
      } catch (e) {
        console.error("Error updating document: ", e);
        setIsFollowingLoading(false);
        snackbarError.setMessage("Error following, please try again");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
      }
    }
  };

  // Check if user is following
  useEffect(() => {
    if (user?.uid === undefined) return;
    const followingRef = doc(
      firestoreDb,
      "follow",
      user?.uid,
      "userFollows",
      user?.uid + "-" + listItem.id
    );
    const unsubscribe = onSnapshot(followingRef, (doc) => {
      if (doc.exists()) {
        setIsFollowing(true);
      } else {
        setIsFollowing(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [listItem.id, user?.uid]);

  const onShareClick = () => {
    /*logEvent(firebaseAnalytics, "user_share", {
      user_id: user?.uid,
    });*/
    copy(`https://app.gath3r.co/users/page/${listItem.id}`);
    snackbarStates.setMessage("Link copied to clipboard");
    snackbarStates.setOpenLength(3000);
    snackbarStates.setIsOpen(true);
  };

  const styles = {
    bookmarkIcon: {
      fontSize: "16px",
      padding: 0,
      color: globalTheme.iconNeutral.color,
      "@media (min-width: 400px)": {
        fontSize: "20px",
      },
      "@media (min-width: 600px)": {
        fontSize: "16px",
      },
      "@media (min-width: 800px)": {
        fontSize: "20px",
      },
    },

    bookmarkIconLarge: {
      fontSize: "12px",
      marginTop: "0px",
      marginLeft: "5px",
      padding: 0,
      color:
        listItem.disabled === true
          ? globalTheme.iconNeutral.color
          : figmaColorVariables.blue400,
      "@media (min-width: 400px)": {
        fontSize: "15px",
        marginTop: "-1px",
      },
      "@media (min-width: 600px)": {
        fontSize: "12px",
        marginTop: "0px",
      },
      "@media (min-width: 800px)": {
        fontSize: "15px",
        marginTop: "-1px",
      },
    },
    shareIcon: {
      fontSize: "14px",
      color: globalTheme.iconNeutral.color,
      "@media (min-width: 400px)": {
        fontSize: "18px",
      },
      "@media (min-width: 600px)": {
        fontSize: "14px",
      },
      "@media (min-width: 800px)": {
        fontSize: "18px",
      },
    },
    followButton: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: "3px 10px",
      marginRight: "5px",
      borderRadius: "99px",
      border: `1px solid ${
        listItem.disabled === true
          ? globalTheme.iconNeutral.color
          : figmaColorVariables.blue400
      }`,
    },
  };

  return itemSizeState === "small" ? (
    <div
      className="ProjectListItemSmall"
      style={{
        backgroundColor:
          listItem.disabled === true
            ? globalTheme.ruggedSurface.color
            : globalTheme.primarySurface.color,
        ...globalTheme.primaryBoxshadow,
      }}
    >
      <div className="ProjectListItemHeader">
        <Link
          to={clickAdmin === true ? "" : `/users/page/${listItem.id}`}
          style={{ textDecoration: "none" }}
          className="ProjectListItemHeaderClickable"
          onClick={onProjectClick}
        >
          {listItem.pfpString !== "" &&
          listItem.pfpString !== undefined &&
          listItem.pfpString !== null ? (
            <div className="ProjectListItemAvatar">
              <PfpViewer pfpString={listItem.pfpString} randomize={false} />
            </div>
          ) : (
            <div className="ProjectListItemAvatar">
              <PfpViewer pfpString={""} randomize={true} />
            </div>
          )}
          <div className="ProjectListItemHeaderTextOuterDivMedium">
            <div className="ProjectListItemHeaderTextOuterDiv">
              <div className="ProjectListItemHeaderTitleOuter">
                <p
                  className="ProjectListItemHeaderTitle"
                  style={{
                    color: globalTheme.primaryText.color,
                  }}
                >
                  {listItem.displayName === ""
                    ? "Anonymous user"
                    : listItem.displayName}
                </p>
                {listItem.verified === true && listItem.disabled !== true && (
                  <Tooltip title="Verified User">
                    <div className="ProjectListItemHeaderBadge">
                      <UserVerifiedBadge />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </Link>
        <div className="ProjectListItemHeaderIcons">
          {isFollowing ? (
            <Tooltip title="Unfollow">
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                }}
                size="small"
                onClick={onFollowClick}
              >
                <PersonRemoveOutlinedIcon sx={styles.bookmarkIcon} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Follow">
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                }}
                size="small"
                onClick={onFollowClick}
              >
                <PersonAddAlt1OutlinedIcon sx={styles.bookmarkIcon} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Share">
            <IconButton
              sx={{
                margin: 0,
                padding: 0,
                marginLeft: "5px",
              }}
              size="small"
              onClick={onShareClick}
            >
              <ShareOutlinedIcon sx={styles.shareIcon} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  ) : itemSizeState === "medium" ? (
    <div
      className="ProjectListItemMedium"
      style={{
        backgroundColor:
          listItem.disabled === true
            ? globalTheme.ruggedSurface.color
            : globalTheme.primarySurface.color,
        ...globalTheme.primaryBoxshadow,
      }}
    >
      <div className="ProjectListItemHeader">
        <Link
          to={clickAdmin === true ? "" : `/users/page/${listItem.id}`}
          style={{ textDecoration: "none", padding: "15px 0px" }}
          className="ProjectListItemHeaderClickable"
          onClick={onProjectClick}
        >
          {listItem.pfpString !== "" &&
          listItem.pfpString !== undefined &&
          listItem.pfpString !== null ? (
            <div className="ProjectListItemAvatarMedium">
              <PfpViewer pfpString={listItem.pfpString} randomize={false} />
            </div>
          ) : (
            <div className="ProjectListItemAvatarMedium">
              <PfpViewer pfpString={""} randomize={true} />
            </div>
          )}
          <div className="ProjectListItemHeaderTextOuterDivMedium">
            <div className="ProjectListItemHeaderTextOuterDiv">
              <div className="ProjectListItemHeaderTitleOuter">
                <p
                  className="ProjectListItemHeaderTitle"
                  style={{
                    color: globalTheme.primaryText.color,
                  }}
                >
                  {listItem.displayName === ""
                    ? "Anonymous user"
                    : listItem.displayName}
                </p>
                {listItem.verified === true && listItem.disabled !== true && (
                  <Tooltip title="Verified User">
                    <div className="ProjectListItemHeaderBadge">
                      <UserVerifiedBadge />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
            <div
              className="ProjectListItemHeaderRatingOuter"
              style={{ marginLeft: "8px" }}
            >
              <UserListItemNumber listItem={listItem} />
            </div>
          </div>
        </Link>
        <div className="ProjectListItemHeaderIconsMedium">
          {isFollowing ? (
            <Tooltip title="Unfollow">
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                  marginBottom: "3px",
                }}
                size="small"
                onClick={onFollowClick}
              >
                <PersonRemoveOutlinedIcon sx={styles.bookmarkIcon} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Follow">
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                  marginBottom: "3px",
                }}
                size="small"
                onClick={onFollowClick}
              >
                <PersonAddAlt1OutlinedIcon sx={styles.bookmarkIcon} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Share">
            <IconButton
              sx={{
                margin: 0,
                padding: 0,
                marginTop: "3px",
              }}
              size="small"
              onClick={onShareClick}
            >
              <ShareOutlinedIcon sx={styles.shareIcon} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="ProjectListItemLarge"
      style={{
        backgroundColor:
          listItem.disabled === true
            ? globalTheme.ruggedSurface.color
            : globalTheme.primarySurface.color,
        ...globalTheme.primaryBoxshadow,
      }}
    >
      <div className="ProjectListItemHeader">
        <Link
          to={clickAdmin === true ? "" : `/users/page/${listItem.id}`}
          style={{ textDecoration: "none" }}
          className="ProjectListItemHeaderClickable"
          onClick={onProjectClick}
        >
          {listItem.pfpString !== undefined &&
          listItem.pfpString !== null &&
          listItem.pfpString !== "" ? (
            <div className="ProjectListItemAvatarLarge">
              <PfpViewer pfpString={listItem.pfpString} randomize={false} />
            </div>
          ) : (
            <div className="ProjectListItemAvatarLarge">
              <PfpViewer pfpString={""} randomize={true} />
            </div>
          )}
          <div className="ProjectListItemHeaderTextOuterDivMedium">
            <div className="ProjectListItemHeaderTextOuterDiv">
              <div className="ProjectListItemHeaderTitleOuter">
                <p
                  className="ProjectListItemHeaderTitleLarge"
                  style={{
                    color: globalTheme.primaryText.color,
                  }}
                >
                  {listItem.displayName === ""
                    ? "Anonymous user"
                    : listItem.displayName}
                </p>
                {listItem.verified === true && listItem.disabled !== true && (
                  <Tooltip title="Verified User">
                    <div className="ProjectListItemHeaderBadgeLarge">
                      <UserVerifiedBadge />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </Link>
        <div
          className="ProjectListItemHeaderIconsMedium"
          style={{ justifyContent: "flex-start" }}
        >
          <Tooltip title="Share">
            <IconButton
              sx={{
                margin: 0,
                padding: 0,
                marginTop: "-15px",
              }}
              size="small"
              onClick={onShareClick}
            >
              <ShareOutlinedIcon sx={styles.shareIcon} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <Link
        to={clickAdmin === true ? "" : `/users/page/${listItem.id}`}
        style={{ textDecoration: "none" }}
        className="ProjectListItemInnerLarge"
        onClick={onProjectClick}
      >
        <div
          className="ProjectListItemHeaderRatingOuterLarge"
          style={{
            marginBottom: "5px",
          }}
        >
          <UserListItemNumber listItem={listItem} />
        </div>
        <p
          className="UserListItemInnerLargeText"
          style={{
            color: globalTheme.primaryText.color,
          }}
        >
          {listItem.about}
        </p>
      </Link>
      <div className="UserListItemInnerLargeFooter">
        <ButtonBase sx={styles.followButton} onClick={onFollowClick}>
          <p
            className="UserListItemFooterText"
            style={{
              color:
                listItem.disabled === true
                  ? globalTheme.iconNeutral.color
                  : figmaColorVariables.blue400,
            }}
          >
            {isFollowing ? "Unfollow" : "Follow"}
          </p>
          {isFollowing ? (
            <PersonRemoveOutlinedIcon sx={styles.bookmarkIconLarge} />
          ) : (
            <PersonAddAlt1OutlinedIcon sx={styles.bookmarkIconLarge} />
          )}
        </ButtonBase>
        {listItem.xHandle !== null &&
          listItem.xHandle !== undefined &&
          listItem.xHandle !== "" && (
            <a
              href={`https://x.com/${listItem.xHandle.replace(/^@/, "")}`}
              target="_blank"
              rel="noopener noreferrer"
              className="UserListItemFooterIcon"
            >
              <XLogo
                customColor={
                  listItem.disabled === true
                    ? globalTheme.iconNeutral.color
                    : figmaColorVariables.blue400
                }
              />
            </a>
          )}

        {listItem.instagramHandle !== null &&
          listItem.instagramHandle !== undefined &&
          listItem.instagramHandle !== "" && (
            <a
              href={`https://instagram.com/${listItem.instagramHandle.replace(
                /^@/,
                ""
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="UserListItemFooterIcon"
            >
              <InstagramLogo
                customColor={
                  listItem.disabled === true
                    ? globalTheme.iconNeutral.color
                    : figmaColorVariables.blue400
                }
              />
            </a>
          )}
        {listItem.facebookUrl !== null &&
          listItem.facebookUrl !== undefined &&
          listItem.facebookUrl !== "" && (
            <a
              href={listItem.facebookUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="UserListItemFooterIcon"
            >
              <FacebookLogo
                customColor={
                  listItem.disabled === true
                    ? globalTheme.iconNeutral.color
                    : figmaColorVariables.blue400
                }
              />
            </a>
          )}
        {listItem.telegramHandle !== null &&
          listItem.telegramHandle !== undefined &&
          listItem.telegramHandle !== "" && (
            <a
              href={`https://t.me/${listItem.telegramHandle.replace(/^@/, "")}`}
              target="_blank"
              rel="noopener noreferrer"
              className="UserListItemFooterIcon"
            >
              <TelegramLogo
                customColor={
                  listItem.disabled === true
                    ? globalTheme.iconNeutral.color
                    : figmaColorVariables.blue400
                }
              />
            </a>
          )}
      </div>
    </div>
  );
}
