/**
 * SocialConfig component allows users to configure their social media handles.
 * It supports X/Twitter, Instagram, Telegram, Facebook, and Discord handles.
 * 
 * The component uses Firebase functions to update the social media handles and
 * provides real-time validation for each handle.
 * 
 * @component
 * 
 * @example
 * return (
 *   <SocialConfig />
 * )
 * 
 * @returns {JSX.Element} The rendered SocialConfig component.
 * 
 * @remarks
 * This component relies on several custom hooks and utility functions:
 * - `useAuthState` from `react-firebase-hooks/auth` to get the current user state.
 * - `useSnackbarErrorState`, `useSnackbarState`, `useAuthStates`, and `useThemeState` from `globalStates` for managing global state.
 * - `isValidXHandle`, `isValidInstagramHandle`, `isValidFacebookUrl`, and `isValidTelegramHandle` for validating social media handles.
 * 
 * The component also listens for changes in the user's social media handles and updates the state accordingly.
 * 
 * @function
 * @name SocialConfig
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import React, { useState, useEffect } from "react";

import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../../components/firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../../components/firebase/auth";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TextField from "@mui/material/TextField";
import {
  isValidXHandle,
  isValidInstagramHandle,
  isValidFacebookUrl,
  isValidTelegramHandle,
} from "../../../../components/utils/parsers";

import { ButtonBase, CircularProgress } from "@mui/material";
import {
  useSnackbarErrorState,
  useSnackbarState,
  useAuthStates,
  useThemeState,
} from "../../../../components/utils/globalStates";
import "./ProfileSettingsComp.css";

export default function SocialConfig() {
  const [xHandle, setXHandle] = useState<string>("");
  const [xHandleError, setXHandleError] = useState<boolean>(false);
  const [xHandleHelperText, setXHandleHelperText] = useState<string>("");
  const [xHandleUpdating, setXHandleUpdating] = useState<boolean>(false);
  const [initialXHandle, setInitialXHandle] = useState<string>("");

  const [instagramHandle, setInstagramHandle] = useState<string>("");
  const [instagramHandleError, setInstagramHandleError] =
    useState<boolean>(false);
  const [instagramHandleHelperText, setInstagramHandleHelperText] =
    useState<string>("");
  const [instagramHandleUpdating, setInstagramHandleUpdating] =
    useState<boolean>(false);
  const [initialInstagramHandle, setInitialInstagramHandle] =
    useState<string>("");

  const [telegramHandle, setTelegramHandle] = useState<string>("");
  const [telegramHandleError, setTelegramHandleError] =
    useState<boolean>(false);
  const [telegramHandleHelperText, setTelegramHandleHelperText] =
    useState<string>("");
  const [telegramHandleUpdating, setTelegramHandleUpdating] =
    useState<boolean>(false);
  const [initialTelegramHandle, setInitialTelegramHandle] =
    useState<string>("");

  const [facebookUrl, setFacebookUrl] = useState<string>("");
  const [facebookUrlError, setFacebookUrlError] = useState<boolean>(false);
  const [facebookUrlHelperText, setFacebookUrlHelperText] =
    useState<string>("");
  const [facebookUrlUpdating, setFacebookUrlUpdating] =
    useState<boolean>(false);
  const [initialFacebookUrl, setInitialFacebookUrl] = useState<string>("");

  const [discordHandle, setDiscordHandle] = useState<string>("");
  const [discordHandleError, setDiscordHandleError] = useState<boolean>(false);
  const [discordHandleHelperText, setDiscordHandleHelperText] =
    useState<string>("");
  const [discordHandleUpdating, setDiscordHandleUpdating] =
    useState<boolean>(false);
  const [initialDiscordHandle, setInitialDiscordHandle] = useState<string>("");

  const [user, loading, error] = useAuthState(firebaseAuth);
  const snackbarStates = useSnackbarState((state) => state);
  const snackbarErrorStates = useSnackbarErrorState((state) => state);
  const authStates = useAuthStates((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);

  // Listener for valid xHandle
  useEffect(() => {
    if (xHandle === "") {
      setXHandleError(false);
      setXHandleHelperText("");
      return;
    }
    if (xHandle.length < 3) {
      setXHandleError(true);
      setXHandleHelperText("Invalid X/Twitter handle (@gath3rco)");
      return;
    }
    if (isValidXHandle(xHandle)) {
      setXHandleError(false);
      setXHandleHelperText("");
    } else {
      setXHandleError(true);
      setXHandleHelperText("Invalid X/Twitter handle (@gath3rco)");
    }
  }, [xHandle]);

  // Listener for valid instagramHandle
  useEffect(() => {
    if (instagramHandle === "") {
      setInstagramHandleError(false);
      setInstagramHandleHelperText("");
      return;
    }
    if (instagramHandle.length < 3) {
      setInstagramHandleError(true);
      setInstagramHandleHelperText("Invalid Instagram handle (gath3rco)");
      return;
    }
    if (isValidInstagramHandle(instagramHandle)) {
      setInstagramHandleError(false);
      setInstagramHandleHelperText("");
    } else {
      setInstagramHandleError(true);
      setInstagramHandleHelperText("Invalid Instagram handle (gath3rco)");
    }
  }, [instagramHandle]);

  // Listener for valid telegramHandle
  useEffect(() => {
    if (telegramHandle === "") {
      setTelegramHandleError(false);
      setTelegramHandleHelperText("");
      return;
    }
    if (telegramHandle.length < 3) {
      setTelegramHandleError(true);
      setTelegramHandleHelperText("Invalid Telegram handle (gath3rco)");
      return;
    }
    if (isValidTelegramHandle(telegramHandle)) {
      setTelegramHandleError(false);
      setTelegramHandleHelperText("");
    } else {
      setTelegramHandleError(true);
      setTelegramHandleHelperText("Invalid Telegram handle (gath3rco)");
    }
  }, [telegramHandle]);

  // Listener for valid facebookUrl
  useEffect(() => {
    if (facebookUrl === "") {
      setFacebookUrlError(false);
      setFacebookUrlHelperText("");
      return;
    }
    if (facebookUrl.length < 10) {
      setFacebookUrlError(true);
      setFacebookUrlHelperText("Invalid Facebook URL");
      return;
    }
    if (isValidFacebookUrl(facebookUrl)) {
      setFacebookUrlError(false);
      setFacebookUrlHelperText("");
    } else {
      setFacebookUrlError(true);
      setFacebookUrlHelperText("Invalid Facebook URL");
    }
  }, [facebookUrl]);

  // Listener for valid discordHandle
  useEffect(() => {
    if (discordHandle === "") {
      setDiscordHandleError(false);
      setDiscordHandleHelperText("");
      return;
    }
    if (discordHandle.length < 3) {
      setDiscordHandleError(true);
      setDiscordHandleHelperText("Invalid Discord handle (gath3rco)");
      return;
    }
    if (isValidTelegramHandle(discordHandle)) {
      setDiscordHandleError(false);
      setDiscordHandleHelperText("");
    } else {
      setDiscordHandleError(true);
      setDiscordHandleHelperText("Invalid Discord handle (gath3rco)");
    }
  }, [discordHandle]);

  // Listen for changes to authStates.userData for changes to x/twitter handles

  useEffect(() => {
    if (
      authStates.userData !== undefined &&
      authStates.userData !== null &&
      authStates.userData.xHandle !== undefined &&
      authStates.userData.xHandle !== null &&
      authStates.userData.xHandle !== initialXHandle
    ) {
      setXHandle(authStates.userData.xHandle);
      setInitialXHandle(authStates.userData.xHandle);
    }
  }, [authStates.userData]);

  // Listen for changes to authStates.userData for changes to telegram handles
  useEffect(() => {
    if (
      authStates.userData !== undefined &&
      authStates.userData !== null &&
      authStates.userData.telegramHandle !== undefined &&
      authStates.userData.telegramHandle !== null &&
      authStates.userData.telegramHandle !== initialTelegramHandle
    ) {
      setTelegramHandle(authStates.userData.telegramHandle);
      setInitialTelegramHandle(authStates.userData.telegramHandle);
    }
  }, [authStates.userData]);

  // Listen for changes to authStates.userData for changes to instagram handles
  useEffect(() => {
    if (
      authStates.userData !== undefined &&
      authStates.userData !== null &&
      authStates.userData.instagramHandle !== undefined &&
      authStates.userData.instagramHandle !== null &&
      authStates.userData.instagramHandle !== initialInstagramHandle
    ) {
      setInstagramHandle(authStates.userData.instagramHandle);
      setInitialInstagramHandle(authStates.userData.instagramHandle);
    }
  }, [authStates.userData]);

  // Listen for changes to authStates.userData for changes to facebook urls
  useEffect(() => {
    if (
      authStates.userData !== undefined &&
      authStates.userData !== null &&
      authStates.userData.facebookUrl !== undefined &&
      authStates.userData.facebookUrl !== null &&
      authStates.userData.facebookUrl !== initialFacebookUrl
    ) {
      setFacebookUrl(authStates.userData.facebookUrl);
      setInitialFacebookUrl(authStates.userData.facebookUrl);
    }
  }, [authStates.userData]);

  // Listen for changes to authStates.userData for changes to discord handles
  useEffect(() => {
    if (
      authStates.userData !== undefined &&
      authStates.userData !== null &&
      authStates.userData.discordHandle !== undefined &&
      authStates.userData.discordHandle !== null &&
      authStates.userData.discordHandle !== initialDiscordHandle
    ) {
      setDiscordHandle(authStates.userData.discordHandle);
      setInitialDiscordHandle(authStates.userData.discordHandle);
    }
  }, [authStates.userData]);

  const onUpdating = async (type: string, handle: string) => {
    if (user === null || user === undefined) {
      snackbarErrorStates.setMessage(
        "Please sign in to set social media handle"
      );
      snackbarErrorStates.setOpenLength(4000);
      snackbarErrorStates.setIsOpen(true);
      return;
    }
    if (handle === "") {
      snackbarErrorStates.setMessage("Social media handle cannot be empty");
      snackbarErrorStates.setOpenLength(4000);
      snackbarErrorStates.setIsOpen(true);
      return;
    }

    let tempHandle = handle

    const updateSocialHandle = httpsCallable(
      firebaseFunctions,
      "profile-social_update"
    );

    if (type === "twitter") {
      setXHandleUpdating(true);
      if (handle === initialXHandle) {
        tempHandle = "";
      }
    } else if (type === "instagram") {
      setInstagramHandleUpdating(true);
      if (handle === initialInstagramHandle) {
        tempHandle = "";
      }
    } else if (type === "telegram") {
      setTelegramHandleUpdating(true);
      if (handle === initialTelegramHandle) {
        tempHandle = "";
      }
    } else if (type === "facebook") {
      setFacebookUrlUpdating(true);
      if (handle === initialFacebookUrl) {
        tempHandle = "";
      }
    } else if (type === "discord") {
      setDiscordHandleUpdating(true);
      if (handle === initialDiscordHandle) {
        tempHandle = "";
      }
    } else {
      snackbarErrorStates.setMessage("Invalid social media type");
      snackbarErrorStates.setOpenLength(4000);
      snackbarErrorStates.setIsOpen(true);
      return;
    }

    const tokenCheck = await user.getIdToken();

    try {
      await updateSocialHandle({
        type: type,
        handle: tempHandle,
        token: tokenCheck,
      });

      if (type === "twitter") {
        setXHandleUpdating(false);
      } else if (type === "instagram") {
        setInstagramHandleUpdating(false);
      } else if (type === "telegram") {
        setTelegramHandleUpdating(false);
      } else if (type === "facebook") {
        setFacebookUrlUpdating(false);
      } else if (type === "discord") {
        setDiscordHandleUpdating(false);
      }

      snackbarStates.setMessage("Social media handle updated");
      snackbarStates.setOpenLength(4000);
      snackbarStates.setIsOpen(true);
    } catch (error: any) {
      if (type === "twitter") {
        setXHandleUpdating(false);
      } else if (type === "instagram") {
        setInstagramHandleUpdating(false);
      } else if (type === "telegram") {
        setTelegramHandleUpdating(false);
      } else if (type === "facebook") {
        setFacebookUrlUpdating(false);
      } else if (type === "discord") {
        setDiscordHandleUpdating(false);
      }

      snackbarErrorStates.setMessage(error.message);
      snackbarErrorStates.setOpenLength(4000);
      snackbarErrorStates.setIsOpen(true);
    }
  };

  const styles = {
  textField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      color: globalTheme.solidMainBlack.color,
      backgroundColor: globalTheme.solidMainWhite.color,
      boxShadow: "none",
      borderRadius: "5px",
      padding: "0px",
      fontSize: "14px",
      paddingLeft: "8px",
      paddingRight: "8px",
      border: "none",
      "& fieldset": {
        border: "none",
        color: globalTheme.solidMainBlack.color,
      },
      "&:hover fieldset": {
        border: "none",
        color: globalTheme.solidMainBlack.color,
      },
      "&.Mui-focused fieldset": {
        border: "none",
        color: globalTheme.solidMainBlack.color,
      },
    },
    "& label.Mui-focused": {
      border: "none",
      color: globalTheme.solidMainBlack.color,
    },
    "& label": {
      border: "none",
      color: globalTheme.solidMainBlack.color,
    },
  },
};

  return (
    <div className="SocialConfig">
      <div className="SocialConfigInner">
        <div className="SocialConfigTextField">
          <TextField
            sx={styles.textField}
            size="small"
            placeholder="......"
            value={xHandle}
            onChange={(event) => {
              setXHandle(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <React.Fragment>
                  <p
                    className="SocialConfigTextFieldLabel"
                    style={{
                      color: globalTheme.figmaPrimaryText.color,
                    }}
                  >
                    X/Twitter
                  </p>
                </React.Fragment>
              ),
              endAdornment: (
                <React.Fragment>
                  {xHandle !== "" && xHandle !== initialXHandle ? (
                    <CloseOutlinedIcon
                      onClick={() => {
                        setXHandle(initialXHandle);
                      }}
                      color="inherit"
                      sx={{
                        fontSize: "20px",
                        marginLeft: "5px",
                        opacity: 0.4,
                        cursor: "pointer",
                      }}
                    />
                  ) : null}
                </React.Fragment>
              ),
            }}
          />
        </div>
        <ButtonBase
          onClick={() => onUpdating("twitter", xHandle)}
          disabled={xHandle === "" || xHandleError || xHandleUpdating}
          style={{
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.figmaAlt2.color,
            width: "60px",
            marginLeft: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {xHandleUpdating ? (
            <CircularProgress
              size={13}
              color="inherit"
              sx={{ color: globalTheme.figmaPrimaryText.color }}
            />
          ) : (
            <p
              className="SocialConfigButtonText"
              style={{
                color:
                  xHandle === "" || xHandleError || xHandleUpdating
                    ? globalTheme.figmaPrimaryTextSub.color
                    : globalTheme.figmaPrimaryText.color,
              }}
            >
              {initialXHandle !== "" &&
              initialXHandle.toLowerCase() === xHandle.toLowerCase()
                ? "Delete"
                : xHandleUpdating
                ? "Saving"
                : initialXHandle !== ""
                ? "Update"
                : "Add"}
            </p>
          )}
        </ButtonBase>
      </div>
      <p
        className="SocialConfigHelperText"
        style={{
          color: globalTheme.textMainBlack.color,
        }}
      >
        {xHandleHelperText}
      </p>
      <div className="SocialConfigInner">
        <div className="SocialConfigTextField">
          <TextField
            placeholder="......"
            sx={styles.textField}
            size="small"
            value={telegramHandle}
            onChange={(event) => {
              setTelegramHandle(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <React.Fragment>
                  <p
                    className="SocialConfigTextFieldLabel"
                    style={{
                      color: globalTheme.figmaPrimaryText.color,
                    }}
                  >
                    Telegram
                  </p>
                </React.Fragment>
              ),
              endAdornment: (
                <React.Fragment>
                  {telegramHandle !== "" && telegramHandle !== initialTelegramHandle ? (
                    <CloseOutlinedIcon
                      onClick={() => {
                        setTelegramHandle(initialTelegramHandle);
                      }}
                      color="inherit"
                      sx={{
                        fontSize: "20px",
                        marginLeft: "5px",
                        opacity: 0.4,
                        cursor: "pointer",
                      }}
                    />
                  ) : null}
                </React.Fragment>
              ),
            }}
          />
        </div>
        <ButtonBase
          onClick={() => onUpdating("telegram", telegramHandle)}
          disabled={
            telegramHandle === "" ||
            telegramHandleError ||
            telegramHandleUpdating
          }
          style={{
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.figmaAlt2.color,
            width: "60px",
            marginLeft: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {telegramHandleUpdating ? (
            <CircularProgress
              size={13}
              color="inherit"
              sx={{ color: globalTheme.figmaPrimaryText.color }}
            />
          ) : (
            <p
              className="SocialConfigButtonText"
              style={{
                color:
                  telegramHandle === "" ||
                  telegramHandleError ||
                  telegramHandleUpdating
                    ? globalTheme.figmaPrimaryTextSub.color
                    : globalTheme.figmaPrimaryText.color,
              }}
            >
              {initialTelegramHandle !== "" &&
              initialTelegramHandle.toLowerCase() ===
                telegramHandle.toLowerCase()
                ? "Delete"
                : telegramHandleUpdating
                ? "Saving"
                : initialTelegramHandle !== ""
                ? "Update"
                : "Add"}
            </p>
          )}
        </ButtonBase>
      </div>
      <p
        className="SocialConfigHelperText"
        style={{
          color: globalTheme.textMainBlack.color,
        }}
      >
        {telegramHandleHelperText}
      </p>
      <div className="SocialConfigInner">
        <div className="SocialConfigTextField">
          <TextField
            placeholder="......"
            sx={styles.textField}
            size="small"
            value={instagramHandle}
            onChange={(event) => {
              setInstagramHandle(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <React.Fragment>
                  <p
                    className="SocialConfigTextFieldLabel"
                    style={{
                      color: globalTheme.figmaPrimaryText.color,
                    }}
                  >
                    Instagram
                  </p>
                </React.Fragment>
              ),
              endAdornment: (
                <React.Fragment>
                  {instagramHandle !== "" && instagramHandle !== initialInstagramHandle ? (
                    <CloseOutlinedIcon
                      onClick={() => {
                        setInstagramHandle(initialInstagramHandle);
                      }}
                      color="inherit"
                      sx={{
                        fontSize: "20px",
                        marginLeft: "5px",
                        opacity: 0.4,
                        cursor: "pointer",
                      }}
                    />
                  ) : null}
                </React.Fragment>
              ),
            }}
          />
        </div>
        <ButtonBase
          onClick={() => onUpdating("instagram", instagramHandle)}
          disabled={
            instagramHandle === "" ||
            instagramHandleError ||
            instagramHandleUpdating
          }
          style={{
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.figmaAlt2.color,
            width: "60px",
            marginLeft: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {instagramHandleUpdating ? (
            <CircularProgress
              size={13}
              color="inherit"
              sx={{ color: globalTheme.figmaPrimaryText.color }}
            />
          ) : (
            <p
              className="SocialConfigButtonText"
              style={{
                color:
                  instagramHandle === "" ||
                  instagramHandleError ||
                  instagramHandleUpdating
                    ? globalTheme.figmaPrimaryTextSub.color
                    : globalTheme.figmaPrimaryText.color,
              }}
            >
              {initialInstagramHandle !== "" &&
              initialInstagramHandle.toLowerCase() ===
                instagramHandle.toLowerCase()
                ? "Delete"
                : instagramHandleUpdating
                ? "Saving"
                : initialInstagramHandle !== ""
                ? "Update"
                : "Add"}
            </p>
          )}
        </ButtonBase>
      </div>
      <p
        className="SocialConfigHelperText"
        style={{
          color: globalTheme.textMainBlack.color,
        }}
      >
        {instagramHandleHelperText}
      </p>
      <div className="SocialConfigInner">
        <div className="SocialConfigTextField">
          <TextField
            placeholder="......"
            sx={styles.textField}
            size="small"
            value={facebookUrl}
            onChange={(event) => {
              setFacebookUrl(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <React.Fragment>
                  <p
                    className="SocialConfigTextFieldLabel"
                    style={{
                      color: globalTheme.figmaPrimaryText.color,
                    }}
                  >
                    Facebook
                  </p>
                </React.Fragment>
              ),
              endAdornment: (
                <React.Fragment>
                  {facebookUrl !== "" && facebookUrl !== initialFacebookUrl ? (
                    <CloseOutlinedIcon
                      onClick={() => {
                        setFacebookUrl(initialFacebookUrl);
                      }}
                      color="inherit"
                      sx={{
                        fontSize: "20px",
                        marginLeft: "5px",
                        opacity: 0.4,
                        cursor: "pointer",
                      }}
                    />
                  ) : null}
                </React.Fragment>
              ),
            }}
          />
        </div>
        <ButtonBase
          onClick={() => onUpdating("facebook", facebookUrl)}
          disabled={
            facebookUrl === "" || facebookUrlError || facebookUrlUpdating
          }
          style={{
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.figmaAlt2.color,
            width: "60px",
            marginLeft: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {facebookUrlUpdating ? (
            <CircularProgress
              size={13}
              color="inherit"
              sx={{ color: globalTheme.figmaPrimaryText.color }}
            />
          ) : (
            <p
              className="SocialConfigButtonText"
              style={{
                color:
                  facebookUrl === "" || facebookUrlError || facebookUrlUpdating
                    ? globalTheme.figmaPrimaryTextSub.color
                    : globalTheme.figmaPrimaryText.color,
              }}
            >
              {initialFacebookUrl !== "" &&
              initialFacebookUrl.toLowerCase() === facebookUrl.toLowerCase()
                ? "Delete"
                : facebookUrlUpdating
                ? "Saving"
                : initialFacebookUrl !== ""
                ? "Update"
                : "Add"}
            </p>
          )}
        </ButtonBase>
      </div>
      <p
        className="SocialConfigHelperText"
        style={{
          color: globalTheme.textMainBlack.color,
        }}
      >
        {facebookUrlHelperText}
      </p>
      <div className="SocialConfigInner">
        <div className="SocialConfigTextField">
          <TextField
            placeholder="......"
            sx={styles.textField}
            size="small"
            value={discordHandle}
            onChange={(event) => {
              setDiscordHandle(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <React.Fragment>
                  <p
                    className="SocialConfigTextFieldLabel"
                    style={{
                      color: globalTheme.figmaPrimaryText.color,
                    }}
                  >
                    Discord
                  </p>
                </React.Fragment>
              ),
              endAdornment: (
                <React.Fragment>
                  {discordHandle !== "" && discordHandle !== initialDiscordHandle ? (
                    <CloseOutlinedIcon
                      onClick={() => {
                        setDiscordHandle(initialDiscordHandle);
                      }}
                      color="inherit"
                      sx={{
                        fontSize: "20px",
                        marginLeft: "5px",
                        opacity: 0.4,
                        cursor: "pointer",
                      }}
                    />
                  ) : null}
                </React.Fragment>
              ),
            }}
          />
        </div>
        <ButtonBase
          onClick={() => onUpdating("discord", discordHandle)}
          disabled={
            discordHandle === "" || discordHandleError || discordHandleUpdating
          }
          style={{
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.figmaAlt2.color,
            width: "60px",
            marginLeft: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {discordHandleUpdating ? (
            <CircularProgress
              size={13}
              color="inherit"
              sx={{ color: globalTheme.figmaPrimaryText.color }}
            />
          ) : (
            <p
              className="SocialConfigButtonText"
              style={{
                color:
                  discordHandle === "" ||
                  discordHandleError ||
                  discordHandleUpdating
                    ? globalTheme.figmaPrimaryTextSub.color
                    : globalTheme.figmaPrimaryText.color,
              }}
            >
              {initialDiscordHandle !== "" &&
              initialDiscordHandle.toLowerCase() === discordHandle.toLowerCase()
                ? "Delete"
                : discordHandleUpdating
                ? "Saving"
                : initialDiscordHandle !== ""
                ? "Update"
                : "Add"}
            </p>
          )}
        </ButtonBase>
      </div>
      <p
        className="SocialConfigHelperText"
        style={{
          color: globalTheme.textMainBlack.color,
        }}
      >
        {discordHandleHelperText}
      </p>
    </div>
  );
}

// /profile/settings,/explore,/profile/bookmarks,/listings
