

/**
 * AdminListingDialog component provides an interface for administrators to manage listings.
 * It allows administrators to perform various actions such as editing, verifying, deleting,
 * moving to quarantine, marking as rugged, reviving, and featuring listings.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.listingId - The ID of the listing to be managed.
 * @param {number} props.lastUpdatedAt - The timestamp of the last update to the listing.
 * @param {string} props.listingName - The name of the listing.
 * @param {string} props.goToDialog - The dialog to navigate to.
 *
 * @returns {JSX.Element} The AdminListingDialog component.
 *
 * @example
 * <AdminListingDialog
 *   listingId="12345"
 *   lastUpdatedAt={1627849200000}
 *   listingName="Sample Listing"
 *   goToDialog="featured"
 * />
 *
 * @remarks
 * This component uses Firebase for data fetching and updating, and Material-UI for UI components.
 * It also includes AI auto-fill functionality to automatically populate listing details.
 *
 * @todo
 * - Implement the merge functionality.
 * - Improve error handling and user feedback.
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
// @ts-nocheck
import React, { useEffect, useState } from "react";
import moment from "moment";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button, ButtonBase } from "@mui/material";
import { categoryEnums } from "../../../components/utils/enums";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "../../../components/firebase/analytics";
import AdminLogoUpload from "../components/AdminLogoUpload";
import AdminFeaturedImageUpload from "../components/AdminFeaturedImageUpload";
import CircularProgress from "@mui/material/CircularProgress";

import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../components/firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import {
  doc,
  updateDoc,
  deleteDoc,
  setDoc,
  onSnapshot,
} from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";
import {
  useSnackbarState,
  useSnackbarErrorState,
  useThemeState,
  useAdminState,
} from "../../../components/utils/globalStates";
import {
  standardDialogTitle,
  standardDialogText,
} from "../../../components/theme/globalMuiTheme";
import { figmaColorVariables } from "../../../components/theme/globalTheme";
import "./ListingDialogs.css";

moment().format();
let isClicked = false;
export default function AdminListingDialog({
  listingId,
  lastUpdatedAt,
  listingName,
  goToDialog,
}: any) {
  const [user] = useAuthState(firebaseAuth);
  const snackbarState = useSnackbarState();
  const snackbarErrorState = useSnackbarErrorState();
  const adminState = useAdminState();
  const globalTheme = useThemeState((state) => state.globalTheme);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listingData, setListingData] = useState<any>(null);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [category, setCategory] = useState("");
  const [xHandle, setXHandle] = useState("");
  const [instagramHandle, setInstagramHandle] = useState("");
  const [telegramHandle, setTelegramHandle] = useState("");
  const [mediumHandle, setMediumHandle] = useState("");
  const [substackHandle, setSubstackHandle] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [logo, setLogo] = useState("");

  const [featuredTitle, setFeaturedTitle] = useState("");
  const [featuredDescription, setFeaturedDescription] = useState("");
  const [featuredImage, setFeaturedImage] = useState("");

  const [isVerfying, setIsVerfying] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmMove, setConfirmMove] = useState(false);
  const [confirmVerify, setConfirmVerify] = useState(false);
  const [confirmMerge, setConfirmMerge] = useState(false);
  const [confirmFeatured, setConfirmFeatured] = useState(false);
  const [confirmRugged, setConfirmRugged] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);
  const [isAiLoading, setIsAiLoading] = useState(false);

  useEffect(() => {
    if (isClicked) return;
    isClicked = true;
    setTimeout(() => {
      logEvent(firebaseAnalytics, "listing_click", {
        listing_id: listingId,
      });
      isClicked = false;
    }, 3000);
  }, []);

  // Fetch listing data from listings collection
  useEffect(() => {
    if (listingId === undefined || listingId === "" || listingId === null) {
      snackbarErrorState.setMessage("Listing not found");
      snackbarErrorState.setOpenLength(4000);
      snackbarErrorState.setIsOpen(true);
      return adminState.setActiveListing("");
    }

    setIsLoading(true);
    const listingRef = doc(firestoreDb, "listings", listingId);
    const unsubscribe = onSnapshot(listingRef, (doc) => {
      if (doc.exists()) {
        console.log("Document data:", doc.data());
        setListingData(doc.data());
        setIsLoading(false);
      } else {
        snackbarErrorState.setMessage("Listing not found");
        snackbarErrorState.setOpenLength(4000);
        snackbarErrorState.setIsOpen(true);
        return adminState.setActiveListing("");
      }
    });
    return () => {
      unsubscribe();
    };
  }, [listingId]);

  // Update changes to listing properties, set to useState

  useEffect(() => {
    onClear();
  }, [lastUpdatedAt]);

  useEffect(() => {
    if (goToDialog === "featured") {
      setConfirmFeatured(true);
    } else if (goToDialog === "rugged") {
      setConfirmRugged(true);
    }
  }, []);

  const onClear = () => {
    if (listingData === null) return;
    setName(listingData.name);
    setDescription(listingData.description);
    setWebsite(listingData.website);
    setCategory(listingData.category);
    setXHandle(listingData.xHandle);
    setInstagramHandle(listingData.instagramHandle);
    setTelegramHandle(listingData.telegramHandle);
    setMediumHandle(listingData.mediumHandle);
    setSubstackHandle(listingData.substackHandle);
    setFacebookUrl(listingData.facebookUrl);
    setLogo(listingData.logo);
  };

  // Update all states when listing data is fetched
  useEffect(() => {
    if (listingData === null) return;
    setName(listingData.name);
    setDescription(listingData.description);
    setWebsite(listingData.website);
    setCategory(listingData.category);
    setXHandle(listingData.xHandle);
    setInstagramHandle(listingData.instagramHandle);
    setTelegramHandle(listingData.telegramHandle);
    setMediumHandle(listingData.mediumHandle);
    setSubstackHandle(listingData.substackHandle);
    setFacebookUrl(listingData.facebookUrl);
    setLogo(listingData.logo);
    setFeaturedTitle(
      featuredTitle !== "" ? featuredTitle : listingData.featuredTitle || ""
    );
    setFeaturedDescription(
      featuredDescription !== ""
        ? featuredDescription
        : listingData.featuredDescription || ""
    );
    setFeaturedImage(listingData.featuredImage || "");
  }, [listingData]);

  // Update listing in database
  const onUpdate = async () => {
    if (listingData === null) return;
    try {
      setIsUpdating(true);
      setConfirmEdit(false);
      await updateDoc(doc(firestoreDb, "listings", listingId), {
        name: name,
        description: description,
        website: website,
        category: category,
        xHandle: xHandle,
        instagramHandle: instagramHandle,
        telegramHandle: telegramHandle,
        mediumHandle: mediumHandle,
        substackHandle: substackHandle,
        facebookUrl: facebookUrl,
        logo: logo,
        updatedAt: Date.now(),
        isVerified: true,
      });
      setIsUpdating(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setMessage('Listing "' + listingId + '" updated.');
      snackbarState.setIsOpen(true);
      adminState.setActiveListing("");
    } catch (err) {
      console.error(err);
      setIsUpdating(false);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage("Error updating listing " + listingId);
      snackbarErrorState.setIsOpen(true);
    }
  };

  // Verify listing in database
  const onVerify = async () => {
    if (listingData === null) return;
    try {
      setIsVerfying(true);
      setConfirmVerify(false);
      await updateDoc(doc(firestoreDb, "listings", listingId), {
        manualVerified: true,
        updatedAt: Date.now(),
      });
      setIsVerfying(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setMessage('Listing "' + listingId + '" verified.');
      snackbarState.setIsOpen(true);
    } catch (err) {
      console.error(err);
      setIsVerfying(false);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage("Error verifying listing " + listingId);
      snackbarErrorState.setIsOpen(true);
    }
  };

  // Unverify listing in database
  const onUnverify = async () => {
    if (listingData === null) return;
    try {
      setIsVerfying(true);
      setConfirmVerify(false);
      await updateDoc(doc(firestoreDb, "listings", listingId), {
        manualVerified: false,
        updatedAt: Date.now(),
      });
      setIsVerfying(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setMessage('Listing "' + listingId + '" unverified.');
      snackbarState.setIsOpen(true);
    } catch (err) {
      console.error(err);
      setIsVerfying(false);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage("Error unverifying listing " + listingId);
      snackbarErrorState.setIsOpen(true);
    }
  };

  // Mark listing as rugged in database
  const onRugged = async () => {
    if (listingData === null) return;
    try {
      setIsVerfying(true);
      setConfirmRugged(false);
      await updateDoc(doc(firestoreDb, "listings", listingId), {
        rugged: true,
        updatedAt: Date.now(),
      });
      setIsVerfying(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setMessage('Listing "' + listingId + '" marked as rugged.');
      snackbarState.setIsOpen(true);
    } catch (err) {
      console.error(err);
      setIsVerfying(false);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage(
        "Error marking listing " + listingId + " as rugged."
      );
      snackbarErrorState.setIsOpen(true);
    }
  };

  // Revive listing from rugged in database
  const onRevive = async () => {
    if (listingData === null) return;
    try {
      setIsVerfying(true);
      setConfirmRugged(false);
      await updateDoc(doc(firestoreDb, "listings", listingId), {
        rugged: false,
        revivedAt: Date.now(),
        updatedAt: Date.now(),
      });
      setIsVerfying(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setMessage('Listing "' + listingId + '" revived.');
      snackbarState.setIsOpen(true);
    } catch (err) {
      console.error(err);
      setIsVerfying(false);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage("Error reviving listing " + listingId);
      snackbarErrorState.setIsOpen(true);
    }
  };

  // Mark listing as featured in database
  const onFeatured = async () => {
    if (listingData === null) return;
    if (
      featuredTitle === "" ||
      featuredDescription === "" ||
      featuredImage === ""
    ) {
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage(
        "Featured title, description and image are required."
      );
      snackbarErrorState.setIsOpen(true);
      return;
    }
    try {
      setIsVerfying(true);
      setConfirmFeatured(false);
      await updateDoc(doc(firestoreDb, "listings", listingId), {
        featured: true,
        featuredTitle: featuredTitle,
        featuredDescription: featuredDescription,
        featuredImage: featuredImage,
        featuredUpdatedAt: Date.now(),
        updatedAt: Date.now(),
      });
      setIsVerfying(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setMessage(
        'Listing "' + listingId + '" marked as featured.'
      );
      snackbarState.setIsOpen(true);
    } catch (err) {
      console.error(err);
      setIsVerfying(false);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage(
        "Error marking listing " + listingId + " as featured."
      );
      snackbarErrorState.setIsOpen(true);
    }
  };

  // Remove featured from listing in database
  const onRemoveFeatured = async () => {
    if (listingData === null) return;
    if (listingData.featured === false) {
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage("Listing is not featured.");
      snackbarErrorState.setIsOpen(true);
      return;
    }
    try {
      setIsVerfying(true);
      setConfirmFeatured(false);
      await updateDoc(doc(firestoreDb, "listings", listingId), {
        featured: false,
        featuredUpdatedAt: Date.now(),
        updatedAt: Date.now(),
      });
      setIsVerfying(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setMessage(
        'Listing "' + listingId + '" removed from featured.'
      );
      snackbarState.setIsOpen(true);
    } catch (err) {
      console.error(err);
      setIsVerfying(false);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage(
        "Error removing listing " + listingId + " from featured."
      );
      snackbarErrorState.setIsOpen(true);
    }
  };

  const onDelete = async () => {
    if (listingData === null) return;
    try {
      setIsDeleting(true);
      await deleteDoc(doc(firestoreDb, "listings", listingId));
      setIsDeleting(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setMessage('Listing "' + listingId + '" deleted.');
      snackbarState.setIsOpen(true);
      adminState.setActiveListing("");
    } catch (err) {
      console.error(err);
      setIsDeleting(false);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage("Error deleting listing " + listingId);
      snackbarErrorState.setIsOpen(true);
    }
  };

  const onMove = async () => {
    if (listingData === null) return;
    try {
      setIsMoving(true);
      await setDoc(doc(firestoreDb, "errorListings", listingId), listingData);
      await deleteDoc(doc(firestoreDb, "listings", listingId));
      setIsMoving(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setMessage('Listing "' + listingId + '" moved.');
      snackbarState.setIsOpen(true);
      adminState.setActiveListing("");
    } catch (err) {
      console.error(err);
      setIsMoving(false);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage("Error moving listing " + listingId);
      snackbarErrorState.setIsOpen(true);
    }
  };

  // On AI auto fill send to backend
  const autoFillAi = async () => {
    if (name === "") {
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage("Name are required.");
      snackbarErrorState.setIsOpen(true);
      return;
    }

    setIsAiLoading(true);
    snackbarState.setMessage("Performing Ai auto fill.");
    snackbarState.setOpenLength(null);
    snackbarState.setIsActionLoading(true);
    snackbarState.setIsOpen(true);

    try {
      const token = await user?.getIdToken();
      const autofillCallable = httpsCallable(
        firebaseFunctions,
        "ai-auto_fill_v2"
      );
      const result = await autofillCallable({
        name: name,
        description: description,
        website: website,
        category: category,
        xHandle: xHandle,
        instagramHandle: instagramHandle,
        telegramHandle: telegramHandle,
        mediumHandle: mediumHandle,
        substackHandle: substackHandle,
        facebookUrl: facebookUrl,
        logoUrl: logo,
        token: token,
      });
      // @ts-ignore
      let resultString =
        result.data.choices[0].message.tool_calls[0].function.arguments;
      const parsedResult = JSON.parse(resultString);
      setName(parsedResult.name);
      setDescription(parsedResult.description);
      setWebsite(parsedResult.website);
      setXHandle(parsedResult.xHandle);
      setInstagramHandle(parsedResult.instagramHandle);
      setTelegramHandle(parsedResult.telegramHandle);
      setMediumHandle(parsedResult.mediumHandle);
      setSubstackHandle(parsedResult.substackHandle);
      setFacebookUrl(parsedResult.facebookUrl);
      setLogo(parsedResult.logoUrl);
      setIsAiLoading(false);
      snackbarState.setMessage("Ai auto fill completed.");
      snackbarState.setIsActionLoading(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setIsOpen(true);
    } catch (error) {
      console.error(error);
      setIsAiLoading(false);
      snackbarState.setIsOpen(false);
      snackbarState.setIsActionLoading(false);
      snackbarState.setMessage("");
      snackbarErrorState.setMessage("Error performing Ai auto fill.");
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setIsOpen(true);
    }
  };

  const materialListingFormStyles = {
    "& .MuiOutlinedInput-root": {
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color,
      "& fieldset": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color,
      },
      "&:hover fieldset": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color,
      },
      "&.Mui-focused fieldset": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color,
      },
    },
    "& label.Mui-focused": {
      color: globalTheme.backgroundMainOffBeige.color,
    },
    "& label": {
      color: globalTheme.backgroundMainOffBeige.color,
    },
  };

  const buttonStyles = {
    color: "#1A1A1A",
    backgroundColor: "#FFED8F",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    boxShadow: globalTheme.boxShadow,
    border: "1px solid #1A1A1A",
    flex: 1,
    margin: "5px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
  };

  return confirmDelete === false &&
    confirmMove === false &&
    confirmVerify === false &&
    confirmRugged === false &&
    confirmEdit === false &&
    confirmFeatured === false ? (
    <div className="AdminListDialog">
      <DialogTitle
        sx={{
          ...standardDialogTitle,
          width: "calc(100% - 8px)",
          marginLeft: "4px",
        }}
      >
        Admin Settings
        <br />
        <span style={{ fontSize: "55%", fontWeight: 400 }}>
          Remove, Edit, Merge, Verify, or send <b>{listingName}</b> to
          quarantine in admin panel.
        </span>
      </DialogTitle>
      <div className="AdminListDialogEditButtons">
        <ButtonBase sx={buttonStyles} onClick={() => setConfirmDelete(true)}>
          Remove
        </ButtonBase>
        <ButtonBase sx={buttonStyles} onClick={() => setConfirmEdit(true)}>
          Edit
        </ButtonBase>
        <ButtonBase
          sx={{ ...buttonStyles, textDecoration: "line-through" }}
          disabled
        >
          Merge
        </ButtonBase>
        <ButtonBase onClick={() => setConfirmVerify(true)} sx={buttonStyles}>
          {listingData?.manualVerified ? "Unverify" : "Verify"}
        </ButtonBase>
        <ButtonBase sx={buttonStyles} onClick={() => setConfirmMove(true)}>
          Quarantine
        </ButtonBase>
        <ButtonBase
          onClick={() => setConfirmRugged(true)}
          sx={{ ...buttonStyles }}
        >
          {listingData?.rugged ? "Revive" : "Rugged"}
        </ButtonBase>
        <ButtonBase
          onClick={() => setConfirmFeatured(true)}
          sx={{ ...buttonStyles }}
        >
          {listingData?.featured ? "Remove Featured" : "Add Featured"}
        </ButtonBase>
      </div>
    </div>
  ) : confirmDelete ? (
    <div className="AdminListDialog">
      <DialogTitle
        sx={{
          ...standardDialogTitle,
          width: "calc(100% - 8px)",
          marginLeft: "4px",
          lineHeight: "110%",
        }}
      >
        Remove {listingName}
        <br />
        <span style={{ fontSize: "55%", fontWeight: 400 }}>
          Are you sure you want to remove listing <b>{listingName}</b>?
        </span>
        <br />
        <span style={{ fontSize: "55%", fontWeight: 400, color: "red" }}>
          This will remove the listing and all it’s ratings/reviews from public
          search results.
        </span>
      </DialogTitle>
      <div className="AdminListDialogEditButtons">
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonRed.color,
            flex: "unset",
          }}
          onClick={() => setConfirmDelete(false)}
          disabled={isDeleting}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonGreen.color,
            flex: "unset",
          }}
          disabled={isDeleting}
          onClick={onDelete}
        >
          {isDeleting ? "Removing..." : "Yes, remove the listing"}
        </ButtonBase>
      </div>
    </div>
  ) : confirmMove ? (
    <div className="AdminListDialog">
      <DialogTitle
        sx={{
          ...standardDialogTitle,
          width: "calc(100% - 8px)",
          marginLeft: "4px",
          lineHeight: "110%",
        }}
      >
        Quarantine {listingName}
        <br />
        <span style={{ fontSize: "55%", fontWeight: 400 }}>
          Send this listing to the quarantine folder in the ADMIN PANEL.
        </span>
        <br />
        <span style={{ fontSize: "55%", fontWeight: 400, color: "red" }}>
          This will remove the listing and all it’s ratings/reviews from public
          search results.
        </span>
      </DialogTitle>
      <div className="AdminListDialogEditButtons">
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonRed.color,
            flex: "unset",
          }}
          onClick={() => setConfirmMove(false)}
          disabled={isMoving}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonGreen.color,
            flex: "unset",
          }}
          onClick={onMove}
          disabled={isMoving}
        >
          Yes, move the listing
        </ButtonBase>
      </div>
    </div>
  ) : confirmVerify ? (
    <div className="AdminListDialog">
      <DialogTitle
        sx={{
          ...standardDialogTitle,
          width: "calc(100% - 8px)",
          marginLeft: "4px",
          lineHeight: "110%",
        }}
      >
        {listingData?.manualVerified ? "Remove verification" : "Verify"}
        <br />
        <span style={{ fontSize: "55%", fontWeight: 400 }}>
          {listingData?.manualVerified
            ? `Are you sure you want to remove verification from ${
                listingName !== null &&
                listingName !== undefined &&
                listingName !== ""
                  ? listingName
                  : "listing"
              }?`
            : `Verify ${
                listingName !== null &&
                listingName !== undefined &&
                listingName !== ""
                  ? listingName
                  : "this listing"
              } is correct and reviewed by a GATH3R auditor.`}
        </span>
      </DialogTitle>
      <div className="AdminListDialogEditButtons">
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonRed.color,
            flex: "unset",
          }}
          onClick={() => setConfirmVerify(false)}
          disabled={isVerfying}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonGreen.color,
            flex: "unset",
          }}
          onClick={listingData?.manualVerified ? onUnverify : onVerify}
          disabled={isVerfying}
        >
          {listingData?.manualVerified
            ? "Remove Verification"
            : "Verify Listing"}
        </ButtonBase>
      </div>
    </div>
  ) : confirmRugged ? (
    <div className="AdminListDialog">
      <DialogTitle
        sx={{
          ...standardDialogTitle,
          width: "calc(100% - 8px)",
          marginLeft: "4px",
          lineHeight: "110%",
        }}
      >
        {listingData?.rugged ? "Revive" : "Rugged"}
        <br />
        <span style={{ fontSize: "55%", fontWeight: 400 }}>
          {listingData?.rugged
            ? `Are you sure you want to revive ${
                listingName !== null &&
                listingName !== undefined &&
                listingName !== ""
                  ? listingName
                  : "this listing"
              }?`
            : `This project is known to be a “rug” this action will mark ${
                listingName !== null &&
                listingName !== undefined &&
                listingName !== ""
                  ? listingName
                  : "the listing"
              }  as such.`}
        </span>
        <br />
        <span style={{ fontSize: "55%", fontWeight: 400, color: "red" }}>
          {listingData?.rugged
            ? "This will remove the rug badge from the listing."
            : "Rugged listings will have a “rug” badge and also be sorted into separate category."}
        </span>
      </DialogTitle>
      <div className="AdminListDialogEditButtons">
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonRed.color,
            flex: "unset",
          }}
          onClick={() => setConfirmRugged(false)}
          disabled={isVerfying}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonGreen.color,
            flex: "unset",
          }}
          onClick={listingData?.rugged ? onRevive : onRugged}
          disabled={isVerfying}
        >
          {listingData?.rugged ? "Revive" : "Rugged"}
        </ButtonBase>
      </div>
    </div>
  ) : confirmFeatured ? (
    <div className="AdminListDialog">
      <DialogTitle
        sx={{
          ...standardDialogTitle,
          width: "calc(100% - 8px)",
          marginLeft: "4px",
          lineHeight: "110%",
          marginBottom: "10px",
        }}
      >
        {listingData?.featured ? "Remove Featured Project" : "Featured Project"}
        <br />
        <span style={{ fontSize: "55%", fontWeight: 400 }}>
          {listingData?.featured
            ? `Are you sure you want to remove Featured highlight from ${
                listingName !== null &&
                listingName !== undefined &&
                listingName !== ""
                  ? listingName
                  : "listing"
              }?`
            : `This will highlight ${
                listingName !== null &&
                listingName !== undefined &&
                listingName !== ""
                  ? listingName
                  : "the listing"
              } in all Featured Projects sections.`}
        </span>
      </DialogTitle>

      <TextField
        label="Featured Title"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          ...materialListingFormStyles,
          marginBottom: "20px",
          width: "calc(100% - 10px)",
        }}
        value={featuredTitle}
        onChange={(e) => setFeaturedTitle(e.target.value)}
      />
      <TextField
        label="Featured Description"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          ...materialListingFormStyles,
          marginBottom: "20px",
          width: "calc(100% - 10px)",
        }}
        value={featuredDescription}
        multiline
        minRows={4}
        onChange={(e) => setFeaturedDescription(e.target.value)}
      />
      <TextField
        label="Featured Image URL"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          ...materialListingFormStyles,
          marginBottom: "20px",
          width: "calc(100% - 10px)",
        }}
        value={featuredImage}
        onChange={(e) => setFeaturedImage(e.target.value)}
        InputProps={{
          endAdornment:
            featuredImage !== "" ? (
              <Button
                variant="text"
                size="small"
                sx={{
                  ...globalTheme.backgroundMainOffBeige,
                }}
                href={featuredImage}
                target="_blank"
                rel="noopener noreferrer"
              >
                GO TO
              </Button>
            ) : null,
        }}
      />
      <AdminFeaturedImageUpload listingId={listingId} />
      <div className="AdminListDialogEditButtons">
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonRed.color,
            flex: "unset",
          }}
          onClick={() => setConfirmFeatured(false)}
          disabled={isVerfying}
        >
          Cancel
        </ButtonBase>
        {listingData?.featured && (
          <ButtonBase
            sx={{
              ...buttonStyles,
              backgroundColor: figmaColorVariables.red200,
              flex: "unset",
            }}
            disabled={isVerfying}
            onClick={onRemoveFeatured}
          >
            Remove Featured Highlight
          </ButtonBase>
        )}
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonGreen.color,
            flex: "unset",
          }}
          disabled={
            isVerfying ||
            featuredTitle === "" ||
            featuredDescription === "" ||
            featuredImage === ""
          }
          onClick={onFeatured}
        >
          {listingData?.featured
            ? "Update Featured Highlight"
            : "Add Featured Highlight"}
        </ButtonBase>
      </div>
    </div>
  ) : confirmEdit ? (
    <div className="AdminListDialog">
      <DialogTitle
        sx={{
          ...standardDialogTitle,
          width: "calc(100% - 8px)",
          marginLeft: "4px",
          lineHeight: "110%",
        }}
      >
        Edit {listingName}
        <br />
        <span style={{ fontSize: "55%", fontWeight: 400 }}>
          Make an instant edit to this listing.
        </span>
      </DialogTitle>
      <div
        style={{
          width: "calc(100% - 10px)",
          height: "2px",
          backgroundColor: globalTheme.backgroundMainOffBeige.color,
        }}
      ></div>
      <DialogContentText
        sx={{
          ...standardDialogText,
          fontWeight: 400,
          color: globalTheme.textMainGrey.color,
          marginTop: "10px",
          marginBottom: "30px",
          textAlign: "left",
          width: "calc(100% - 10px)",
        }}
      >
        Edit and make changes to the listing, then hit update.
      </DialogContentText>
      <TextField
        label="Name"
        variant="outlined"
        fullWidth
        size="small"
        sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
        value={name}
        placeholder="Name"
        onChange={(e) => setName(e.target.value)}
        InputProps={{
          endAdornment: name !== listingData.name && (
            <Button
              variant="text"
              size="small"
              sx={{ ...globalTheme.backgroundMainOffBeige }}
              onClick={() => setName(listingData.name)}
            >
              RESET
            </Button>
          ),
        }}
      />
      <TextField
        label="Category"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          ...materialListingFormStyles,
          marginBottom: "20px",
          "& svg": { color: globalTheme.backgroundMainOffBeige.color },
        }}
        value={category}
        SelectProps={{ MenuProps: { sx: { zIndex: 9999 } } }}
        onChange={(e) => setCategory(e.target.value)}
        select
        InputProps={{
          endAdornment: category !== listingData.category && (
            <Button
              variant="text"
              size="small"
              sx={{
                ...globalTheme.backgroundMainOffBeige,
                marginRight: "5%",
              }}
              onClick={() => setCategory(listingData.category)}
            >
              RESET
            </Button>
          ),
        }}
      >
        {categoryEnums.map((catEnum) => (
          <MenuItem key={catEnum.value} value={catEnum.displayname}>
            {catEnum.displayname}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Description"
        variant="outlined"
        fullWidth
        size="small"
        sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
        value={description}
        placeholder="Description"
        multiline
        minRows={4}
        onChange={(e) => setDescription(e.target.value)}
        InputProps={{
          endAdornment: description !== listingData.description && (
            <Button
              variant="text"
              size="small"
              sx={{ ...globalTheme.backgroundMainOffBeige }}
              onClick={() => setDescription(listingData.description)}
            >
              RESET
            </Button>
          ),
        }}
      />
      <TextField
        label="Website"
        variant="outlined"
        fullWidth
        size="small"
        sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
        value={website}
        placeholder="Website"
        onChange={(e) => setWebsite(e.target.value)}
        InputProps={{
          endAdornment:
            website !== listingData.website ? (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.backgroundMainOffBeige }}
                onClick={() => setWebsite(listingData.website)}
              >
                RESET
              </Button>
            ) : (
              listingData.website !== "" && (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    ...globalTheme.backgroundMainOffBeige,
                  }}
                  href={website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO
                </Button>
              )
            ),
        }}
      />
      <TextField
        label="Logo URL"
        variant="outlined"
        fullWidth
        size="small"
        sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
        value={logo}
        onChange={(e) => setLogo(e.target.value)}
        InputProps={{
          endAdornment:
            logo !== listingData.logo ? (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.backgroundMainOffBeige }}
                onClick={() => setLogo(listingData.logo)}
              >
                RESET
              </Button>
            ) : listingData.logo !== "" ? (
              <Button
                variant="text"
                size="small"
                sx={{
                  ...globalTheme.backgroundMainOffBeige,
                }}
                href={logo}
                target="_blank"
                rel="noopener noreferrer"
              >
                GO TO
              </Button>
            ) : null,
        }}
      />
      <TextField
        label="X Handle"
        variant="outlined"
        fullWidth
        size="small"
        sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
        value={xHandle}
        onChange={(e) => setXHandle(e.target.value)}
        InputProps={{
          endAdornment:
            xHandle !== listingData.xHandle ? (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.backgroundMainOffBeige }}
                onClick={() => setXHandle(listingData.xHandle)}
              >
                RESET
              </Button>
            ) : listingData.xHandle !== null &&
              listingData.xHandle !== undefined &&
              listingData.xHandle !== "" ? (
              <Button
                variant="text"
                size="small"
                sx={{
                  ...globalTheme.backgroundMainOffBeige,
                }}
                href={"https://twitter.com/" + xHandle.replace("@", "")}
                target="_blank"
                rel="noopener noreferrer"
              >
                GO TO
              </Button>
            ) : null,
        }}
      />
      <TextField
        label="Instagram Handle"
        variant="outlined"
        fullWidth
        size="small"
        sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
        value={instagramHandle}
        onChange={(e) => setInstagramHandle(e.target.value)}
        InputProps={{
          endAdornment:
            instagramHandle !== listingData.instagramHandle ? (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.backgroundMainOffBeige }}
                onClick={() => setInstagramHandle(listingData.instagramHandle)}
              >
                RESET
              </Button>
            ) : listingData.instagramHandle !== null &&
              listingData.instagramHandle !== undefined &&
              listingData.instagramHandle !== "" ? (
              <Button
                variant="text"
                size="small"
                sx={{
                  ...globalTheme.backgroundMainOffBeige,
                }}
                href={
                  "https://instagram.com/" + instagramHandle.replace("@", "")
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                GO TO
              </Button>
            ) : null,
        }}
      />
      <TextField
        label="Medium Handle"
        variant="outlined"
        fullWidth
        size="small"
        sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
        value={mediumHandle}
        onChange={(e) => setMediumHandle(e.target.value)}
        InputProps={{
          endAdornment:
            mediumHandle !== listingData.mediumHandle ? (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.backgroundMainOffBeige }}
                onClick={() => setMediumHandle(listingData.mediumHandle)}
              >
                RESET
              </Button>
            ) : listingData.mediumHandle !== null &&
              listingData.mediumHandle !== undefined &&
              listingData.mediumHandle !== "" ? (
              <Button
                variant="text"
                size="small"
                sx={{
                  ...globalTheme.backgroundMainOffBeige,
                }}
                href={"https://medium.com/@" + mediumHandle.replace("@", "")}
                target="_blank"
                rel="noopener noreferrer"
              >
                GO TO
              </Button>
            ) : null,
        }}
      />
      <TextField
        label="Telegram Handle"
        variant="outlined"
        fullWidth
        size="small"
        sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
        value={telegramHandle}
        onChange={(e) => setTelegramHandle(e.target.value)}
        InputProps={{
          endAdornment:
            telegramHandle !== listingData.telegramHandle ? (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.backgroundMainOffBeige }}
                onClick={() => setTelegramHandle(listingData.telegramHandle)}
              >
                RESET
              </Button>
            ) : listingData.telegramHandle !== null &&
              listingData.telegramHandle !== undefined &&
              listingData.telegramHandle !== "" ? (
              <Button
                variant="text"
                size="small"
                sx={{
                  ...globalTheme.backgroundMainOffBeige,
                }}
                href={"https://t.me/" + telegramHandle.replace("@", "")}
                target="_blank"
                rel="noopener noreferrer"
              >
                GO TO
              </Button>
            ) : null,
        }}
      />
      <TextField
        label="Facebook URL"
        variant="outlined"
        fullWidth
        size="small"
        sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
        value={facebookUrl}
        onChange={(e) => setFacebookUrl(e.target.value)}
        InputProps={{
          endAdornment:
            facebookUrl !== listingData.facebookUrl ? (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.backgroundMainOffBeige }}
                onClick={() => setFacebookUrl(listingData.facebookUrl)}
              >
                RESET
              </Button>
            ) : listingData.facebookUrl !== null &&
              listingData.facebookUrl !== undefined &&
              listingData.facebookUrl !== "" ? (
              <Button
                variant="text"
                size="small"
                sx={{
                  ...globalTheme.backgroundMainOffBeige,
                }}
                href={facebookUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                GO TO
              </Button>
            ) : null,
        }}
      />
      <TextField
        label="Substack Handle"
        variant="outlined"
        fullWidth
        size="small"
        sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
        value={substackHandle}
        onChange={(e) => setSubstackHandle(e.target.value)}
        InputProps={{
          endAdornment:
            substackHandle !== listingData.substackHandle ? (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.backgroundMainOffBeige }}
                onClick={() => setSubstackHandle(listingData.substackHandle)}
              >
                RESET
              </Button>
            ) : listingData.substackHandle !== null &&
              listingData.substackHandle !== undefined &&
              listingData.substackHandle !== "" ? (
              <Button
                variant="text"
                size="small"
                sx={{
                  ...globalTheme.backgroundMainOffBeige,
                }}
                href={"https://substack.com/" + substackHandle.replace("@", "")}
                target="_blank"
                rel="noopener noreferrer"
              >
                GO TO
              </Button>
            ) : null,
        }}
      />
      <AdminLogoUpload listingId={listingId} />
      <div
        className="AdminListDialogEditButtons"
        style={{ justifyContent: "space-between", marginTop: "30px" }}
      >
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonRed.color,
            flex: "unset",
          }}
          onClick={() => setConfirmEdit(false)}
          disabled={isUpdating || isAiLoading}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonRed.color,
            flex: "unset",
            backgroundColor: "#FFED8F",
          }}
          onClick={autoFillAi}
          disabled={isUpdating || isAiLoading}
        >
          AI Auto Fill Information
        </ButtonBase>
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonRed.color,
            flex: "unset",
            backgroundColor: "#FFED8F",
          }}
          onClick={onClear}
          disabled={isUpdating || isAiLoading}
        >
          Reset
        </ButtonBase>
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonGreen.color,
            flex: "unset",
          }}
          onClick={onUpdate}
          disabled={isUpdating || isAiLoading}
        >
          Update
        </ButtonBase>
      </div>
    </div>
  ) : null;
}

/*
isLoading ? (
    <div className="AdminListDialog">
      <CircularProgress
        size={30}
        style={{
          color: globalTheme.backgroundMainOffBeige.color,
          margin: "auto",
        }}
      />
    </div>
  ) : (
    <div className="AdminListDialog">
      <p
        style={{ ...globalTheme.backgroundMainOffBeige }}
        className="AdminListDialogTitle"
      >
        {listingId}
      </p>
      <p
        className="AdminDialogDescription"
        style={{ ...globalTheme.backgroundMainOffBeige }}
      >
        Edit, delete or move to error listings
      </p>
      <div className="AdminListDialogForms">
        <TextField
          label="Category"
          variant="outlined"
          fullWidth
          size="small"
          sx={{
            ...materialListingFormStyles,
            marginBottom: "20px",
            "& svg": { color: globalTheme.backgroundMainOffBeige.color },
          }}
          value={category}
          SelectProps={{ MenuProps: { sx: { zIndex: 9999 } } }}
          onChange={(e) => setCategory(e.target.value)}
          select
          InputProps={{
            endAdornment: category !== listingData.category && (
              <Button
                variant="text"
                size="small"
                sx={{
                  ...globalTheme.backgroundMainOffBeige,
                  marginRight: "5%",
                }}
                onClick={() => setCategory(listingData.category)}
              >
                RESET
              </Button>
            ),
          }}
        >
          {categoryEnums.map((catEnum) => (
            <MenuItem key={catEnum.value} value={catEnum.displayname}>
              {catEnum.displayname}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={name}
          placeholder="Name"
          onChange={(e) => setName(e.target.value)}
          InputProps={{
            endAdornment: name !== listingData.name && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.backgroundMainOffBeige }}
                onClick={() => setName(listingData.name)}
              >
                RESET
              </Button>
            ),
          }}
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={description}
          placeholder="Description"
          multiline
          minRows={4}
          onChange={(e) => setDescription(e.target.value)}
          InputProps={{
            endAdornment: description !== listingData.description && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.backgroundMainOffBeige }}
                onClick={() => setDescription(listingData.description)}
              >
                RESET
              </Button>
            ),
          }}
        />
        <TextField
          label="Website"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={website}
          placeholder="Website"
          onChange={(e) => setWebsite(e.target.value)}
          InputProps={{
            endAdornment:
              website !== listingData.website ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ ...globalTheme.backgroundMainOffBeige }}
                  onClick={() => setWebsite(listingData.website)}
                >
                  RESET
                </Button>
              ) : (
                listingData.website !== "" && (
                  <Button
                    variant="text"
                    size="small"
                    sx={{
                      ...globalTheme.backgroundMainOffBeige,
                    }}
                    href={website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    GO TO
                  </Button>
                )
              ),
          }}
        />
        <TextField
          label="Logo URL"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={logo}
          onChange={(e) => setLogo(e.target.value)}
          InputProps={{
            endAdornment:
              logo !== listingData.logo ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ ...globalTheme.backgroundMainOffBeige }}
                  onClick={() => setLogo(listingData.logo)}
                >
                  RESET
                </Button>
              ) : listingData.logo !== "" ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    ...globalTheme.backgroundMainOffBeige,
                  }}
                  href={logo}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO
                </Button>
              ) : null,
          }}
        />
        <TextField
          label="X Handle"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={xHandle}
          onChange={(e) => setXHandle(e.target.value)}
          InputProps={{
            endAdornment:
              xHandle !== listingData.xHandle ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ ...globalTheme.backgroundMainOffBeige }}
                  onClick={() => setXHandle(listingData.xHandle)}
                >
                  RESET
                </Button>
              ) : listingData.xHandle !== null &&
                listingData.xHandle !== undefined &&
                listingData.xHandle !== "" ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    ...globalTheme.backgroundMainOffBeige,
                  }}
                  href={"https://twitter.com/" + xHandle.replace("@", "")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO
                </Button>
              ) : null,
          }}
        />
        <TextField
          label="Instagram Handle"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={instagramHandle}
          onChange={(e) => setInstagramHandle(e.target.value)}
          InputProps={{
            endAdornment:
              instagramHandle !== listingData.instagramHandle ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ ...globalTheme.backgroundMainOffBeige }}
                  onClick={() =>
                    setInstagramHandle(listingData.instagramHandle)
                  }
                >
                  RESET
                </Button>
              ) : listingData.instagramHandle !== null &&
                listingData.instagramHandle !== undefined &&
                listingData.instagramHandle !== "" ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    ...globalTheme.backgroundMainOffBeige,
                  }}
                  href={
                    "https://instagram.com/" + instagramHandle.replace("@", "")
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO
                </Button>
              ) : null,
          }}
        />
        <TextField
          label="Medium Handle"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={mediumHandle}
          onChange={(e) => setMediumHandle(e.target.value)}
          InputProps={{
            endAdornment:
              mediumHandle !== listingData.mediumHandle ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ ...globalTheme.backgroundMainOffBeige }}
                  onClick={() => setMediumHandle(listingData.mediumHandle)}
                >
                  RESET
                </Button>
              ) : listingData.mediumHandle !== null &&
                listingData.mediumHandle !== undefined &&
                listingData.mediumHandle !== "" ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    ...globalTheme.backgroundMainOffBeige,
                  }}
                  href={"https://medium.com/@" + mediumHandle.replace("@", "")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO
                </Button>
              ) : null,
          }}
        />
        <TextField
          label="Telegram Handle"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={telegramHandle}
          onChange={(e) => setTelegramHandle(e.target.value)}
          InputProps={{
            endAdornment:
              telegramHandle !== listingData.telegramHandle ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ ...globalTheme.backgroundMainOffBeige }}
                  onClick={() => setTelegramHandle(listingData.telegramHandle)}
                >
                  RESET
                </Button>
              ) : listingData.telegramHandle !== null &&
                listingData.telegramHandle !== undefined &&
                listingData.telegramHandle !== "" ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    ...globalTheme.backgroundMainOffBeige,
                  }}
                  href={"https://t.me/" + telegramHandle.replace("@", "")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO
                </Button>
              ) : null,
          }}
        />
        <TextField
          label="Facebook URL"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={facebookUrl}
          onChange={(e) => setFacebookUrl(e.target.value)}
          InputProps={{
            endAdornment:
              facebookUrl !== listingData.facebookUrl ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ ...globalTheme.backgroundMainOffBeige }}
                  onClick={() => setFacebookUrl(listingData.facebookUrl)}
                >
                  RESET
                </Button>
              ) : listingData.facebookUrl !== null &&
                listingData.facebookUrl !== undefined &&
                listingData.facebookUrl !== "" ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    ...globalTheme.backgroundMainOffBeige,
                  }}
                  href={facebookUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO
                </Button>
              ) : null,
          }}
        />
        <TextField
          label="Substack Handle"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={substackHandle}
          onChange={(e) => setSubstackHandle(e.target.value)}
          InputProps={{
            endAdornment:
              substackHandle !== listingData.substackHandle ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ ...globalTheme.backgroundMainOffBeige }}
                  onClick={() => setSubstackHandle(listingData.substackHandle)}
                >
                  RESET
                </Button>
              ) : listingData.substackHandle !== null &&
                listingData.substackHandle !== undefined &&
                listingData.substackHandle !== "" ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    ...globalTheme.backgroundMainOffBeige,
                  }}
                  href={
                    "https://substack.com/" + substackHandle.replace("@", "")
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO
                </Button>
              ) : null,
          }}
        />
        <AdminLogoUpload listingId={listingId} />
        {confirmDelete ? (
          <div
            className="AdminListDialogButtons"
            style={{ ...globalTheme.backgroundMainOffBeige }}
          >
            <p className="AdminListDialogConfirmTitle">
              Confirm deletion of listing "{listingId}"?
            </p>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ marginRight: "10px", fontSize: "12px" }}
              onClick={() => setConfirmDelete(false)}
              disabled={isUpdating || isMoving || isDeleting}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ marginRight: "0px", fontSize: "12px" }}
              onClick={onDelete}
              disabled={isUpdating || isMoving || isDeleting}
            >
              {isDeleting ? "Deleting..." : "Delete"}
            </Button>
          </div>
        ) : confirmMove ? (
          <div
            className="AdminListDialogButtons"
            style={{ ...globalTheme.backgroundMainOffBeige }}
          >
            <p className="AdminListDialogConfirmTitle">
              Confirm move of listing "{listingId}" to error listings?
            </p>
            <Button
              variant="outlined"
              color="inherit"
              sx={{ marginRight: "10px", fontSize: "12px" }}
              onClick={() => setConfirmMove(false)}
              size="small"
              disabled={isUpdating || isMoving || isDeleting}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              sx={{ marginRight: "0px", fontSize: "12px" }}
              onClick={onMove}
              size="small"
              disabled={isUpdating || isMoving || isDeleting}
            >
              {isMoving ? "Moving..." : "Move"}
            </Button>
          </div>
        ) : (
          <>
            <div
              className="AdminListDialogButtons"
              style={{
                ...globalTheme.backgroundMainOffBeige,
                marginBottom: "0px",
              }}
            >
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                sx={{ fontSize: "12px", width: "30%" }}
                onClick={() => setConfirmDelete(true)}
                disabled={isUpdating || isMoving || isDeleting}
              >
                {isDeleting ? "Deleting..." : "Delete"}
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                sx={{ fontSize: "12px", width: "30%" }}
                onClick={onClear}
                size="small"
                disabled={isUpdating || isMoving || isDeleting}
              >
                Reset
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                sx={{ fontSize: "12px", width: "30%" }}
                onClick={autoFillAi}
                disabled={
                  name === "" ||
                  isAiLoading ||
                  isUpdating ||
                  isMoving ||
                  isDeleting
                }
              >
                Ai Auto Fill
              </Button>
            </div>
            <div
              className="AdminListDialogButtons"
              style={{
                ...globalTheme.backgroundMainOffBeige,
                marginTop: "20px",
              }}
            >
              <Button
                variant="outlined"
                color="inherit"
                sx={{ fontSize: "12px", width: "30%" }}
                onClick={onVerify}
                size="small"
                disabled={
                  isUpdating ||
                  isMoving ||
                  isDeleting ||
                  isVerfying ||
                  (listingData.manualVerified !== null &&
                    listingData.manualVerified !== undefined &&
                    listingData.manualVerified === true)
                }
              >
                {listingData.manualVerified !== null &&
                listingData.manualVerified !== undefined &&
                listingData.manualVerified === true
                  ? "Verified"
                  : isVerfying
                  ? "Verifying..."
                  : "Verify"}
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                sx={{ fontSize: "12px", width: "30%" }}
                onClick={onUpdate}
                size="small"
                disabled={isUpdating || isMoving || isDeleting}
              >
                {isUpdating ? "Updating..." : "Update"}
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                sx={{ fontSize: "12px", width: "30%" }}
                onClick={() => setConfirmMove(true)}
                disabled={isUpdating || isMoving || isDeleting}
              >
                Move
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
*/
