/**
 * Component that renders an individual item in the achievements list.
 * 
 * @component
 * @param {Object} props - The component props
 * @param {Object} props.listItem - The data for the list item
 * @param {string} [props.listItem.url] - The URL of the image for the list item
 * @param {string} props.listItem.icon - The icon to display if there is no image URL
 * @param {string} props.listItem.name - The name of the achievement
 * @param {string} props.listItem.subtitle - The subtitle of the achievement
 * @param {number} props.listItem.count - The number of times the achievement has been collected
 * @param {number} props.listItem.points - The points associated with the achievement
 * 
 * @returns {JSX.Element} The rendered component
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { LazyLoadImg } from "../../../components/ui/misc/LazyLoadImg";
import { useThemeState } from "../../../components/utils/globalStates";
import "./AchievementsComp.css";

export default function AchievementsListItem({ listItem }: { listItem: any }) {

  const globalTheme = useThemeState((state) => state.globalTheme);
  return (
    <div
      className="AchievementsListItem"
      style={{
        backgroundColor: globalTheme.figmaBeigeLight.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
      }}
    >
      {listItem.url !== null &&
      listItem.url !== undefined &&
      listItem.url !== "" ? (
        <div className="AchievementsListItemImg">
          <LazyLoadImg imgUrl={listItem.url} />
        </div>
      ) : (
        <div className="AchievementsListItemImg">
          <span
            style={{
              marginTop: "0px",
              color: globalTheme.figmaPrimaryText.color,
            }}
            className="material-symbols-outlined"
          >
            {listItem.icon}
          </span>
        </div>
      )}
      <p
        className="AchievementsListItemTitle"
        style={{
          color: globalTheme.figmaPrimaryText.color,
        }}
      >
        {listItem.name.toUpperCase()}
      </p>
      <p
        className="AchievementsListItemSub"
        style={{
          color: globalTheme.figmaPrimaryTextSub.color,
        }}
      >
        {listItem.subtitle}
      </p>
      <div
        className="AchievementsListItemRow"
        style={{
          backgroundColor: globalTheme.figmaSecondaryText.color,
        }}
      >
        <div className="AchievementsListItemRowInner">
          <p
            className="AchievementsListItemRowTitle"
            style={{
              color: globalTheme.figmaBeigeLight.color,
            }}
          >
            Collected
          </p>
          <p
            className="AchievementsListItemRowSub"
            style={{
              color: globalTheme.figmaBeigeLight.color,
            }}
          >
            {listItem.count}
          </p>
        </div>
        <div className="AchievementsListItemRowInner">
          <p
            className="AchievementsListItemRowTitle"
            style={{
              color: globalTheme.figmaBeigeLight.color,
            }}
          >
            Points
          </p>
          <p
            className="AchievementsListItemRowSub"
            style={{
              color: globalTheme.figmaBeigeLight.color,
            }}
          >
            {listItem.points}
          </p>
        </div>
      </div>
    </div>
  );
}
