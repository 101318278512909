/**
 * The `ProjectsStart` component sets up the routing for project listings.
 * It uses `react-router-dom` to define a route that matches `/:listingId`
 * and renders the `ProjectPageV2` component when the route is matched.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { Routes, Route } from "react-router-dom";
import ProjectPageV2 from "./page/ProjectPageV2";
import "./Listings.css";

export default function ProjectsStart() {
  return (
    <div className="Listings">
      <Routes>
        <Route path="/:listingId" element={<ProjectPageV2 />} />
      </Routes>
    </div>
  );
}
