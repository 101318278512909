/**
 * The `Listings` component is responsible for rendering the listings page.
 * It uses React Router to define a route for displaying individual project pages.
 *
 * @component
 * @example
 * return (
 *   <Listings />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { Routes, Route } from "react-router-dom";
import ProjectPageV2 from "./page/ProjectPageV2";
import "./Listings.css";

export default function Listings() {
  return (
    <div className="Listings">
      <Routes>
        <Route path="/page/:listingId" element={<ProjectPageV2 />} />
      </Routes>
    </div>
  );
}
