/**
 * Component to display an achievement item in the inventory.
 *
 * @param {Object} props - The properties object.
 * @param {any} props.achievementData - The data for the achievement item.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { LazyLoadImg } from "../../../components/ui/misc/LazyLoadImg";
import moment from "moment";
import { useThemeState } from "../../../components/utils/globalStates";
import "./AchievementsProfile.css";

moment().format();

export default function AchievementsInventoryItem({
  achievementData,
}: {
  achievementData: any;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  return (
    <div className="AchievementsInventoryItem">
      <div className="AchievementsInventoryItemBadge">
        {achievementData.url !== null &&
        achievementData.url !== undefined &&
        achievementData.url !== "" ? (
          <div className="AchievementsInventoryItemImg">
            <LazyLoadImg imgUrl={achievementData.url} />
          </div>
        ) : (
          <span
            style={{
              marginTop: "0px",
              color: globalTheme.figmaPrimaryText.color,
            }}
            className="material-symbols-outlined"
          >
            {achievementData.icon}
          </span>
        )}
      </div>
      <div className="AchievementsInventoryItemMiddle">
        <p
          className="AchievementsInventoryItemMiddleTitle"
          style={{
            color: globalTheme.figmaPrimaryText.color,
          }}
        >
          {achievementData.name}
        </p>
        <p
          className="AchievementsInventoryItemMiddleSub"
          style={{
            color: globalTheme.figmaPrimaryTextSub.color,
          }}
        >
          {achievementData.subtitle}
        </p>
      </div>
      <div className="AchievementsInventoryItemLast">
        <p
          className="AchievementsInventoryItemLastText"
          style={{
            color: globalTheme.figmaPrimaryText.color,
            fontWeight: "600",
          }}
        >
          {achievementData.points} points
        </p>
        <p
          className="AchievementsInventoryItemLastText"
          style={{
            color: globalTheme.figmaPrimaryTextSub.color,
          }}
        >
          {moment(achievementData.createdAt).fromNow()}
        </p>
      </div>
    </div>
  );
}
