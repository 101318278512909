/**
 * WalletsInfo component displays information about wallet transactions.
 * It shows a header with the title and subtitle, and an optional info box
 * with additional details about wallet transactions. The info box can be
 * toggled on and off, and its visibility is persisted in local storage.
 *
 * @component
 *
 * @example
 * return (
 *   <WalletsInfo />
 * )
 *
 * @returns {JSX.Element} The WalletsInfo component.
 *
 * @remarks
 * This component uses Material UI icons for the info and cancel buttons,
 * and it applies styles dynamically based on the global theme state.
 * 
 * @author 
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import { useThemeState } from "../../utils/globalStates";
import "./TopBoxes.css";

export default function WalletsInfo() {

    const [showInfo, setShowInfo] = useState(false);
    const globalTheme = useThemeState((state) => state.globalTheme);

  // Check local storage if user has seen the info
  useEffect(() => {
    if (localStorage.getItem("walletsLatestInfo") === "true") {
      setShowInfo(false);
    } else {
      setShowInfo(true);
    }
  }, []);

  const onInfoClose = () => {
    setShowInfo(false);
    localStorage.setItem("walletsLatestInfo", "true");
  };

  const onInfoOpen = () => {
    localStorage.removeItem("walletsLatestInfo");
    setShowInfo(true);
  }
  return (
    <div
      className="SearchAndExplore"
      style={{
        backgroundColor: globalTheme.figmaPurplePastel.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        ...globalTheme.boxShadow,
      }}
    >
        {!showInfo && (
            <InfoIcon
            onClick={onInfoOpen}
            sx={{
              color: globalTheme.figmaPrimaryText.color,
              cursor: "pointer",
              position: "absolute",
              right: "6px",
              top: "6px",
              fontSize: "17px",
              "@media (min-width: 350px)": {
                fontSize: "18px",
              },
              "@media (min-width: 550px)": {
                fontSize: "19px",
              },
              "@media (min-width: 700px)": {
                fontSize: "20px",
              },
            }}
          />
        )}
      <div className="SearchAndExploreHeader">
        
        <p
          className="SearchAndExploreHeaderTitle"
          style={{ color: globalTheme.figmaPrimaryText.color, maxWidth: "95%" }}
        >
            Wallet Transactions
        </p>
        <p
            className="SearchAndExploreHeaderSub"
            style={{
              color: globalTheme.figmaPrimaryText.color,
              maxWidth: "95%",
              marginBottom: "-10px",
            }}
          >
            Stay up to date with yours or others wallets
          </p>
      </div>
      {showInfo && (
        <div
          className="ExploreInfo"
          style={{
            backgroundColor: globalTheme.figmaYellowAlt.color,
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            marginBottom: "5px",
          }}
        >
          <p
            className="ExploreInfoText"
            style={{ color: globalTheme.figmaPrimaryText.color }}
          >
            A timeline of the latest transactions made by the wallets you follow.
            See NFT mints and transfers, posts on Mirror and other Web3 social media platforms, and more.
          </p>
          <CancelIcon
            onClick={onInfoClose}
            sx={{
              color: globalTheme.figmaPrimaryText.color,
              cursor: "pointer",
              fontSize: "17px",
              "@media (min-width: 350px)": {
                fontSize: "18px",
              },
              "@media (min-width: 550px)": {
                fontSize: "19px",
              },
              "@media (min-width: 700px)": {
                fontSize: "20px",
              },
            }}
          />
        </div>
      )}
    </div>
  );
}