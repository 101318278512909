/**
 * AchievementsListeners component listens for changes in user achievements and updates the state accordingly.
 * It also fetches all achievements from the Firestore database and checks for new achievements.
 * 
 * @component
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <AchievementsListeners />
 * 
 * @remarks
 * This component uses multiple hooks to manage state and side effects:
 * - `useAuthState` from `react-firebase-hooks/auth` to get the current user and loading state.
 * - Custom hooks `useDeviceState`, `useAchievementsState`, `useAuthStates`, and `useThemeState` to manage global state.
 * 
 * @description
 * The component performs the following tasks:
 * 1. Listens for changes in the `userAchievements` collection where the `uid` matches the current user and updates the achievements state.
 * 2. Fetches all documents from the `achievements` collection and updates the achievements state.
 * 3. Checks for new achievements based on the user's data and updates the state accordingly.
 * 4. Displays a notification if new achievements are found.
 * 
 * @hook
 * @name useEffect
 * @description
 * - The first `useEffect` listens for changes in the `userAchievements` collection and updates the state.
 * - The second `useEffect` fetches all achievements from the Firestore database.
 * - The third `useEffect` checks for new achievements and displays a notification if new achievements are found.
 * 
 * @state {boolean} isNotificationOpen - State to manage the visibility of the notification.
 * 
 * @dependencies
 * - `firebaseAuth` from `../firebase/auth`
 * - `firestoreDb` from `../firebase/firestore`
 * - Custom hooks from `../utils/globalStates`
 * - `LogoMaskOnly` component from `../ui/img/LogoMaskOnly`
 * - CSS styles from `./Listeners.css`
 * 
 * @author jimmybengtsson (itchy-fingers)
 */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../firebase/auth";
import {
  doc,
  onSnapshot,
  where,
  collection,
  query,
  getDocs,
} from "firebase/firestore";
import { firestoreDb } from "../firebase/firestore";
import {
  useDeviceState,
  useAchievementsState,
  useAuthStates,
  useThemeState,
} from "../utils/globalStates";
import LogoMaskOnly from "../ui/img/LogoMaskOnly";
import "./Listeners.css";

export default function AchievementsListeners() {
  const [user, loading] = useAuthState(firebaseAuth);
  const authStates = useAuthStates();
  const deviceState = useDeviceState();
  const achievementsState = useAchievementsState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  // Listen for changes in userAchievements where uid is same as user and update list
  useEffect(() => {
    if (user === null || user === undefined || loading === true) {
      return;
    }
    const q = query(
      collection(firestoreDb, "userAchievements"),
      where("uid", "==", user?.uid)
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const tempArr: any[] = [];
      snapshot.forEach((doc) => {
        tempArr.push(doc.data());
      });

      // sort temparr by createdAt by newest to oldest
      tempArr.sort((a, b) => {
        return b.createdAt - a.createdAt;
      });
      achievementsState.setEarnedAchievements(tempArr);
      achievementsState.setNewAchievementsLength(tempArr.length);
      achievementsState.setEarnedAchievementsLoaded(true);
    });
    return () => {
      unsubscribe();
    };
  }, [user, loading]);

  // Fetch all docs from achievements collection and update list
  useEffect(() => {
    const fetchAchievements = async () => {
      const achievementsRef = collection(firestoreDb, "achievements");
      const querySnapshot = await getDocs(achievementsRef);
      const tempArr: any[] = [];
      querySnapshot.forEach((doc) => {
        tempArr.push(doc.data());
      });
      achievementsState.setAllAchievements(tempArr);
      achievementsState.setAllAchievementsLoaded(true);
    };
    fetchAchievements();
  }, []);

  //Check for new achievements
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    let timeoutId2: NodeJS.Timeout;
    if (isNotificationOpen === true) return;
    if (
      achievementsState.earnedAchievementsLoaded === true &&
      achievementsState.allAchievementsLoaded === true &&
      authStates.userData !== null
    ) {
      const newAchievements = achievementsState.allAchievements.filter(
        (achievement) => {
          return !achievementsState.earnedAchievements.some(
            (earnedAchievement) => {
              return earnedAchievement.achievement === achievement.id;
            }
          );
        }
      );
      let newCount = 0;
      let tempProgressArr: any[] = [];
      for (let i = 0; i < newAchievements.length; i++) {
        if (newAchievements[i].type === "listings") {
          if (newAchievements[i].goal <= authStates.userData.listingCount) {
            newCount++;
            tempProgressArr.push({
              ...newAchievements[i],
              progress: 100,
            });
          } else {
            tempProgressArr.push({
              ...newAchievements[i],
              progress: Math.floor(
                (authStates.userData.listingCount / newAchievements[i].goal) *
                  100
              ),
            });
          }
        } else if (newAchievements[i].type === "ratings") {
          if (newAchievements[i].goal <= authStates.userData.ratingCount) {
            newCount++;
            tempProgressArr.push({
              ...newAchievements[i],
              progress: 100,
            });
          } else {
            tempProgressArr.push({
              ...newAchievements[i],
              progress: Math.floor(
                (authStates.userData.ratingCount / newAchievements[i].goal) *
                  100
              ),
            });
          }
        } else if (newAchievements[i].type === "reviews") {
          if (newAchievements[i].goal <= authStates.userData.reviewsCount) {
            newCount++;
            tempProgressArr.push({
              ...newAchievements[i],
              progress: 100,
            });
          } else {
            tempProgressArr.push({
              ...newAchievements[i],
              progress: Math.floor(
                (authStates.userData.reviewsCount / newAchievements[i].goal) *
                  100
              ),
            });
          }
        } else if (newAchievements[i].type === "register") {
          newCount++;
          tempProgressArr.push({
            ...newAchievements[i],
            progress: 100,
          });
        } else if (newAchievements[i].type === "role") {
          if (
            newAchievements[i].goal === "beta" &&
            (authStates.role === "beta" ||
              authStates.role === "admin" ||
              authStates.role === "owner")
          ) {
            newCount++;
            tempProgressArr.push({
              ...newAchievements[i],
              progress: 100,
            });
          } else if (
            newAchievements[i].goal === "alpha" &&
            (authStates.role === "alpha" ||
              authStates.role === "admin" ||
              authStates.role === "owner")
          ) {
            newCount++;
            tempProgressArr.push({
              ...newAchievements[i],
              progress: 100,
            });
          } else {
            tempProgressArr.push({
              ...newAchievements[i],
              progress: 0,
            });
          }
        } else if (newAchievements[i].type === "following") {
          if (newAchievements[i].goal <= authStates.userData.followingCount) {
            newCount++;
            tempProgressArr.push({
              ...newAchievements[i],
              progress: 100,
            });
          } else {
            tempProgressArr.push({
              ...newAchievements[i],
              progress: Math.floor(
                (authStates.userData.followingCount / newAchievements[i].goal) *
                  100
              ),
            });
          }
        } else if (newAchievements[i].type === "followed") {
          if (newAchievements[i].goal <= authStates.userData.followersCount) {
            newCount++;
            tempProgressArr.push({
              ...newAchievements[i],
              progress: 100,
            });
          } else {
            tempProgressArr.push({
              ...newAchievements[i],
              progress: Math.floor(
                (authStates.userData.followersCount / newAchievements[i].goal) *
                  100
              ),
            });
          }
        }
      }
      // Sort array by progress highest to lowest
      tempProgressArr.sort((a, b) => {
        return b.progress - a.progress;
      });

      // Shorten array to 5 items
      tempProgressArr = tempProgressArr.slice(0, 5);
      achievementsState.setProgressAchievements(tempProgressArr);
      achievementsState.setProgressAchievementsLoaded(true);
      const checkCount = () => {
        if (newCount > 0) {
          clearTimeout(timeoutId);
          clearTimeout(timeoutId2);
          achievementsState.setNewAchievementsLength(newCount);
          achievementsState.setHasNewAchievements(true);
          const lastNotification = localStorage.getItem(
            "lastAchievementNotification"
          );
          const breakpointTime = Date.now() - 60 * 1000 * 30;
          if (
            lastNotification !== null &&
            lastNotification !== undefined &&
            lastNotification !== "" &&
            parseInt(lastNotification) > breakpointTime
          ) {
            return;
          }
          localStorage.setItem(
            "lastAchievementNotification",
            Date.now().toString()
          );
          timeoutId = setTimeout(() => {
            deviceState.setIsNotificationOpen(true);
            setIsNotificationOpen(true);

            timeoutId2 = setTimeout(() => {
              deviceState.setIsNotificationOpen(false);
              setIsNotificationOpen(false);
            }, 10000);
          }, 12000);
        }
      };
      checkCount();
    }
  }, [
    achievementsState.earnedAchievements,
    achievementsState.allAchievements,
    authStates.userData,
  ]);

  return (
    <div
      className="NotificationListener"
      style={{
        backgroundColor: globalTheme.figmaBeigeLight.color,
        height: isNotificationOpen ? "90px" : "0px",
        textDecoration: "none",
        color: globalTheme.figmaPrimaryText.color,
        pointerEvents: isNotificationOpen ? "auto" : "none",
      }}
    >
      <div className="NotificationListenerInner">
        <div className="NotificationListenerLogo">
          <LogoMaskOnly maskFill={'#ffd379'} />
        </div>
        <p
          style={{
            color: globalTheme.figmaPrimaryText.color,
            textDecoration: "none",
          }}
          className="bubbleText left"
        >
          {achievementsState.newAchievementsLength} new{" "}
          {achievementsState.newAchievementsLength === 1
            ? "achievement"
            : "achievements"}{" "}
          available,{" "}
          <Link
            style={{
              color: globalTheme.figmaPrimaryText.color,
            }}
            to="/achievements"
          >
            {achievementsState.newAchievementsLength === 1
              ? "check it out!"
              : "check them out!"}
          </Link>
        </p>
      </div>
    </div>
  );
}
