/**
 * Component for adding a featured news article to the admin panel.
 *
 * This component allows an admin to add a news article from Substack or Medium, preview it,
 * pair it with a project listing, and then upload it to the "featuredNews" collection in Firestore.
 *
 * @component
 * @returns {JSX.Element} The AdminAddFeaturedNews component.
 *
 * @example
 * return (
 *   <AdminAddFeaturedNews />
 * )
 *
 * @remarks
 * - The component uses Firebase for authentication, Firestore for database operations,
 *   and Algolia for search functionality.
 * - It supports fetching and previewing articles from Substack and Medium.
 * - The component provides a dialog for previewing the news article and pairing it with a project listing.
 *
 * @function
 * @name AdminAddFeaturedNews
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import React, { useEffect, useState } from "react";

import { ButtonBase, TextField, CircularProgress } from "@mui/material";
import aa from "search-insights";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import DOMPurify from "dompurify";
import Button from "@mui/material/Button";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../components/firebase/functions";
import { setDoc, doc } from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DensitySmallOutlinedIcon from "@mui/icons-material/DensitySmallOutlined";
import DensityLargeOutlinedIcon from "@mui/icons-material/DensityLargeOutlined";

// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

import {
  useThemeState,
  useSnackbarErrorState,
  useSnackbarState,
} from "../../../components/utils/globalStates";
import {
  standardDialogPaper,
  standardDialogRoot,
  standardDialogTitle,
} from "../../../components/theme/globalMuiTheme";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import { algoliaListingsRelevance } from "../../../components/search/algoliaSearch";
import { figmaColorVariables } from "../../../components/theme/globalTheme";
import "./AdminComponents.css";

export default function AdminAddFeaturedNews() {
  const [user] = useAuthState(firebaseAuth);
  const [newsLink, setNewsLink] = useState("");
  const [newsLinkData, setNewsLinkData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isPreviewLarge, setIsPreviewLarge] = useState(true);
  const [linkedProject, setLinkedProject] = useState({});
  const [linkedProjectName, setLinkedProjectName] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState("Preview link");

  const globalTheme = useThemeState((state) => state.globalTheme);
  const snackbarState = useSnackbarState((state) => state);
  const snackbarErrorState = useSnackbarErrorState((state) => state);

  // Upload to featuredNews collection
  const onUpload = async () => {
    if (user === null) return;
    if (newsLinkData === null || newsLinkData === undefined) {
      return;
    }
    if (linkedProject === "" || linkedProjectName === "") {
      return;
    }
    try {
      setIsSending(true);
      setOpenDialog(false);
      const tempDate = Date.now();
      await setDoc(
        doc(
          firestoreDb,
          "featuredNews",
          linkedProject + "-" + tempDate.toString()
        ),
        {
          ...newsLinkData,
          project: linkedProject,
          project_name: linkedProjectName,
          uid: user?.uid,
          db_timestamp: tempDate,
        }
      );
      snackbarState.setOpenLength(4000);
      snackbarState.setMessage(
        "Featured news article paired to " + linkedProjectName + " and uploaded"
      );
      snackbarState.setIsOpen(true);
      setNewsLink("");
      setNewsLinkData(null);
      setLinkedProject("");
      setLinkedProjectName("");
      setError("Preview link");
      setIsSending(false);
    } catch (e) {
      console.error(e);
      setError("Error: Try again");
      setIsSending(false);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage("Error uploading news article");
      snackbarErrorState.setIsOpen(true);
      setOpenDialog(true);
    }
  };

  const onPreviewClick = () => {
    if (newsLink === "") {
      setError("Error: Invalid link");
      return;
    }
    if (newsLink.includes("substack")) {
      onFetchSubstackPost();
    } else if (newsLink.includes("medium")) {
      onFetchMediumPost();
    } else {
      setError("Error: Invalid link");
    }
  };
  const onFetchSubstackPost = async () => {
    let linkCopy = newsLink;
    // remove everything in url after substack.com/ and add feed
    linkCopy = linkCopy.split("substack.com/")[0] + "substack.com/feed";
    console.log(linkCopy);
    try {
      setLoading(true);
      const feed = await httpsCallable(
        firebaseFunctions,
        "rss-substack"
      )({ url: linkCopy });
      console.log(feed);
      if (feed.data === null || feed.data === undefined) {
        setError("Error: Invalid link");
        setLoading(false);
        return;
      }

      const feedData: any = feed.data;
      const feedItems: any[] = feedData.items;

      // check if feedData.items is an array
      if (!Array.isArray(feedItems)) {
        setError("Error: Invalid link");
        setLoading(false);
        return;
      }

      const found = feedItems.find((item: any) => item.link.includes(newsLink));

      if (found) {
        console.log(found);
        // Find the first image in the found["content:encoded"] string
        const imgRegex = /<img[^>]+src="([^">]+)"/g;
        const imgSrc = imgRegex.exec(found["content:encoded"]);
        const imgSrcLink =
          imgSrc !== null
            ? imgSrc[1]
            : feedData.image.url !== undefined
            ? feedData.image.url
            : "";
        console.log(imgSrcLink);
        const tempDoc = {
          type: "Substack",
          title: found.title !== undefined ? found.title : "",
          snippet: found["content:encodedSnippet"] !== undefined ? found["content:encodedSnippet"] : "",
          link: found.link !== undefined ? found.link : "",
          date: found.isoDate !== undefined ? found.isoDate : "",
          content: found["content:encoded"] !== undefined ? found["content:encoded"] : "",
          sub: found.content !== undefined ? found.content : "",
          creator: found.creator !== undefined ? found.creator : "",
          feed: feedData.title !== undefined ? feedData.title : "",
          img: imgSrcLink,
        };
        setNewsLinkData(tempDoc);
        setOpenDialog(true);
        setError("Preview link");
        setLinkedProject("");
        setLinkedProjectName("");
        setLoading(false);
      } else {
        setError("Error: Invalid link");
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
      setError("Error: Invalid link");
      setLoading(false);
    }
  };

  const onFetchMediumPost = async () => {
    let linkCopy = newsLink;
    // Remove medium.com/ and everything before it from url
    linkCopy = linkCopy.split("medium.com/")[1];
    // Remove everything after the first / in the link
    linkCopy = linkCopy.split("/")[0];
    // Remove @ from start of link
    if (linkCopy.charAt(0) === "@") {
      linkCopy = linkCopy.substring(1);
    }
    console.log(linkCopy);
    try {
      setLoading(true);
      const feed = await httpsCallable(
        firebaseFunctions,
        "rss-medium"
      )({ name: linkCopy });
      console.log(feed);
      if (feed.data === null || feed.data === undefined) {
        setError("Error: Invalid link");
        setLoading(false);
        return;
      }

      const feedData: any = feed.data;
      const feedItems: any[] = feedData.items;

      // check if feedData.items is an array
      if (!Array.isArray(feedItems)) {
        setError("Error: Invalid link");
        setLoading(false);
        return;
      }

      const found = feedItems.find((item: any) => item.link.includes(newsLink));
      if (found) {
        console.log(found);
        // Find the first image in the found["content:encoded"] string
        const imgRegex = /<img[^>]+src="([^">]+)"/g;
        const imgSrc = imgRegex.exec(found["content:encoded"]);
        const imgSrcLink =
          imgSrc !== null
            ? imgSrc[1]
            : feedData.image.url !== undefined
            ? feedData.image.url
            : "";
        console.log(imgSrcLink);
        const tempDoc = {
          type: "Medium",
          title: found.title !== undefined ? found.title : "",
          snippet: found["content:encodedSnippet"] !== undefined ? found["content:encodedSnippet"] : "",
          link: found.link !== undefined ? found.link : "",
          date: found.isoDate !== undefined ? found.isoDate : "",
          content: found["content:encoded"] !== undefined ? found["content:encoded"] : "",
          sub: found.content !== undefined ? found.content : "",
          creator: found.creator !== undefined ? found.creator : "",
          feed: feedData.title !== undefined ? feedData.title : "",
          img: imgSrcLink,
        };
        setNewsLinkData(tempDoc);
        setOpenDialog(true);
        setError("Preview link");
        setLinkedProject("");
        setLinkedProjectName("");
        setLoading(false);
      } else {
        setError("Error: Invalid link");
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
      setError("Error: Invalid link");
      setLoading(false);
    }
  };

  useEffect(() => {
    let tempArray: any[] = [];
    if (query !== "") {
      setSearchLoading(true);
      algoliaListingsRelevance
        // @ts-ignore
        .search(query, { clickAnalytics: true, userToken: user?.uid })
        .then((res: any) => {
          for (let i = 0; i < res.hits.length; i++) {
            const tempListing = res.hits[i];
            tempArray.push({
              data: tempListing,
              searchId: res.queryID,
            });
          }
          setResults(tempArray);
          setSearchLoading(false);
        })
        .catch((err: any) => {
          console.error(err);
          setSearchLoading(false);
        });
    }
  }, [query]);

  const onResultClick = async (
    id: string,
    index: number,
    searchId: string,
    name: string
  ) => {
    setLinkedProject(id);
    setLinkedProjectName(name);
    setResults([]);
    setQuery("");
    try {
      await aa("clickedObjectIDsAfterSearch", {
        index: "gath3r_listings",
        eventName: "Listing Clicked after Search",
        queryID: searchId,
        objectIDs: [id],
        positions: [index + 1],
      });
    } catch (error) {
      console.error(error);
      console.log(error);
    }
  };

  // Replace <em> tags with <strong> tags
  const replaceEm = (str: string) => {
    return str.replace(/<em>/g, "<strong>").replace(/<\/em>/g, "</strong>");
  };

  const styles = {
    autoComplete: {
      transition: "transform 0.3s ease",
      marginBottom: "15px",
      marginTop: "20px",
      width: "calc(98% + 2px)",
      "& .MuiAutocomplete-popper": {},
      "& .MuiOutlinedInput-root": {
        height: "40px",
        borderRadius: "8px",
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color,
        borderWidth: "1px",
        backgroundColor: globalTheme.solidMainWhite.color,
        "& fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          borderWidth: "1px",
          color: globalTheme.figmaPrimaryText.color,
        },
        "& input": {
          "&::placeholder": {
            opacity: 1,
          },
        },
        "&:hover fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          borderWidth: "1px",
          color: globalTheme.figmaPrimaryText.color,
        },
        "&.Mui-focused fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          color: globalTheme.figmaPrimaryText.color,
        },
      },
      "& label.Mui-focused": {
        color: globalTheme.figmaPrimaryText.color,
      },
      "& label": {
        color: globalTheme.figmaPrimaryText.color,
      },
    },
  };

  const materialListingFormStyles = {
    "& .MuiOutlinedInput-root": {
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color,
      "& fieldset": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color,
      },
      "&:hover fieldset": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color,
      },
      "&.Mui-focused fieldset": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color,
      },
    },
    "& label.Mui-focused": {
      color: globalTheme.backgroundMainOffBeige.color,
    },
    "& label": {
      color: globalTheme.backgroundMainOffBeige.color,
    },
  };

  const buttonStyles = {
    color: "#1A1A1A",
    backgroundColor: "#FFED8F",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    boxShadow: globalTheme.boxShadow,
    border: "1px solid #1A1A1A",
    flex: 1,
    margin: "5px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
  };

  return (
    <div
      className="AdminNewsletterAddEmail"
      style={{
        borderBottom: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
        borderTop: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
        color: globalTheme.backgroundMainOffBeige.color,
      }}
    >
      <TextField
        label="News Article URL"
        disabled={loading}
        variant="outlined"
        size="small"
        sx={{ ...materialListingFormStyles, marginRight: "20px", flex: 1 }}
        value={newsLink}
        placeholder="URL to a Substack or Medium article"
        onChange={(e) => {
          setNewsLink(e.target.value);
          setError("Preview link");
        }}
      />
      <Button
        variant="outlined"
        color="inherit"
        sx={{ fontSize: "14px", minWidth: "30%" }}
        onClick={onPreviewClick}
        disabled={newsLink === "" || loading}
      >
        {loading || isSending ? (
          <CircularProgress color="inherit" size={"20px"} />
        ) : (
          error
        )}
      </Button>
      <Dialog
        sx={{
          ...standardDialogRoot,
          "& .MuiDialog-paper": {
            ...standardDialogPaper,
            backgroundColor: globalTheme.backgroundMainBeige.color,
            color: globalTheme.backgroundMainOffBeige.color,
            border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
            maxWidth: "1000px",
          },
        }}
        onClose={() => setOpenDialog(false)}
        open={openDialog}
      >
        <IconButton
          sx={{
            color: globalTheme.textMainGrey.color,
            width: "30px",
            height: "30px",
            position: "absolute",
            top: "25px",
            right: "15px",
          }}
          size="small"
          onClick={() => setOpenDialog(false)}
        >
          <CloseOutlined />
        </IconButton>
        <DialogContent
          sx={{
            marginTop: "0px",
            paddingTop: "0px",
            paddingBottom: "50px",
          }}
        >
          <DialogTitle
            sx={{
              ...standardDialogTitle,
              marginLeft: "-20px",
            }}
          >
            Article Preview
            <br />
            <span style={{ fontSize: "55%", fontWeight: 400 }}>
              Check so the news article looks correct and pair it with a project
            </span>
          </DialogTitle>
          <div className="AdminArticlePreview">
            <div className="AdminArticlePreviewButtons">
              <ToggleButtonGroup
                size="small"
                value={isPreviewLarge}
                exclusive
                onChange={(event, newAlignment) => {
                  if (newAlignment !== null) {
                    setIsPreviewLarge(newAlignment);
                  }
                }}
              >
                <ToggleButton size="small" value={true}>
                  <DensityLargeOutlinedIcon />
                </ToggleButton>
                <ToggleButton size="small" value={false}>
                  <DensitySmallOutlinedIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            {isPreviewLarge ? (
              <div
                className="AdminArticlePreviewLarge"
                style={{
                  border: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
                  color: globalTheme.primaryText.color,
                  backgroundColor: globalTheme.primarySurface.color,
                }}
              >
                <div className="AdminArticlePreviewLargeTextOuter">
                  <p className="AdminArticlePreviewLargeTitle">Featured News</p>
                  <p className="AdminArticlePreviewLargeSub">
                    {newsLinkData?.title}
                  </p>
                  <b
                    className="AdminArticlePreviewLargeText"
                    style={{ marginTop: "5px" }}
                  >
                    {newsLinkData?.sub}
                  </b>
                  <p className="AdminArticlePreviewLargeText">
                    {newsLinkData?.snippet}
                  </p>
                  <a
                    href={newsLinkData?.link}
                    target="_blank"
                    rel="noreferrer"
                    className="AdminArticlePreviewLargeText"
                    style={{
                      color: figmaColorVariables.blue300,
                      marginTop: "0px",
                      marginBottom: "5px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    By {newsLinkData?.creator} @ {newsLinkData?.type} {">"}
                  </a>

                  <div style={{ flex: 1 }}></div>
                </div>
                <div className="AdminArticlePreviewLargeImgOuter">
                  <img
                    src={newsLinkData?.img}
                    alt="Article"
                    className="AdminArticlePreviewLargeImg"
                  />
                </div>
              </div>
            ) : (
              <div className="AdminArticlePreviewSmall">
                <div
                  className="AdminArticlePreviewSmallButton"
                  style={{
                    border: `1px solid ${figmaColorVariables.grey800}`,
                    color: figmaColorVariables.grey800,
                    backgroundColor: figmaColorVariables.orange300,
                    ...globalTheme.boxShadow,
                  }}
                >
                  {linkedProjectName}
                </div>
                <div
                  className="AdminArticlePreviewSmallInner"
                  style={{
                    border: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
                    color: globalTheme.primaryText.color,
                    backgroundColor: globalTheme.primarySurface.color,
                  }}
                >
                  <div className="AdminArticlePreviewSmallImgOuter">
                    <img
                      src={newsLinkData?.img}
                      alt="Article"
                      className="AdminArticlePreviewSmallImg"
                    />
                  </div>
                  <div className="AdminArticlePreviewSmallTextOuter">
                    <p className="AdminArticlePreviewSmallSub">
                      {newsLinkData?.title}
                    </p>
                    <b
                      className="AdminArticlePreviewSmallText"
                      style={{ marginTop: "5px" }}
                    >
                      {newsLinkData?.sub}
                    </b>
                    <p className="AdminArticlePreviewSmallText">
                      {newsLinkData?.snippet}
                    </p>
                    <div style={{ flex: 1 }}></div>
                    <a
                      href={newsLinkData?.link}
                      target="_blank"
                      rel="noreferrer"
                      className="AdminArticlePreviewSmallText"
                      style={{
                        color: figmaColorVariables.blue300,
                        marginTop: "0px",
                        marginBottom: "5px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      By {newsLinkData?.creator} @ {newsLinkData?.type} {">"}
                    </a>
                  </div>
                </div>
              </div>
            )}
            {linkedProjectName === "" ? (
              <Autocomplete
                freeSolo
                fullWidth
                disableClearable
                sx={{
                  ...styles.autoComplete,
                }}
                size="small"
                filterOptions={(x) => x}
                loading={searchLoading}
                slotProps={{ popper: { sx: { zIndex: 99999 } } }}
                getOptionLabel={(option: any) => option.data.name}
                renderOption={(props: any, option: any) => {
                  return (
                    <ButtonBase
                      key={option.data.id}
                      onClick={() => {
                        onResultClick(
                          option.data.id,
                          props["data-option-index"],
                          option.searchId,
                          option.data.name
                        );
                      }}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: "10px",
                        backgroundColor: "#fff",
                        zIndex: 10000,
                      }}
                    >
                      <p
                        className="AutocompleteResultTitle"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            replaceEm(option.data._highlightResult.name.value),
                            {
                              FORBID_ATTR: ["style"],
                              FORBID_TAGS: ["style", "a"],
                            }
                          ),
                        }}
                      ></p>
                      <p
                        className="AutocompleteResultText"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            replaceEm(
                              "in " +
                                option.data._highlightResult.category.value
                            ),
                            {
                              FORBID_ATTR: ["style"],
                              FORBID_TAGS: ["style", "a"],
                            }
                          ),
                        }}
                      ></p>
                    </ButtonBase>
                  );
                }}
                options={results}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={query}
                    onChange={(event) => {
                      setQuery(event.target.value);
                    }}
                    placeholder={
                      linkedProjectName === ""
                        ? "Pair article with a GATH3R Listing"
                        : linkedProjectName + " (click to change)"
                    }
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <React.Fragment>
                          <SearchIcon
                            color="inherit"
                            sx={{ fontSize: "20px", marginLeft: "5px" }}
                          />
                        </React.Fragment>
                      ),
                      endAdornment: (
                        <React.Fragment>
                          {searchLoading ? (
                            <CircularProgress color="inherit" size={"20px"} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            ) : (
              <div className="AdminArticlePreviewPickedListing">
                <p>
                  <b>{linkedProjectName}</b> paired to news article
                </p>
                <b
                  onClick={() => {
                    setLinkedProject("");
                    setLinkedProjectName("");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  X
                </b>
              </div>
            )}
            <div className="AdminListDialogEditButtons">
              <ButtonBase
                sx={{
                  ...buttonStyles,
                  backgroundColor: globalTheme.colorPaletteButtonRed.color,
                  flex: "unset",
                }}
                onClick={() => setOpenDialog(false)}
                disabled={isSending}
              >
                Cancel
              </ButtonBase>
              <ButtonBase
                sx={{
                  ...buttonStyles,
                  backgroundColor: globalTheme.colorPaletteButtonGreen.color,
                  flex: "unset",
                }}
                onClick={onUpload}
                disabled={
                  isSending ||
                  linkedProjectName === "" ||
                  newsLinkData === null ||
                  newsLinkData === undefined ||
                  linkedProjectName === ""
                }
              >
                Add to featured news
              </ButtonBase>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
