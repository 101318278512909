/**
 * The `PersonalSettings` component allows users to update their personal information such as name, surname, gender, age, and country/region.
 * This information is not visible to others and is used to customize the user's experience on the GATH3R platform.
 *
 * @component
 * @example
 * return (
 *   <PersonalSettings />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @remarks
 * This component uses various hooks and state management to handle user input and updates.
 * It also interacts with Firebase functions to update the user's profile information.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import React, { useState, useEffect } from "react";

import Radio from "@mui/material/Radio";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";

import moment from "moment";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../../components/firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../../components/firebase/auth";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TextField from "@mui/material/TextField";

import { ButtonBase, CircularProgress } from "@mui/material";
import {
  useSnackbarErrorState,
  useSnackbarState,
  useAuthStates,
  useThemeState,
} from "../../../../components/utils/globalStates";
import "./ProfileSettingsComp.css";

moment().format();

export default function PersonalSettings() {
  const [nameInput, setNameInput] = useState<string>("");
  const [nameInputError, setNameInputError] = useState<boolean>(false);
  const [nameInputHelperText, setNameInputHelperText] = useState<string>("");
  const [nameInputUpdating, setNameInputUpdating] = useState<boolean>(false);
  const [nameInputInitial, setNameInputInitial] = useState<string>("");

  const [surnameInput, setSurnameInput] = useState<string>("");
  const [surnameInputError, setSurnameInputError] = useState<boolean>(false);
  const [surnameInputHelperText, setSurnameInputHelperText] =
    useState<string>("");
  const [surnameInputUpdating, setSurnameInputUpdating] =
    useState<boolean>(false);
  const [surnameInputInitial, setSurnameInputInitial] = useState<string>("");

  const [user, loading, error] = useAuthState(firebaseAuth);
  const snackbarStates = useSnackbarState((state) => state);
  const snackbarErrorStates = useSnackbarErrorState((state) => state);
  const authStates = useAuthStates((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);

  const [radioValue, setRadioValue] = useState("");
  const [radioValueInitial, setRadioValueInitial] = useState<string>("");

  const [ageValue, setAgeValue] = useState<string>("");
  const [ageValueInitial, setAgeValueInitial] = useState<string>("");
  const [ageValueUpdating, setAgeValueUpdating] = useState<boolean>(false);
  const [ageValueError, setAgeValueError] = useState<boolean>(false);

  const [countryValue, setCountryValue] = useState<string>("Country/Region");
  const [countryValueInitial, setCountryValueInitial] =
    useState<string>("Country/Region");
  const [countryValueUpdating, setCountryValueUpdating] =
    useState<boolean>(false);
  const [countryValueError, setCountryValueError] = useState<boolean>(false);

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (countries.length > 0) {
      return;
    }
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setCountries(data.countries);
      });
  }, []);

  const handleCountryChange: any = (event: SelectChangeEvent) => {
    setCountryValue(event.target.value as string);
  };

  // Listeners for valid names
  useEffect(() => {
    if (nameInput === "") {
      setNameInputError(false);
      setNameInputHelperText("");
      return;
    }
    if (nameInput.length < 2) {
      setNameInputError(true);
      setNameInputHelperText("Name must be at least 2 characters long");
      return;
    }
    setNameInputError(false);
    setNameInputHelperText("");
  }, [nameInput]);

  useEffect(() => {
    if (surnameInput === "") {
      setSurnameInputError(false);
      setSurnameInputHelperText("");
      return;
    }
    if (surnameInput.length < 2) {
      setSurnameInputError(true);
      setSurnameInputHelperText("Surname must be at least 2 characters long");
      return;
    }
    setSurnameInputError(false);
    setSurnameInputHelperText("");
  }, [surnameInput]);

  useEffect(() => {
    if (
      authStates.userData !== undefined &&
      authStates.userData !== null &&
      authStates.userData.privateName !== undefined &&
      authStates.userData.privateName !== null &&
      authStates.userData.privateName !== nameInputInitial
    ) {
      setNameInput(authStates.userData.privateName);
      setNameInputInitial(authStates.userData.privateName);
    }
  }, [authStates.userData]);

  useEffect(() => {
    if (
      authStates.userData !== undefined &&
      authStates.userData !== null &&
      authStates.userData.privateSurname !== undefined &&
      authStates.userData.privateSurname !== null &&
      authStates.userData.privateSurname !== surnameInputInitial
    ) {
      setSurnameInput(authStates.userData.privateSurname);
      setSurnameInputInitial(authStates.userData.privateSurname);
    }
  }, [authStates.userData]);

  useEffect(() => {
    if (
      authStates.userData !== undefined &&
      authStates.userData !== null &&
      authStates.userData.privateGender !== undefined &&
      authStates.userData.privateGender !== null &&
      authStates.userData.privateGender !== ""
    ) {
      setRadioValue(authStates.userData.privateGender);
      setRadioValueInitial(authStates.userData.privateGender);
    }
  }, [authStates.userData]);

  useEffect(() => {
    if (
      authStates.userData !== undefined &&
      authStates.userData !== null &&
      authStates.userData.privateAge !== undefined &&
      authStates.userData.privateAge !== null &&
      authStates.userData.privateAge !== ageValueInitial
    ) {
      setAgeValue(authStates.userData.privateAge);
      setAgeValueInitial(authStates.userData.privateAge);
    }
  }, [authStates.userData]);

  useEffect(() => {
    if (
      authStates.userData !== undefined &&
      authStates.userData !== null &&
      authStates.userData.privateCountry !== undefined &&
      authStates.userData.privateCountry !== null &&
      authStates.userData.privateCountry !== countryValueInitial
    ) {
      if (authStates.userData.privateCountry === "") {
        setCountryValue("Country/Region");
        setCountryValueInitial("Country/Region");
      } else {
        setCountryValue(authStates.userData.privateCountry);
        setCountryValueInitial(authStates.userData.privateCountry);
      }
    }
  }, [authStates.userData]);

  const onNameUpdate = async () => {
    if (user === null || user === undefined) {
      return;
    }

    let tempValue = nameInput;

    if (nameInput === nameInputInitial) {
      tempValue = "";
    }

    setNameInputUpdating(true);

    const updateName = httpsCallable(firebaseFunctions, "profile-name_update");
    const tokenCheck = await user.getIdToken();

    try {
      await updateName({
        name: tempValue,
        token: tokenCheck,
      });
      setNameInputUpdating(false);
      snackbarStates.setMessage("Name updated");
      snackbarStates.setOpenLength(4000);
      snackbarStates.setIsOpen(true);
    } catch (error) {
      setNameInputUpdating(false);
      snackbarErrorStates.setMessage("Error updating name, please try again");
      snackbarErrorStates.setOpenLength(4000);
      snackbarErrorStates.setIsOpen(true);
    }
  };

  const onSurnameUpdate = async () => {
    if (user === null || user === undefined) {
      return;
    }

    let tempValue = surnameInput;

    if (surnameInput === surnameInputInitial) {
      tempValue = "";
    }

    setSurnameInputUpdating(true);

    const updateSurname = httpsCallable(
      firebaseFunctions,
      "profile-surname_update"
    );
    const tokenCheck = await user.getIdToken();

    try {
      await updateSurname({
        surname: tempValue,
        token: tokenCheck,
      });
      setSurnameInputUpdating(false);
      snackbarStates.setMessage("Surname updated");
      snackbarStates.setOpenLength(4000);
      snackbarStates.setIsOpen(true);
    } catch (error) {
      console.log(error);
      setSurnameInputUpdating(false);
      snackbarErrorStates.setMessage(
        "Error updating surname, please try again"
      );
      snackbarErrorStates.setOpenLength(4000);
      snackbarErrorStates.setIsOpen(true);
    }
  };

  const onGenderUpdate = async (genderValue: string) => {
    if (user === null || user === undefined) {
      return;
    }

    if (genderValue === radioValueInitial) {
      return;
    }

    if (
      genderValue !== "Male" &&
      genderValue !== "Female" &&
      genderValue !== "Non-Binary"
    ) {
      return;
    }

    setRadioValue(genderValue);
    snackbarStates.setMessage("Updating Gender...");
    snackbarStates.setOpenLength(null);
    snackbarStates.setIsActionLoading(true);
    snackbarStates.setIsOpen(true);

    const updateGender = httpsCallable(
      firebaseFunctions,
      "profile-gender_update"
    );
    const tokenCheck = await user.getIdToken();

    try {
      await updateGender({
        gender: genderValue,
        token: tokenCheck,
      });
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setOpenLength(3000);
      snackbarStates.setMessage("Gender updated");
    } catch (error) {
      console.error(error);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("");
      snackbarErrorStates.setMessage("Error updating gender, please try again");
      snackbarErrorStates.setOpenLength(4000);
      snackbarErrorStates.setIsOpen(true);
    }
  };

  const onAgeUpdate = async () => {
    if (user === null || user === undefined) {
      return;
    }

    let tempValue = ageValue;
    if (ageValue === ageValueInitial) {
      tempValue = "";
    }

    setAgeValueUpdating(true);
    const updateAge = httpsCallable(firebaseFunctions, "profile-age_update");
    const tokenCheck = await user.getIdToken();

    try {
      await updateAge({
        age: tempValue,
        token: tokenCheck,
      });
      setAgeValueUpdating(false);
      snackbarStates.setMessage("Age updated");
      snackbarStates.setOpenLength(4000);
      snackbarStates.setIsOpen(true);
    } catch (error) {
      console.error(error);
      setAgeValueUpdating(false);
      snackbarErrorStates.setMessage("Error updating age, please try again");
      snackbarErrorStates.setOpenLength(4000);
      snackbarErrorStates.setIsOpen(true);
    }
  };

  const onCountryUpdate = async () => {
    if (user === null || user === undefined) {
      return;
    }

    let tempValue = countryValue;
    if (countryValue === countryValueInitial) {
      tempValue = "";
    }

    setCountryValueUpdating(true);
    const updateCountry = httpsCallable(
      firebaseFunctions,
      "profile-country_update"
    );
    const tokenCheck = await user.getIdToken();

    try {
      await updateCountry({
        country: tempValue,
        token: tokenCheck,
      });
      setCountryValueUpdating(false);
      snackbarStates.setMessage("Country updated");
      snackbarStates.setOpenLength(4000);
      snackbarStates.setIsOpen(true);
    } catch (error) {
      console.error(error);
      setCountryValueUpdating(false);
      snackbarErrorStates.setMessage(
        "Error updating country, please try again"
      );
      snackbarErrorStates.setOpenLength(4000);
      snackbarErrorStates.setIsOpen(true);
    }
  };

  const selectStyles = {
    color:
      countryValue !== "Country/Region"
        ? globalTheme.solidMainBlack.color
        : "#39393c74",
    backgroundColor: globalTheme.solidMainWhite.color,
    boxShadow: "none",
    borderRadius: "5px",
    padding: "0px",
    fontSize: "14px",
    paddingLeft: "8px",
    paddingRight: "8px",
    border: "none !important",
    textAlign: "left",
    "& .MuiOutlinedInput-notchedOutline": {
      color: globalTheme.solidMainBlack.color,
      border: "none !important",
    },
    "& .MuiSelect-root": {
      color: globalTheme.solidMainBlack.color,
      border: "none !important",
      "& fieldset": {
        color: globalTheme.solidMainBlack.color,
        border: "none !important",
      },
      "&:hover fieldset": {
        color: globalTheme.solidMainBlack.color,
        border: "none !important",
      },
      "&:after": {
        color: globalTheme.solidMainBlack.color,
        border: "none !important",
      },
    },
    "&.Mui-focused fieldset": {
      color: globalTheme.solidMainBlack.color,
      border: "none !important",
    },
    "&.Mui-focused ": {
      color: globalTheme.solidMainBlack.color,
      border: "none !important",
    },
  };
  const styles = {
    textField: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        color: globalTheme.solidMainBlack.color,
        backgroundColor: globalTheme.solidMainWhite.color,
        boxShadow: "none",
        borderRadius: "5px",
        padding: "0px",
        fontSize: "14px",
        paddingLeft: "8px",
        paddingRight: "8px",
        border: "none",
        "& fieldset": {
          border: "none",
          color: globalTheme.solidMainBlack.color,
        },
        "&:hover fieldset": {
          border: "none",
          color: globalTheme.solidMainBlack.color,
        },
        "&.Mui-focused fieldset": {
          border: "none",
          color: globalTheme.solidMainBlack.color,
        },
      },
      "& label.Mui-focused": {
        border: "none",
        color: globalTheme.solidMainBlack.color,
      },
      "& label": {
        border: "none",
        color: globalTheme.solidMainBlack.color,
      },
    },
    datePicker: {
      width: "100%",
      "& .MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
        backgroundColor: globalTheme.solidMainBlack.color,
        color: globalTheme.solidMainWhite.color,
      },
      "& .MuiOutlinedInput-root": {
        color: globalTheme.solidMainBlack.color,
        backgroundColor: globalTheme.solidMainWhite.color,
        boxShadow: "none",
        borderRadius: "5px",
        padding: "0px",
        fontSize: "14px",
        paddingLeft: "8px",
        paddingRight: "16px",
        border: "none",
        "& fieldset": {
          border: "none",
          color: globalTheme.solidMainBlack.color,
        },
        "&:hover fieldset": {
          border: "none",
          color: globalTheme.solidMainBlack.color,
        },
        "&.Mui-focused fieldset": {
          border: "none",
          color: globalTheme.solidMainBlack.color,
        },
      },
      "& label.Mui-focused": {
        border: "none",
        color: globalTheme.solidMainBlack.color,
      },
      "& label": {
        border: "none",
        color: globalTheme.solidMainBlack.color,
      },
    },
    selectMenu: {
      zIndex: 9999,
      color: globalTheme.solidMainBlack.color,
      "& .MuiPaper-root": {
        backgroundColor: globalTheme.solidMainWhite.color,
        color: globalTheme.solidMainBlack.color,
        "& .MuiMenuItem-root": {
          "& .Mui-selected": {
            color: globalTheme.backgroundMainOffBeigeDark.color,
            backgroundColor: globalTheme.backgroundMainBeigeDark.color,
          },
        },
        "& .Mui-selected": {
          color: globalTheme.backgroundMainOffBeigeDark.color,
          backgroundColor: globalTheme.backgroundMainBeigeDark.color,
        },
      },
    },
  };
  // /profile/settings,/explore,/profile/bookmarks,/listings
  const RadioLabelStyle = {
    color: globalTheme.textMainBlack.color,
    margin: 0,
    "& .MuiFormControlLabel-label": {
      fontSize: "9px",
      fontWeight: 500,
      marginLeft: "2px",
      marginRight: "20px",
      "@media (min-width: 350px)": {
        fontSize: "11px",
        marginLeft: "3px",
        marginRight: "30px",
      },
      "@media (min-width: 550px)": {
        fontSize: "13px",
        marginLeft: "4px",
        marginRight: "40px",
      },
      "@media (min-width: 701px)": {
        fontSize: "15px",
        marginLeft: "5px",
        marginRight: "50px",
      },
    },
  };

  const radioButtonStyle = {
    color: globalTheme.textMainBlack.color,
    padding: 0,
    "& svg": {
      width: "16px",
      height: "16px",
    },
    "&.Mui-checked": {
      color: globalTheme.textMainBlack.color,
    },
    "@media (min-width: 350px)": {
      "& svg": {
        width: "18px",
        height: "18px",
      },
    },
    "@media (min-width: 550px)": {
      "& svg": {
        width: "20px",
        height: "20px",
      },
    },
    "@media (min-width: 701px)": {
      "& svg": {
        width: "22px",
        height: "22px",
      },
    },
  };

  return (
    <div className="SocialConfig">
      <p
        className="UsernameTitle"
        style={{ marginBottom: "20px", color: globalTheme.textMainBlack.color }}
      >
        This information is not visible to anyone, and is used to customize your
        experience and interactions with the GATH3R platform.
      </p>
      <div className="SocialConfigInner">
        <div className="SocialConfigTextField">
          <TextField
            placeholder="Name (optional)"
            sx={styles.textField}
            size="small"
            value={nameInput}
            onChange={(event) => {
              setNameInput(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  {nameInput !== "" ? (
                    <CloseOutlinedIcon
                      onClick={() => {
                        setNameInput(nameInputInitial);
                      }}
                      color="inherit"
                      sx={{
                        fontSize: "20px",
                        marginLeft: "5px",
                        opacity: 0.4,
                        cursor: "pointer",
                      }}
                    />
                  ) : null}
                </React.Fragment>
              ),
            }}
          />
        </div>
        <ButtonBase
          onClick={onNameUpdate}
          disabled={
            (nameInput === "" && nameInputInitial === "") ||
            nameInputError ||
            nameInputUpdating
          }
          style={{
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.figmaAlt2.color,
            width: "60px",
            marginLeft: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {nameInputUpdating ? (
            <CircularProgress
              size={13}
              color="inherit"
              sx={{ color: globalTheme.figmaPrimaryText.color }}
            />
          ) : (
            <p
              className="SocialConfigButtonText"
              style={{
                color:
                  nameInput === "" || nameInputError || nameInputUpdating
                    ? globalTheme.figmaPrimaryTextSub.color
                    : globalTheme.figmaPrimaryText.color,
              }}
            >
              {nameInputInitial !== "" &&
              nameInputInitial.toLowerCase() === nameInput.toLowerCase()
                ? "Delete"
                : nameInputUpdating
                ? "Saving"
                : nameInputInitial !== ""
                ? "Update"
                : "Add"}
            </p>
          )}
        </ButtonBase>
      </div>
      <p
        className="SocialConfigHelperText"
        style={{
          color: globalTheme.textMainBlack.color,
        }}
      >
        {nameInputHelperText}
      </p>
      <div className="SocialConfigInner">
        <div className="SocialConfigTextField">
          <TextField
            placeholder="Surname (optional)"
            sx={styles.textField}
            size="small"
            value={surnameInput}
            onChange={(event) => {
              setSurnameInput(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  {surnameInput !== "" ? (
                    <CloseOutlinedIcon
                      onClick={() => {
                        setSurnameInput(surnameInputInitial);
                      }}
                      color="inherit"
                      sx={{
                        fontSize: "20px",
                        marginLeft: "5px",
                        opacity: 0.4,
                        cursor: "pointer",
                      }}
                    />
                  ) : null}
                </React.Fragment>
              ),
            }}
          />
        </div>
        <ButtonBase
          onClick={onSurnameUpdate}
          disabled={
            (surnameInput === "" && surnameInputInitial === "") ||
            surnameInputError ||
            surnameInputUpdating
          }
          style={{
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.figmaAlt2.color,
            width: "60px",
            marginLeft: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {surnameInputUpdating ? (
            <CircularProgress
              size={13}
              color="inherit"
              sx={{ color: globalTheme.figmaPrimaryText.color }}
            />
          ) : (
            <p
              className="SocialConfigButtonText"
              style={{
                color:
                  (surnameInput === "" && surnameInputInitial === "") ||
                  surnameInputError ||
                  surnameInputUpdating
                    ? globalTheme.figmaPrimaryTextSub.color
                    : globalTheme.figmaPrimaryText.color,
              }}
            >
              {surnameInputInitial !== "" &&
              surnameInputInitial.toLowerCase() === surnameInput.toLowerCase()
                ? "Delete"
                : surnameInputUpdating
                ? "Saving"
                : surnameInputInitial !== ""
                ? "Update"
                : "Add"}
            </p>
          )}
        </ButtonBase>
      </div>
      <p
        className="SocialConfigHelperText"
        style={{
          color: globalTheme.textMainBlack.color,
        }}
      >
        {surnameInputHelperText}
      </p>
      <p
        className="PersonalSettingsTitle"
        style={{
          color: globalTheme.textMainBlack.color,
        }}
      >
        Gender
      </p>
      <div className="PersonalSettingsRadios">
        <FormControlLabel
          value="Female"
          control={
            <Radio
              checked={radioValue === "Female"}
              onChange={(e) => onGenderUpdate(e.target.value)}
              value="Female"
              inputProps={{ "aria-label": "Female" }}
              sx={{
                ...radioButtonStyle,
              }}
            />
          }
          label="Female"
          sx={{
            ...RadioLabelStyle,
          }}
        />
        <FormControlLabel
          value="Male"
          control={
            <Radio
              checked={radioValue === "Male"}
              onChange={(e) => onGenderUpdate(e.target.value)}
              value="Male"
              inputProps={{ "aria-label": "Male" }}
              sx={{
                ...radioButtonStyle,
              }}
            />
          }
          label="Male"
          sx={{
            ...RadioLabelStyle,
          }}
        />
        <FormControlLabel
          value="Non-Binary"
          control={
            <Radio
              checked={radioValue === "Non-Binary"}
              onChange={(e) => onGenderUpdate(e.target.value)}
              value="Non-Binary"
              inputProps={{ "aria-label": "Non-Binary" }}
              sx={{
                ...radioButtonStyle,
              }}
            />
          }
          label="Non-Binary"
          sx={{
            ...RadioLabelStyle,
          }}
        />
      </div>
      <p
        className="PersonalSettingsTitle"
        style={{
          color: globalTheme.textMainBlack.color,
        }}
      >
        Age
      </p>
      <div className="SocialConfigInner">
        <div className="SocialConfigTextField">
          <DatePicker
            slotProps={{
              field: { clearable: true },
              layout: {
                sx: {
                  "& .Mui-selected": {
                    backgroundColor:
                      globalTheme.solidMainBlack.color + " !important",
                    color: globalTheme.solidMainWhite.color,
                  },
                },
              },
            }}
            sx={styles.datePicker}
            disableFuture
            value={moment(ageValue)}
            onChange={(newValue) => {
              if (newValue !== null) {
                setAgeValue(newValue.format("YYYY-MM-DD"));
              } else {
                setAgeValue("");
              }
            }}
          />
        </div>
        <ButtonBase
          onClick={onAgeUpdate}
          disabled={
            (ageValue === "" && ageValueInitial === "") ||
            ageValueError ||
            ageValueUpdating
          }
          style={{
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.figmaGOrange.color,
            width: "60px",
            marginLeft: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {ageValueUpdating ? (
            <CircularProgress
              size={13}
              color="inherit"
              sx={{ color: globalTheme.figmaPrimaryText.color }}
            />
          ) : (
            <p
              className="SocialConfigButtonText"
              style={{
                color:
                  (ageValue === "" && ageValueInitial === "") ||
                  ageValueError ||
                  ageValueUpdating
                    ? globalTheme.figmaPrimaryTextSub.color
                    : globalTheme.figmaPrimaryText.color,
              }}
            >
              {ageValue !== "" && ageValueInitial === ageValue
                ? "Delete"
                : ageValueUpdating
                ? "Saving"
                : ageValueInitial !== ""
                ? "Update"
                : "Add"}
            </p>
          )}
        </ButtonBase>
      </div>
      <p className="PersonalSettingsTitle" style={{
          color: globalTheme.textMainBlack.color,
        }}>Country/Region</p>
      <div className="SocialConfigInner">
        <div className="SocialConfigTextField">
          <Select
            fullWidth
            sx={{ ...selectStyles }}
            MenuProps={{
              sx: styles.selectMenu,
            }}
            value={countryValue}
            placeholder="Country/Region"
            onChange={handleCountryChange}
          >
            <MenuItem disabled key={"start"} value={"Country/Region"}>
              Country/Region
            </MenuItem>
            {countries.map((country: any, index) => (
              <MenuItem key={index} value={country.value}>
                {country.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <ButtonBase
          onClick={onCountryUpdate}
          disabled={
            (countryValue === "" && countryValueInitial === "") ||
            (countryValue === "Country/Region" &&
              countryValueInitial === "Country/Region") ||
            countryValueError ||
            countryValueUpdating
          }
          style={{
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.figmaGOrange.color,
            width: "60px",
            marginLeft: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {countryValueUpdating ? (
            <CircularProgress
              size={13}
              color="inherit"
              sx={{ color: globalTheme.figmaPrimaryText.color }}
            />
          ) : (
            <p
              className="SocialConfigButtonText"
              style={{
                color:
                  (countryValue === "" && countryValueInitial === "") ||
                  (countryValue === "Country/Region" &&
                    countryValueInitial === "Country/Region") ||
                  countryValueError ||
                  countryValueUpdating
                    ? globalTheme.figmaPrimaryTextSub.color
                    : globalTheme.figmaPrimaryText.color,
              }}
            >
              {countryValue !== "" &&
              countryValue !== "Country/Region" &&
              countryValueInitial === countryValue
                ? "Delete"
                : countryValueUpdating
                ? "Saving"
                : countryValueInitial !== "" &&
                  countryValueInitial !== "Country/Region"
                ? "Update"
                : "Add"}
            </p>
          )}
        </ButtonBase>
      </div>
    </div>
  );
}
