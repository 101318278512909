/**
 * Component that renders a wallet list item with options to share and visit the wallet page.
 * It fetches a random NFT image from Alchemy API to display as the wallet's profile picture.
 * If no NFT image is found, it displays the wallet address initials.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {any} props.walletItem - The wallet item data.
 * @param {boolean} props.editIsOpen - Flag indicating if the edit mode is open.
 * @returns {JSX.Element} The rendered WalletListItem component.
 *
 * @example
 * <WalletListItem walletItem={walletItem} editIsOpen={editIsOpen} />
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReadMoreOutlinedIcon from "@mui/icons-material/ReadMoreOutlined";
import copy from "copy-to-clipboard";

import Avatar from "@mui/material/Avatar";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { LazyLoadImg } from "../../../components/ui/misc/LazyLoadImg";
import ButtonBase from "@mui/material/ButtonBase";
import moment from "moment";

import {
  useSnackbarState,
  useThemeState,
} from "../../../components/utils/globalStates";

import { alchemyKey } from "../../../.config/config";
import "./WalletComp.css";
import "../../user/components/UserComp.css";

moment().format();

export default function WalletListItem({
  walletItem,
  editIsOpen,
}: {
  walletItem: any;
  editIsOpen: boolean;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);

  const [walletUrl, setWalletUrl] = useState("");
  const snackbarStates = useSnackbarState();

  const onShareClick = () => {
    /*logEvent(firebaseAnalytics, "user_share", {
      user_id: user?.uid,
    });*/
    copy(`https://app.gath3r.co/wallets/page/${walletItem.wallet}`);
    snackbarStates.setMessage("Link copied to clipboard");
    snackbarStates.setOpenLength(3000);
    snackbarStates.setIsOpen(true);
  };

  const fetchOneNft = async () => {
    if (walletItem.wallet === "") return;
    try {
      const options = {
        method: "GET",
        headers: { accept: "application/json" },
      };
      // @ts-ignore
      let response = await fetch(
        `https://eth-mainnet.g.alchemy.com/nft/v3/${alchemyKey}/getNFTsForOwner?owner=${walletItem.wallet}&withMetadata=true&orderBy=transferTime&excludeFilters[]=AIRDROPS&pageSize=10`,
        options
      );

      let ownedNfts = await response.json();
      // randomize the nft from 0-4
      if (ownedNfts.ownedNfts.length === 0) return;
      let randomNft = Math.floor(Math.random() * ownedNfts.ownedNfts.length);
      let nft = ownedNfts.ownedNfts[randomNft];

      if (
        nft.raw.metadata.image !== undefined &&
        nft.raw.metadata.image !== "" &&
        nft.raw.metadata.image !== null
      ) {
        setWalletUrl(nft.raw.metadata.image);
      }
    } catch (error) {
      console.error("Error fetching nft", error);
    }
  };
  // Fetch one nft image from alchemy and set it as the wallets profile picture
  useEffect(() => {
    if (walletUrl === "") {
      fetchOneNft();
    }
  }, [walletItem.wallet]);

  return (
    <div
      className="WalletListItemOuter"
      style={{
        backgroundColor: globalTheme.figmaWhite.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        ...globalTheme.boxShadow,
        width: editIsOpen ? "calc(100% - 50px)" : "calc(100% - 10px)",
        pointerEvents: editIsOpen ? "none" : "auto",
        transition: "width 0.3s ease",
      }}
    >
      {walletUrl === "" ? (
        <div className="UserListItemAvatar">
          <Avatar
            sx={{
              bgcolor: globalTheme.figmaWhite.color,
              color: globalTheme.figmaLinkTextSub.color,
              border: `0.5px solid ${globalTheme.figmaLinkTextSub.color}`,
              width: "100%",
              height: "100%",
              borderRadius: "8px",
              fontSize: "16px",
              "@media (min-width: 350px)": {
                fontSize: "17px",
              },
              "@media (min-width: 550px)": {
                fontSize: "18px",
              },
              "@media (min-width: 700px)": {
                fontSize: "20px",
              },
            }}
            alt={walletItem.wallet}
          >
            {walletItem.wallet.substr(0, 2)}
          </Avatar>
        </div>
      ) : (
        <div className="UserListItemAvatar">
          <LazyLoadImg imgUrl={walletUrl} />
        </div>
      )}
      <div className="WalletListItemInner">
        <p
          className="UserListItemTitle"
          style={{ color: globalTheme.figmaPrimaryText.color }}
        >
          {walletItem.name}
        </p>
        <p
          className="ReviewsItemUserHeaderInnerSub"
          style={{
            color: globalTheme.figmaPrimaryText.color,
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "100%",
            marginBottom: "-4px",
          }}
        >
          {walletItem.wallet}
        </p>
        <div className="UserListItemButtons">
          <ButtonBase
            sx={{
              textDecoration: "none",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: "7px",
              "@media (min-width: 350px)": {
                marginRight: "10px",
              },
              "@media (min-width: 550px)": {
                marginRight: "14px",
              },
              "@media (min-width: 700px)": {
                marginRight: "20px",
              },
            }}
            onClick={onShareClick}
          >
            <ShareOutlinedIcon
              sx={{
                color: globalTheme.figmaLinkTextSub.color,
                marginRight: "3px",
                fontSize: "14px",
                "@media (min-width: 350px)": {
                  fontSize: "16px",
                  marginRight: "5px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "18px",
                  marginRight: "7px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "20px",
                },
              }}
            />
            <p
              className="ReviewsItemUserHeaderInnerSub"
              style={{
                color: globalTheme.figmaLinkTextSub.color,
                fontWeight: 600,
              }}
            >
              Share
            </p>
          </ButtonBase>
          <Link
            to={`/wallets/page/${walletItem.wallet}`}
            style={{
              textDecoration: "none",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ReadMoreOutlinedIcon
              sx={{
                color: globalTheme.figmaLinkTextSub.color,
                marginRight: "3px",
                marginTop: "2px",
                fontSize: "17px",
                "@media (min-width: 350px)": {
                  fontSize: "20px",
                  marginRight: "5px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "24px",
                  marginRight: "7px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "28px",
                },
              }}
            />
            <p
              className="ReviewsItemUserHeaderInnerSub"
              style={{
                color: globalTheme.figmaLinkTextSub.color,
                fontWeight: 600,
              }}
            >
              Visit Wallet Page
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}
