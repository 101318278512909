/**
 * LoginView component handles the user authentication process.
 * 
 * This component provides the following functionalities:
 * - Email login with OTP verification.
 * - Google login using Firebase authentication.
 * - Twitter login using Firebase authentication.
 * 
 * The component also manages various states such as:
 * - `emailInput`: Stores the user's email input.
 * - `messageEmail`: Indicates if the email input is invalid.
 * - `authLoading`: Indicates if the email login process is loading.
 * - `googleLoading`: Indicates if the Google login process is loading.
 * - `errorMessage`: Stores any error messages encountered during login.
 * - `inputMessage`: Stores messages related to email input validation.
 * - `successMessage`: Stores success messages after initiating login.
 * - `isLoginInit`: Indicates if the login process has been initiated.
 * - `otp`: Stores the OTP input by the user.
 * - `otpLoading`: Indicates if the OTP verification process is loading.
 * - `validOtpUnix`: Stores the Unix timestamp until which the OTP is valid.
 * 
 * The component uses the following hooks:
 * - `useEffect`: To set the theme color and handle user redirection if already logged in.
 * - `useState`: To manage various states mentioned above.
 * - `useAuthState`: To get the current authentication state from Firebase.
 * - `useNavigate`: To navigate to different routes.
 * 
 * The component also defines various styles for the input fields, buttons, and other elements.
 * 
 * @returns {JSX.Element} The rendered LoginView component.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import TextField from "@mui/material/TextField";
import { signInWithCustomToken, GoogleAuthProvider, signInWithPopup, TwitterAuthProvider } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../components/firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { isValidEmail } from "../../components/utils/parsers";
import LogoMask from "../../components/ui/img/LogoMask";
import GoogleLogo from "../../components/ui/img/GoogleLogo";
import XLogo from "../../components/ui/img/socials/Xlogo";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../components/firebase/functions";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "../../components/firebase/analytics";
import Countdown from "react-countdown";
import {
  useThemeState,
} from "../../components/utils/globalStates";
import "./Auth.css";

export default function LoginView() {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [user] = useAuthState(firebaseAuth);
  const navigate = useNavigate();
  const [emailInput, setEmailInput] = useState("");
  const [messageEmail, setMessageEmail] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoginInit, setIsLoginInit] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const [validOtpUnix, setValidOtpUnix] = useState(0);

  useEffect(() => {
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.dashboardBackground.color);
    return () => {
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, []);
  useEffect(() => {
    if (user !== null) {
      return navigate("/");
    }
  }, []);

  useEffect(() => {
    if (emailInput !== "" && !isValidEmail(emailInput)) {
      setMessageEmail(true);
      setInputMessage("Please enter a valid email address");
    } else {
      setMessageEmail(false);
      setInputMessage(" ");
    }

    if (emailInput === "") {
      setMessageEmail(true);
    }
  }, [emailInput]);

  const handleSignin = async () => {
    if (emailInput === "") {
      return;
    }
    if (messageEmail === true) {
      return;
    }
    setAuthLoading(true);
    setErrorMessage("");
    const data = {
      email: emailInput,
    };

    try {
      const signin = httpsCallable(firebaseFunctions, "auth-init_login");
      const res: any = await signin(data);
      console.log(res.data);
      setIsLoginInit(true);
      setAuthLoading(false);
      setValidOtpUnix(res.data.valid);
      setSuccessMessage(`Hi ${res.data.name}!`);
      //setWelcomeMessage(res.data);
      //localStorage.setItem("loginEmail", res.data.email);
      //setAuthLoading(false);
    } catch (error: any) {
      console.log(error);
      if (error.message === "INTERNAL") {
        setErrorMessage("Server error, please try again later.");
        setAuthLoading(false);
      } else {
        setErrorMessage(error.message);
        setAuthLoading(false);
      }
    }
  };

  const handleGoogleSignin = async () => {
    setGoogleLoading(true);
    setErrorMessage("");
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(firebaseAuth, provider);
      const user = result.user;
      console.log(user);
      logEvent(firebaseAnalytics, "login", {
        method: "google",
      });
      navigate("/");
    } catch (error: any) {
      console.log(error);
      if (error.message === "INTERNAL") {
        setErrorMessage("Server error, please try again later.");
        setGoogleLoading(false);
      } else {
        setErrorMessage(error.message);
        setGoogleLoading(false);
      }
    }
  }

  const handleTwitterSignin = async () => {
    setGoogleLoading(true);
    setErrorMessage("");
    const provider = new TwitterAuthProvider();
    try {
      const result = await signInWithPopup(firebaseAuth, provider);
      const user = result.user;
      console.log(user);
      logEvent(firebaseAnalytics, "login", {
        method: "twitter",
      });
      navigate("/");
    } catch (error: any) {
      console.log(error);
      console.log(error.message);
      console.log(error.code);
      console.error(error);
      if (error.message === "INTERNAL") {
        setErrorMessage("Server error, please try again later.");
        setGoogleLoading(false);
      } else {
        setErrorMessage(error.message);
        setGoogleLoading(false);
      }
    }
  }

  const verifyOtp = async () => {
    if (otp.length !== 6) {
      return;
    }
    setOtpLoading(true);
    setErrorMessage("");
    const data = {
      email: emailInput,
      secret: otp,
    };

    try {
      const signin = httpsCallable(firebaseFunctions, "auth-verify_login");
      const res: any = await signin(data);
      console.log(res.data);

      // Sign in with token returned from server
      await signInWithCustomToken(firebaseAuth, res.data.token);
      navigate("/");
      //setWelcomeMessage(res.data);
      //localStorage.setItem("loginEmail", res.data.email);
      //setAuthLoading(false);
    } catch (error: any) {
      console.log(error);
      if (error.message === "INTERNAL") {
        setErrorMessage("Server error, please try again later.");
        setOtpLoading(false);
      } else {
        setErrorMessage(error.message);
        setOtpLoading(false);
      }
    }
  };

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      setIsLoginInit(false);
      setErrorMessage("Time limit exceeded, please try again.");
    } else {
      // Render a countdown
      return (
        <span style={{ fontWeight: 600 }}>
          {"0" + minutes}:{seconds < 10 ? "0" + seconds : seconds}
        </span>
      );
    }
  };

  const styles = {
    input: {
      backgroundColor: "transparent",
      color: globalTheme.textMainBlack.color + " !important",
      borderColor: globalTheme.textMainBlack.color + " !important",
      WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
      "& .MuiInput-root": {
        color: globalTheme.textMainBlack.color + " !important",
        borderColor: globalTheme.textMainBlack.color + " !important",
        WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        "& fieldset": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&:after": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&.Mui-focused fieldset": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
      },
      "& .MuiInputLabel-root": {
        color: globalTheme.textMainBlack.color + " !important",
      },
    },
    inputOTP: {
      width: "90%",
      backgroundColor: "transparent",
      color: globalTheme.textMainBlack.color + " !important",
      borderColor: globalTheme.textMainBlack.color + " !important",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "5px",
          color: globalTheme.textMainBlack.color + " !important",
          border: `1px solid ${globalTheme.textMainBlack.color}`,
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "& input": {
          textAlign: "center !important",
          fontSize: "20px",
          letterSpacing: "4px",
          fontWeight: 600,
          color: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&:after": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&.Mui-focused fieldset": {
          color: globalTheme.textMainBlack.color + " !important",
          border: `1px solid ${globalTheme.textMainBlack.color}`,
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
      },
    },
    button: {
      width: "100%",
      borderRadius: "10px",
      marginTop: "20px",
      backgroundColor: globalTheme.coreOrange.color,
      color: globalTheme.coreOrangeOff.color,
      textTransform: "none",
      boxShadow: globalTheme.boxShadow.boxShadow,
      border: `1px solid ${globalTheme.coreOrangeOff.color}`,
      "&.Mui-disabled": {
        borderRadius: "10px",
        backgroundColor: globalTheme.coreOrange.color,
        color: globalTheme.coreOrangeOff.color,
        opacity: 0.96,
        border: `1px solid ${globalTheme.coreOrangeOff.color}`,
      },
      "&:hover": {
        backgroundColor: globalTheme.coreOrange.color,
        color: globalTheme.coreOrangeOff.color,
        opacity: 0.96,
        border: `1px solid ${globalTheme.coreOrangeOff.color}`,
      },
    },
    buttonError: {
      marginTop: "34px",
      textTransform: "none",
      color: globalTheme.textColor.color,
      "&:hover": {
        color: globalTheme.textColor.color,
      },
    },

    iconButton: {
      borderRadius: "10px",
      backgroundColor: globalTheme.boxBackgroundWhite.color,
      color: globalTheme.textMainBlack.color,
      marginBottom: "25px",
      border: `1px solid ${globalTheme.textMainBlack.color}`,
      "&:hover": {
        backgroundColor: globalTheme.boxBackgroundWhite.color,
        color: globalTheme.textMainBlack.color,
        opacity: 0.96,
        border: `1px solid ${globalTheme.textMainBlack.color}`,
      },
    },
    iconButtonGoogle: {
      marginTop: "25px",
      width: "86%",
      height: "44px",
      borderRadius: "10px",
      textTransform: "none",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      border: `1px solid ${globalTheme.textMainBlack.color}`,
    },
  };

  return (
    <div
      className="LoginViewOuter"
      style={{ backgroundColor: globalTheme.dashboardBackground.color }}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Log In</title>
      </Helmet>
      <div className="LoginViewAlt">
        <div className="AuthIconStart">
          <div className="AuthIcon">
            <LogoMask
              fillColor={globalTheme.dashboardBackground.color}
              strokeColor={globalTheme.backgroundMainOffYellow.color}
            />
          </div>
        </div>
        <div className="AuthStartButtons">
          <div
            className="AuthStartButtonsInner"
            style={{
              boxShadow: globalTheme.boxShadow.boxShadow,
              backgroundColor: globalTheme.boxBackgroundWhite.color,
              border: `1px solid ${globalTheme.loginBoxBorder.color}`,
              transform: isLoginInit ? "translateX(-100vw)" : "translateX(0vw)",
            }}
          >
            <p
              className="AuthText"
              style={{
                color: globalTheme.textMainBlack.color,
                flex: 'unset',
                marginBottom: '20px',
                maxWidth: '80%',
              }}
            >
              Welcome to GATH3R Open Beta! Please log in to continue.
            </p>
            <div className="textfieldOuter">
              <TextField
                sx={styles.input}
                label="Log in with Email"
                placeholder="Email address"
                value={emailInput}
                type="email"
                fullWidth
                variant="standard"
                disabled={authLoading}
                onChange={(e) => setEmailInput(e.target.value)}
              />
              <p
                className="InputText"
                style={{ color: globalTheme.textMainBlack.color }}
              >
                {inputMessage}
              </p>
            </div>
            <p className="AuthText">or</p>
            <Button
              sx={styles.iconButtonGoogle}
              color="inherit"
              size="large"
              onClick={handleGoogleSignin}
            >
              <div className="AuthButtonIcon">
                <GoogleLogo />
              </div>

              <p
                className="AuthText"
                style={{ color: globalTheme.textMainBlack.color }}
              >
                Log in with Google
              </p>
            </Button>
            <Button
              sx={styles.iconButtonGoogle}
              color="inherit"
              size="large"
              onClick={handleTwitterSignin}
            >
             <div className="AuthButtonIcon" style={{ width: "9%" }}>
                <XLogo />
              </div>

              <p
                className="AuthText"
                style={{ color: globalTheme.textMainBlack.color }}
              >
                Log in with X
              </p>
            </Button>
            {/*<Button
              disabled
              sx={styles.iconButtonGoogle}
              color="inherit"
              size="large"
            >
              <div className="AuthButtonIcon" style={{ width: "9%" }}>
                <XLogo />
              </div>
              <p
                className="AuthText"
                style={{ color: globalTheme.textMainBlack.color }}
              >
                Log in with X/Twitter
              </p>
          </Button>*/}
          </div>
          <div
            className="AuthStartButtonsInnerAlt"
            style={{
              boxShadow: globalTheme.boxShadow.boxShadow,
              backgroundColor: globalTheme.boxBackgroundWhite.color,
              border: `1px solid ${globalTheme.loginBoxBorder.color}`,
              transform: isLoginInit ? "translateX(0vw)" : "translateX(100vw)",
            }}
          >
            <p
              className="SuccessTitle"
              style={{ color: globalTheme.textMainBlack.color }}
            >
              {successMessage}
            </p>
            <p
              className="AuthText"
              style={{
                marginBottom: "25px",
                color: globalTheme.textMainBlack.color,
              }}
            >
              Copy the code sent to your email and enter it below to login. Time
              left:{" "}
              {validOtpUnix !== 0 && (
                <Countdown date={validOtpUnix} renderer={renderer} />
              )}
            </p>
            <div className="textfieldOuter">
              <TextField
                sx={styles.inputOTP}
                placeholder="OTP"
                value={otp}
                fullWidth
                disabled={otpLoading}
                onChange={(e) => setOtp(e.target.value)}
                type="tel"
              />
              <p
                className="ErrorText"
                style={{
                  marginTop: "20px",
                  color: globalTheme.textMainBlack.color,
                }}
              >
                <span style={{ fontSize: "80%" }}>
                  Can't find the email? Check your spam!
                </span>
              </p>
            </div>
          </div>
          {isLoginInit ? (
            <Button
              sx={styles.button}
              size="large"
              color="inherit"
              variant="outlined"
              disabled={otp.length !== 6 || otpLoading}
              onClick={verifyOtp}
            >
              {otpLoading ? (
                <CircularProgress
                  color="inherit"
                  size={20}
                  sx={{ paddingTop: "3px", paddingBottom: "3px" }}
                />
              ) : (
                "LOG IN"
              )}
            </Button>
          ) : (
            <Button
              sx={styles.button}
              size="large"
              color="inherit"
              variant="outlined"
              disabled={messageEmail || authLoading}
              onClick={handleSignin}
            >
              {authLoading ? (
                <CircularProgress
                  color="inherit"
                  size={20}
                  sx={{ paddingTop: "3px", paddingBottom: "3px" }}
                />
              ) : (
                "LOG IN"
              )}
            </Button>
          )}
          {errorMessage === "" && !isLoginInit ? (
            <p
              className="ErrorText"
              style={{
                marginTop: "20px",
                color: globalTheme.textMainBlack.color,
              }}
            >
              Not registered?{" "}
              <Link
                style={{
                  textDecoration: "none",
                  fontWeight: 500,
                  color: globalTheme.textMainBlack.color,
                }}
                to={"/auth/register"}
              >
                Sign up here!
              </Link>
            </p>
          ) : (
            <p
              onClick={() => setErrorMessage("")}
              style={{
                marginTop: "20px",
                cursor: "pointer",
                color: globalTheme.textMainBlack.color,
              }}
              className="ErrorText"
            >
              {errorMessage}
            </p>
          )}
        </div>
        <div className="AuthStartFooter">
          {!isLoginInit && (
            <IconButton
              sx={styles.iconButton}
              color="inherit"
              href="https://gath3r.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <HomeOutlinedIcon fontSize="medium" />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
}

/*
<div className="AuthStartButtons">
              <div
                className="AuthStartButtonsInner"
                style={{ ...globalTheme.background, ...globalTheme.boxShadow }}
              >
                <p className="SuccessTitle">Error message:</p>
                <p className="SuccessText">{errorMessage}</p>
                <Button
                  sx={styles.buttonError}
                  color="inherit"
                  onClick={() => setErrorMessage("")}
                  startIcon={<CancelOutlinedIcon />}
                >
                  <p className="ButtonTitle">Close</p>
                </Button>
              </div>
            </div>
          )
        ) : (
          <div className="AuthStartButtons">
            <div
              className="AuthStartButtonsInner"
              style={{ ...globalTheme.background, ...globalTheme.boxShadow }}
            >
              <p className="SuccessTitle">{successTitle}</p>
              <p className="SuccessText">{successMessage}</p>
              <p className="SuccessText">You can close this tab now!</p>
            </div>
          </div>
          */
