/**
 * AuthVerify component handles the email link verification process for user authentication.
 * 
 * This component:
 * - Checks if the user is already authenticated.
 * - If not authenticated, retrieves the email from local storage.
 * - Verifies the email link using Firebase authentication.
 * - Displays appropriate messages using snackbar notifications.
 * - Navigates the user based on the authentication status.
 * 
 * @component
 * @returns {JSX.Element} The AuthVerify component.
 * 
 * @example
 * // Usage in a route
 * <Route path="/verify" element={<AuthVerify />} />
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import {useEffect } from "react";
import LoadingLogo from "../../components/ui/misc/LoadingLogo";
import { Helmet } from "react-helmet-async";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../components/firebase/auth";
import { useNavigate } from "react-router-dom";
import { useSnackbarState, useSnackbarErrorState } from "../../components/utils/globalStates";
import "./Auth.css";

function AuthVerify() {
  const [user] = useAuthState(firebaseAuth);
  const navigate = useNavigate();
  const snackbarState = useSnackbarState((state) => state);
  const snackbarErrorState = useSnackbarErrorState((state) => state);

  useEffect(() => {
    if (user === null) {
      let email = localStorage.getItem("loginEmail");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        snackbarErrorState.setOpenLength(6000);
        snackbarErrorState.setMessage("Email auth error, make sure you use the same browser you initiated the sign in with.");
        snackbarErrorState.setIsOpen(true);

        return navigate("/");
      }
      if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(firebaseAuth, email, window.location.href)
          .then((result) => {
            // Clear email from storage.
            localStorage.removeItem("loginEmail");
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
            snackbarState.setOpenLength(6000);
            snackbarState.setMessage(`Welcome to GATH3R${
              result.user.displayName === ""
                ? ""
                : " " + result.user.displayName
            }!`);
            snackbarState.setIsOpen(true);
            return navigate("/");
          })
          .catch((error) => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
            console.error(error);
            snackbarErrorState.setOpenLength(6000);
            snackbarErrorState.setMessage("Email auth error, please try again.");
            snackbarErrorState.setIsOpen(true);
            return navigate("/");
          });
      } else {
        snackbarErrorState.setOpenLength(6000);
        snackbarErrorState.setMessage("Invalid auth link.");
        snackbarErrorState.setIsOpen(true);
        return navigate("/");
      }
    } else {
      return navigate("/");
    }
  }, [user]);
  return (
    <div className="AuthVerify">
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Verify Auth</title>
      </Helmet>
      <div className="AuthVerifyIcon">
        <LoadingLogo />
      </div>
    </div>
  );
}

export default AuthVerify;

//<img src={logo} className="App-logo" alt="logo" />
