/**
 * AdminListingSearch component provides a search functionality for the admin listing page.
 * It supports both mobile and desktop views, with different UI elements for each.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * // Usage example:
 * <AdminListingSearch />
 *
 * @remarks
 * This component uses the following hooks:
 * - `useAuthState` from `react-firebase-hooks/auth` to get the current authenticated user.
 * - `useExploreSortState`, `useDeviceState`, `useThemeState`, `useAdminState` from custom global state hooks.
 *
 * The component renders an `Autocomplete` input for mobile devices and a `TextField` input for desktop devices.
 * It also handles the search query state and updates the global state accordingly.
 *
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { ButtonBase, TextField } from "@mui/material";
import aa from "search-insights";
import Autocomplete from "@mui/material/Autocomplete";
import {
  useExploreSortState,
  useDeviceState,
  useThemeState,
  useAdminState,
} from "../../../components/utils/globalStates";
import "../../../components/ui/topBoxes/TopBoxes.css";

export default function AdminListingSearch() {
  const [query, setQuery] = useState("");
  const exploreSortState = useExploreSortState();
  const adminState = useAdminState();
  const deviceState = useDeviceState();
  const globalTheme = useThemeState((state) => state.globalTheme);

  useEffect(() => {
    setQuery(exploreSortState.search);
  }, [exploreSortState.search]);

  const styles = {
    exploreSearchForm: {
      "& .MuiOutlinedInput-root": {
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color,
        borderWidth: "1px",
        backgroundColor: globalTheme.figmaWhite.color,
        boxShadow: "none",
        borderRadius: "8px",
        padding: "6px",
        paddingLeft: "14px",
        paddingRight: "14px !important",
        marginTop: "10px",
        "& fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          borderWidth: "1px",
          color: globalTheme.figmaPrimaryText.color,
        },
        "&:hover fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          borderWidth: "1px",
          color: globalTheme.figmaPrimaryText.color,
        },
        "&.Mui-focused fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          color: globalTheme.figmaPrimaryText.color,
        },
      },
      "& label.Mui-focused": {
        color: globalTheme.figmaPrimaryText.color,
      },
      "& label": {
        color: globalTheme.figmaPrimaryText.color,
      },
    },
  };

  return deviceState.isMobile ? (
    <Autocomplete
      freeSolo
      fullWidth
      inputValue={query}
      sx={{
        ...styles.exploreSearchForm,
      }}
      filterOptions={(x) => x}
      getOptionLabel={(option: any) => {
        return option.name;
      }}
      renderOption={(props: any, option: any) => {
        return (
          query !== "" && (
            <ButtonBase
              key={option.id}
              onClick={async () => {
                adminState.setActiveListing(option.id);
                adminState.setActiveListingName(option.name);
                try {
                  await aa("clickedObjectIDsAfterSearch", {
                    index: "gath3r_listings",
                    eventName: "Listing Clicked after Search",
                    queryID: option.searchId,
                    objectIDs: [option.id],
                    positions: [props["data-option-index"] + 1],
                  });
                } catch (error) {
                  console.error(error);
                  console.log(error);
                }
              }}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "space-between",
                width: "100%",
                padding: "10px",
                backgroundColor: "#fff",
              }}
            >
              <p className="AutocompleteResultTitle">{option.name}</p>
              <p className="AutocompleteResultText">
                {"in " + option.category}
              </p>
            </ButtonBase>
          )
        );
      }}
      options={exploreSortState.results}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search"
          onChange={(event) => {
            exploreSortState.setSortType("relevance");
            exploreSortState.setSearch(event.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon
                  style={{ color: globalTheme.figmaExploreSearch.color }}
                />
              </InputAdornment>
            ),
            endAdornment: exploreSortState.search !== "" && (
              <InputAdornment position="end">
                <CloseOutlinedIcon
                  style={{
                    color: globalTheme.figmaExploreSearch.color,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    exploreSortState.setSortType("createdAt");
                    exploreSortState.setSearch("");
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  ) : (
    <TextField
      id="outlined-basic"
      variant="outlined"
      fullWidth
      size="small"
      placeholder="Search"
      value={exploreSortState.search}
      sx={styles.exploreSearchForm}
      onChange={(e) => {
        exploreSortState.setSortType("relevance");
        exploreSortState.setSearch(e.target.value);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon
              style={{ color: globalTheme.figmaExploreSearch.color }}
            />
          </InputAdornment>
        ),
        endAdornment: exploreSortState.search !== "" && (
          <InputAdornment position="end">
            <CloseOutlinedIcon
              style={{
                color: globalTheme.figmaExploreSearch.color,
                cursor: "pointer",
              }}
              onClick={() => {
                exploreSortState.setSortType("createdAt");
                exploreSortState.setSearch("");
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
}
