/**
 * ProfileAddImage component is responsible for rendering the profile image addition interface.
 * It utilizes various global states and hooks to manage the UI and theme settings.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <ProfileAddImage />
 *
 * @remarks
 * This component uses the following hooks:
 * - `useAuthState` from `react-firebase-hooks/auth` to get the current user authentication state.
 * - `useNavigate` from `react-router-dom` to navigate between routes.
 * - `useHeaderSettingsState`, `useTabSettingsState`, and `useThemeState` from `globalStates` to manage global UI settings.
 *
 * The component also sets and resets various UI settings on mount and unmount using `useEffect`.
 *
 * @dependencies
 * - `PfpCreatorInfo`: A component that provides information about the profile picture creator.
 * - `PfpCreator`: A component that handles the creation of the profile picture.
 *
 * @css
 * The component applies styles from `ProfileStartComp.css`.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../../components/firebase/auth";

import PfpCreatorInfo from "../../../../components/ui/topBoxes/PfpCreatorInfo";
import PfpCreator from "./PfpCreator";
import {
  useHeaderSettingsState,
  useTabSettingsState,
  useThemeState,
} from "../../../../components/utils/globalStates";
import "./ProfileStartComp.css";

export default function ProfileAddImage() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const navigate = useNavigate();
  const headerSettingsState = useHeaderSettingsState((state) => state);
  const tabSettingsState = useTabSettingsState((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);

  useEffect(() => {
    headerSettingsState.setIsProfileButtonOpen(true);
    tabSettingsState.setColor(globalTheme.figmaBeigeDark.color);
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.backgroundMainBeige.color);
    return () => {
      headerSettingsState.setIsProfileButtonOpen(false);
      tabSettingsState.setColor(globalTheme.backgroundMainYellow.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      headerSettingsState.setIsLogoOpen(false);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  return (
    <div
      className="ProfileAddImage"
      style={{ backgroundColor: globalTheme.backgroundMainBeige.color, paddingTop: "80px" }}
    >
      <PfpCreatorInfo />
      <PfpCreator />
    </div>
  );
}
