/**
 * Initial dashboard settings configuration.
 * 
 * This array contains multiple sections, each section is an array of settings objects.
 * Each settings object can have different properties based on its type.
 * 
 * Types of settings objects:
 * - "shortcut": Represents a shortcut item with a title, path, icon, and background color.
 * - "listings": Represents a listings section filtered by a specific criterion.
 * - "singleListing": Represents a single listing item identified by a project ID.
 * - "count": Represents a count item with a title, filter, and path.
 * - "users": Represents a users section filtered by a specific criterion.
 * 
 * Properties:
 * - `type` (string): The type of the settings object.
 * - `rowLength` (number): The number of rows the item spans.
 * - `title` (string, optional): The title of the item (for "shortcut" and "count" types).
 * - `uidRequired` (boolean, optional): Indicates if a user ID is required (for "shortcut" and "count" types).
 * - `path` (string, optional): The path for navigation (for "shortcut" and "count" types).
 * - `icon` (string, optional): The icon associated with the item (for "shortcut" type).
 * - `bgColorCode` (string): The background color code for the item.
 * - `filter` (string, optional): The filter criterion (for "listings" and "users" types).
 * - `category` (string, optional): The category for listings (for "listings" type).
 * - `projectId` (string, optional): The project ID for single listing items (for "singleListing" type).
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
export const initialDashSettings = [
  [
    {
      type: "shortcut",
      rowLength: 1,
      title: "Achievements",
      uidRequired: false,
      path: "/achievements",
      icon: "editor_choice",
      bgColorCode: "#FFF8BE",
    },
    {
      type: "shortcut",
      rowLength: 1,
      title: "My Listings",
      uidRequired: true,
      path: "/users/listings/",
      icon: "folder_open",
      bgColorCode: "#FFF8BE",
    },
    {
      type: "shortcut",
      rowLength: 1,
      title: "Bookmarks",
      uidRequired: false,
      path: "/profile/bookmarks",
      icon: "book",
      bgColorCode: "#FFF8BE",
    },
    {
      type: "shortcut",
      rowLength: 1,
      title: "Following",
      uidRequired: true,
      path: "/users/follow/out/",
      icon: "account_circle",
      bgColorCode: "#FFF8BE",
    },
  ],
  [
    {
      type: "listings",
      rowLength: 4,
      filter: "createdAt",
      bgColorCode: "#FFF8BE",
      category: "",
    },
  ],
  [
    {
      type: "listings",
      rowLength: 4,
      filter: "rating",
      bgColorCode: "#FFF8BE",
      category: "",
    },
  ],
  /*[
    {
      type: "customSearch",
      rowLength: 4,
      filter: "nft",
      bgColorCode: "#AAE1A9",
      title: "Custom Search",
    },
  ],*/
  [
    {
      type: "singleListing",
      rowLength: 2,
      projectId: "trust-wallet",
      bgColorCode: "#FFF8BE",
    },
    {
      type: "count",
      rowLength: 1,
      title: "Points",
      filter: "points",
      bgColorCode: "#FFF8BE",
      path: "/achievements",
      uidRequired: false,
    },
    {
      type: "count",
      rowLength: 1,
      title: "Listings",
      filter: "listingCount",
      bgColorCode: "#FFD379",
      path: "/users/listings",
      uidRequired: true,
    },
  ],
  [
    {
      type: "singleListing",
      rowLength: 2,
      projectId: "trezor",
      bgColorCode: "#FFF8BE",
    },
    {
      type: "count",
      rowLength: 1,
      title: "Reviews",
      filter: "reviewsCount",
      bgColorCode: "#FFD379",
      path: "/reviews/user",
      uidRequired: true,
    },
    {
      type: "count",
      rowLength: 1,
      title: "Ratings",
      filter: "ratingCount",
      bgColorCode: "#FFF8BE",
      path: "/reviews/user/rating",
      uidRequired: true,
    },
  ],
  [
    {
      type: "singleListing",
      rowLength: 2,
      projectId: "zora",
      bgColorCode: "#FFF8BE",
    },
    {
      type: "singleListing",
      rowLength: 2,
      projectId: "metamask",
      bgColorCode: "#FFF8BE",
    },
  ],
  [
    {
      type: "users",
      rowLength: 4,
      filter: "latestWeekPoints",
      bgColorCode: "#FFF8BE",
      uidRequired: true,
    },
  ],
  [
    {
      type: "users",
      rowLength: 4,
      title: "Top Users",
      filter: "points",
      bgColorCode: "#FFF8BE",
    },
  ],
];
