/**
 * Component to display a user's achievement item.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.achievement - The achievement object containing details about the achievement.
 * @param {string} [props.achievement.url] - The URL of the achievement image.
 * @param {string} props.achievement.name - The name of the achievement.
 * @param {string} props.achievement.subtitle - The subtitle of the achievement.
 * @param {number} props.achievement.points - The points associated with the achievement.
 * @param {string} [props.achievement.icon] - The icon to display if the URL is not provided.
 * @returns {JSX.Element} The JSX element representing the user's achievement item.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { LazyLoadImg } from "../../../../components/ui/misc/LazyLoadImg";
import Tooltip from "@mui/material/Tooltip";
import { useThemeState } from "../../../../components/utils/globalStates";
import "./UserPageComp.css";

export default function UserPageAchievementsItem({
  achievement,
}: {
  achievement: any;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  return (
    <div
      className="ProfileAchivementItem"
      style={{
        backgroundColor: globalTheme.backgroundMainBeigeDark.color,
        border: `1px solid ${globalTheme.solidMainBlack.color}`,
      }}
    >
      {achievement.url !== null &&
      achievement.url !== undefined &&
      achievement.url !== "" ? (
        <Tooltip
          title={
            achievement.name +
            " - " +
            achievement.subtitle +
            " - " +
            achievement.points +
            " points"
          }
        >
          <div className="UserPageV2AchievementsListItemImg">
            <LazyLoadImg imgUrl={achievement.url} />
          </div>
        </Tooltip>
      ) : (
        /*<div className="UserPageV2AchievementsListItemImg">
          <LazyLoadImg
            imgUrl={`https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fachievements%2F${achievement.achievement}.png?alt=media`}
          />
        </div>*/
        <Tooltip
          title={
            achievement.name +
            " - " +
            achievement.subtitle +
            " - " +
            achievement.points +
            " points"
          }
        >
          <div className="UserPageV2AchievementsListItemImg">
            <span
              style={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
              className="material-symbols-outlined"
            >
              {achievement.icon}
            </span>
          </div>
        </Tooltip>
      )}
    </div>
  );
}
