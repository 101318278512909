/**
 * Represents a component that allows an authenticated user to create, update,
 * and delete reviews and ratings for a particular listing. It also displays
 * current review data—including rating, title, and body—and provides validation
 * for user inputs. When a user submits or updates a review or rating, it triggers
 * Firebase callable functions to store or modify the review data, and sends
 * conversion events to Algolia for analytics.
 *
 * @remarks
 * - Handles initial data fetching to determine whether a user has already
 *   reviewed the listing.
 * - Manages user input validation and error display via global snackbars.
 * - Communicates with Firebase Functions for creating, updating, and deleting
 *   both ratings and reviews.
 * - Integrates Algolia analytics events for tracking conversions.
 * - Prevents certain updates and deletions after a configurable timeframe
 *   (e.g., 3 days).
 *
 * @param listing - An object representing the current listing, which includes
 *                  its unique identifier, name, and category.
 * @returns The component that renders user interface for rating and review
 *          submission, along with controls for updating existing data.
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";
import aa from "search-insights";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import Skeleton from "@mui/material/Skeleton";
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import { ButtonBase } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../components/firebase/functions";
import moment from "moment";
import { doc, getDoc } from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import {
  useSnackbarErrorState,
  useSnackbarState,
  useThemeState,
  useExploreSortState,
  useDeviceState,
} from "../../../components/utils/globalStates";
import "./ReviewsComp.css";

moment().format();

export default function NewReview({ listing }: { listing: any }) {
  const [user] = useAuthState(firebaseAuth);
  const exploreSortState = useExploreSortState((state) => state);
  const deviceState = useDeviceState((state) => state);
  const snackbarStates = useSnackbarState((state) => state);
  const snackbarError = useSnackbarErrorState((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [ratingLoading, setRatingLoading] = useState(true);
  const [rating, setRating] = useState(0);
  const [reviewLoading, setReviewLoading] = useState(true);
  const [reviewData, setReviewData] = useState<any | null>(null);
  const [ratingExpanded, setRatingExpanded] = useState(false);
  const [reviewExpanded, setReviewExpanded] = useState(false);
  const [isRatingUpdating, setIsRatingUpdating] = useState(false);
  const [isReviewUpdating, setIsReviewUpdating] = useState(false);
  const [isRatingDeleteConfirm, setIsRatingDeleteConfirm] = useState(false);
  const [isReviewDeleteConfirm, setIsReviewDeleteConfirm] = useState(false);
  const [isRatingDelete, setIsRatingDelete] = useState(false);
  const [isReviewDelete, setIsReviewDelete] = useState(false);
  const [reviewTitle, setReviewTitle] = useState("");
  const [reviewBody, setReviewBody] = useState("");

  // Check if user has reviewed listing
  useEffect(() => {
    if (listing.id === null || listing.id === undefined) {
      return;
    }
    if (user?.uid === null || user?.uid === undefined) {
      return;
    }
    const getData = async () => {
      try {
        const reviewDoc = await getDoc(
          doc(firestoreDb, "reviews", user?.uid + "--" + listing.id)
        );
        if (reviewDoc.exists()) {
          setReviewData(reviewDoc.data());
          setRating(reviewDoc.data().rating);
          setReviewLoading(false);
          setRatingLoading(false);
        } else {
          setReviewLoading(false);
          setRatingLoading(false);
        }
      } catch (error) {
        console.error("Error getting review:", error);
        setReviewData(null);
      }
    };
    getData();
  }, []);

  const validateInputs = async () => {
    if (user === null || user === undefined) {
      return false;
    }
    if (listing.id === null || listing.id === undefined) {
      return false;
    }
    if (reviewExpanded) {
      if (rating === 0 || rating === null || rating === undefined) {
        snackbarError.setMessage("Invalid rating");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        return false;
      }
      if (
        reviewTitle === "" ||
        reviewTitle.length < 5 ||
        reviewTitle.length > 100
      ) {
        snackbarError.setMessage("Invalid title (5-100 characters)");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        return false;
      }
      if (
        reviewBody === "" ||
        reviewBody.length < 50 ||
        reviewBody.length > 1000
      ) {
        snackbarError.setMessage("Invalid review (50-1000 characters)");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        return false;
      }
    } else {
      if (rating === 0 || rating === null || rating === undefined) {
        snackbarError.setMessage("Invalid rating");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        return false;
      }
    }
    return true;
  };

  const onRatingSubmit = async () => {
    if (!(await validateInputs())) {
      return;
    }
    const oldRating = rating;
    setIsRatingUpdating(true);
    snackbarStates.setMessage(
      oldRating === 0 ? "Submitting rating" : "Updating rating"
    );
    snackbarStates.setOpenLength(null);
    snackbarStates.setIsActionLoading(true);
    snackbarStates.setIsOpen(true);

    const userAuthToken = await user?.getIdToken();
    const data = {
      id: listing.id,
      rating: rating,
      token: userAuthToken,
      category: listing.category,
      name: listing.name,
    };
    try {
      const updateRating = httpsCallable(
        firebaseFunctions,
        "reviews-new_rating_v2"
      );
      const ratingData = await updateRating(data);
      setReviewData(ratingData.data);
      setIsRatingUpdating(false);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage(
        oldRating === 0 ? "Rating submitted" : "Rating updated"
      );
      snackbarStates.setOpenLength(3000);
      snackbarStates.setIsOpen(true);
    } catch (err: any) {
      console.error(err);
      setIsRatingUpdating(false);
      setRating(oldRating);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarError.setMessage("Error: " + err.message);
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
    }

    // Send event to algolia
    let isFound = false;
    for (let i = 0; i < exploreSortState.results.length; i++) {
      if (exploreSortState.results[i].id === listing.id) {
        isFound = true;
        break;
      }
    }
    if (isFound && exploreSortState.results[0].searchId !== undefined) {
      try {
        await aa("convertedObjectIDsAfterSearch", {
          index: "gath3r_listings",
          eventName: "Rated listing after Search",
          queryID: exploreSortState.results[0].searchId,
          objectIDs: [listing.id],
        });
      } catch (error) {
        console.error(error);
        console.log(error);
      }
    } else {
      console.log("convertedObjectIDs");
      try {
        await aa("convertedObjectIDs", {
          index: "gath3r_listings",
          eventName: "Rated listing",
          objectIDs: [listing.id],
        });
      } catch (error) {
        console.error(error);
        console.log(error);
      }
    }
  };

  const onRatingUpdate = async () => {
    if (!(await validateInputs())) {
      return;
    }
    // Check if rating is different
    if (reviewData !== null && reviewData.rating === rating) {
      snackbarError.setMessage("Rating is the same");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }

    // Check if rating is more then 3 days old, return error if so
    if (
      reviewData !== null &&
      reviewData.createdAt !== null &&
      reviewData.createdAt !== undefined
    ) {
      const reviewDate = new Date(reviewData.createdAt);
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate.getTime() - reviewDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 3) {
        snackbarError.setMessage(
          "Rating is more than 3 days old and cannot be updated"
        );
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        return;
      }
    }
    const oldRating = rating;
    setIsRatingUpdating(true);
    snackbarStates.setMessage("Updating rating");
    snackbarStates.setOpenLength(null);
    snackbarStates.setIsActionLoading(true);
    snackbarStates.setIsOpen(true);

    const userAuthToken = await user?.getIdToken();
    const data = {
      id: reviewData.id,
      rating: rating,
      token: userAuthToken,
    };
    try {
      const updateRating = httpsCallable(
        firebaseFunctions,
        "reviews-update_rating"
      );
      await updateRating(data);
      setReviewData({ ...reviewData, rating: rating });
      setIsRatingUpdating(false);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("Rating updated");
      snackbarStates.setOpenLength(3000);
      snackbarStates.setIsOpen(true);
    } catch (err: any) {
      console.error(err);
      setIsRatingUpdating(false);
      setRating(oldRating);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarError.setMessage("Error: " + err.message);
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
    }
  };

  const onRatingDelete = async () => {
    if (reviewData === null) {
      return;
    }
    // Check if rating is more then 3 days old, return error if so
    if (
      reviewData !== null &&
      reviewData.createdAt !== null &&
      reviewData.createdAt !== undefined
    ) {
      const reviewDate = new Date(reviewData.createdAt);
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate.getTime() - reviewDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 3) {
        snackbarError.setMessage(
          "Rating is more than 3 days old and cannot be deleted"
        );
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        return;
      }
    }
    setIsRatingDelete(true);
    snackbarStates.setMessage("Deleting rating");
    snackbarStates.setOpenLength(null);
    snackbarStates.setIsActionLoading(true);
    snackbarStates.setIsOpen(true);

    try {
      const userAuthToken = await user?.getIdToken();
      const data = {
        id: reviewData.id,
        token: userAuthToken,
      };
      const deleteRating = httpsCallable(firebaseFunctions, "reviews-delete");
      await deleteRating(data);
      setReviewData(null);
      setRating(0);
      setReviewTitle("");
      setReviewBody("");
      setIsRatingDeleteConfirm(false);
      setRatingExpanded(false);
      setIsRatingDelete(false);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("Rating deleted");
      snackbarStates.setOpenLength(3000);
      snackbarStates.setIsOpen(true);
    } catch (err: any) {
      console.error(err);
      setIsRatingDelete(false);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarError.setMessage("Error: " + err.message);
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
    }
  };

  const onReviewSubmit = async () => {
    if (!(await validateInputs())) {
      return;
    }
    const isWhitespaceString = (str: string) => !str.replace(/\s/g, "").length;
    if (isWhitespaceString(reviewTitle) || isWhitespaceString(reviewBody)) {
      snackbarError.setMessage(
        "Invalid review, please enter some text! White spaces is not allowed."
      );
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }
    const oldTitle = reviewTitle;
    const oldBody = reviewBody;
    setIsReviewUpdating(true);
    snackbarStates.setMessage("Submitting review");
    snackbarStates.setOpenLength(null);
    snackbarStates.setIsActionLoading(true);
    snackbarStates.setIsOpen(true);

    try {
      const userAuthToken = await user?.getIdToken();
      const data = {
        id: listing.id,
        title: reviewTitle,
        text: reviewBody,
        token: userAuthToken,
        category: listing.category,
        name: listing.name,
        rating: rating,
      };
      const updateReview = httpsCallable(
        firebaseFunctions,
        "reviews-new_review_v2"
      );
      const ratingData = await updateReview(data);
      setReviewData(ratingData.data);
      setIsReviewUpdating(false);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("Review submitted");
      snackbarStates.setOpenLength(3000);
      snackbarStates.setIsOpen(true);
    } catch (err: any) {
      console.error(err);
      setIsReviewUpdating(false);
      setReviewTitle(oldTitle);
      setReviewBody(oldBody);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("");
      snackbarError.setMessage("Error: " + err.message);
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
    }
    // Send event to algolia
    let isFound = false;
    for (let i = 0; i < exploreSortState.results.length; i++) {
      if (exploreSortState.results[i].id === listing.id) {
        isFound = true;
        break;
      }
    }
    if (isFound && exploreSortState.results[0].searchId !== undefined) {
      console.log("convertedObjectIDsAfterSearch");
      try {
        await aa("convertedObjectIDsAfterSearch", {
          index: "gath3r_listings",
          eventName: "Reviewed listing after Search",
          queryID: exploreSortState.results[0].searchId,
          objectIDs: [listing.id],
        });
      } catch (error) {
        console.error(error);
        console.log(error);
      }
    } else {
      console.log("convertedObjectIDs");
      try {
        await aa("convertedObjectIDs", {
          index: "gath3r_listings",
          eventName: "Reviewed listing",
          objectIDs: [listing.id],
        });
      } catch (error) {
        console.error(error);
        console.log(error);
      }
    }
  };

  const onReviewUpdate = async () => {
    if (!(await validateInputs())) {
      return;
    }
    // Check if rating is different
    if (
      reviewData !== null &&
      reviewData.rating === rating &&
      reviewData.title === reviewTitle &&
      reviewData.text === reviewBody
    ) {
      snackbarError.setMessage("Review is the same");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }

    // Check if rating is more then 3 days old, return error if so
    if (
      reviewData !== null &&
      reviewData.createdAt !== null &&
      reviewData.createdAt !== undefined
    ) {
      const reviewDate = new Date(reviewData.createdAt);
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate.getTime() - reviewDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 3) {
        snackbarError.setMessage(
          "Review is more than 3 days old and cannot be updated"
        );
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        return;
      }
    }
    const oldTitle = reviewTitle;
    const oldBody = reviewBody;
    setIsReviewUpdating(true);
    snackbarStates.setMessage("Updating review");
    snackbarStates.setOpenLength(null);
    snackbarStates.setIsActionLoading(true);
    snackbarStates.setIsOpen(true);

    try {
      const userAuthToken = await user?.getIdToken();
      const data = {
        id: reviewData.id,
        title: reviewTitle,
        text: reviewBody,
        token: userAuthToken,
        rating: rating,
      };
      const updateReview = httpsCallable(
        firebaseFunctions,
        "reviews-update_review"
      );
      await updateReview(data);
      setIsReviewUpdating(false);
      setReviewData({
        ...reviewData,
        review: true,
        title: reviewTitle,
        text: reviewBody,
        rating: rating,
      });
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("Review updated");
      snackbarStates.setOpenLength(3000);
      snackbarStates.setIsOpen(true);
    } catch (err: any) {
      console.error(err);
      setIsReviewUpdating(false);
      setReviewTitle(oldTitle);
      setReviewBody(oldBody);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("");
      snackbarError.setMessage("Error: " + err.message);
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
    }
  };

  const onReviewDelete = async () => {
    if (reviewData === null) {
      return;
    }
    // Check if rating is more then 3 days old, return error if so
    if (
      reviewData !== null &&
      reviewData.createdAt !== null &&
      reviewData.createdAt !== undefined
    ) {
      const reviewDate = new Date(reviewData.createdAt);
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate.getTime() - reviewDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 3) {
        snackbarError.setMessage(
          "Review is more than 3 days old and cannot be deleted"
        );
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        return;
      }
    }
    setIsReviewDelete(true);
    snackbarStates.setMessage("Deleting review");
    snackbarStates.setOpenLength(null);
    snackbarStates.setIsActionLoading(true);
    snackbarStates.setIsOpen(true);

    try {
      const userAuthToken = await user?.getIdToken();
      const data = {
        id: reviewData.id,
        token: userAuthToken,
      };
      const deleteReview = httpsCallable(
        firebaseFunctions,
        "reviews-delete_review"
      );
      await deleteReview(data);
      setReviewTitle("");
      setReviewBody("");
      setReviewData({ ...reviewData, review: false, title: "", text: "" });
      setIsReviewDeleteConfirm(false);
      setReviewExpanded(false);
      setIsReviewDelete(false);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("Review deleted");
      snackbarStates.setOpenLength(3000);
      snackbarStates.setIsOpen(true);
    } catch (err: any) {
      console.error(err);
      setIsReviewDelete(false);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarError.setMessage("Error: " + err.message);
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
    }
  };

  useEffect(() => {
    if (ratingExpanded) {
      setReviewExpanded(false);
      setIsReviewDeleteConfirm(false);
    }
  }, [ratingExpanded]);

  useEffect(() => {
    if (reviewExpanded) {
      setRatingExpanded(false);
      setIsRatingDeleteConfirm(false);
    }
  }, [reviewExpanded]);

  useEffect(() => {
    if (
      reviewData !== null &&
      reviewData !== undefined &&
      reviewData.review === true
    ) {
      if (reviewData.title !== null && reviewData.title !== undefined) {
        setReviewTitle(reviewData.title);
      }
      if (reviewData.text !== null && reviewData.text !== undefined) {
        setReviewBody(reviewData.text);
      }
    }
  }, [reviewData]);

  const onReset = () => {
    if (reviewExpanded) {
      if (reviewData !== null && reviewData !== undefined) {
        if (reviewData.title !== null && reviewData.title !== undefined) {
          setReviewTitle(reviewData.title);
        } else {
          setReviewTitle("");
        }
        if (reviewData.text !== null && reviewData.text !== undefined) {
          setReviewBody(reviewData.text);
        } else {
          setReviewBody("");
        }
        if (reviewData.rating !== null && reviewData.rating !== undefined) {
          setRating(reviewData.rating);
        } else {
          setRating(0);
        }
      } else {
        setReviewTitle("");
        setReviewBody("");
        setRating(0);
      }
    } else if (ratingExpanded) {
      if (reviewData !== null && reviewData !== undefined) {
        if (reviewData.rating !== null && reviewData.rating !== undefined) {
          setRating(reviewData.rating);
        } else {
          setRating(0);
        }
      } else {
        setRating(0);
      }
    }
  };

  const onClose = () => {
    setRatingExpanded(false);
    setReviewExpanded(false);
  };

  const primaryColorTextField = {
    "& .MuiOutlinedInput-root": {
      color: globalTheme.textMainBlack.color,
      borderColor: globalTheme.textMainBlack.color,
      borderWidth: "1px",
      backgroundColor: globalTheme.boxBackgroundWhite.color,
      boxShadow: "1px 1px 5px rgba(35, 35, 35, 0.05)",
      borderRadius: "9px",
      "& fieldset": {
        borderColor: globalTheme.textMainBlack.color,
        borderWidth: "1px",
        color: globalTheme.textMainBlack.color,
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: globalTheme.textMainBlack.color,
        borderRadius: "8px",
        borderWidth: "1px",
        color: globalTheme.textMainBlack.color,
      },
      "&.Mui-focused fieldset": {
        borderColor: globalTheme.textMainBlack.color,
        borderRadius: "8px",
        borderWidth: "2px",
        color: globalTheme.textMainBlack.color,
      },
    },
    "& label.Mui-focused": {
      color: globalTheme.textMainBlack.color,
    },
    "& label": {
      color: globalTheme.textMainBlack.color,
    },
  };

  const buttonStyles = {
    color: "#1A1A1A",
    backgroundColor: "#FFED8F",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #1A1A1A",
    margin: "0px",
    cursor: "pointer",
    width: "48%",
    marginBottom: "3px",
    padding: "3px 0px",
    "&:hover": {
      opacity: 0.9,
    },
    "@media (max-width: 1200px)": {
      marginBottom: "1px",
      padding: "3px 0px",
    },
    "@media (max-width: 800px)": {
      marginBottom: "2px",
      padding: "3px 0px",
    },
    "@media (max-width: 700px)": {
      marginBottom: "3px",
      padding: "2.5px 0px",
    },
    "@media (max-width: 600px)": {
      marginBottom: "4px",
      padding: "2px 0px",
    },
    "@media (max-width: 500px)": {
      marginBottom: "2px",
      padding: "2px 0px",
    },
    "@media (max-width: 400px)": {
      marginBottom: "1px",
      padding: "3px 0px",
    },
    "@media (max-width: 300px)": {
      marginBottom: "0px",
      padding: "1.5px 0px",
    },
  };

  return (
    <div
      className="NewReviewItemOuter"
      style={{
        backgroundColor: globalTheme.boxBackgroundWhite.color,
      }}
    >
      <div className="NewReviewItem">
        <div className="NewReviewItemInner">
          <ButtonBase
            disableRipple
            disabled={isRatingUpdating || isReviewUpdating}
            onClick={() => setRatingExpanded(!ratingExpanded)}
            sx={{
              padding: "0px",
              margin: "0px",
              borderRadius: "5px",
              marginLeft: "5px",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <StarOutlineOutlinedIcon
              sx={{
                color: globalTheme.textMainBlack.color,
                fontSize: "16px",
                marginTop: "0px",
                "@media (min-width: 350px)": {
                  fontSize: "19px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "22px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "24px",
                },
              }}
            />
            {ratingLoading ? (
              <Skeleton
                variant="text"
                sx={{
                  width: "60px",
                  fontSize: "14px",
                  marginBottom: "0px",
                  marginLeft: "5px",
                }}
              />
            ) : (
              <p
                className="NewReviewItemInnerText"
                style={{ color: globalTheme.textMainBlack.color }}
              >
                {ratingExpanded
                  ? "Close"
                  : rating !== 0 && reviewData !== null
                  ? "Update rating"
                  : "Rate " +
                    (listing.name.length > 20
                      ? listing.name.slice(0, 20) + "..."
                      : listing.name)}
              </p>
            )}
          </ButtonBase>
        </div>
        <div className="NewReviewItemInner">
          <ButtonBase
            disableRipple
            onClick={() => setReviewExpanded(!reviewExpanded)}
            disabled={isReviewUpdating || isRatingUpdating}
            sx={{
              padding: "0px",
              margin: "0px",
              borderRadius: "5px",
              marginLeft: "5px",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <RateReviewOutlinedIcon
              sx={{
                color: globalTheme.textMainBlack.color,
                fontSize: "16px",
                marginTop: "3px",
                "@media (min-width: 350px)": {
                  fontSize: "19px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "22px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "24px",
                },
              }}
            />
            {reviewLoading ? (
              <Skeleton
                variant="text"
                sx={{
                  width: "60px",
                  fontSize: "14px",
                  marginBottom: "0px",
                  marginLeft: "5px",
                }}
              />
            ) : (
              <p
                className="NewReviewItemInnerText"
                style={{ color: globalTheme.textMainBlack.color }}
              >
                {reviewExpanded
                  ? "Close"
                  : reviewData?.review === true
                  ? "Update review"
                  : "Write a review"}
              </p>
            )}
          </ButtonBase>
        </div>
      </div>
      <div
        className="NewReviewItemBody"
        style={{
          height: ratingExpanded ? "40px" : reviewExpanded ? "270px" : "0px",
          marginTop: reviewExpanded || ratingExpanded ? "10px" : "0px",
          marginBottom: reviewExpanded || ratingExpanded ? "20px" : "0px",
          opacity: ratingExpanded || reviewExpanded ? 1 : 0,
        }}
      >
        <div className="NewReviewItemBodyRating">
          <div className="NewReviewItemBodyRatingInner">
            {isRatingDeleteConfirm && ratingExpanded && !reviewExpanded ? (
              <p
                className="NewReviewItemInnerText"
                style={{
                  color: globalTheme.textMainBlack.color,
                  marginBottom: "5px",
                }}
              >
                {reviewData !== null && reviewData.review === true
                  ? "Delete rating & review?"
                  : "Delete rating?"}
              </p>
            ) : (
              <Rating
                value={rating}
                onChange={(event, newRating) => {
                  if (newRating !== null) {
                    setRating(newRating);
                  }
                }}
                readOnly={isRatingUpdating || isReviewUpdating}
                size={
                  deviceState.deviceWidth < 300
                    ? "small"
                    : deviceState.deviceWidth < 500
                    ? "medium"
                    : "large"
                }
              />
            )}
          </div>
        </div>
        <div
          className="NewReviewItemBodyReview"
          style={{
            height: reviewExpanded ? "200px" : "0px",
            marginBottom: reviewExpanded ? "20px" : "0px",
            opacity: reviewExpanded ? 1 : 0,
          }}
        >
          <TextField
            id="outlined-multiline-static"
            label={reviewTitle.length > 0 ? `Title - ${reviewTitle.length}/100` : "Title"}
            placeholder="Title (5-100 characters)"
            multiline
            rows={1}
            size="small"
            value={reviewTitle}
            onChange={(e) => setReviewTitle(e.target.value)}
            variant="outlined"
            sx={{
              ...primaryColorTextField,
              width: "100%",
              borderRadius: "8px",
              marginTop: "15px",
            }}
          />
          <TextField
            id="outlined-multiline-static"
            label={reviewBody.length > 0 ? `Review - ${reviewBody.length}/1000` : "Review"}
            placeholder="Review (50-1000 characters)"
            size="small"
            multiline
            rows={4}
            value={reviewBody}
            onChange={(e) => setReviewBody(e.target.value)}
            variant="outlined"
            sx={{
              ...primaryColorTextField,
              width: "100%",
              borderRadius: "8px",
              marginTop: "13px",
            }}
          />
        </div>
        {!isRatingDeleteConfirm && !isReviewDeleteConfirm ? (
          <div className="NewReviewItemBodyButtons">
            <ButtonBase
              onClick={
                reviewExpanded
                  ? reviewData !== null &&
                    (rating !== reviewData.rating ||
                      reviewTitle !== reviewData.title ||
                      reviewBody !== reviewData.text)
                    ? onReset
                    : onClose
                  : reviewData !== null && rating !== reviewData.rating
                  ? onReset
                  : onClose
              }
              disabled={isReviewUpdating || isRatingUpdating}
              sx={{
                ...buttonStyles,
                backgroundColor: globalTheme.colorPaletteButtonRed.color,
              }}
            >
              <p
                className="NewReviewItemButtonText"
                style={{
                  color: globalTheme.buttonText.color,
                }}
              >
                {reviewExpanded
                  ? reviewData !== null &&
                    (rating !== reviewData.rating ||
                      reviewTitle !== reviewData.title ||
                      reviewBody !== reviewData.text)
                    ? "Reset"
                    : "Close"
                  : reviewData !== null && rating !== reviewData.rating
                  ? "Reset"
                  : "Close"}
              </p>
            </ButtonBase>
            {reviewExpanded &&
              (reviewData !== null && reviewData.review === true ? (
                rating !== reviewData.rating ||
                reviewTitle !== reviewData.title ||
                reviewBody !== reviewData.text ? (
                  <ButtonBase
                    onClick={onReviewUpdate}
                    sx={{
                      ...buttonStyles,
                      backgroundColor:
                        globalTheme.colorPaletteButtonGreen.color,
                    }}
                  >
                    <p
                      className="NewReviewItemButtonText"
                      style={{
                        color: globalTheme.buttonText.color,
                      }}
                    >
                      {isReviewUpdating ? "Updating" : "Update"}
                    </p>
                  </ButtonBase>
                ) : (
                  <ButtonBase
                    onClick={() => setIsReviewDeleteConfirm(true)}
                    sx={{
                      ...buttonStyles,
                      backgroundColor:
                        globalTheme.colorPaletteButtonGreen.color,
                    }}
                  >
                    <p
                      className="NewReviewItemButtonText"
                      style={{
                        color: globalTheme.buttonText.color,
                      }}
                    >
                      Delete
                    </p>
                  </ButtonBase>
                )
              ) : (
                <ButtonBase
                  onClick={onReviewSubmit}
                  sx={{
                    ...buttonStyles,
                    backgroundColor: globalTheme.colorPaletteButtonGreen.color,
                  }}
                >
                  <p
                    className="NewReviewItemButtonText"
                    style={{
                      color: globalTheme.buttonText.color,
                    }}
                  >
                    {isReviewUpdating ? "Submitting" : "Review"}
                  </p>
                </ButtonBase>
              ))}
            {ratingExpanded &&
              (reviewData !== null ? (
                reviewData.rating !== rating ? (
                  <ButtonBase
                    onClick={onRatingUpdate}
                    sx={{
                      ...buttonStyles,
                      backgroundColor:
                        globalTheme.colorPaletteButtonGreen.color,
                    }}
                  >
                    <p
                      className="NewReviewItemButtonText"
                      style={{
                        color: globalTheme.buttonText.color,
                      }}
                    >
                      {isRatingUpdating ? "Updating" : "Update"}
                    </p>
                  </ButtonBase>
                ) : (
                  <ButtonBase
                    onClick={() => setIsRatingDeleteConfirm(true)}
                    sx={{
                      ...buttonStyles,
                      backgroundColor:
                        globalTheme.colorPaletteButtonGreen.color,
                    }}
                  >
                    <p
                      className="NewReviewItemButtonText"
                      style={{
                        color: globalTheme.buttonText.color,
                      }}
                    >
                      Delete
                    </p>
                  </ButtonBase>
                )
              ) : (
                <ButtonBase
                  onClick={onRatingSubmit}
                  sx={{
                    ...buttonStyles,
                    backgroundColor: globalTheme.colorPaletteButtonGreen.color,
                  }}
                >
                  <p
                    className="NewReviewItemButtonText"
                    style={{
                      color: globalTheme.buttonText.color,
                    }}
                  >
                    {isRatingUpdating ? "Submitting" : "Rate"}
                  </p>
                </ButtonBase>
              ))}
            {!reviewExpanded && !ratingExpanded && (
              <ButtonBase
                sx={{
                  ...buttonStyles,
                  backgroundColor: globalTheme.colorPaletteButtonGreen.color,
                }}
              >
                <p
                  className="NewReviewItemButtonText"
                  style={{
                    color: globalTheme.buttonText.color,
                  }}
                >
                  Submit
                </p>
              </ButtonBase>
            )}
          </div>
        ) : isRatingDeleteConfirm && ratingExpanded && !reviewExpanded ? (
          <div className="NewReviewItemBodyButtons">
            <ButtonBase
              onClick={() => setIsRatingDeleteConfirm(false)}
              disabled={isReviewUpdating || isRatingUpdating}
              sx={{
                ...buttonStyles,
                backgroundColor: globalTheme.colorPaletteButtonGreen.color,
              }}
            >
              <p
                className="NewReviewItemButtonText"
                style={{
                  color: globalTheme.buttonText.color,
                }}
              >
                Cancel
              </p>
            </ButtonBase>
            <ButtonBase
              onClick={onRatingDelete}
              disabled={isReviewUpdating || isRatingUpdating}
              sx={{
                ...buttonStyles,
                backgroundColor: globalTheme.colorPaletteButtonRed.color,
              }}
            >
              <p
                className="NewReviewItemButtonText"
                style={{
                  color: globalTheme.buttonText.color,
                }}
              >
                {isRatingDelete ? "Deleting" : "Confirm"}
              </p>
            </ButtonBase>
          </div>
        ) : isReviewDeleteConfirm && reviewExpanded && !ratingExpanded ? (
          <div className="NewReviewItemBodyButtons">
            <ButtonBase
              onClick={() => setIsReviewDeleteConfirm(false)}
              disabled={isReviewUpdating || isRatingUpdating}
              sx={{
                ...buttonStyles,
                backgroundColor: globalTheme.colorPaletteButtonGreen.color,
              }}
            >
              <p
                className="NewReviewItemButtonText"
                style={{
                  color: globalTheme.buttonText.color,
                }}
              >
                Cancel
              </p>
            </ButtonBase>
            <ButtonBase
              onClick={onReviewDelete}
              disabled={isReviewUpdating || isRatingUpdating}
              sx={{
                ...buttonStyles,
                backgroundColor: globalTheme.colorPaletteButtonRed.color,
              }}
            >
              <p
                className="NewReviewItemButtonText"
                style={{
                  color: globalTheme.buttonText.color,
                }}
              >
                {isReviewDelete ? "Deleting" : "Confirm"}
              </p>
            </ButtonBase>
          </div>
        ) : null}
        {isReviewDeleteConfirm && reviewExpanded && !ratingExpanded && (
          <div
            className="NewReviewItemBodyRatingInner"
            style={{
              position: "absolute",
              bottom: "0px",
              left: "0px",
            }}
          >
            <p
              className="NewReviewItemInnerText"
              style={{
                color: globalTheme.textMainBlack.color,
                marginBottom: "5px",
              }}
            >
              Delete review?
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
