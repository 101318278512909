/**
 * Initializes the Algolia search client and sets up various indices for searching listings, users, reviews, and display names.
 * 
 * @fileoverview This file configures the Algolia search client and initializes multiple indices for different search functionalities within the Gath3r application.
 * 
 * @requires algoliasearch/lite - Algolia search client for JavaScript.
 * @requires search-insights - Algolia search insights for tracking user interactions.
 * @requires ../../.config/config - Configuration file containing Algolia app ID and API key.
 * 
 * @constant {object} searchClient - The Algolia search client initialized with the app ID and API key.
 * 
 * @constant {object} algoliaListingsRelevance - Index for searching listings by relevance.
 * @constant {object} algoliaListingsCreatedAtDesc - Index for searching listings sorted by creation date in descending order.
 * @constant {object} algoliaListingsRatingDesc - Index for searching listings sorted by rating in descending order.
 * 
 * @constant {object} algoliaUsersRelevance - Index for searching users by relevance.
 * @constant {object} algoliaUsersCreatedAtDesc - Index for searching users sorted by creation date in descending order.
 * @constant {object} algoliaUsersLastActiveDesc - Index for searching users sorted by last active date in descending order.
 * @constant {object} algoliaUsersPointsDesc - Index for searching users sorted by points in descending order.
 * @constant {object} algoliaUsersPointsHourDesc - Index for searching users sorted by points earned in the last hour in descending order.
 * @constant {object} algoliaUsersPointsDayDesc - Index for searching users sorted by points earned in the last day in descending order.
 * @constant {object} algoliaUsersPointsWeekDesc - Index for searching users sorted by points earned in the last week in descending order.
 * 
 * @constant {object} algoliaReviewsRelevance - Index for searching reviews by relevance.
 * @constant {object} algoliaReviewsCreatedAtDesc - Index for searching reviews sorted by creation date in descending order.
 * 
 * @constant {object} algoliaUsedDisplayNames - Index for searching used display names.
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 * 
 */
import algoliasearch from "algoliasearch/lite";
import aa from 'search-insights';
import { algoliaId, algoliaKey } from "../../.config/config";

const searchClient = algoliasearch(algoliaId, algoliaKey);

aa("init", {
  appId: algoliaId,
  apiKey: algoliaKey,
});

export const algoliaListingsRelevance = searchClient.initIndex("gath3r_listings");
export const algoliaListingsCreatedAtDesc = searchClient.initIndex(
  "gath3r_listings_desc_createdAt"
);
export const algoliaListingsRatingDesc = searchClient.initIndex("gath3r_listings_desc_rating");
/*export const algoliaListingsTrendingHourDesc = searchClient.initIndex(
  "gath3r_listings_desc_trendingHour"
);*/
/*export const algoliaListingsViewsTotalDesc = searchClient.initIndex(
  "gath3r_listings_desc_viewsTotal"
);*/

export const algoliaUsersRelevance = searchClient.initIndex("gath3r_users");
export const algoliaUsersCreatedAtDesc = searchClient.initIndex("gath3r_users_desc_createdAt");
export const algoliaUsersLastActiveDesc = searchClient.initIndex("gath3r_users_desc_last_active");
export const algoliaUsersPointsDesc = searchClient.initIndex("gath3r_users_desc_points");
export const algoliaUsersPointsHourDesc = searchClient.initIndex("gath3r_users_desc_points_hour");
export const algoliaUsersPointsDayDesc = searchClient.initIndex("gath3r_users_desc_points_day");
export const algoliaUsersPointsWeekDesc = searchClient.initIndex("gath3r_users_desc_points_week");

export const algoliaReviewsRelevance = searchClient.initIndex("gath3r_reviews");
export const algoliaReviewsCreatedAtDesc = searchClient.initIndex("gath3r_reviews_desc_createdAt");

export const algoliaUsedDisplayNames = searchClient.initIndex("gath3r_display_names");