/**
 * ProfileStart component renders the profile-related routes and applies the global theme.
 *
 * This component uses `react-router-dom` to define the routes for the profile section of the application.
 * It includes routes for the main profile view, profile image editing, profile settings, and bookmarks.
 * If a route does not match any of the defined paths, it defaults to rendering the main Profile component.
 *
 * The component also applies a background color from the global theme state.
 *
 * @returns {JSX.Element} The rendered ProfileStart component.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { Routes, Route } from "react-router-dom";

import Profile from "./start/Profile";
import ProfileSettings from "./settings/ProfileSettings";
import Bookmarks from "./bookmarks/Bookmarks";
import ProfileAddImage from "./start/components/ProfileAddImage";
import { useThemeState } from "../../components/utils/globalStates";
import "./Profile.css";

export default function ProfileStart() {
  const globalTheme = useThemeState((state) => state.globalTheme);
  return (
    <div className="ProfileStart" style={{ backgroundColor: globalTheme.backgroundMainBeige.color }}>
      <Routes>
        <Route path="/" element={<Profile />} />
        <Route path="/edit/image" element={<ProfileAddImage />} />
        <Route path="/settings" element={<ProfileSettings />} />
        <Route path="/bookmarks" element={<Bookmarks />} />
        <Route path="*" element={<Profile />} />
      </Routes>
    </div>
  );
}
