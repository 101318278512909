/**
 * The `IssueReport` component allows users to submit issue reports with a title, description, and optional images.
 * It includes form validation, image resizing, and submission to a Firebase function.
 *
 * @component
 * @example
 * return (
 *   <IssueReport />
 * )
 *
 * @returns {JSX.Element} The rendered IssueReport component.
 *
 * @remarks
 * This component uses the following hooks and libraries:
 * - `useState`, `useEffect`, `useCallback` from React
 * - `TextField`, `Button`, `CircularProgress` from @mui/material
 * - `ListIcon`, `DeleteIcon` from @mui/icons-material
 * - `useDropzone` from react-dropzone
 * - `Resizer` from react-image-file-resizer
 * - `httpsCallable` from firebase/functions
 * - `useAuthState` from react-firebase-hooks/auth
 * - Various global state hooks from "../../../components/utils/globalStates"
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../components/firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";

// Internal imports
import {
  useHeaderSettingsState,
  useThemeState,
  useTabSettingsState,
  useSnackbarErrorState,
  useSnackbarState,
} from "../../../components/utils/globalStates";
import IssueReportInfo from "../../../components/ui/topBoxes/IssueReportInfo";

import "./IssueReport.css";

export default function IssueReport() {
    const [user] = useAuthState(firebaseAuth);

  const snackbarStates = useSnackbarState((state) => state);
  const snackbarError = useSnackbarErrorState((state) => state);
  const headerSettings = useHeaderSettingsState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const tabSettings = useTabSettingsState();

  // Set the title states
  const [issueTitle, setIssueTitle] = useState("");
  const [issueTitleSub, setIssueTitleSub] = useState("");
  const [issueTitleVerified, setIssueTitleVerified] = useState(false);

  // Set the description states
  const [issueDescription, setIssueDescription] = useState("");
  const [issueDescriptionSub, setIssueDescriptionSub] = useState("");
  const [issueDescriptionVerified, setIssueDescriptionVerified] =
    useState(false);

  // Set images state
  const [images, setImages] = useState<any[]>([]);
  const [imagesError, setImagesError] = useState("");

  const [isSending, setIsSending] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setImagesError("");
      setImages([...images, ...acceptedFiles]);
    },
    [images]
  );

  const onDropRejected = useCallback((fileRejections: any) => {
    console.log(fileRejections);
    if (fileRejections[0].errors[0].code === "too-many-files") {
      setImagesError(
        "You can only upload a maximum of 5 images. Please try again!"
      );
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
      "image/webp": [".webp"],
    },
    maxFiles: 5,
  });

  const removeFile = (file: any) => () => {
    const newFiles = [...images];
    newFiles.splice(newFiles.indexOf(file), 1);
    setImages(newFiles);
  };

  const files = images.map((file, index) => (
    <img
      key={file.path}
      src={URL.createObjectURL(file)}
      alt={"image " + index}
      className="DropzoneImage"
      onClick={removeFile(file)}
    />
  ));

  // Helper function for title
  const updateIssueTitle = (e: any) => {
    setIssueTitle(e);
    if (e.length < 3) {
      setIssueTitleSub(
        "Issue report title must be at least 3 characters long."
      );
      setIssueTitleVerified(false);
    } else if (e.length > 200) {
      setIssueTitleSub(
        "Issue report title must be less than 200 characters long."
      );
      setIssueTitleVerified(false);
    } else {
      setIssueTitleSub("");
      setIssueTitleVerified(true);
    }
  };

  // Helper function for description
  const updateIssueDescription = (e: any) => {
    setIssueDescription(e);
    if (e.length < 10) {
      setIssueDescriptionSub(
        "Issue report description must be at least 10 characters long."
      );
      setIssueDescriptionVerified(false);
    } else if (e.length > 2000) {
      setIssueDescriptionSub(
        "Issue report description must be less than 2000 characters long."
      );
      setIssueDescriptionVerified(false);
    } else {
      setIssueDescriptionSub("");
      setIssueDescriptionVerified(true);
    }
  };

  const onReset = () => {
    setIssueTitle("");
    setIssueTitleSub("");
    setIssueTitleVerified(false);
    setIssueDescription("");
    setIssueDescriptionSub("");
    setIssueDescriptionVerified(false);
    setImages([]);
    setImagesError("");
  };

  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1500,
        1500,
        "jpeg",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const onSend = async () => {
    if (user === null || user === undefined) {
        snackbarError.setMessage(
            "You need to be logged in to send an issue report."
        );
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        return;
        }
    if (!issueTitleVerified) {
      snackbarError.setMessage(
        "Please fill in a valid title for the issue report."
      );
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }
    if (!issueDescriptionVerified) {
      snackbarError.setMessage(
        "Please fill in a valid description for the issue report."
      );
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }
    setIsSending(true);
    let tempImages = [];
    try {
      snackbarStates.setMessage("Resizing images...");
      snackbarStates.setOpenLength(null);
      snackbarStates.setIsActionLoading(true);
      snackbarStates.setIsOpen(true);
      for (let i = 0; i < images.length; i++) {
        const resizedImage = await resizeFile(images[i]);
        tempImages.push(resizedImage);
      }
    } catch (error) {
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("");
      snackbarError.setMessage(
        "Error resizing images. Please try again or contact support."
      );
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      setIsSending(false);
      return;
    }

    // Send the issue report
    try {
      snackbarStates.setMessage("Sending issue report...");
      const idToken = await user.getIdToken();
      const sendIssueReport = httpsCallable(
        firebaseFunctions,
        "misc-issue_report"
      );
      await sendIssueReport({
        title: issueTitle,
        description: issueDescription,
        images: tempImages,
        token: idToken,
      });
      
      snackbarStates.setMessage("Issue report sent successfully!");
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setOpenLength(4000);
      setIsSending(false);
      onReset();
      return;
    } catch (error) {
        snackbarStates.setIsOpen(false);
        snackbarStates.setIsActionLoading(false);
        snackbarStates.setMessage("");
        snackbarError.setMessage(
            "Error sending issue report. Please try again or contact support."
        );
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        setIsSending(false);
        return
  };
}

  useEffect(() => {
    tabSettings.setColor(globalTheme.primarySurface.color);
    tabSettings.setIconColor(globalTheme.backgroundMainOffYellow.color);
    headerSettings.setLogoBGColor(globalTheme.primarySurface.color);
    headerSettings.setLogoColor(globalTheme.backgroundMainOffYellow.color);
    headerSettings.setIsLogoOpen(true);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.primarySurface.color);
    return () => {
      tabSettings.setColor(globalTheme.backgroundMainYellow.color);
      tabSettings.setIconColor(globalTheme.backgroundMainOffYellow.color);
      headerSettings.setLogoBGColor(globalTheme.backgroundMainYellow.color);
      headerSettings.setLogoColor(globalTheme.backgroundMainOffYellow.color);
      headerSettings.setIsLogoOpen(false);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  const materialFormsStyles = {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      color: globalTheme.textMainBlack.color,
      backgroundColor: globalTheme.primarySurface.color,
      border: `1px solid ${globalTheme.solidMainBlack.color}`,
      "& fieldset": {
        border: "none",
        color: globalTheme.textMainBlack.color,
      },
      "&:hover fieldset": {
        border: "none",
        color: globalTheme.textMainBlack.color,
      },
      "&.Mui-focused fieldset": {
        border: "none",
        color: globalTheme.textMainBlack.color,
      },
    },
    "& label.Mui-focused": {
      display: "none",
    },
    "& legend": { display: "none" },
    "& .MuiInputLabel-shrink": { opacity: 0, transition: "all 0.2s ease-in" },
    "& label": {
      color: globalTheme.textMainBlack.color,
      fontSize: "14px",
      marginTop: "2px",
      zIndex: 1,
    },
    "& span": {
      fontSize: "10px",
    },
  };

  const buttonStyles = {
    color: "#1A1A1A",
    backgroundColor: "#FFED8F",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    boxShadow: globalTheme.boxShadow,
    border: "1px solid #1A1A1A",
    flex: 1,
    margin: "5px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
  };

  return (
    <div
      className="IssueReport"
      style={{ backgroundColor: globalTheme.primarySurface.color }}
    >
      <IssueReportInfo />
      <div className="IssueReportInner" style={{}}>
        <div className="formOuter">
          <TextField
            label="Title"
            variant="outlined"
            size="small"
            sx={materialFormsStyles}
            value={issueTitle}
            multiline
            minRows={2}
            maxRows={2}
            placeholder="Please provide a title for the issue or feature you would like to report."
            onChange={(e) => updateIssueTitle(e.target.value)}
            disabled={false}
          />
          <p className="formDescription">{issueTitleSub}</p>
        </div>
        <div className="formOuter">
          <TextField
            label="Description"
            variant="outlined"
            size="small"
            sx={materialFormsStyles}
            value={issueDescription}
            multiline
            minRows={10}
            maxRows={10}
            placeholder="Please provide a detailed description of the issue or feature you would like to report."
            onChange={(e) => updateIssueDescription(e.target.value)}
            disabled={false}
          />
          <p className="formDescription">{issueDescriptionSub}</p>
        </div>
        <section
          className="DropzoneOuter"
          style={{ borderColor: globalTheme.solidMainBlack.color }}
        >
          <div {...getRootProps({ className: "DropzoneInner" })}>
            <input {...getInputProps()} />
            <p style={{ color: globalTheme.textMainBlack.color }}>
              {imagesError === ""
                ? files.length > 0
                  ? "Click on an image to remove it."
                  : "Drag 'n' drop some images here, or click to select (max 5)."
                : imagesError}
            </p>
          </div>
          {files.length > 0 && <div className="DropzoneImages">{files}</div>}
        </section>
        <div className="IssueReportButtons">
          <Button
            variant="text"
            startIcon={<DeleteIcon />}
            sx={{
              ...buttonStyles,
              backgroundColor: globalTheme.colorPaletteButtonRed.color,
              flex: "unset",
              minWidth: "100px",
            }}
            color="inherit"
            size="small"
            onClick={onReset}
            disabled={isSending}
          >
            RESET
          </Button>
          <Button
            variant="text"
            startIcon={!isSending ? <ListIcon /> : null}
            sx={{
              ...buttonStyles,
              backgroundColor: globalTheme.colorPaletteButtonGreen.color,
              flex: "unset",
              marginLeft: "20px",
              minWidth: "100px",
            }}
            color="inherit"
            size="small"
            onClick={onSend}
            disabled={isSending}
          >
            {isSending ? (
              <CircularProgress size={18} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
