/**
 * AchievementsUser component renders the user achievements page.
 * It includes a Helmet component to manage the document head for SEO purposes.
 *
 * @returns {JSX.Element} The AchievementsUser component.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { Helmet } from 'react-helmet-async';
import './AchievementsUser.css';

export default function AchievementsUser() {

  return (
    <div className="AchievementsUser">
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Achievements</title>
      </Helmet>
      User Achievements
    </div>
  );
}
