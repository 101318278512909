/**
 * SingleListingWidget component displays a single listing with its details such as title, rating, and review count.
 * It also provides an option to delete the widget when in editing mode.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.isEditing - Indicates if the widget is in editing mode.
 * @param {Function} props.deleteWidget - Function to delete the widget.
 * @param {any} props.fulldata - Full data of the listing.
 * @param {number} props.elementIndex - Index of the element in the row.
 * @param {number} props.rowIndex - Index of the row.
 *
 * @returns {JSX.Element} The rendered SingleListingWidget component.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Rating from "@mui/material/Rating";
import { getDoc, doc } from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";
import { IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Skeleton from "@mui/material/Skeleton";
import { useThemeState } from "../../../components/utils/globalStates";
import "./DashWidgets.css";
import "./components/DashWidgetsComp.css"

export default function SingleListingWidget({
  isEditing,
  deleteWidget,
  fulldata,
  elementIndex,
  rowIndex,
}: {
  isEditing: boolean;
  deleteWidget: Function;
  fulldata: any;
  elementIndex: number;
  rowIndex: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const navigate = useNavigate();
  const [isDeleteing, setIsDeleting] = useState(false);
  const [fetchedTitle, setFetchedTitle] = useState("");
  const [fetchedRating, setFetchedRating] = useState(0);
  const [fetchedRatingLoading, setFetchedRatingLoading] = useState(true);
  const [fetchedRatingCount, setFetchedRatingCount] = useState(0);
  const [fetchedReviewCount, setFetchedReviewCount] = useState(0);
  // On delete
  const onDelete = () => {
    setIsDeleting(true);
    deleteWidget(rowIndex, elementIndex);
  };

  const onShortcutClick = () => {
    if (isEditing) return;
    navigate("/projects/" + fulldata.projectId);
  };

  // Fetch project info
  useEffect(() => {
    if (fulldata.projectId === null || fulldata.projectId === undefined) return;
    const fetchProject = async () => {
      try {
        const docRef = doc(firestoreDb, "listings", fulldata.projectId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setFetchedTitle(docSnap.data().name);
          setFetchedRating(docSnap.data().rating);
          setFetchedRatingCount(docSnap.data().ratingCount);
          setFetchedReviewCount(docSnap.data().reviewsCount);
          setFetchedRatingLoading(false);
        } 
      } catch (error) {
        console.error("Error getting document:", error);
      }
    };
    fetchProject();
  }, [fulldata.listingId]);

  return (
    <div
      onClick={onShortcutClick}
      className="SingleListingWidget"
      style={{
        color: globalTheme.figmaPrimaryText.color,
        border: isEditing
          ? `1px dashed ${globalTheme.backgroundMainOffYellow.color}`
          : `1px solid ${globalTheme.figmaPrimaryText.color}`,
        borderRadius: isEditing ? "0px" : "8px",
        backgroundColor:
          fulldata.bgColorCode !== null &&
          fulldata.bgColorCode !== undefined &&
          fulldata.bgColorCode !== ""
            ? fulldata.bgColorCode
            : "transparent",
      }}
    >
      {fetchedRatingLoading ? (
        <>
          <Skeleton
            variant="text"
            sx={{
              marginLeft: "10px",
              marginTop: "3px",
              width: "70%",
              fontSize: "22px",
              marginBottom: "3px",
            }}
          />
          <Skeleton
            variant="text"
            sx={{
              marginLeft: "10px",
              width: "85%",
              fontSize: "13px",
              marginBottom: "3px",
            }}
          />
          <Skeleton
            variant="text"
            sx={{
              marginLeft: "10px",
              marginBottom: "5px",
              width: "65%",
              fontSize: "15px",
            }}
          />
        </>
      ) : (
        <>
          <p style={{ marginLeft: "10px" }} className="CountWidgetTitle">
            {fetchedTitle}
          </p>
          {fetchedRating > 0 ? (
            <>
              <div
                className="RatingItemRating"
                style={{ marginBottom: "0px", alignItems: "center" }}
              >
                {fetchedRatingCount > 0 && (
                  <p
                    className="ListingsWidgetItemSub"
                    style={{
                      color: globalTheme.figmaPrimaryTextSub.color,
                      marginLeft: "10px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {fetchedRatingCount} Ratings
                  </p>
                )}
                {fetchedReviewCount > 0 && (
                  <p
                    className="ListingsWidgetItemSub"
                    style={{
                      color: globalTheme.figmaPrimaryTextSub.color,
                      marginLeft: fetchedRatingCount > 0 ? "3px" : "10px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {fetchedReviewCount} Reviews
                  </p>
                )}
              </div>
              <div className="RatingItemRating" style={{ marginLeft: "10px" }}>
                <p
                  className="ListingsWidgetItemSub"
                  style={{
                    color: globalTheme.figmaPrimaryTextSub.color, marginRight: '5px'
                  }}
                >
                  {fetchedRating.toFixed(1)}
                </p>
                <Rating
                  value={fetchedRating}
                  readOnly
                  size="small"
                  sx={{
                    "& svg": { color: globalTheme.figmaPrimaryText.color },
                  }}
                />
              </div>
            </>
          ) : (
            <p
              className="ListingsWidgetItemSub"
              style={{
                color: globalTheme.figmaPrimaryTextSub.color,
                marginLeft: "10px",
                width: "calc(100% - 20px)",
                textAlign: "left",
                whiteSpace: "normal",
              }}
            >
              Be the first to rate or review this project
            </p>
          )}
        </>
      )}

      {isEditing && (
        <IconButton
          size="small"
          sx={{
            position: "absolute",
            right: "-1px",
            top: "-1px",
            padding: "2px",
            borderRadius: "0px 0px 0px 5px",
            width: "45%",
            color: globalTheme.backgroundMainOffYellow.color,
            backgroundColor: globalTheme.backgroundMainYellow.color,
            border: `1px dashed ${globalTheme.backgroundMainOffYellow.color}`,
            "&:hover": {
              backgroundColor: globalTheme.backgroundMainYellow.color,
            },
          }}
          onClick={onDelete}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      )}
    </div>
  );
}
