/**
 * ProfileSettings component renders the settings page for the user's profile.
 * It includes various settings sections such as Tutorials, Bottom Navigation,
 * Light/Dark Mode, Referral Code, Social Settings, and Account Settings.
 *
 * The component uses several hooks to manage global state and side effects:
 * - `useHeaderSettingsState`: Manages the state of the header settings.
 * - `useTabSettingsState`: Manages the state of the tab settings.
 * - `useThemeState`: Manages the global theme state.
 *
 * The component also uses the `useEffect` hook to set up and clean up the
 * header and tab settings when the component mounts and unmounts.
 *
 * The component renders various sub-components to handle specific settings:
 * - `TutorialsSettings`: Manages tutorial-related settings.
 * - `BottomNavigatorConfigurator`: Configures the bottom navigation.
 * - `ColorThemePicker`: Allows the user to pick a color theme.
 * - `ReferralGenerator`: Generates referral codes.
 * - `ReferralsCount`: Displays the count of referrals.
 * - `SocialConfig`: Configures social media links.
 * - `UserNameSettings`: Manages the user's username settings.
 * - `PersonalSettings`: Manages personal information settings.
 * - `DeleteAccount`: Provides an option to delete the account.
 *
 * The component also uses the `Helmet` component to set the page title for SEO purposes.
 *
 * @returns {JSX.Element} The ProfileSettings component.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useEffect } from "react";

import { Helmet } from "react-helmet-async";

import SocialConfig from "./components/SocialConfig";
import BottomNavigatorConfigurator from "./components/BottomNavigatorConfigurator";
import UserNameSettings from "./components/UserNameSettings";
import PersonalSettings from "./components/PersonalSettings";
import DeleteAccount from "./components/DeleteAccount";
import ColorThemePicker from "./components/ColorThemePicker";
import ReferralGenerator from "./components/ReferralGenerator";
import ReferralsCount from "./components/ReferralsCount";
import TutorialsSettings from "./components/TutorialsSettings";
import {
  useHeaderSettingsState,
  useTabSettingsState,
  useThemeState,
} from "../../../components/utils/globalStates";

import "./ProfileSettings.css";

export default function ProfileSettings() {
  const headerSettingsState = useHeaderSettingsState();
  const tabSettingsState = useTabSettingsState();
  const globalTheme = useThemeState((state) => state.globalTheme);

  useEffect(() => {
    headerSettingsState.setIsProfileButtonOpen(true);
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.backgroundMainBeige.color);
    return () => {
      headerSettingsState.setIsProfileButtonOpen(false);
      tabSettingsState.setColor(globalTheme.backgroundMainYellow.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      headerSettingsState.setIsLogoOpen(false);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  return (
    <div
      className="ProfileSettings"
      style={{ backgroundColor: globalTheme.backgroundMainBeige.color, paddingTop: "80px" }}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Settings</title>
      </Helmet>
      <p
        className="ProfileSettingsTitle"
        style={{ color: globalTheme.backgroundMainOffYellow.color }}
      >
        Tutorials
      </p>
      <TutorialsSettings />
      <p
        className="ProfileSettingsTitle"
        style={{ color: globalTheme.backgroundMainOffYellow.color }}
      >
        Bottom Navigation
      </p>
      <p
        className="ProfileSettingsSub"
        style={{ color: globalTheme.backgroundMainOffBeige.color }}
      >
        Customize the bottom navigation to make finding what’s important a
        breeze.
      </p>
      <BottomNavigatorConfigurator />
      <p
        className="ProfileSettingsSub"
        style={{
          marginTop: "10px",
          color: globalTheme.backgroundMainOffBeige.color,
        }}
      >
        Change shortcuts by clicking the +, the last entry on the list will be
        replaced. You can also re-order the navigation by clicking up/down on
        the arrows.
      </p>
      <p
        className="ProfileSettingsTitle"
        style={{
          marginTop: "40px",
          color: globalTheme.backgroundMainOffYellow.color,
        }}
      >
        Light/Dark Mode
      </p>
      <p
        className="ProfileSettingsSub"
        style={{ color: globalTheme.backgroundMainOffBeige.color }}
      >
        Change the color theme of the app to match your preference.
      </p>
      <ColorThemePicker />
      <p
        className="ProfileSettingsTitle"
        style={{
          marginTop: "40px",
          color: globalTheme.backgroundMainOffYellow.color,
        }}
      >
        Referral Code
      </p>
      <p
        className="ProfileSettingsSub"
        style={{ color: globalTheme.backgroundMainOffBeige.color }}
      >
        Share your referral code with friends and earn points. People who use
        your referral code will also get points and early access to GATH3R! A
        referral code can be used unlimited number of times if you registered
        through a Alpha invitation, but needs to be generated again after 5
        referrals if you registered by referral code.
      </p>
      <ReferralGenerator />
      <ReferralsCount />
      <p
        className="ProfileSettingsTitle"
        style={{
          marginTop: "40px",
          color: globalTheme.backgroundMainOffYellow.color,
        }}
      >
        Social Settings
      </p>
      <p
        className="ProfileSettingsSub"
        style={{ color: globalTheme.backgroundMainOffBeige.color }}
      >
        Add your social media links, if you want people to find you elsewhere.
        (optional)
      </p>
      <SocialConfig />
      <p
        className="ProfileSettingsTitle"
        style={{
          marginTop: "40px",
          color: globalTheme.backgroundMainOffYellow.color,
        }}
      >
        Account Settings
      </p>
      <p
        className="ProfileSettingsSub"
        style={{ color: globalTheme.backgroundMainOffBeige.color }}
      >
        Make changes to your personal information or account type.
      </p>
      <p
        className="ProfileSettingsSubTitle"
        style={{ color: globalTheme.backgroundMainOffYellow.color }}
      >
        Your Account
      </p>
      <UserNameSettings />
      <p
        className="ProfileSettingsSubTitle"
        style={{
          marginTop: "30px",
          color: globalTheme.backgroundMainOffYellow.color,
        }}
      >
        Personal information • Private
      </p>
      <PersonalSettings />
      <DeleteAccount />
      <div style={{ flex: 1 }}></div>
    </div>
  );
}
