// Still in progress

import React, { useState, useEffect } from "react";

import { CircularProgress } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import Pagination from "@mui/material/Pagination";
import { Helmet } from "react-helmet-async";

// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Radio from "@mui/material/Radio";

import {
  useExploreSortState,
  useTabSettingsState,
  useHeaderSettingsState,
  useThemeState,
  useDeviceState
} from "../../components/utils/globalStates";

import NewListingBox from "../listings/new/NewListingBox";
import DashboardSearch from "../../components/ui/topBoxes/DashboardSearch";
import moment from "moment";
import {
  algoliaListingsRelevance,
  algoliaListingsCreatedAtDesc,
  algoliaListingsRatingDesc,
  //algoliaListingsTrendingHourDesc,
  //algoliaListingsViewsTotalDesc,
} from "../../components/search/algoliaSearch";
import ProjectListItem from "../../components/ui/listItems/ProjectListItem";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../components/firebase/auth";
import PickersCardView from "../../components/ui/pickers/PickerCardView";
import "./Explore.css";

moment().format();

export default function ExploreMain() {
  const [user] = useAuthState(firebaseAuth);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const exploreState = useExploreSortState((state) => state);
  const headerSettingsState = useHeaderSettingsState();
  const tabSettingsState = useTabSettingsState();
    const deviceState = useDeviceState();

  useEffect(() => {
    headerSettingsState.setIsLogoOpen(true);
    headerSettingsState.setLogoBGColor(globalTheme.exploreBackground.color);
    headerSettingsState.setLogoColor(globalTheme.backgroundMainOffYellow.color);
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.exploreBackground.color);
    return () => {
      headerSettingsState.setIsLogoOpen(false);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  return (
    <div
      className="Explore"
      style={{
        backgroundColor: globalTheme.exploreBackground.color,
        marginTop: "-10px",
      }}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Explore</title>
      </Helmet>
      <DashboardSearch /> 
      <div className="ExploreNewListingBox">
        <NewListingBox
          bgColorProps={globalTheme.exploreBackground.color}
          alternativeDisplay={true}
        />
      </div>
      
    </div>
  );
}
