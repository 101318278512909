/**
 * A React component that renders a search bar for user listings.
 * It fetches user information from the Firestore database and displays it in the header.
 * The search bar allows users to filter listings based on their input.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string | undefined} props.userId - The ID of the user whose listings are being searched.
 *
 * @returns {JSX.Element} The rendered UserListingsSearch component.
 *
 * @example
 * <UserListingsSearch userId="user123" />
 * 
 * @author 
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { TextField } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { firestoreDb } from "../../firebase/firestore";
import {
  useUserListingsSortState,
  useThemeState,
} from "../../utils/globalStates";
import "./TopBoxes.css";

export default function UserListingsSearch({
  userId,
}: {
  userId: string | undefined;
}) {
  const userListingsSortState = useUserListingsSortState();
  const [userInfo, setUserInfo] = useState<any>();
  const [userInfoLoading, setUserInfoLoading] = useState(true);
  const globalTheme = useThemeState((state) => state.globalTheme);

  // Fetch user info from publicUserInfo collection
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        if (userId === undefined) return;
        const docRef = doc(firestoreDb, "publicUserInfo", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserInfo(docSnap.data());
          setUserInfoLoading(false);
        }
      } catch (error) {
        console.error("Error getting document:", error);
        setUserInfoLoading(false);
      }
    };
    fetchUserInfo();
  }, [userId]);

  const styles = {
    exploreSearchForm: {
      width: "100%",
      marginBottom: "12px",
      "& .MuiOutlinedInput-root": {
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color,
        borderWidth: "1px",
        backgroundColor: globalTheme.figmaWhite.color,
        boxShadow: "none",
        borderRadius: "8px",
        padding: "6px",
        paddingLeft: "14px",
        paddingRight: "14px",
        "& fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          borderWidth: "1px",
          color: globalTheme.figmaPrimaryText.color,
        },
        "&:hover fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          borderWidth: "1px",
          color: globalTheme.figmaPrimaryText.color,
        },
        "&.Mui-focused fieldset": {
          borderColor: globalTheme.figmaPrimaryText.color,
          color: globalTheme.figmaPrimaryText.color,
        },
      },
      "& label.Mui-focused": {
        color: globalTheme.figmaPrimaryText.color,
      },
      "& label": {
        color: globalTheme.figmaPrimaryText.color,
      },
    },
  };

  return (
    <div
      className="SearchAndExplore"
      style={{
        backgroundColor: globalTheme.figmaYellowPastel.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        ...globalTheme.boxShadow,
        maxWidth: "1170px",
      }}
    >
      <div className="SearchAndExploreHeader">
        <p
          className="SearchAndExploreHeaderTitle"
          style={{ color: globalTheme.figmaPrimaryText.color, maxWidth: "95%" }}
        >
          Search Listings
        </p>
        {userInfoLoading ? (
          <Skeleton variant="text" style={{ width: "40%", fontSize: "20px" }} />
        ) : (
          <p
            className="SearchAndExploreHeaderSubLarge"
            style={{
              color: globalTheme.figmaPrimaryText.color,
              maxWidth: "95%",
            }}
          >
            {userInfo !== undefined && "from " + userInfo.displayName}
          </p>
        )}
      </div>
      <TextField
        id="outlined-basic"
        variant="outlined"
        size="small"
        placeholder="Search"
        value={userListingsSortState.search}
        sx={styles.exploreSearchForm}
        onChange={(e) => userListingsSortState.setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon
                style={{ color: globalTheme.figmaExploreSearch.color }}
              />
            </InputAdornment>
          ),
          endAdornment: userListingsSortState.search !== "" && (
            <InputAdornment position="end">
              <CloseOutlinedIcon
                style={{
                  color: globalTheme.figmaExploreSearch.color,
                  cursor: "pointer",
                }}
                onClick={() => userListingsSortState.setSearch("")}
              />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
