/**
 * FirebaseHandlers component is responsible for updating the user's last active time
 * in the Firebase backend at regular intervals.
 *
 * This component uses the `useAuthState` hook from `react-firebase-hooks` to get the
 * current authenticated user from Firebase Authentication.
 *
 * The `updateIntervalHandler` function is called to update the user's last active time
 * by invoking a Firebase Callable Function named `profile-last_active_update`.
 *
 * The `useEffect` hook is used to set up an interval that calls `updateIntervalHandler`
 * every 5 minutes (300000 milliseconds) to keep the user's last active time updated.
 *
 * Another `useEffect` hook is used to perform an initial update of the user's last active
 * time when the component is first mounted, ensuring that the initial update is only
 * performed once.
 *
 * @component
 * @returns {JSX.Element} An empty React fragment.
 * @author jimmybengtsson (itchy-fingers)
 */

import React, { useEffect, useState } from "react";
import { firebaseAuth } from "../firebase/auth";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../components/firebase/functions";

import { useAuthState } from "react-firebase-hooks/auth";

let initLastActive = false;
export default function FirebaseHandlers() {
  const [user] = useAuthState(firebaseAuth);

  const updateIntervalHandler = async () => {
    if (user === null || user === undefined) return;
    const updateInterval = httpsCallable(
      firebaseFunctions,
      "profile-last_active_update"
    );
    const tokenCheck = await user.getIdToken();
    try {
      await updateInterval({ token: tokenCheck });
      console.log("Updated last active");
    }
    catch (e) {
      console.log(e);
    }
  }
  // Set interval to update users last active time
  useEffect(() => {
    if (user === null || user === undefined) return;
    const interval = setInterval(() => {
      console.log("Updating last active");
      updateIntervalHandler();
    }, 300000);

    return () => clearInterval(interval);
  }, [user]);

  useEffect(() => {
    if (user === null || user === undefined) return;
    if (initLastActive) return;
    console.log("Init updating last active");
    updateIntervalHandler();
    initLastActive = true;
  }, []);

  return <></>;
}
