/**
 * AchievementsProfile component displays the user's achievements and progress.
 * It includes functionality to collect new achievements and updates the UI based on the user's authentication state.
 *
 * @component
 * @returns {JSX.Element} The AchievementsProfile component.
 *
 * @remarks
 * This component uses several global states to manage the UI and user data:
 * - `useAuthStates`
 * - `useSnackbarErrorState`
 * - `useHeaderSettingsState`
 * - `useTabSettingsState`
 * - `useAchievementsState`
 * - `useSnackbarState`
 * - `useThemeState`
 *
 * The component also uses the `useAuthState` hook from `react-firebase-hooks/auth` to get the current user.
 *
 * @example
 * ```tsx
 * import AchievementsProfile from './AchievementsProfile';
 * 
 * function App() {
 *   return (
 *     <div>
 *       <AchievementsProfile />
 *     </div>
 *   );
 * }
 * 
 * export default App;
 * ```
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";

import { Link } from "react-router-dom";

import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../components/firebase/functions";
import { ButtonBase, Skeleton } from "@mui/material";
import AchievementsInventoryItem from "./AchievementsInventoryItem";
import AchievementsProgressItem from "./AchievementsProgressItem";
import AchievementsProfileInfo from "../../../components/ui/topBoxes/AchievementsProfileInfo";
import {
  useAuthStates,
  useSnackbarErrorState,
  useHeaderSettingsState,
  useTabSettingsState,
  useAchievementsState,
  useSnackbarState,
  useThemeState,
} from "../../../components/utils/globalStates";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import "./AchievementsProfile.css";

export default function AchievementsProfile() {
  const [user] = useAuthState(firebaseAuth);

  const snackbarErrorState = useSnackbarErrorState();
  const snackbarState = useSnackbarState();
  const authStates = useAuthStates();
  const headerSettingsState = useHeaderSettingsState();
  const tabSettingsState = useTabSettingsState();
  const achievementsState = useAchievementsState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [isCollecting, setIsCollecting] = useState(false);

  useEffect(() => {
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    headerSettingsState.setLogoBGColor(globalTheme.backgroundMainBeige.color);
    headerSettingsState.setLogoColor(globalTheme.backgroundMainOffBeige.color);
    headerSettingsState.setIsLogoOpen(true);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.backgroundMainBeige.color);
    return () => {
      tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      headerSettingsState.setIsLogoOpen(false);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  const onCollect = async () => {
    if (user === null || user === undefined) {
      snackbarErrorState.setMessage("Please sign in to collect achievements.");
      snackbarErrorState.setOpenLength(4000);
      snackbarErrorState.setIsOpen(true);
      return;
    }
    setIsCollecting(true);
    snackbarState.setMessage("Collecting achievements...");
    snackbarState.setOpenLength(null);
    snackbarState.setIsActionLoading(true);
    snackbarState.setIsOpen(true);
    const token = await user?.getIdToken();
    try {
      const collectAchievements = httpsCallable(
        firebaseFunctions,
        "achievements-collect"
      );
      const result: any = await collectAchievements({ token: token });
      snackbarState.setIsActionLoading(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setMessage(result.data);
      achievementsState.setHasNewAchievements(false);
      achievementsState.setNewAchievementsLength(0);
      setIsCollecting(false);
    } catch (error: any) {
      console.error(error);
      snackbarState.setIsOpen(false);
      snackbarState.setIsActionLoading(false);
      snackbarState.setMessage("");
      snackbarErrorState.setMessage("Error: " + error.message);
      snackbarErrorState.setOpenLength(4000);
      snackbarErrorState.setIsOpen(true);
      setIsCollecting(false);
    }
  };
  return (
    <div
      className="AchievementsProfile"
      style={{ backgroundColor: globalTheme.backgroundMainBeige.color }}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - My Achievements</title>
      </Helmet>
      <AchievementsProfileInfo />
      <div className="ReviewsListTabs">
        <div
          style={{
            textDecoration: "none",
            width: "50%",
            backgroundColor: globalTheme.coreOrange.color,
            paddingTop: "4px",
            paddingBottom: "5px",
            border: `1px solid ${globalTheme.coreOrangeOffAnti.color}`,
            borderRightWidth: "0.5px",
            borderRadius: "8px 0px 0px 8px",
          }}
        >
          <div
            className="ListingsPageTabsInner"
            style={{
              color: globalTheme.solidMainBlack.color,
              backgroundColor: "transparent",
            }}
          >
            <p
              className="ListingsPageTabsText"
              style={{ textDecoration: "none" }}
            >
              My Achievements
            </p>
          </div>
        </div>
        <Link
          to={"/achievements/all"}
          style={{
            textDecoration: "none",
            width: "50%",
            paddingTop: "4px",
            paddingBottom: "5px",
            border: `1px solid ${globalTheme.coreOrangeOffAnti.color}`,
            borderLeftWidth: "0.5px",
            borderRadius: "0px 8px 8px 0px",
          }}
        >
          <div
            className="ListingsPageTabsInner"
            style={{
              color: globalTheme.coreOrangeOffAnti.color,
            }}
          >
            <p className="ListingsPageTabsText">All Achievements</p>
          </div>
        </Link>
      </div>
      {authStates.userData === null ? (
        <Skeleton
          variant="rectangular"
          sx={{
            height: "60px",
            width: "90%",
            maxWidth: "800px",
            marginTop: "20px",
            borderRadius: "8px",
          }}
        />
      ) : (
        <div
          className="ProfilePointsLink"
          style={{
            border: `1px solid ${globalTheme.coreOrangeOffAnti.color}`,
            backgroundColor: globalTheme.coreOrangeAnti.color,
            color: globalTheme.coreOrangeOffAnti.color,
            width: "90%",
            maxWidth: "800px",
            marginTop: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <p className="ProfilePointsLinkSub">
            Points: {authStates.userData?.points}
          </p>
        </div>
      )}

      <div
        className="AchievementsProfileInventory"
        style={{
          border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          backgroundColor: globalTheme.figmaCoreOrange.color,
          color: globalTheme.figmaPrimaryText.color,
          width: "90%",
          maxWidth: "800px",
          marginTop: "10px",
        }}
      >
        <div
          className="ListingsWidgetHeader"
          style={{
            color: globalTheme.figmaPrimaryText.color,
            borderBottom: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          }}
        >
          <p className="ListingsWidgetTitle">Achievements</p>
        </div>
        <div className="AchievementsProfileInventoryList">
          {achievementsState.earnedAchievementsLoaded ? (
            achievementsState.earnedAchievements.length > 0 ? (
              achievementsState.earnedAchievements.map((achievement, index) => {
                return (
                  <AchievementsInventoryItem
                    key={index}
                    achievementData={achievement}
                  />
                );
              })
            ) : (
              <p
                className="UserListSortTitle"
                style={{
                  color: globalTheme.figmaPrimaryText.color,
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                No achievements yet
              </p>
            )
          ) : (
            <>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "30px",
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "8px",
                }}
              />
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "30px",
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "8px",
                }}
              />
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "30px",
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "8px",
                }}
              />
            </>
          )}
        </div>
      </div>

      <div
        className="AchievementsProfileInventory"
        style={{
          border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          backgroundColor: globalTheme.figmaGreyPastel.color,
          color: globalTheme.figmaPrimaryText.color,
          width: "90%",
          maxWidth: "800px",
          marginTop: "10px",
        }}
      >
        <div
          className="ListingsWidgetHeader"
          style={{
            color: globalTheme.figmaPrimaryText.color,
            borderBottom: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          }}
        >
          <p className="ListingsWidgetTitle">Progress</p>
          {achievementsState.hasNewAchievements && (
            <ButtonBase disabled={isCollecting} onClick={onCollect}>
              <p
                className="ListingsWidgetCategory"
                style={{
                  border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
                  marginLeft: "0px",
                }}
              >
                {isCollecting ? "COLLECTING" : "COLLECT"}
              </p>
            </ButtonBase>
          )}
        </div>
        <div className="AchievementsProfileInventoryList">
          {achievementsState.progressAchievementsLoaded ? (
            achievementsState.progressAchievements.length > 0 ? (
              achievementsState.progressAchievements.map(
                (achievement, index) => {
                  return (
                    <AchievementsProgressItem
                      key={index}
                      achievementData={achievement}
                    />
                  );
                }
              )
            ) : (
              <p
                className="UserListSortTitle"
                style={{
                  color: globalTheme.figmaPrimaryText.color,
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                No achievements yet
              </p>
            )
          ) : (
            <>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "30px",
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "8px",
                }}
              />
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "30px",
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "8px",
                }}
              />
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "30px",
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "8px",
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
