/**
 * RegisterView component handles the user registration process for the GATH3R application.
 * It provides options for users to sign up using email, Google, or Twitter.
 * The component also validates user inputs such as email, display name, and referral code.
 * 
 * @component
 * 
 * @returns {JSX.Element} The rendered RegisterView component.
 * 
 * @example
 * <RegisterView />
 * 
 * @remarks
 * - The component uses Firebase for authentication and Firestore for checking display name availability.
 * - It also uses Algolia for searching used display names.
 * - The component handles OTP verification for email registration.
 * - The component uses Material-UI for styling and components.
 * - The component uses Zustand for global state management.
 * - The component uses React-Router for navigation.
 * - The component uses react-helmet-async for managing document head.
 * - The component uses react-countdown for OTP countdown timer.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import TextField from "@mui/material/TextField";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../components/firebase/auth";
import { useNavigate, Link, useLocation } from "react-router-dom";
import LogoMask from "../../components/ui/img/LogoMask";
import XLogo from "../../components/ui/img/socials/Xlogo";
import { Helmet } from "react-helmet-async";
import Countdown from "react-countdown";
import Tooltip from "@mui/material/Tooltip";
import {
  signInWithCustomToken,
  GoogleAuthProvider,
  signInWithPopup,
  TwitterAuthProvider,
} from "firebase/auth";
import {
  isValidEmail,
  isValidUsername,
} from "../../components/utils/parsers";
import GoogleLogo from "../../components/ui/img/GoogleLogo";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../components/firebase/functions";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "../../components/firebase/analytics";
import {
  useThemeState,
} from "../../components/utils/globalStates";
import { algoliaUsedDisplayNames } from "../../components/search/algoliaSearch";
import "./Auth.css";

export default function RegisterView() {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [user] = useAuthState(firebaseAuth);
  const navigate = useNavigate();
  const { search } = useLocation();
  const [emailInput, setEmailInput] = useState("");
  const [messageEmail, setMessageEmail] = useState(false);
  const [displayNameInput, setDisplayNameInput] = useState("");
  const [messageDisplayName, setMessageDisplayName] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [isGoogle, setIsGoogle] = useState(false);
  const [isTwitter, setIsTwitter] = useState(false);
  const [inputMessageEmail, setInputMessageEmail] = useState("");
  const [inputMessageDisplayName, setInputMessageDisplayName] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [referralCodeMessage, setReferralCodeMessage] = useState("");

  const [isLoginInit, setIsLoginInit] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const [validOtpUnix, setValidOtpUnix] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isDisplayNameTaken, setIsDisplayNameTaken] = useState(false);
  const [isDisplayNameTakenLoading, setIsDisplayNameTakenLoading] =
    useState(false);

  useEffect(() => {
    if (user !== null) {
      return navigate("/");
    }
  }, []);

  useEffect(() => {
    if (emailInput !== "" && !isValidEmail(emailInput)) {
      setMessageEmail(true);
      setInputMessageEmail("Please enter a valid email address");
    } else {
      setMessageEmail(false);
      setInputMessageEmail(" ");
    }

    if (emailInput === "") {
      setMessageEmail(true);
    }
  }, [emailInput]);

  useEffect(() => {
    if (
      displayNameInput !== "" &&
      !isValidUsername(displayNameInput.toLocaleLowerCase())
    ) {
      setMessageDisplayName(true);
      setIsDisplayNameTaken(true);
      setInputMessageDisplayName(
        "Please enter a valid display name (4-20 characters)"
      );
    } else if (displayNameInput === "") {
      setMessageDisplayName(true);
      setIsDisplayNameTaken(true);
      setInputMessageDisplayName(" ");
    } else {
      // Search in algoliaUsedDisplaynames and check if displayname is taken
      setIsDisplayNameTakenLoading(true);
      algoliaUsedDisplayNames
        .search(displayNameInput)
        .then((res) => {
          console.log(res);
          if (res.hits.length > 0) {
            const resultArray: any[] = res.hits;
            // Check if any of the hits are the same as the input in lowercase
            for (let i = 0; i < resultArray.length; i++) {
              if (
                resultArray[i].displayName.toLowerCase() ===
                displayNameInput.toLowerCase()
              ) {
                setIsDisplayNameTaken(true);
                setMessageDisplayName(true);
                setInputMessageDisplayName("Display name is already taken");
                break;
              } else {
                setIsDisplayNameTaken(false);
                setMessageDisplayName(false);
                setInputMessageDisplayName(" ");
              }
            }
          } else {
            setIsDisplayNameTaken(false);
            setMessageDisplayName(false);
            setInputMessageDisplayName(" ");
          }
          setIsDisplayNameTakenLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsDisplayNameTakenLoading(false);
        });
    }
  }, [displayNameInput]);

  useEffect(() => {
    if (referralCode.length === 0) {
      setReferralCodeMessage(" ");
    } else if (referralCode.length !== 12) {
      setReferralCodeMessage("Referral code must be 12 characters long");
    } else {
      setReferralCodeMessage(" ");
    }
  }, [referralCode]);

  // Check if url has referral code
  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const referral = urlParams.get("referral");
    if (referral !== null) {
      setReferralCode(referral);
    }
  }, []);

  const handleRegister = async () => {
    if (emailInput === "") {
      return;
    }
    if (messageEmail === true) {
      return;
    }

    if (displayNameInput === "") {
      return;
    }
    if (messageDisplayName === true) {
      return;
    }
    setAuthLoading(true);
    setErrorMessage("");
    const data = {
      email: emailInput,
      name: displayNameInput,
      referral: referralCode,
    };

    try {
      const signin = httpsCallable(firebaseFunctions, "auth-init_register_v4");
      const res: any = await signin(data);
      console.log(res.data);
      if (
        res.data.code !== null &&
        res.data.code !== undefined &&
        res.data.code === "already-exists"
      ) {
        setErrorMessage("User already exists, please login.");
        setAuthLoading(false);
        return;
      }
      setIsLoginInit(true);
      setAuthLoading(false);
      setValidOtpUnix(res.data.valid);
      setSuccessMessage(`Thanks for choosing GATH3R ${res.data.name}!`);
      //setWelcomeMessage(res.data);
      //localStorage.setItem("loginEmail", res.data.email);
      //setAuthLoading(false);
    } catch (error: any) {
      console.log(error);
      if (error.message === "INTERNAL") {
        setErrorMessage("Server error, please try again later.");
        setAuthLoading(false);
      } else {
        setErrorMessage(error.message);
        setAuthLoading(false);
      }
    }
  };

  const verifyOtp = async () => {
    if (otp.length !== 6) {
      return;
    }
    setOtpLoading(true);
    setErrorMessage("");
    const data = {
      email: emailInput,
      secret: otp,
    };

    try {
      const signin = httpsCallable(
        firebaseFunctions,
        "auth-verify_register_v2"
      );
      const res: any = await signin(data);
      console.log(res.data);
      if (
        res.data.code !== null &&
        res.data.code !== undefined &&
        res.data.code === "already-exists"
      ) {
        setErrorMessage("User already exists, please login.");
        setAuthLoading(false);
        return;
      }
      // Sign in with token returned from server
      await signInWithCustomToken(firebaseAuth, res.data.token);
      navigate("/");
      //setWelcomeMessage(res.data);
      //localStorage.setItem("loginEmail", res.data.email);
      //setAuthLoading(false);
    } catch (error: any) {
      console.log(error);
      if (error.message === "INTERNAL") {
        setErrorMessage("Server error, please try again later.");
        setOtpLoading(false);
      } else {
        setErrorMessage(error.message);
        setOtpLoading(false);
      }
    }
  };

  const handleGoogleSignin = async () => {
    setGoogleLoading(true);
    setErrorMessage("");
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(firebaseAuth, provider);
      const user = result.user;
      console.log(user);
      logEvent(firebaseAnalytics, "login", {
        method: "google",
      });
      navigate("/");
    } catch (error: any) {
      console.log(error);
      if (error.message === "INTERNAL") {
        setErrorMessage("Server error, please try again later.");
        setGoogleLoading(false);
      } else {
        setErrorMessage(error.message);
        setGoogleLoading(false);
      }
    }
  };

  const handleTwitterSignin = async () => {
    setGoogleLoading(true);
    setErrorMessage("");
    const provider = new TwitterAuthProvider();
    try {
      const result = await signInWithPopup(firebaseAuth, provider);
      const user = result.user;
      console.log(user);
      logEvent(firebaseAnalytics, "login", {
        method: "twitter",
      });
      navigate("/");
    } catch (error: any) {
      console.log(error);
      console.log(error.message);
      console.log(error.code);
      console.error(error);
      if (error.message === "INTERNAL") {
        setErrorMessage("Server error, please try again later.");
        setGoogleLoading(false);
      } else {
        setErrorMessage(error.message);
        setGoogleLoading(false);
      }
    }
  }

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      setIsLoginInit(false);
      setErrorMessage("Time limit exceeded, please try again.");
    } else {
      // Render a countdown
      return (
        <span style={{ fontWeight: 600 }}>
          {"0" + minutes}:{seconds < 10 ? "0" + seconds : seconds}
        </span>
      );
    }
  };

  const styles = {
    input: {
      backgroundColor: "transparent",
      color: globalTheme.textMainBlack.color + " !important",
      borderColor: globalTheme.textMainBlack.color + " !important",
      WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
      "& .MuiInput-root": {
        color: globalTheme.textMainBlack.color + " !important",
        borderColor: globalTheme.textMainBlack.color + " !important",
        WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        "& fieldset": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&:after": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&.Mui-focused fieldset": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
      },
      "& .MuiInputLabel-root": {
        color: globalTheme.textMainBlack.color + " !important",
      },
    },
    inputOTP: {
      width: "90%",
      backgroundColor: "transparent",
      color: globalTheme.textMainBlack.color + " !important",
      borderColor: globalTheme.textMainBlack.color + " !important",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "5px",
          color: globalTheme.textMainBlack.color + " !important",
          border: `1px solid ${globalTheme.textMainBlack.color}`,
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "& input": {
          textAlign: "center !important",
          fontSize: "20px",
          letterSpacing: "4px",
          fontWeight: 600,
          color: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&:after": {
          color: globalTheme.textMainBlack.color + " !important",
          borderColor: globalTheme.textMainBlack.color + " !important",
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
        "&.Mui-focused fieldset": {
          color: globalTheme.textMainBlack.color + " !important",
          border: `1px solid ${globalTheme.textMainBlack.color}`,
          WebkitTextFillColor: globalTheme.textMainBlack.color + " !important",
        },
      },
    },
    button: {
      width: "100%",
      borderRadius: "10px",
      marginTop: "20px",
      backgroundColor: globalTheme.coreOrange.color,
      color: globalTheme.coreOrangeOff.color,
      textTransform: "none",
      boxShadow: globalTheme.boxShadow.boxShadow,
      border: `1px solid ${globalTheme.coreOrangeOff.color}`,
      "&.Mui-disabled": {
        borderRadius: "10px",
        backgroundColor: globalTheme.coreOrange.color,
        color: globalTheme.coreOrangeOff.color,
        opacity: 0.96,
        border: `1px solid ${globalTheme.coreOrangeOff.color}`,
      },
      "&:hover": {
        backgroundColor: globalTheme.coreOrange.color,
        color: globalTheme.coreOrangeOff.color,
        opacity: 0.96,
        border: `1px solid ${globalTheme.coreOrangeOff.color}`,
      },
    },
    buttonError: {
      marginTop: "34px",
      textTransform: "none",
      color: globalTheme.textColor.color,
      "&:hover": {
        color: globalTheme.textColor.color,
      },
    },

    iconButton: {
      borderRadius: "10px",
      backgroundColor: globalTheme.boxBackgroundWhite.color,
      color: globalTheme.textMainBlack.color,
      marginBottom: "25px",
      border: `1px solid ${globalTheme.textMainBlack.color}`,
      "&:hover": {
        backgroundColor: globalTheme.boxBackgroundWhite.color,
        color: globalTheme.textMainBlack.color,
        opacity: 0.96,
        border: `1px solid ${globalTheme.textMainBlack.color}`,
      },
    },
    iconButtonGoogle: {
      marginTop: "25px",
      width: "86%",
      height: "44px",
      borderRadius: "10px",
      textTransform: "none",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      border: `1px solid ${globalTheme.textMainBlack.color}`,
    },
  };

  return (
    <div
      className="LoginViewOuter"
      style={{ backgroundColor: globalTheme.dashboardBackground.color }}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Register</title>
      </Helmet>
      <div className="LoginViewAlt">
        <div className="AuthIconStart">
          <div className="AuthIcon">
            <LogoMask
              fillColor={globalTheme.dashboardBackground.color}
              strokeColor={globalTheme.backgroundMainOffYellow.color}
            />
          </div>
        </div>
        <div className="AuthStartButtons">
          <div
            className="AuthStartButtonsInner"
            style={{
              boxShadow: globalTheme.boxShadow.boxShadow,
              backgroundColor: globalTheme.boxBackgroundWhite.color,
              border: `1px solid ${globalTheme.loginBoxBorder.color}`,
              transform: isLoginInit ? "translateX(-100vw)" : "translateX(0vw)",
            }}
          >
            <p
              className="AuthText"
              style={{
                color: globalTheme.textMainBlack.color,
                flex: "unset",
                marginBottom: "20px",
              }}
            >
              Welcome to GATH3R Open Beta. Register an account!
            </p>
            {!isGoogle && !isTwitter && (
              <div className="textfieldOuter">
                <TextField
                  sx={styles.input}
                  label="Sign up with Email"
                  placeholder="Email"
                  value={emailInput}
                  type="email"
                  fullWidth
                  variant="standard"
                  disabled={authLoading}
                  onChange={(e) => setEmailInput(e.target.value)}
                />
                <p
                  className="InputText"
                  style={{ color: globalTheme.textMainBlack.color }}
                >
                  {inputMessageEmail}
                </p>
              </div>
            )}
            <div className="textfieldOuter">
              <TextField
                sx={styles.input}
                label="Choose Display Name"
                placeholder="Display name (4-20 characters)"
                value={displayNameInput}
                fullWidth
                variant="standard"
                type="text"
                disabled={authLoading}
                onChange={(e) => setDisplayNameInput(e.target.value)}
                InputProps={{
                  endAdornment: displayNameInput !== '' ? (isDisplayNameTakenLoading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : isDisplayNameTaken ? (
                    <Tooltip title={inputMessageDisplayName} arrow>
                      <div
                        style={{
                          height: "10px",
                          width: "10px",
                          borderRadius: "50%",
                          backgroundColor: "#FF0000",
                          opacity: 0.5,
                        }}
                      ></div>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Display name is available" arrow>
                      <div
                        style={{
                          height: "10px",
                          width: "10px",
                          borderRadius: "50%",
                          backgroundColor: "#00FF00",
                          opacity: 0.5,
                        }}
                      ></div>
                    </Tooltip>
                  )): null,
                }}
              />
              <p
                className="InputText"
                style={{ color: globalTheme.textMainBlack.color }}
              >
                {inputMessageDisplayName}
              </p>
            </div>
            <div className="textfieldOuter">
              <TextField
                sx={styles.input}
                label="Referral Code (Optional)"
                placeholder="Referral Code"
                value={referralCode}
                fullWidth
                variant="standard"
                disabled={authLoading}
                onChange={(e) => setReferralCode(e.target.value)}
              />
              <p
                className="InputText"
                style={{ color: globalTheme.textMainBlack.color }}
              >
                {referralCodeMessage}
              </p>
            </div>
            <p className="AuthText">or</p>
            <Button
              sx={styles.iconButtonGoogle}
              color="inherit"
              size="large"
              onClick={handleGoogleSignin}
            >
              <div className="AuthButtonIcon">
                <GoogleLogo />
              </div>

              <p
                className="AuthText"
                style={{ color: globalTheme.textMainBlack.color }}
              >
                Sign up with Google
              </p>
            </Button>
            <Button
              sx={styles.iconButtonGoogle}
              color="inherit"
              size="large"
              onClick={handleTwitterSignin}
            >
             <div className="AuthButtonIcon" style={{ width: "9%" }}>
                <XLogo />
              </div>

              <p
                className="AuthText"
                style={{ color: globalTheme.textMainBlack.color }}
              >
                Sign up with X
              </p>
            </Button>
            {/*<p
              className="AuthText"
              style={{ color: globalTheme.textMainBlack.color }}
            >
              or
            </p>
            <Button
              sx={styles.iconButtonGoogle}
              color="inherit"
              size="large"
              onClick={() => {
                setIsGoogle(!isGoogle);
                setIsTwitter(false);
              }}
            >
              <div
                className="AuthButtonIcon"
                style={{ width: !isGoogle ? "8%" : "6%" }}
              >
                {!isGoogle ? (
                  <GoogleLogo />
                ) : (
                  <LogoMask
                    fillColor={globalTheme.backgroundMainYellow.color}
                    strokeColor={globalTheme.backgroundMainOffYellow.color}
                  />
                )}
              </div>
              <p
                className="AuthText"
                style={{ color: globalTheme.textMainBlack.color }}
              >
                {!isGoogle ? "Sign up with Google" : "Sign up with Email"}
              </p>
            </Button>
            <Button
              onClick={() => {
                setIsGoogle(false);
                setIsTwitter(!isTwitter);
              }}
              sx={styles.iconButtonGoogle}
              color="inherit"
              size="large"
            >
              <div
                className="AuthButtonIcon"
                style={{ width: !isTwitter ? "9%" : "6%" }}
              >
                {!isTwitter ? (
                  <XLogo />
                ) : (
                  <LogoMask
                    fillColor={globalTheme.backgroundMainYellow.color}
                    strokeColor={globalTheme.backgroundMainOffYellow.color}
                  />
                )}
              </div>
              <p className="AuthText"
                style={{ color: globalTheme.textMainBlack.color }}>
                {!isTwitter ? "Sign up with X/Twitter" : "Sign up with Email"}
              </p>
                </Button>*/}
          </div>
          <div
            className="AuthStartButtonsInnerAlt"
            style={{
              boxShadow: globalTheme.boxShadow.boxShadow,
              backgroundColor: globalTheme.boxBackgroundWhite.color,
              border: `1px solid ${globalTheme.loginBoxBorder.color}`,
              transform: isLoginInit ? "translateX(0vw)" : "translateX(100vw)",
            }}
          >
            <p
              className="SuccessTitle"
              style={{ color: globalTheme.textMainBlack.color }}
            >
              {successMessage}
            </p>
            <p
              className="AuthText"
              style={{
                marginBottom: "25px",
                color: globalTheme.textMainBlack.color,
              }}
            >
              Copy the code sent to your email and enter it below to register.
              Time left:{" "}
              {validOtpUnix !== 0 && (
                <Countdown date={validOtpUnix} renderer={renderer} />
              )}
            </p>
            <div className="textfieldOuter">
              <TextField
                sx={styles.inputOTP}
                placeholder="OTP"
                value={otp}
                fullWidth
                disabled={otpLoading}
                onChange={(e) => setOtp(e.target.value)}
                type="tel"
              />
              <p
                className="ErrorText"
                style={{
                  marginTop: "20px",
                  color: globalTheme.textMainBlack.color,
                }}
              >
                <span style={{ fontSize: "80%" }}>
                  Can't find the email? Check your spam!
                </span>
              </p>
            </div>
          </div>
          {isLoginInit ? (
            <Button
              sx={styles.button}
              size="large"
              color="inherit"
              variant="outlined"
              disabled={otp.length !== 6 || otpLoading}
              onClick={verifyOtp}
            >
              {otpLoading ? (
                <CircularProgress
                  color="inherit"
                  size={20}
                  sx={{ paddingTop: "3px", paddingBottom: "3px" }}
                />
              ) : (
                "Verify Email"
              )}
            </Button>
          ) : (
            <Button
              sx={styles.button}
              size="large"
              color="inherit"
              variant="outlined"
              disabled={
                messageEmail ||
                authLoading ||
                messageDisplayName ||
                (referralCode !== "" && referralCode.length !== 12) ||
                isGoogle ||
                isTwitter
              }
              onClick={handleRegister}
            >
              {authLoading ? (
                <CircularProgress
                  color="inherit"
                  size={20}
                  sx={{ paddingTop: "3px", paddingBottom: "3px" }}
                />
              ) : isGoogle ? (
                "Sign up with Google"
              ) : isTwitter ? (
                "Sign up with X/Twitter"
              ) : (
                "SIGN UP"
              )}
            </Button>
          )}
          {errorMessage === "" && !isLoginInit ? (
            <p
              className="ErrorText"
              style={{
                marginTop: "20px",
                color: globalTheme.textMainBlack.color,
              }}
            >
              Already have an account?{" "}
              <Link
                style={{
                  textDecoration: "none",
                  fontWeight: 500,
                  color: globalTheme.textMainBlack.color,
                }}
                to={"/auth/login"}
              >
                Go to Log in!
              </Link>
            </p>
          ) : (
            <p
              onClick={() => setErrorMessage("")}
              style={{ marginTop: "20px", cursor: "pointer" }}
              className="ErrorText"
            >
              {errorMessage}
            </p>
          )}
        </div>
        <div className="AuthStartFooter">
          {!isLoginInit && (
            <IconButton
              sx={styles.iconButton}
              color="inherit"
              href="https://gath3r.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <HomeOutlinedIcon fontSize="medium" />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
}
