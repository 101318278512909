/**
 * A React functional component that displays various listing statistics in the Admin panel.
 *
 * This component fetches the total number of listings, as well as the number
 * of listings created within specific timeframes (last 24 hours, 7 days, and 30 days),
 * the number of verified listings, and the number of rugged listings. These statistics
 * are gathered via Firebase Firestore queries counting documents that match certain criteria.
 *
 * @remarks
 * Each statistic is displayed with a tooltip explaining its meaning, and a Skeleton
 * loader is shown while the data is being fetched. Data is processed using the
 * Firestore 'getCountFromServer' function.
 *
 * @component
 * @example
 * <AdminListingsStats />
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 *
 */

import { useEffect, useState } from "react";

import {
  useThemeState,
} from "../../../components/utils/globalStates";
import { Skeleton, Tooltip } from "@mui/material";

import {
  getCountFromServer,
  collection,
  query,
  where,
} from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";

import "../Admin.css";

export default function AdminListingsStats() {
  const globalTheme = useThemeState((state) => state.globalTheme);

  const [listingCount, setListingCount] = useState(0);
  const [listingCountLoading, setListingCountLoading] = useState(true);

  const [listingCountDay, setListingCountDay] = useState(0);
  const [listingCountLoadingDay, setListingCountLoadingDay] = useState(true);

  const [listingCountWeek, setListingCountWeek] = useState(0);
  const [listingCountLoadingWeek, setListingCountLoadingWeek] = useState(true);

  const [listingCountMonth, setListingCountMonth] = useState(0);
  const [listingCountLoadingMonth, setListingCountLoadingMonth] =
    useState(true);

  const [verifiedCount, setVerifiedCount] = useState(0);
  const [verifiedCountLoading, setVerifiedCountLoading] = useState(true);

  const [ruggedCount, setRuggedCount] = useState(0);
  const [ruggedCountLoading, setRuggedCountLoading] = useState(true);

  // Get total listings count from listings collection
  useEffect(() => {
    const fetchActiveNowCount = async () => {
      const q = query(collection(firestoreDb, "listings"));
      const snapshot = await getCountFromServer(q);
      setListingCount(snapshot.data().count);
      setListingCountLoading(false);
    };
    fetchActiveNowCount();
  }, []);

  // Get total listings count from listings collection where createdAt is within the last 24 hours
  useEffect(() => {
    const fetchListingCountDay = async () => {
      const dateNow = Date.now();
      const dateYesterday = dateNow - 86400000;
      const q = query(
        collection(firestoreDb, "listings"),
        where("createdAt", ">", dateYesterday)
      );
      const snapshot = await getCountFromServer(q);
      setListingCountDay(snapshot.data().count);
      setListingCountLoadingDay(false);
    };
    fetchListingCountDay();
  }, []);

  // Get total listings count from listings collection where createdAt is within the last 7 days
  useEffect(() => {
    const fetchListingCountWeek = async () => {
      const dateNow = Date.now();
      const dateWeekAgo = dateNow - 604800000;
      const q = query(
        collection(firestoreDb, "listings"),
        where("createdAt", ">", dateWeekAgo)
      );
      const snapshot = await getCountFromServer(q);
      setListingCountWeek(snapshot.data().count);
      setListingCountLoadingWeek(false);
    };
    fetchListingCountWeek();
  }, []);

  // Get total listings count from listings collection where createdAt is within the last 30 days
  useEffect(() => {
    const fetchListingCountMonth = async () => {
      const dateNow = Date.now();
      const dateMonthAgo = dateNow - 2592000000;
      const q = query(
        collection(firestoreDb, "listings"),
        where("createdAt", ">", dateMonthAgo)
      );
      const snapshot = await getCountFromServer(q);
      setListingCountMonth(snapshot.data().count);
      setListingCountLoadingMonth(false);
    };
    fetchListingCountMonth();
  }, []);

  // Get total listings count from listings collection where manualVerified is true
  useEffect(() => {
    const fetchVerifiedCount = async () => {
      const q = query(
        collection(firestoreDb, "listings"),
        where("manualVerified", "==", true)
      );
      const snapshot = await getCountFromServer(q);
      setVerifiedCount(snapshot.data().count);
      setVerifiedCountLoading(false);
    };
    fetchVerifiedCount();
  }, []);

  // Get total listings count from listings collection where rugged is true
  useEffect(() => {
    const fetchRuggedCount = async () => {
      const q = query(
        collection(firestoreDb, "listings"),
        where("rugged", "==", true)
      );
      const snapshot = await getCountFromServer(q);
      setRuggedCount(snapshot.data().count);
      setRuggedCountLoading(false);
    };
    fetchRuggedCount();
  }, []);

  return (
    <div className="AdminInnerTopRow">
      <Tooltip
        title="Total number of listings in the system"
        placement="top"
        arrow
      >
        <div className="AdminInnerTopRowItem">
          <p
            className="AdminDescription"
            style={{
              ...globalTheme.backgroundMainOffBeige,
              width: "unset",
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "0px",
            }}
          >
            Total
          </p>
          {listingCountLoading ? (
            <Skeleton
              variant="text"
              sx={{ borderRadius: "5px", width: "80px", height: "40px" }}
            />
          ) : (
            <p
              className="AdminInnerTopRowCounter"
              style={{ ...globalTheme.backgroundMainOffBeige }}
            >
              {new Intl.NumberFormat().format(listingCount)}
            </p>
          )}
        </div>
      </Tooltip>
      <Tooltip
        title="Total number of listings created in the last 24 hours"
        placement="top"
        arrow
      >
        <div className="AdminInnerTopRowItem">
          <p
            className="AdminDescription"
            style={{
              ...globalTheme.backgroundMainOffBeige,
              width: "unset",
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "0px",
            }}
          >
            Today
          </p>
          {listingCountLoadingDay ? (
            <Skeleton
              variant="text"
              sx={{ borderRadius: "5px", width: "80px", height: "40px" }}
            />
          ) : (
            <p
              className="AdminInnerTopRowCounter"
              style={{ ...globalTheme.backgroundMainOffBeige }}
            >
              {new Intl.NumberFormat().format(listingCountDay)}
            </p>
          )}
        </div>
      </Tooltip>
      <Tooltip
        title="Total number of listings created in the last 7 days"
        placement="top"
        arrow
      >
        <div className="AdminInnerTopRowItem">
          <p
            className="AdminDescription"
            style={{
              ...globalTheme.backgroundMainOffBeige,
              width: "unset",
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "0px",
            }}
          >
            This Week
          </p>
          {listingCountLoadingWeek ? (
            <Skeleton
              variant="text"
              sx={{ borderRadius: "5px", width: "80px", height: "40px" }}
            />
          ) : (
            <p
              className="AdminInnerTopRowCounter"
              style={{ ...globalTheme.backgroundMainOffBeige }}
            >
              {new Intl.NumberFormat().format(listingCountWeek)}
            </p>
          )}
        </div>
      </Tooltip>
      <Tooltip
        title="Total number of listings created in the last 30 days"
        placement="top"
        arrow
      >
        <div className="AdminInnerTopRowItem">
          <p
            className="AdminDescription"
            style={{
              ...globalTheme.backgroundMainOffBeige,
              width: "unset",
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "0px",
            }}
          >
            This Month
          </p>
          {listingCountLoadingMonth ? (
            <Skeleton
              variant="text"
              sx={{ borderRadius: "5px", width: "80px", height: "40px" }}
            />
          ) : (
            <p
              className="AdminInnerTopRowCounter"
              style={{ ...globalTheme.backgroundMainOffBeige }}
            >
              {new Intl.NumberFormat().format(listingCountMonth)}
            </p>
          )}
        </div>
      </Tooltip>
      <Tooltip
        title="Total number of listings that have been manually verified"
        placement="top"
        arrow
      >
        <div className="AdminInnerTopRowItem">
          <p
            className="AdminDescription"
            style={{
              ...globalTheme.backgroundMainOffBeige,
              width: "unset",
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "0px",
            }}
          >
            Verified
          </p>
          {verifiedCountLoading ? (
            <Skeleton
              variant="text"
              sx={{ borderRadius: "5px", width: "80px", height: "40px" }}
            />
          ) : (
            <p
              className="AdminInnerTopRowCounter"
              style={{ ...globalTheme.backgroundMainOffBeige }}
            >
              {new Intl.NumberFormat().format(verifiedCount)}
            </p>
          )}
        </div>
      </Tooltip>
      <Tooltip
        title="Total number of listings that have been flagged as rugged"
        placement="top"
        arrow
      >
        <div className="AdminInnerTopRowItem">
          <p
            className="AdminDescription"
            style={{
              ...globalTheme.backgroundMainOffBeige,
              width: "unset",
              textAlign: "left",
              fontWeight: 600,
              marginBottom: "0px",
            }}
          >
            Rugged
          </p>
          {ruggedCountLoading ? (
            <Skeleton
              variant="text"
              sx={{ borderRadius: "5px", width: "80px", height: "40px" }}
            />
          ) : (
            <p
              className="AdminInnerTopRowCounter"
              style={{ ...globalTheme.backgroundMainOffBeige }}
            >
              {new Intl.NumberFormat().format(ruggedCount)}
            </p>
          )}
        </div>
      </Tooltip>
    </div>
  );
}
