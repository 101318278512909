/**
 * Component to display the list of users who follow a specific user.
 * 
 * @component
 * @example
 * return (
 *   <UserFollowers />
 * )
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @remarks
 * This component fetches and displays the list of users who follow a specific user.
 * It uses Firebase Firestore to fetch the data and displays it using Material-UI components.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";
import {
  where,
  limit,
  orderBy,
  OrderByDirection,
  doc,
  query,
  collectionGroup,
  getDoc,
  onSnapshot
} from "firebase/firestore";
import { Helmet } from "react-helmet-async";
import { CircularProgress, Skeleton } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { Link, useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  useSnackbarErrorState,
  useHeaderSettingsState,
  useTabSettingsState,
  useThemeState,
  useDeviceState,
} from "../../../components/utils/globalStates";
import UserListItemNew from "../../../components/ui/listItems/UserListItemNew";
import UserFollowInfo from "../../../components/ui/topBoxes/UserFollowInfo";
import PickersCardView from "../../../components/ui/pickers/PickerCardView";
import { firestoreDb } from "../../../components/firebase/firestore";
import "./UserFollow.css";

moment().format();

let externalLoaded = false;
export default function UserFollowers() {
  const { userId } = useParams();
  const [itemLoading, setItemLoading] = useState(true);
  const [loadingList, setLoadingList] = useState<any[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const snackbarError = useSnackbarErrorState((state) => state);
  const headerSettingsState = useHeaderSettingsState();
  const tabSettingsState = useTabSettingsState();
  const deviceState = useDeviceState();
  const globalTheme = useThemeState((state) => state.globalTheme);

  const fetchUserInfo = async (infoId: string) => {
    const userDoc = await getDoc(
            doc(firestoreDb, "publicUserInfo", infoId)
          );
    if (userDoc.exists()) {
      return userDoc.data();
    } else {
      return undefined;
    }
  };

  // Listener Fetch all users in userFollows collection that the user follows and fetch info for each user
  useEffect(() => {
    const q = query(
      collectionGroup(firestoreDb, "userFollows"),
      where("following", "==", userId),
      limit(300),
      orderBy("followedAt", "desc" as OrderByDirection)
    );
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const list: any[] = [];
      querySnapshot.forEach((doc) => {
        list.push(doc.data());
      });
      setLoadingList(list);
      setLoading(false);

      if (list.length !== 0) {
        let followingUserList: any[] = [];
        Promise.all(
            list.map(async (item) => {
              return await fetchUserInfo(item.follower);
            })
          )
            .then((res) => {
              for (let i = 0; i < res.length; i++) {
                followingUserList.push(res[i]);
              }
              console.log(followingUserList);
              setUserList(followingUserList);
              setItemLoading(false);
            })
            .catch((err) => {
              console.error(err);
              setItemLoading(false);
              setLoading(false);
            });
      } else {
        setItemLoading(false);
        return;
      }
    });
    return () => {
      unsubscribe();
    };
  }, [userId]);

  // Fetch all users that the user follows and fetch info for each user
  /*useEffect(() => {
    if (userId === undefined || userId === "" || userId === null) {
      snackbarError.setMessage("User not found");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return navigate("/users");
    }
    if (externalLoaded === true) return;
    externalLoaded = true;
    const fetchFollowing = async () => {
      try {
        const querySnapshot = await getDocs(
          query(
            collectionGroup(firestoreDb, "userFollows"),
            where("following", "==", userId),
            limit(100),
            orderBy("followedAt", "desc" as OrderByDirection)
          )
        );
        let followingList: any[] = [];
        querySnapshot.forEach((doc) => {
          followingList.push(doc.data());
        });

        setLoadingList(followingList);
        setLoading(false);

        let followingUserList: any[] = [];
        for (let i = 0; i < followingList.length; i++) {
          let following = followingList[i];
          const userDoc = await getDoc(
            doc(firestoreDb, "publicUserInfo", following.follower)
          );
          if (userDoc.exists()) {
            followingUserList.push({
              ...userDoc.data(),
              followedAt: following.followedAt,
            });
          } else {
            followingUserList.push(undefined);
          }
        }
        console.log(followingUserList);
        setUserList(followingUserList);
        setLoading(false);
        setItemLoading(false);
        externalLoaded = false;
      } catch (error) {
        console.error("Error fetching following", error);
        setLoading(false);
        setItemLoading(false);
        externalLoaded = false;
        snackbarError.setMessage(
          "Error fetching follower list, please reload page."
        );
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
      }
    };
    fetchFollowing();
  }, [userId]);*/

  useEffect(() => {
    headerSettingsState.setIsBackButtonOpen(true);
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.backgroundMainBeige.color);
    return () => {
      headerSettingsState.setIsBackButtonOpen(false);
      tabSettingsState.setColor(globalTheme.backgroundMainYellow.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      headerSettingsState.setIsLogoOpen(false);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  return (
    <div
      className="UserFollow"
      style={{
        paddingTop: "80px",
        backgroundColor: globalTheme.backgroundMainBeige.color,
      }}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Followed by User</title>
      </Helmet>
      <UserFollowInfo userId={userId} viewType="People Following" subType="" />
      <div className="ReviewsListTabs" style={{ maxWidth: "1200px" }}>
        <Link
          to={"/users/follow/out/" + userId}
          style={{
            textDecoration: "none",
            width: "50%",
            backgroundColor: "transparent",
            paddingTop: "5px",
            paddingBottom: "5px",
            border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
            borderRightWidth: "0.5px",
            borderRadius: "8px 0px 0px 8px",
          }}
        >
          <div
            className="ListingsPageTabsInner"
            style={{
              color: globalTheme.backgroundMainOffYellow.color,
            }}
          >
            <div className="UserFollowLinksIconOuter">
              <PersonOutlinedIcon
                color="inherit"
                sx={{
                  position: "absolute",
                  fontSize: "20px",
                  marginRight: "5px",
                  "@media (min-width: 350px)": {
                    fontSize: "20px",
                  },
                  "@media (min-width: 550px)": {
                    fontSize: "22px",
                  },
                  "@media (min-width: 700px)": {
                    fontSize: "24px",
                  },
                }}
              />
              <ExpandCircleDownIcon
                color="inherit"
                sx={{
                  position: "absolute",
                  fontSize: "12px",
                  bottom: "0px",
                  right: "0px",
                  transform: "rotate(-90deg)",
                  marginRight: "5px",
                  "@media (min-width: 350px)": {
                    fontSize: "13px",
                  },
                  "@media (min-width: 550px)": {
                    fontSize: "14px",
                    bottom: "-1px",
                    right: "-2px",
                  },
                  "@media (min-width: 700px)": {
                    fontSize: "15px",
                    bottom: "-3px",
                    right: "-3px",
                  },
                }}
              />
            </div>
            <p className="ListingsPageTabsText">Following</p>
          </div>
        </Link>
        <div
          style={{
            textDecoration: "none",
            width: "50%",
            paddingTop: "5px",
            paddingBottom: "5px",
            border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
            borderLeftWidth: "0.5px",
            borderRadius: "0px 8px 8px 0px",
            backgroundColor: globalTheme.solidMainYellow.color,
          }}
        >
          <div
            className="ListingsPageTabsInner"
            style={{
              color: globalTheme.solidMainBlack.color,
              backgroundColor: "transparent",
            }}
          >
            <div className="UserFollowLinksIconOuter">
              <PersonOutlinedIcon
                color="inherit"
                sx={{
                  position: "absolute",
                  fontSize: "20px",
                  marginRight: "5px",
                  "@media (min-width: 350px)": {
                    fontSize: "20px",
                  },
                  "@media (min-width: 550px)": {
                    fontSize: "22px",
                  },
                  "@media (min-width: 700px)": {
                    fontSize: "24px",
                  },
                }}
              />
              <ExpandCircleDownIcon
                color="inherit"
                sx={{
                  position: "absolute",
                  fontSize: "12px",
                  bottom: "0px",
                  right: "0px",
                  transform: "rotate(90deg)",
                  marginRight: "5px",
                  "@media (min-width: 350px)": {
                    fontSize: "13px",
                  },
                  "@media (min-width: 550px)": {
                    fontSize: "14px",
                    bottom: "-1px",
                    right: "-2px",
                  },
                  "@media (min-width: 700px)": {
                    fontSize: "15px",
                    bottom: "-3px",
                    right: "-3px",
                  },
                }}
              />
            </div>
            <p
              className="ListingsPageTabsText"
              style={{ textDecoration: "none" }}
            >
              Followers
            </p>
          </div>
        </div>
      </div>
      <div className="UserFollowSort">
        <PickersCardView
          iconColor={globalTheme.backgroundMainOffYellow.color}
        />
      </div>
      {loading ? (
        <div className="UserListLoading">
          <CircularProgress
            sx={{ color: globalTheme.backgroundMainOffBeige.color }}
          />
        </div>
      ) : loadingList.length > 0 ? (
        <div className="UserListInner" style={{ marginTop: "0px" }}>
          {loadingList.map((user, index) => {
            if (itemLoading) {
              return (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  sx={{
                    marginTop: "10px",
                    borderRadius: "8px",
                    width: "100%",
                    height: "60px",
                    "@media (min-width: 600px)": {
                      width: "calc(50% - 10px)",
                    },
                    "@media (min-width: 1200px)": {
                      width: "calc(33% - 10px)",
                    },
                  }}
                />
              );
            } else {
              if (userList[index] !== undefined) {
                return (
                  <UserListItemNew
                    listItem={userList[index]}
                    indexKey={index}
                    itemSize={deviceState.viewSizeBox}
                    clickAdmin={false}
                    key={index}
                  />
                );
              }
            }
          })}
          <Link
            to={`/users/page/${userId}`}
            style={{ textDecoration: "none", width: "100%" }}
          >
            <p
              className="UserReviewsLinkBack"
              style={{ color: globalTheme.figmaHighlight.color }}
            >
              Back to users profile
            </p>
          </Link>
        </div>
      ) : (
        <div className="UserListLoading">
          <p
            className="UserListSortTitle"
            style={{ color: globalTheme.backgroundMainOffBeige.color }}
          >
            Doesn't have any followers
          </p>
          <Link
            to={`/users/page/${userId}`}
            style={{ textDecoration: "none", width: "100%" }}
          >
            <p
              className="UserReviewsLinkBack"
              style={{ color: globalTheme.figmaHighlight.color }}
            >
              Back to users profile
            </p>
          </Link>
        </div>
      )}
    </div>
  );
}
