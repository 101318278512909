/**
 * Rss3Item component renders a listing item with expandable content.
 * 
 * @param {ListingDialogProps} props - The properties for the component.
 * @param {any} props.listing - The listing data to be displayed.
 * 
 * @returns {JSX.Element} The rendered Rss3Item component.
 * 
 * @component
 * 
 * @example
 * const listing = {
 *   img: "https://example.com/image.jpg",
 *   platform: "Crossbell",
 *   handle: "@example",
 *   title: "Example Title",
 *   timestamp: "2023-10-01T12:00:00Z",
 *   body: "This is the body of the listing.",
 *   isMd: true,
 *   link: "https://example.com/article"
 * };
 * 
 * return <Rss3Item listing={listing} />;
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import moment from "moment";
import DOMPurify from "dompurify";

import { useThemeState } from "../../../../components/utils/globalStates";
import "./ListingPageNews.css";
interface ListingDialogProps {
  listing: any;
}

moment().format();

export default function Rss3Item({ listing }: ListingDialogProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const globalTheme = useThemeState((state) => state.globalTheme);

  const onLessClick = () => {
    if (!isExpanded) {
      setIsExpanded(true);
      return;
    }
    setIsExpanded(false);
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };


  const avatarStyle = {
  bgcolor: globalTheme.boxBackgroundWhite.color,
  color: globalTheme.textMainBlack.color,
  width: "30px",
  height: "30px",
  fontSize: "15px",
  borderRadius: "4px",
  marginTop: "4px",
  "@media (min-width: 350px)": {
    width: "40px",
    height: "40px",
    fontSize: "20px",
  },
  "@media (min-width: 550px)": {
    width: "50px",
    height: "50px",
    fontSize: "25px",
  },
};

const avatarStyleLogo = {
  width: "30px",
  height: "30px",
  fontSize: "15px",
  borderRadius: "4px",
  marginTop: "2px",
  "@media (min-width: 350px)": {
    width: "40px",
    height: "40px",
    fontSize: "20px",
  },
  "@media (min-width: 550px)": {
    width: "50px",
    height: "50px",
    fontSize: "25px",
  },
};

  return (
    <div
      className="rss3Listing"
      style={{
        maxHeight: isExpanded ? "600px" : "300px",
        backgroundColor: globalTheme.boxBackgroundWhite.color,
        border: `1px solid ${globalTheme.solidMainBlack.color}`,
        ...globalTheme.boxShadow,
      }}
      ref={ref}
    >
      <div className="rss3ListingHeader">
        {listing.img !== "" ? (
          <Avatar alt={"logo"} src={listing.img} sx={{ ...avatarStyleLogo }} />
        ) : (
          <Avatar sx={avatarStyle} alt={"logo"}>
            {listing.platform === "Crossbell" ? "Cb" : "Mi"}
          </Avatar>
        )}
        <div className="rss3ListingHeaderInner">
          <p
            className="rss3ListingTitle"
            style={{ color: globalTheme.textMainBlack.color }}
          >
            {listing.title}
          </p>
          <p
            className="rss3ListingSubtitle"
            style={{ color: globalTheme.textMainGrey.color }}
          >
            {`${listing.handle} @ ${listing.platform} · ${moment(
              listing.timestamp
            ).fromNow()}`}
          </p>
        </div>
      </div>
      {listing.isMd ? (
        <div
          className="markdownOuter"
          style={{ color: globalTheme.textMainBlack.color }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(listing.body, {
              FORBID_ATTR: ["style"],
              FORBID_TAGS: ["style", "a"],
            }),
          }}
        ></div>
      ) : (
        <p
          className="rss3ListingText"
          style={{ color: globalTheme.textMainBlack.color }}
        >
          {listing.body}
        </p>
      )}
      <div
        className="rssListingFooter"
        style={{
          backgroundColor: globalTheme.boxBackgroundWhite.color,
          color: globalTheme.textMainGrey.color,
        }}
      >
        <div className="rssListingFooterInner" onClick={onLessClick}>
          <p className="rssListingFooterText">{isExpanded ? "Less" : "More"}</p>
          {isExpanded ? (
            <ExpandLessIcon
              sx={{
                fontSize: "16px",
                marginLeft: "3px",
                color: globalTheme.textMainGrey.color,
                 "@media (min-width: 350px)": {
                fontSize: "18px",
              },
              "@media (min-width: 550px)": {
                fontSize: "20px",
              },
              "@media (min-width: 700px)": {
                fontSize: "22px",
              },
              }}
            />
          ) : (
            <ExpandMoreIcon
              sx={{
                fontSize: "16px",
                marginLeft: "3px",
                color: globalTheme.textMainGrey.color,
                 "@media (min-width: 350px)": {
                fontSize: "18px",
              },
              "@media (min-width: 550px)": {
                fontSize: "20px",
              },
              "@media (min-width: 700px)": {
                fontSize: "22px",
              },
              }}
            />
          )}
        </div>
        <a
          href={listing.link}
          target="_blank"
          rel="noopener noreferrer"
          className="rssListingFooterInner"
          style={{ color: globalTheme.textMainGrey.color }}
        >
          <p className="rssListingFooterText">Go to article</p>
          <OpenInNewIcon
            color="inherit"
            sx={{
              fontSize: "12px",
              marginLeft: "5px",
              "@media (min-width: 350px)": {
                fontSize: "14px",
              },
              "@media (min-width: 550px)": {
                fontSize: "16px",
              },
              "@media (min-width: 700px)": {
                fontSize: "18px",
              },
            }}
          />
        </a>
      </div>
    </div>
  );
}

