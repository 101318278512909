/**
 * AdminReviewDialog component allows administrators to manage reviews for listings.
 * It provides functionalities to delete a review, delete only the review text, and show reports.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.reviewId - The ID of the review to be managed.
 * @param {string} props.listingName - The name of the listing associated with the review.
 * @param {string} props.userName - The name of the user who wrote the review.
 *
 * @returns {JSX.Element} The rendered AdminReviewDialog component.
 *
 * @example
 * <AdminReviewDialog reviewId="12345" listingName="Sample Listing" userName="John Doe" />
 *
 * @remarks
 * This component uses Firebase Firestore to fetch and update review data.
 * It also utilizes global state hooks for managing snackbar notifications and theme.
 *
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
// @ts-nocheck
import React, { useEffect, useState } from "react";
import moment from "moment";
import { ButtonBase } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";

import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import { doc, deleteDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";
import {
  useSnackbarState,
  useSnackbarErrorState,
  useThemeState,
  useAdminState,
} from "../../../components/utils/globalStates";
import { standardDialogTitle } from "../../../components/theme/globalMuiTheme";
import "./ListingDialogs.css";
import { title } from "process";

moment().format();

export default function AdminReviewDialog({
  reviewId,
  listingName,
  userName,
}: any) {
  const [user] = useAuthState(firebaseAuth);
  const snackbarState = useSnackbarState();
  const snackbarErrorState = useSnackbarErrorState();
  const adminState = useAdminState();
  const globalTheme = useThemeState((state) => state.globalTheme);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reviewData, setReviewData] = useState<any>(null);

  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteReview, setConfirmDeleteReview] = useState(false);
  const [confirmFeatured, setConfirmFeatured] = useState(false);
  const [showReports, setShowReports] = useState(false);

  // Fetch listing data from listings collection
  useEffect(() => {
    if (reviewId === undefined || reviewId === "" || reviewId === null) {
      if (adminState.activeReview !== "") {
        return adminState.setActiveReview("");
      }
      snackbarErrorState.setMessage("Review not found");
      snackbarErrorState.setOpenLength(4000);
      snackbarErrorState.setIsOpen(true);
      return adminState.setActiveReview("");
    }

    setIsLoading(true);
    const listingRef = doc(firestoreDb, "reviews", reviewId);
    const unsubscribe = onSnapshot(listingRef, (doc) => {
      if (doc.exists()) {
        console.log("Document data:", doc.data());
        setReviewData(doc.data());
        setIsLoading(false);
      } else {
        if (adminState.activeReview !== "") {
          return adminState.setActiveReview("");
        }
        snackbarErrorState.setMessage("Review not found");
        snackbarErrorState.setOpenLength(4000);
        snackbarErrorState.setIsOpen(true);
        return adminState.setActiveListing("");
      }
    });
    return () => {
      unsubscribe();
    };
  }, [reviewId]);

  const onDelete = async () => {
    if (reviewData === null) return;
    try {
      setIsDeleting(true);
      await deleteDoc(doc(firestoreDb, "reviews", reviewId));
      setIsDeleting(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setMessage("Review deleted.");
      snackbarState.setIsOpen(true);
      adminState.setActiveReview("");
    } catch (err) {
      console.error(err);
      setIsDeleting(false);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage("Error deleting review " + reviewId);
      snackbarErrorState.setIsOpen(true);
    }
  };

  const onDeleteReview = async () => {
    if (reviewData === null) return;
    try {
      setIsDeleting(true);
      await updateDoc(doc(firestoreDb, "reviews", reviewId), {
        review: false,
        title: "",
        text: "",
      });
      setIsDeleting(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setMessage("Review updated to rating only.");
      snackbarState.setIsOpen(true);
      setConfirmDeleteReview(false);
    } catch (err) {
      console.error(err);
      setIsDeleting(false);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage("Error updating review " + reviewId);
      snackbarErrorState.setIsOpen(true);
    }
  };

  const buttonStyles = {
    color: "#1A1A1A",
    backgroundColor: "#FFED8F",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    boxShadow: globalTheme.boxShadow,
    border: "1px solid #1A1A1A",
    flex: 1,
    margin: "5px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
  };

  return confirmDelete === false && confirmDeleteReview === false ? (
    <div className="AdminListDialog">
      <DialogTitle
        sx={{
          ...standardDialogTitle,
          width: "calc(100% - 8px)",
          marginLeft: "4px",
        }}
      >
        Admin Settings
        <br />
        <span style={{ fontSize: "55%", fontWeight: 400 }}>
          Remove, show reports and more for review by <b>{userName}</b> for{" "}
          <b>{listingName}</b>.
        </span>
      </DialogTitle>
      <div className="AdminListDialogEditButtons">
        <ButtonBase sx={buttonStyles} onClick={() => setConfirmDelete(true)}>
          Remove Rating and Review
        </ButtonBase>
        <ButtonBase
          sx={buttonStyles}
          onClick={() => setConfirmDeleteReview(true)}
          disabled={reviewData?.review === false}
        >
          Remove Review only
        </ButtonBase>
        <ButtonBase
          onClick={() => setConfirmFeatured(true)}
          disabled
          sx={{ ...buttonStyles }}
        >
          {"Add Featured"}
        </ButtonBase>
        <ButtonBase
          disabled
          sx={buttonStyles}
          onClick={() => setShowReports(true)}
        >
          Reports
        </ButtonBase>
      </div>
    </div>
  ) : confirmDelete ? (
    <div className="AdminListDialog">
      <DialogTitle
        sx={{
          ...standardDialogTitle,
          width: "calc(100% - 8px)",
          marginLeft: "4px",
          lineHeight: "110%",
        }}
      >
        Remove Rating and Review
        <br />
        <span style={{ fontSize: "55%", fontWeight: 400 }}>
          Are you sure you want to remove this rating & review?
        </span>
        <br />
        <span style={{ fontSize: "55%", fontWeight: 400, color: "red" }}>
          This will remove all data associated with the review.
        </span>
      </DialogTitle>
      <div className="AdminListDialogEditButtons">
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonRed.color,
            flex: "unset",
          }}
          onClick={() => setConfirmDelete(false)}
          disabled={isDeleting}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonGreen.color,
            flex: "unset",
          }}
          disabled={isDeleting}
          onClick={onDelete}
        >
          {isDeleting ? "Removing..." : "Yes, remove the review"}
        </ButtonBase>
      </div>
    </div>
  ) : confirmDeleteReview ? (
    <div className="AdminListDialog">
      <DialogTitle
        sx={{
          ...standardDialogTitle,
          width: "calc(100% - 8px)",
          marginLeft: "4px",
          lineHeight: "110%",
        }}
      >
        Remove Review
        <br />
        <span style={{ fontSize: "55%", fontWeight: 400 }}>
          Are you sure you want to remove this review?
        </span>
        <br />
        <span style={{ fontSize: "55%", fontWeight: 400, color: "red" }}>
          This will turn the review into a rating only.
        </span>
      </DialogTitle>
      <div className="AdminListDialogEditButtons">
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonRed.color,
            flex: "unset",
          }}
          onClick={() => setConfirmDeleteReview(false)}
          disabled={isDeleting}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          sx={{
            ...buttonStyles,
            backgroundColor: globalTheme.colorPaletteButtonGreen.color,
            flex: "unset",
          }}
          disabled={isDeleting}
          onClick={onDeleteReview}
        >
          {isDeleting ? "Removing..." : "Yes, remove the review"}
        </ButtonBase>
      </div>
    </div>
  ) : null;
}
