/**
 * AchievementsProfileInfo component displays the user's achievements information.
 * It shows a brief info message about the achievements page when the user visits it for the first time.
 * The info message can be toggled on and off, and its state is saved in local storage.
 *
 * @component
 * @example
 * return (
 *   <AchievementsProfileInfo />
 * )
 *
 * @returns {JSX.Element} The AchievementsProfileInfo component.
 *
 * @remarks
 * This component uses the `useThemeState` hook to get the global theme and applies it to the styles.
 * It also uses Material-UI icons for displaying the info and cancel buttons.
 *
 * @hook
 * - `useState` - Manages the state of the info message visibility.
 * - `useEffect` - Checks local storage to determine if the info message should be shown.
 *
 * @function
 * - `onInfoClose` - Hides the info message and sets a flag in local storage.
 * - `onInfoOpen` - Shows the info message and removes the flag from local storage.
 * 
 * @author 
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import { useThemeState } from "../../utils/globalStates";
import "./TopBoxes.css";

export default function AchievementsProfileInfo() {
  const [showInfo, setShowInfo] = useState(false);
  const globalTheme = useThemeState((state) => state.globalTheme);

  // Check local storage if user has seen the info
  useEffect(() => {
    if (localStorage.getItem("achievementsProfileInfo") === "true") {
      setShowInfo(false);
    } else {
      setShowInfo(true);
    }
  }, []);

  const onInfoClose = () => {
    setShowInfo(false);
    localStorage.setItem("achievementsProfileInfo", "true");
  };

  const onInfoOpen = () => {
    localStorage.removeItem("achievementsProfileInfo");
    setShowInfo(true);
  };
  return (
    <div
      className="SearchAndExplore"
      style={{
        backgroundColor: globalTheme.figmaCoreOrange.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        ...globalTheme.boxShadow,
        maxWidth: "770px",
      }}
    >
      {!showInfo && (
        <InfoIcon
          onClick={onInfoOpen}
          sx={{
            color: globalTheme.figmaPrimaryText.color,
            cursor: "pointer",
            position: "absolute",
            right: "6px",
            top: "6px",
            fontSize: "17px",
            "@media (min-width: 350px)": {
              fontSize: "18px",
            },
            "@media (min-width: 550px)": {
              fontSize: "19px",
            },
            "@media (min-width: 700px)": {
              fontSize: "20px",
            },
          }}
        />
      )}
      <div className="SearchAndExploreHeader">
        <p
          className="SearchAndExploreHeaderTitle"
          style={{ color: globalTheme.figmaPrimaryText.color, maxWidth: "95%" }}
        >
          My Achievements
        </p>
        <p
          className="SearchAndExploreHeaderSub"
          style={{
            color: globalTheme.figmaPrimaryText.color,
            maxWidth: "95%",
            marginBottom: "-10px",
          }}
        >
          View your earned achievements
        </p>
      </div>
      {showInfo && (
        <div
          className="ExploreInfo"
          style={{
            backgroundColor: globalTheme.figmaYellowAlt.color,
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            marginBottom: "5px",
          }}
        >
          <p
            className="ExploreInfoText"
            style={{ color: globalTheme.figmaPrimaryText.color }}
          >
            This is your personal achievements page. Here you can view all the
            achievements you have earned and see the progress of the ones you
            are working on. You can also see the achievements of other users by
            visiting their profile.
          </p>
          <CancelIcon
            onClick={onInfoClose}
            sx={{
              color: globalTheme.figmaPrimaryText.color,
              cursor: "pointer",
              fontSize: "17px",
              "@media (min-width: 350px)": {
                fontSize: "18px",
              },
              "@media (min-width: 550px)": {
                fontSize: "19px",
              },
              "@media (min-width: 700px)": {
                fontSize: "20px",
              },
            }}
          />
        </div>
      )}
    </div>
  );
}
