/**
 * ErrorUrl component renders a 404 error page with a message and a link to the start page.
 *
 * @component
 * @example
 * return (
 *   <ErrorUrl />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @remarks
 * This component uses the `react-helmet-async` library to set the page title for SEO purposes.
 * It also imports a logo image and a CSS file for styling.
 *
 * @see {@link https://reactrouter.com/web/guides/quick-start|React Router} for more information on the `Link` component.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import React from 'react';
import logo from '../../static/img/icon.png';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import './Error.css';

function ErrorUrl() {

  
  return (
    <div className="ErrorUrl">
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Error</title>
      </Helmet>
      <div className="ErrorUrlHeader">
      <img src={logo} className="ErrorUrlLogo" alt="logo" />
       <div className="ErrorUrlMain">
      <p className="ErrorUrlTitle">404</p>
      <p className="ErrorUrlText">Ooops! Not a valid url, head over to <Link className="ErrorUrlText" to={'/'}>start page! </Link></p>
    </div>
    </div>
    </div>
  );
}

export default ErrorUrl;

//<img src={logo} className="App-logo" alt="logo" />