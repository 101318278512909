/**
 * TokenPagePrices component displays various price data and charts for a given token.
 * It fetches price data from CoinCap and CoinGecko APIs and displays it in different time intervals.
 *
 * @param {Object} props - The component props.
 * @param {any} props.priceData - The price data for the token.
 * @param {string} props.tokenId - The ID of the token.
 *
 * @returns {JSX.Element} The TokenPagePrices component.
 *
 * @component
 * @example
 * const priceData = {
 *   current_price: { usd: 123.45 },
 *   price_change_percentage_24h: 1.23,
 *   price_change_percentage_7d: 4.56,
 *   price_change_percentage_14d: 7.89,
 *   price_change_percentage_30d: 10.11,
 *   price_change_percentage_60d: 12.34,
 *   price_change_percentage_200d: 15.67,
 *   price_change_percentage_1y: 18.90,
 *   low_24h: { usd: 120.00 },
 *   high_24h: { usd: 125.00 },
 *   ath: { usd: 150.00 },
 *   market_cap: { usd: 1000000 },
 *   market_cap_change_24h: 50000,
 *   market_cap_change_percentage_24h: 5.00,
 *   circulating_supply: 100000,
 *   max_supply: 200000,
 * };
 * const tokenId = "bitcoin";
 * 
 * <TokenPagePrices priceData={priceData} tokenId={tokenId} />
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";
import { useThemeState } from "../../../components/utils/globalStates";
import { SparkLineChart } from "@mui/x-charts/SparkLineChart";

import moment from "moment";
import { coinGeckoKey } from "../../../.config/config";

import "./TokensPage.css";

moment().format();

export default function TokenPagePrices({
  priceData,
  tokenId,
}: {
  priceData: any;
  tokenId: string;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [chartData1h, setChartData1h] = useState<any>([]);
  const [chartDataTimes1h, setChartDataTimes1h] = useState<any>([]);
  const [chartData6h, setChartData6h] = useState<any>([]);
  const [chartDataTimes6h, setChartDataTimes6h] = useState<any>([]);
  const [chartData24h, setChartData24h] = useState<any>([]);
  const [chartDataTimes24h, setChartDataTimes24h] = useState<any>([]);
  const [chartData7d, setChartData7d] = useState<any>([]);
  const [chartDataTimes7d, setChartDataTimes7d] = useState<any>([]);
  const [chartData30d, setChartData30d] = useState<any>([]);
  const [chartDataTimes30d, setChartDataTimes30d] = useState<any>([]);
  const [chartData1y, setChartData1y] = useState<any>([]);
  const [chartDataTimes1y, setChartDataTimes1y] = useState<any>([]);

  const fetchCoinGap = async () => {
    let dateNow = Date.now();
    let date6Hours = dateNow - 21600000;
    let dateWeek = dateNow - 604800000;
    let dateYear = dateNow - 31536000000;
    let fetchUrl6Hours = `https://api.coincap.io/v2/assets/${tokenId}/history?interval=m1&start=${date6Hours}&end=${dateNow}`;
    let fetchUrlWeek = `https://api.coincap.io/v2/assets/${tokenId}/history?interval=h1&start=${dateWeek}&end=${dateNow}`;
    let fetchUrlYear = `https://api.coincap.io/v2/assets/${tokenId}/history?interval=d1&start=${dateYear}&end=${dateNow}`;

    return Promise.all([
      fetch(fetchUrl6Hours),
      fetch(fetchUrlWeek),
      fetch(fetchUrlYear),
    ])
      .then(async (responses) => {
        const jsonResponse6Hours = await responses[0].json();
        const jsonResponseWeek = await responses[1].json();
        const jsonResponseYear = await responses[2].json();

        let data1Hours: any[] = [];
        let tempTimes1Hours: any[] = [];
        let data6Hours: any[] = [];
        let tempTimes6Hours: any[] = [];
        if (
          jsonResponse6Hours.error === undefined ||
          jsonResponse6Hours.error === null
        ) {
          for (let i = 0; i < jsonResponse6Hours.data.length; i++) {
            const limitDate1Hour = dateNow - 3600000;
            const limitDate6Hours = dateNow - 21600000;
            if (jsonResponse6Hours.data[i].time > limitDate1Hour) {
              data1Hours.push(Number(jsonResponse6Hours.data[i].priceUsd));
              tempTimes1Hours.push(
                moment(jsonResponse6Hours.data[i].time).format(
                  "YYYY-MM-DD HH:mm"
                )
              );
            }
            if (jsonResponse6Hours.data[i].time > limitDate6Hours) {
              data6Hours.push(Number(jsonResponse6Hours.data[i].priceUsd));
              tempTimes6Hours.push(
                moment(jsonResponse6Hours.data[i].time).format(
                  "YYYY-MM-DD HH:mm"
                )
              );
            }
          }
        }

        let dataDay: any[] = [];
        let tempTimesDay: any[] = [];
        let dataWeek: any[] = [];
        let tempTimesWeek: any[] = [];
        if (
          jsonResponseWeek.error === undefined ||
          jsonResponseWeek.error === null
        ) {
          const limitDate1Day = dateNow - 86400000;
          const limitDateWeek = dateNow - 604800000;
          for (let i = 0; i < jsonResponseWeek.data.length; i++) {
            if (jsonResponseWeek.data[i].time > limitDate1Day) {
              dataDay.push(Number(jsonResponseWeek.data[i].priceUsd));
              tempTimesDay.push(
                moment(jsonResponseWeek.data[i].time).format("YYYY-MM-DD HH:mm")
              );
            }
            if (jsonResponseWeek.data[i].time > limitDateWeek) {
              dataWeek.push(Number(jsonResponseWeek.data[i].priceUsd));
              tempTimesWeek.push(
                moment(jsonResponseWeek.data[i].time).format("YYYY-MM-DD HH:mm")
              );
            }
          }
        }

        let dataMonth: any[] = [];
        let tempTimesMonth: any[] = [];
        let dataYear: any[] = [];
        let tempTimesYear: any[] = [];
        if (
          jsonResponseYear.error === undefined ||
          jsonResponseYear.error === null
        ) {
          const limitDate1Month = dateNow - 2592000000;
          const limitDateYear = dateNow - 31536000000;
          for (let i = 0; i < jsonResponseYear.data.length; i++) {
            if (jsonResponseYear.data[i].time > limitDate1Month) {
              dataMonth.push(Number(jsonResponseYear.data[i].priceUsd));
              tempTimesMonth.push(
                moment(jsonResponseYear.data[i].time).format("YYYY-MM-DD HH:mm")
              );
            }
            if (jsonResponseYear.data[i].time > limitDateYear) {
              dataYear.push(Number(jsonResponseYear.data[i].priceUsd));
              tempTimesYear.push(
                moment(jsonResponseYear.data[i].time).format("YYYY-MM-DD HH:mm")
              );
            }
          }
        }
        return {
          data1Hours,
          tempTimes1Hours,
          data6Hours,
          tempTimes6Hours,
          dataDay,
          tempTimesDay,
          dataWeek,
          tempTimesWeek,
          dataMonth,
          tempTimesMonth,
          dataYear,
          tempTimesYear,
        };
      })
      .catch((error) => {
        console.error("Error:", error);
        return {
          data1Hours: [],
          tempTimes1Hours: [],
          data6Hours: [],
          tempTimes6Hours: [],
          dataDay: [],
          tempTimesDay: [],
          dataWeek: [],
          tempTimesWeek: [],
          dataMonth: [],
          tempTimesMonth: [],
          dataYear: [],
          tempTimesYear: [],
        };
      });
  };

  const fetchCoinGecko = async (dateLength: number) => {
    try {
      const url = `https://api.coingecko.com/api/v3/coins/${tokenId}/market_chart?vs_currency=usd&days=${dateLength}&precision=10`;
      const options = {
        method: "GET",
        headers: { "x-cg-demo-api-key": coinGeckoKey },
      };
      const response = await fetch(url, options);
      const data = await response.json();
      return data.prices;
    } catch (error) {
      console.error("Error:", error);
      return [];
    }

    /*let dataPrice: any = [];
    let dataTimes = [];
        for (let i = 0; i < jsonResponseYear.data.length; i++) {
            dataYear.push(Number(jsonResponseYear.data[i].priceUsd));
            tempTimesYear.push(
              moment(jsonResponseYear.data[i].time).format("YYYY-MM-DD HH:mm")
            );
          }*/
  };

  useEffect(() => {
    fetchCoinGap().then(async (data) => {
      console.log(data);
      if (data.data6Hours.length > 0 && data.data1Hours.length > 0) {
        setChartData1h(data.data1Hours);
        setChartDataTimes1h(data.tempTimes1Hours);
        setChartData6h(data.data6Hours);
        setChartDataTimes6h(data.tempTimes6Hours);
      } else {
        const secondAtempt = await fetchCoinGecko(1);
        let dateNow = Date.now();
        let tempData1h: any[] = [];
        let tempData6h: any[] = [];
        let tempTimes1h: any[] = [];
        let tempTimes6h: any[] = [];
        for (let i = 0; i < secondAtempt.length; i++) {
          const limitDate1Hour = dateNow - 3600000;
          const limitDate6Hours = dateNow - 21600000;
          if (secondAtempt[i][0] > limitDate1Hour) {
            tempData1h.push(Number(secondAtempt[i][1]));
            tempTimes1h.push(
              moment(secondAtempt[i][0]).format("YYYY-MM-DD HH:mm")
            );
          }
          if (secondAtempt[i][0] > limitDate6Hours) {
            tempData6h.push(Number(secondAtempt[i][1]));
            tempTimes6h.push(
              moment(secondAtempt[i][0]).format("YYYY-MM-DD HH:mm")
            );
          }
        }
        setChartData1h(tempData1h);
        setChartDataTimes1h(tempTimes1h);
        setChartData6h(tempData6h);
        setChartDataTimes6h(tempTimes6h);
      }

      if (data.dataWeek.length > 0 && data.dataDay.length > 0) {
        setChartData24h(data.dataDay);
        setChartDataTimes24h(data.tempTimesDay);
        setChartData7d(data.dataWeek);
        setChartDataTimes7d(data.tempTimesWeek);
      } else {
        const secondAtempt = await fetchCoinGecko(7);
        let dateNow = Date.now();
        let tempData24h: any[] = [];
        let tempData7d: any[] = [];
        let tempTimes24h: any[] = [];
        let tempTimes7d: any[] = [];
        for (let i = 0; i < secondAtempt.length; i++) {
          const limitDate1Day = dateNow - 86400000;
          const limitDateWeek = dateNow - 604800000;
          if (secondAtempt[i][0] > limitDate1Day) {
            tempData24h.push(Number(secondAtempt[i][1]));
            tempTimes24h.push(
              moment(secondAtempt[i][0]).format("YYYY-MM-DD HH:mm")
            );
          }
          if (secondAtempt[i][0] > limitDateWeek) {
            tempData7d.push(Number(secondAtempt[i][1]));
            tempTimes7d.push(
              moment(secondAtempt[i][0]).format("YYYY-MM-DD HH:mm")
            );
          }
        }
        setChartData24h(tempData24h);
        setChartDataTimes24h(tempTimes24h);
        setChartData7d(tempData7d);
        setChartDataTimes7d(tempTimes7d);
      }

      if (data.dataYear.length > 0 && data.dataMonth.length > 0) {
        setChartData30d(data.dataMonth);
        setChartDataTimes30d(data.tempTimesMonth);
        setChartData1y(data.dataYear);
        setChartDataTimes1y(data.tempTimesYear);
      } else {
        const secondAtempt = await fetchCoinGecko(365);
        let dateNow = Date.now();
        let tempData30d: any[] = [];
        let tempData1y: any[] = [];
        let tempTimes30d: any[] = [];
        let tempTimes1y: any[] = [];
        for (let i = 0; i < secondAtempt.length; i++) {
          const limitDate1Month = dateNow - 2592000000;
          const limitDateYear = dateNow - 31536000000;
          if (secondAtempt[i][0] > limitDate1Month) {
            tempData30d.push(Number(secondAtempt[i][1]));
            tempTimes30d.push(
              moment(secondAtempt[i][0]).format("YYYY-MM-DD HH:mm")
            );
          }
          if (secondAtempt[i][0] > limitDateYear) {
            tempData1y.push(Number(secondAtempt[i][1]));
            tempTimes1y.push(
              moment(secondAtempt[i][0]).format("YYYY-MM-DD HH:mm")
            );
          }
        }
        setChartData30d(tempData30d);
        setChartDataTimes30d(tempTimes30d);
        setChartData1y(tempData1y);
        setChartDataTimes1y(tempTimes1y);
      }
    });
  }, [tokenId]);
  return (
    <div
      className="TokensPagePrices"
      style={{
        backgroundColor: globalTheme.figmaGreenPastel.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        borderRadius: "8px",
      }}
    >
      <div className="TokensPagePricesItem">
        <div className="TokensPagePricesItemInnerFull">
          <p className="TokensPagePricesItemTile">
            <span style={{ fontSize: "120%" }}>Price</span>
          </p>
          <p className="TokensPagePricesItemSub">
            <span style={{ fontSize: "120%" }}>
              {Number(priceData.current_price.usd).toFixed(
                Number(priceData.current_price.usd) < 10 ? 6 : 2
              )}
              $
            </span>
          </p>
        </div>
        <div className="TokensPagePricesItemInner">
          <p className="TokensPagePricesItemTile">24h %</p>
          <p className="TokensPagePricesItemSub">
            {Number(priceData.price_change_percentage_24h).toFixed(2)}%
          </p>
        </div>
        <div className="TokensPagePricesItemInner">
          <p className="TokensPagePricesItemTile">7d %</p>
          <p className="TokensPagePricesItemSub">
            {Number(priceData.price_change_percentage_7d).toFixed(2)}%
          </p>
        </div>

        <div className="TokensPagePricesItemInner">
          <p className="TokensPagePricesItemTile">14d %</p>
          <p className="TokensPagePricesItemSub">
            {Number(priceData.price_change_percentage_14d).toFixed(2)}%
          </p>
        </div>

        <div className="TokensPagePricesItemInner">
          <p className="TokensPagePricesItemTile">30d %</p>
          <p className="TokensPagePricesItemSub">
            {Number(priceData.price_change_percentage_30d).toFixed(2)}%
          </p>
        </div>
        <div className="TokensPagePricesItemInner">
          <p className="TokensPagePricesItemTile">60d %</p>
          <p className="TokensPagePricesItemSub">
            {Number(priceData.price_change_percentage_60d).toFixed(2)}%
          </p>
        </div>
        <div className="TokensPagePricesItemInner">
          <p className="TokensPagePricesItemTile">200d %</p>
          <p className="TokensPagePricesItemSub">
            {Number(priceData.price_change_percentage_200d).toFixed(2)}%
          </p>
        </div>
        <div className="TokensPagePricesItemInner">
          <p className="TokensPagePricesItemTile">1y %</p>
          <p className="TokensPagePricesItemSub">
            {Number(priceData.price_change_percentage_1y).toFixed(2)}%
          </p>
        </div>
        <div className="TokensPagePricesItemInner">
          <p className="TokensPagePricesItemTile">24h Low</p>
          <p className="TokensPagePricesItemSub">
            {Number(priceData.low_24h.usd).toFixed(
              Number(priceData.low_24h.usd) < 10 ? 6 : 2
            )}
            $
          </p>
        </div>
        <div className="TokensPagePricesItemInner">
          <p className="TokensPagePricesItemTile">24h High</p>
          <p className="TokensPagePricesItemSub">
            {Number(priceData.high_24h.usd).toFixed(
              Number(priceData.high_24h.usd) < 10 ? 6 : 2
            )}
            $
          </p>
        </div>
        <div className="TokensPagePricesItemInner">
          <p className="TokensPagePricesItemTile">All Time High</p>
          <p className="TokensPagePricesItemSub">
            {Number(priceData.ath.usd).toFixed(
              Number(priceData.ath.usd) < 10 ? 6 : 2
            )}
            $
          </p>
        </div>
        <div className="TokensPagePricesItemInner">
          <p className="TokensPagePricesItemTile">Market Cap</p>
          <p className="TokensPagePricesItemSub">
            {Number(priceData.market_cap.usd).toLocaleString("en-US", {
              // add suffixes for thousands, millions, and billions
              // the maximum number of decimal places to use
              maximumFractionDigits: 2,
              // specify the abbreviations to use for the suffixes
              notation: "compact",
              compactDisplay: "short",
            })}
            $
          </p>
        </div>
        <div className="TokensPagePricesItemInner">
          <p className="TokensPagePricesItemTile">Market Cap 24h</p>
          <p className="TokensPagePricesItemSub">
            {Number(priceData.market_cap_change_24h).toLocaleString("en-US", {
              // add suffixes for thousands, millions, and billions
              // the maximum number of decimal places to use
              maximumFractionDigits: 2,
              // specify the abbreviations to use for the suffixes
              notation: "compact",
              compactDisplay: "short",
            })}
            $
          </p>
        </div>
        <div className="TokensPagePricesItemInner">
          <p className="TokensPagePricesItemTile">Market Cap 24h</p>
          <p className="TokensPagePricesItemSub">
            {Number(priceData.market_cap_change_percentage_24h).toFixed(2)}%
          </p>
        </div>
        <div className="TokensPagePricesItemInner">
          <p className="TokensPagePricesItemTile">Supply</p>
          <p className="TokensPagePricesItemSub">
            {Number(priceData.circulating_supply) === 0
              ? "-"
              : Number(priceData.circulating_supply).toLocaleString("en-US", {
                  // add suffixes for thousands, millions, and billions
                  // the maximum number of decimal places to use
                  maximumFractionDigits: 2,
                  // specify the abbreviations to use for the suffixes
                  notation: "compact",
                  compactDisplay: "short",
                })}
          </p>
        </div>
        <div className="TokensPagePricesItemInner">
          <p className="TokensPagePricesItemTile">Max Supply</p>
          <p className="TokensPagePricesItemSub">
            {Number(priceData.max_supply) === 0
              ? "-"
              : Number(priceData.max_supply).toLocaleString("en-US", {
                  // add suffixes for thousands, millions, and billions
                  // the maximum number of decimal places to use
                  maximumFractionDigits: 2,
                  // specify the abbreviations to use for the suffixes
                  notation: "compact",
                  compactDisplay: "short",
                })}
          </p>
        </div>
      </div>
      <div className="TokensPagePricesItem">
        <div
          className="TokensPagePricesChart"
          style={{
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            backgroundColor: globalTheme.figmaYellowAlt.color,
          }}
        >
          <div
            className="TokensListItemCollapsedChartButtons"
            style={{
              color: globalTheme.figmaPrimaryTextSub.color,
            }}
          >
            <p className="TokensPagePricesItemTile">1h</p>
          </div>
          {chartData1h.length === 0 ? (
            <div
              style={{
                height: "60px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p className="TokensPagePricesItemSub">NO DATA</p>
            </div>
          ) : (
            <SparkLineChart
              data={chartData1h}
              height={60}
              showTooltip
              curve="natural"
              valueFormatter={(value: number) =>
                Number(value).toFixed(Number(value) < 10 ? 6 : 2) + "$"
              }
              xAxis={{
                data: chartDataTimes1h,
              }}
            />
          )}
        </div>
        <div
          className="TokensPagePricesChart"
          style={{
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            backgroundColor: globalTheme.figmaYellowAlt.color,
          }}
        >
          <div
            className="TokensListItemCollapsedChartButtons"
            style={{
              color: globalTheme.figmaPrimaryTextSub.color,
            }}
          >
            <p className="TokensPagePricesItemTile">6h</p>
          </div>
          {chartData6h.length === 0 ? (
            <div
              style={{
                height: "60px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p className="TokensPagePricesItemSub">NO DATA</p>
            </div>
          ) : (
            <SparkLineChart
              data={chartData6h}
              height={60}
              showTooltip
              curve="natural"
              valueFormatter={(value: number) =>
                Number(value).toFixed(Number(value) < 10 ? 6 : 2) + "$"
              }
              xAxis={{
                data: chartDataTimes6h,
              }}
            />
          )}
        </div>
        <div
          className="TokensPagePricesChart"
          style={{
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            backgroundColor: globalTheme.figmaYellowAlt.color,
          }}
        >
          <div
            className="TokensListItemCollapsedChartButtons"
            style={{
              color: globalTheme.figmaPrimaryTextSub.color,
            }}
          >
            <p className="TokensPagePricesItemTile">24h</p>
          </div>
          {chartData24h.length === 0 ? (
            <div
              style={{
                height: "60px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p className="TokensPagePricesItemSub">NO DATA</p>
            </div>
          ) : (
            <SparkLineChart
              data={chartData24h}
              height={60}
              showTooltip
              curve="natural"
              valueFormatter={(value: number) =>
                Number(value).toFixed(Number(value) < 10 ? 6 : 2) + "$"
              }
              xAxis={{
                data: chartDataTimes24h,
              }}
            />
          )}
        </div>
        <div
          className="TokensPagePricesChart"
          style={{
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            backgroundColor: globalTheme.figmaYellowAlt.color,
          }}
        >
          <div
            className="TokensListItemCollapsedChartButtons"
            style={{
              color: globalTheme.figmaPrimaryTextSub.color,
            }}
          >
            <p className="TokensPagePricesItemTile">7d</p>
          </div>
          {chartData7d.length === 0 ? (
            <div
              style={{
                height: "60px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p className="TokensPagePricesItemSub">NO DATA</p>
            </div>
          ) : (
            <SparkLineChart
              data={chartData7d}
              height={60}
              showTooltip
              curve="natural"
              valueFormatter={(value: number) =>
                Number(value).toFixed(Number(value) < 10 ? 6 : 2) + "$"
              }
              xAxis={{
                data: chartDataTimes7d,
              }}
            />
          )}
        </div>
        <div
          className="TokensPagePricesChart"
          style={{
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            backgroundColor: globalTheme.figmaYellowAlt.color,
          }}
        >
          <div
            className="TokensListItemCollapsedChartButtons"
            style={{
              color: globalTheme.figmaPrimaryTextSub.color,
            }}
          >
            <p className="TokensPagePricesItemTile">30d</p>
          </div>
          {chartData30d.length === 0 ? (
            <div
              style={{
                height: "60px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p className="TokensPagePricesItemSub">NO DATA</p>
            </div>
          ) : (
            <SparkLineChart
              data={chartData30d}
              height={60}
              showTooltip
              curve="natural"
              valueFormatter={(value: number) =>
                Number(value).toFixed(Number(value) < 10 ? 6 : 2) + "$"
              }
              xAxis={{
                data: chartDataTimes30d,
              }}
            />
          )}
        </div>
        <div
          className="TokensPagePricesChart"
          style={{
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            backgroundColor: globalTheme.figmaYellowAlt.color,
          }}
        >
          <div
            className="TokensListItemCollapsedChartButtons"
            style={{
              color: globalTheme.figmaPrimaryTextSub.color,
            }}
          >
            <p className="TokensPagePricesItemTile">1y</p>
          </div>
          {chartData1y.length === 0 ? (
            <div
              style={{
                height: "60px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p className="TokensPagePricesItemSub">NO DATA</p>
            </div>
          ) : (
            <SparkLineChart
              data={chartData1y}
              height={60}
              showTooltip
              curve="natural"
              valueFormatter={(value: number) =>
                Number(value).toFixed(Number(value) < 10 ? 6 : 2) + "$"
              }
              xAxis={{
                data: chartDataTimes1y,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
