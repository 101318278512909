/**
 * A widget component that displays a shortcut with an icon and title.
 * It supports editing mode where the widget can be deleted.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.shortcutTitle - The title of the shortcut.
 * @param {string} props.shortcutPath - The path to navigate to when the shortcut is clicked.
 * @param {string} props.shortcutIcon - The icon to display for the shortcut.
 * @param {boolean} props.isEditing - Flag indicating if the widget is in editing mode.
 * @param {Function} props.deleteWidget - Function to call when the widget is deleted.
 * @param {any} props.fulldata - Full data object containing additional widget information.
 * @param {number} props.elementIndex - The index of the element in the row.
 * @param {number} props.rowIndex - The index of the row containing the element.
 *
 * @returns {JSX.Element} The rendered shortcut widget component.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useThemeState } from "../../../components/utils/globalStates";
import "./DashWidgets.css";

export default function ShortCutWidget({
  shortcutTitle,
  shortcutPath,
  shortcutIcon,
  isEditing,
  deleteWidget,
  fulldata,
  elementIndex,
  rowIndex,
}: {
  shortcutTitle: string;
  shortcutPath: string;
  shortcutIcon: string;
  isEditing: boolean;
  deleteWidget: Function;
  fulldata: any;
  elementIndex: number;
  rowIndex: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const navigate = useNavigate();
  const [isDeleteing, setIsDeleting] = useState(false);
  // On delete
  const onDelete = () => {
    setIsDeleting(true);
    deleteWidget(rowIndex, elementIndex);
  };

  const onShortcutClick = () => {
    if (isEditing) return;
    navigate(shortcutPath);
  };

  return (
    <div
      onClick={onShortcutClick}
      className="ShortCutWidget"
      style={{
        color: globalTheme.figmaPrimaryText.color,
        border: isEditing
          ? `1px dashed ${globalTheme.backgroundMainOffYellow.color}`
          : `1px solid ${globalTheme.figmaPrimaryText.color}`,
        borderRadius: isEditing ? "0px" : "8px",
        backgroundColor:
          fulldata.bgColorCode !== null &&
          fulldata.bgColorCode !== undefined &&
          fulldata.bgColorCode !== ""
            ? fulldata.bgColorCode
            : "transparent",
      }}
    >
      <span style={{ marginTop: "5px" }} className="material-symbols-outlined">
        {shortcutIcon}
      </span>
      <p style={{ marginBottom: "5px" }} className="ShortCutWidgetTitle">
        {shortcutTitle}
      </p>
      {isEditing && (
        <IconButton
          size="small"
          sx={{
            position: "absolute",
            right: "-1px",
            top: "-1px",
            padding: "2px",
            borderRadius: "0px 0px 0px 5px",
            width: "45%",
            color: globalTheme.backgroundMainOffYellow.color,
            backgroundColor: globalTheme.backgroundMainYellow.color,
            border: `1px dashed ${globalTheme.backgroundMainOffYellow.color}`,
            "&:hover": {
              backgroundColor: globalTheme.backgroundMainYellow.color,
            },
          }}
          onClick={onDelete}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      )}
    </div>
  );
}
