/**
 * CustomSearchWidget component renders a custom search widget with a title, 
 * a list of search results, and an optional delete button when in editing mode.
 *
 * @param {string} bgColorCode - The background color code for the widget.
 * @param {boolean} isEditing - Flag indicating if the widget is in editing mode.
 * @param {Function} deleteWidget - Function to delete the widget.
 * @param {any} fulldata - Full data object containing widget information such as title and filter.
 * @param {number} elementIndex - Index of the element in the widget list.
 * @param {number} rowIndex - Index of the row in the widget list.
 *
 * @returns {JSX.Element} The rendered CustomSearchWidget component.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";

import { algoliaListingsRelevance } from "../../../components/search/algoliaSearch";
import { IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ListingsWidgetItem from "./components/ListingsWidgetItem";
import { useThemeState } from "../../../components/utils/globalStates";
import "./DashWidgets.css";

export default function CustomSearchWidget({
  bgColorCode,
  isEditing,
  deleteWidget,
  fulldata,
  elementIndex,
  rowIndex,
}: {
  bgColorCode: string;
  isEditing: boolean;
  deleteWidget: Function;
  fulldata: any;
  elementIndex: number;
  rowIndex: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [widgetTitle, setWidgetTitle] = useState("Custom Search");
  const [isDeleteing, setIsDeleting] = useState(false);
  const [widgetList, setWidgetList] = useState([null, null, null, null, null]);
  // On delete
  const onDelete = () => {
    setIsDeleting(true);
    deleteWidget(rowIndex, elementIndex);
  };

  // Update widget title on widgetType change
  useEffect(() => {
    setWidgetTitle(fulldata.title);
  }, [fulldata.title]);

  // Update widget list on widgetType change
  useEffect(() => {
    if (
      fulldata.filter !== null &&
      fulldata.filter !== undefined &&
      fulldata.filter !== ""
    ) {
      algoliaListingsRelevance
        .search(fulldata.filter, { hitsPerPage: 5 })
        .then((res) => {
          let tempArray: any[] = [];
          res.hits.forEach((hit) => {
            tempArray.push({ ...hit, type: "createdAt" });
          });
          setWidgetList(tempArray);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [fulldata.filter]);
  return (
    <div
      className="ListingsWidget"
      style={{
        color: globalTheme.figmaPrimaryText.color,
        border: isEditing
          ? `1px dashed ${globalTheme.backgroundMainOffYellow.color}`
          : `1px solid ${globalTheme.figmaPrimaryText.color}`,
        borderRadius: isEditing ? "0px" : "8px",
        backgroundColor: bgColorCode,
      }}
    >
      <div
        className="ListingsWidgetHeader"
        style={{
          color: globalTheme.figmaPrimaryText.color,
          borderBottom: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        }}
      >
        <p className="ListingsWidgetTitle">{widgetTitle}</p>
        {fulldata.filter !== null &&
          fulldata.filter !== undefined &&
          fulldata.filter !== "" && (
            <p
              className="ListingsWidgetCategory"
              style={{
                border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
              }}
            >
              {fulldata.filter}
            </p>
          )}
      </div>
      <div className="ListingsWidgetInner">
        {widgetList.map((item, index) => (
          <ListingsWidgetItem isLink={true} dataToRender={item} key={index} />
        ))}
      </div>
      {isEditing && (
        <IconButton
          size="small"
          sx={{
            position: "absolute",
            right: "-1px",
            top: "-1px",
            padding: "2px",
            borderRadius: "0px 0px 0px 5px",
            width: "20%",
            color: globalTheme.backgroundMainOffYellow.color,
            backgroundColor: globalTheme.backgroundMainYellow.color,
            border: `1px dashed ${globalTheme.backgroundMainOffYellow.color}`,
            "&:hover": {
              backgroundColor: globalTheme.backgroundMainYellow.color,
            },
          }}
          onClick={onDelete}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      )}
    </div>
  );
}
