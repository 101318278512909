/**
 * CountWidget component displays a widget with a count and a title.
 * It can be in an editable state where it shows a delete button.
 * The count is fetched based on the provided `fulldata` filter.
 * 
 * @param {Object} props - The properties object.
 * @param {string} props.countTitle - The title of the count widget.
 * @param {string} props.bgColorCode - The background color code for the widget.
 * @param {boolean} props.isEditing - Flag indicating if the widget is in editing mode.
 * @param {Function} props.deleteWidget - Function to call when deleting the widget.
 * @param {any} props.fulldata - The data object containing filter and path information.
 * @param {number} props.elementIndex - The index of the element in the row.
 * @param {number} props.rowIndex - The index of the row containing the widget.
 * 
 * @returns {JSX.Element} The rendered CountWidget component.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  useAuthStates,
  useThemeState,
} from "../../../components/utils/globalStates";
import "./DashWidgets.css";

export default function CountWidget({
  countTitle,
  bgColorCode,
  isEditing,
  deleteWidget,
  fulldata,
  elementIndex,
  rowIndex,
}: {
  countTitle: string;
  bgColorCode: string;
  isEditing: boolean;
  deleteWidget: Function;
  fulldata: any;
  elementIndex: number;
  rowIndex: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const authStates = useAuthStates((state) => state);
  const [isDeleteing, setIsDeleting] = useState(false);
  const [fetchedCount, setFetchedCount] = useState(0);
  const [fetchedCountLoading, setFetchedCountLoading] = useState(true);
  const navigate = useNavigate();
  // On delete
  const onDelete = () => {
    setIsDeleting(true);
    deleteWidget(rowIndex, elementIndex);
  };

  // Set fetchedCount based on fulldata.filter
  useEffect(() => {
    if (authStates.userData === null || authStates.userData === undefined)
      return;
    if (fulldata === null || fulldata === undefined) return;
    switch (fulldata.filter) {
      case "points":
        if (
          authStates.userData.points === null ||
          authStates.userData.points === undefined
        ) {
          setFetchedCount(0);
          break;
        }
        setFetchedCount(authStates.userData.points);
        break;
      case "listingCount":
        if (
          authStates.userData.listingCount === null ||
          authStates.userData.listingCount === undefined
        ) {
          setFetchedCount(0);
          break;
        }
        setFetchedCount(authStates.userData.listingCount);
        break;
      case "reviewsCount":
        if (
          authStates.userData.reviewsCount === null ||
          authStates.userData.reviewsCount === undefined
        ) {
          setFetchedCount(0);
          break;
        }
        setFetchedCount(authStates.userData.reviewsCount);
        break;
      case "ratingCount":
        if (
          authStates.userData.ratingCount === null ||
          authStates.userData.ratingCount === undefined
        ) {
          setFetchedCount(0);
          break;
        }
        setFetchedCount(authStates.userData.ratingCount);
        break;
      case "referralCount":
        if (
          authStates.userData.referralCount === null ||
          authStates.userData.referralCount === undefined
        ) {
          setFetchedCount(0);
          break;
        }
        setFetchedCount(authStates.userData.referralCount);
        break;
      default:
        setFetchedCount(0);
        break;
    }
    setFetchedCountLoading(false);
  }, [authStates.userData, fulldata]);

  const onWidgetClick = () => {
    if (isEditing) return;
    if (fulldata.path === undefined) return;
    if (fulldata.uidRequired) {
      console.log(authStates.userData);
      const url = `${fulldata.path}/${authStates.userData.id.toString()}`;
      navigate(url);
      return;
    }
    navigate(fulldata.path);
  };

  return (
    <div
      className="ShortCutWidget"
      style={{
        color: globalTheme.figmaPrimaryText.color,
        border: isEditing
          ? `1px dashed ${globalTheme.backgroundMainOffYellow.color}`
          : `1px solid ${globalTheme.figmaPrimaryText.color}`,
        borderRadius: isEditing ? "0px" : "8px",
        backgroundColor: bgColorCode,
      }}
      onClick={onWidgetClick}
    >
      <p className="CountWidgetTitle">{countTitle}</p>
      <p
        className="CountWidgetTitle"
        style={{
          borderTop: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          paddingTop: "3px",
          borderBottom: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          paddingBottom: "3px",
          width: "75%",
        }}
      >
        {fetchedCount}
      </p>
      {isEditing && (
        <IconButton
          size="small"
          sx={{
            position: "absolute",
            right: "-1px",
            top: "-1px",
            padding: "2px",
            borderRadius: "0px 0px 0px 5px",
            width: "45%",
            color: globalTheme.backgroundMainOffYellow.color,
            backgroundColor: globalTheme.backgroundMainYellow.color,
            border: `1px dashed ${globalTheme.backgroundMainOffYellow.color}`,
            "&:hover": {
              backgroundColor: globalTheme.backgroundMainYellow.color,
            },
          }}
          onClick={onDelete}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      )}
    </div>
  );
}
