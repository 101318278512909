/**
 * Component for adding a custom search widget to the dashboard.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.addWidget - Function to add the widget.
 * @param {number} props.addwidgetRow - The row number to add the widget to.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <AddCustomSearchWidget addWidget={addWidgetFunction} addwidgetRow={1} />
 *
 * @remarks
 * This component allows users to add a custom search widget to their dashboard.
 * It includes a form for setting the widget's title, search term, and background color.
 * The widget displays search results from Algolia based on the search term.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";

import ListingsWidgetItem from "../widgets/components/ListingsWidgetItem";
import { ButtonBase, TextField } from "@mui/material";
import ColorPicker from "./ColorPicker";
import { useThemeState } from "../../../components/utils/globalStates";
import { algoliaListingsRelevance } from "../../../components/search/algoliaSearch";
import "./DashboardAddWidget.css";

export default function AddCustomSearchWidget({
  addWidget,
  addwidgetRow,
}: {
  addWidget: Function;
  addwidgetRow: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [isExpanded, setIsExpanded] = useState(false);
  const [widgetTitle, setWidgetTitle] = useState("Custom Search");
  const [pickedColor, setPickedColor] = useState(
    globalTheme.figmaBluePastel.color
  );
  const [widgetSearch, setWidgetSearch] = useState("");
  const [widgetList, setWidgetList] = useState<any[]>([
    {
      logo: "https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Flogo512.png?alt=media",
      name: "GATH3R",
      createdAt: Date.now() - 1000 * 60 * 60 * 24 * 2,
      category: "Tools",
      type: "createdAt",
      rating: 4.9,
      viewsTotal: 9702,
      trendingHour: 400,
      id: "gath3r",
      reviewCount: 100,
      ratingCount: 100,
      viewsDay: 100,
    },
    null,
    null,
  ]);

  const onAddWidgetPress = () => {
    const widgetData = {
      filter: widgetSearch,
      title: widgetTitle,
      bgColorCode: pickedColor,
      type: "customSearch",
      rowLength: 4,
    };
    addWidget(widgetData, addwidgetRow);
  };

  useEffect(() => {
    if (isExpanded) {
      algoliaListingsRelevance
        .search(widgetSearch, { hitsPerPage: 3 })
        .then((res) => {
          let tempArray: any[] = [];
          res.hits.forEach((hit) => {
            tempArray.push({ ...hit, type: "createdAt" });
          });
          setWidgetList(tempArray);
        });
    }
  }, [widgetSearch, isExpanded]);

  useEffect(() => {
    if (!isExpanded) {
      setWidgetList([
        {
          logo: "https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Flogo512.png?alt=media",
          name: "GATH3R",
          createdAt: Date.now() - 1000 * 60 * 60 * 24 * 2,
          category: "Tools",
          type: "createdAt",
          rating: 4.9,
          viewsTotal: 9702,
          trendingHour: 400,
          id: "gath3r",
          reviewCount: 100,
          ratingCount: 100,
          viewsDay: 100,
        },
        null,
        null,
      ]);
    }
  }, [isExpanded]);

  const styles = {
    formOuter: {
      marginTop: "15px",
      backgroundColor: "transparent",
      color: globalTheme.figmaPrimaryText.color,
      borderColor: globalTheme.figmaPrimaryText.color + " !important",
      "& .MuiInputLabel-root": {
        color: globalTheme.figmaPrimaryText.color + " !important",
        "& .Mui-focused ": {
          color: globalTheme.figmaPrimaryText.color,
        },
      },
    },
    select: {
      marginTop: "20px",
      backgroundColor: "transparent",
      color: globalTheme.figmaPrimaryText.color,
      borderColor: globalTheme.figmaPrimaryText.color + " !important",
      "& .MuiSelect-root": {
        "& fieldset": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
        "&:after": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
      },
      "&.Mui-focused fieldset": {
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color + " !important",
      },
      "&.Mui-focused ": {
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color + " !important",
      },
    },
    selectMenu: {
      zIndex: 9999,
      color: globalTheme.figmaPrimaryText.color,
      "& .MuiPaper-root": {
        backgroundColor: "#fff",
        color: globalTheme.figmaPrimaryText.color,
        "& .MuiMenuItem-root": {
          "& .Mui-selected": {
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.altTextColor.color,
          },
        },
        "& .Mui-selected": {
          color: globalTheme.figmaPrimaryText.color,
          backgroundColor: globalTheme.background.backgroundColor,
        },
      },
    },
  };

  return (
    <div
      className="AddWidget"
      style={{
        color: globalTheme.figmaPrimaryText.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        backgroundColor: globalTheme.figmaWhite.color,
      }}
    >
      <ButtonBase
        onClick={() => setIsExpanded(!isExpanded)}
        className="ListingsWidget"
        style={{
          color: globalTheme.figmaPrimaryText.color,
          border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          borderRadius: "8px",
          backgroundColor: pickedColor,
          width: "calc(100% + 2px)",
          marginTop: "-1px",
          marginBottom: "-1px",
        }}
      >
        <div
          className="ListingsWidgetHeader"
          style={{
            color: globalTheme.figmaPrimaryText.color,
            borderBottom: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          }}
        >
          <p className="ListingsWidgetTitle">{widgetTitle}</p>
          {widgetSearch !== null &&
            widgetSearch !== undefined &&
            widgetSearch !== "" && (
              <p
                className="ListingsWidgetCategory"
                style={{
                  border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
                }}
              >
                {widgetSearch}
              </p>
            )}
        </div>
        <div className="ListingsWidgetInner">
          {widgetList.map((item, index) => {
            if (item) {
              return (
                <ListingsWidgetItem
                  isLink={false}
                  dataToRender={item}
                  key={index}
                />
              );
            }
          })}
        </div>
      </ButtonBase>
      <div
        className="AddWidgetSettingsOuter"
        style={{ maxHeight: isExpanded ? "500px" : "0px" }}
      >
        <div className="AddWidgetSettings">
          <div className="AddWidgetSettingsInner">
            <TextField
              fullWidth
              value={widgetTitle}
              label="Name"
              onChange={(e) => setWidgetTitle(e.target.value)}
              sx={{ ...styles.select }}
            />
            <TextField
              fullWidth
              value={widgetSearch}
              label="Search Term"
              onChange={(e) => setWidgetSearch(e.target.value)}
              sx={{ ...styles.select }}
            />
          </div>
          <ColorPicker
            setPickedColor={setPickedColor}
            pickedColor={pickedColor}
          />
        </div>
        <ButtonBase
          disabled={widgetTitle === ""}
          onClick={onAddWidgetPress}
          className="AddWidgetButton"
          style={{
            backgroundColor: globalTheme.figmaPrimaryText.color,
            color: globalTheme.figmaWhite.color,
            width: "100%",
            padding: "2px",
            borderRadius: "0px 0px 6px 6px",
            justifySelf: "flex-end",
          }}
        >
          <p>ADD WIDGET</p>
        </ButtonBase>
      </div>
    </div>
  );
}
