/**
 * PfpCreatorInfo component displays information about creating a unique profile picture.
 * It shows an info icon that, when clicked, displays detailed instructions.
 * The component uses local storage to remember if the user has seen the info.
 *
 * @component
 * @example
 * return (
 *   <PfpCreatorInfo />
 * )
 *
 * @returns {JSX.Element} The PfpCreatorInfo component.
 *
 * @remarks
 * - The component uses the `useThemeState` hook to get the global theme.
 * - The `useEffect` hook checks local storage to determine if the info should be shown.
 * - The `onInfoClose` function hides the info and sets a flag in local storage.
 * - The `onInfoOpen` function shows the info and removes the flag from local storage.
 *
 * 
 * @author 
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import { useThemeState } from "../../utils/globalStates";
import "./TopBoxes.css";

export default function PfpCreatorInfo() {
  const [showInfo, setShowInfo] = useState(false);
  const globalTheme = useThemeState((state) => state.globalTheme);

  // Check local storage if user has seen the info
  useEffect(() => {
    if (localStorage.getItem("pfpCreatorInfo") === "true") {
      setShowInfo(false);
    } else {
      setShowInfo(true);
    }
  }, []);

  const onInfoClose = () => {
    setShowInfo(false);
    localStorage.setItem("pfpCreatorInfo", "true");
  };

  const onInfoOpen = () => {
    localStorage.removeItem("pfpCreatorInfo");
    setShowInfo(true);
  };
  return (
    <div
      className="SearchAndExplore"
      style={{
        backgroundColor: globalTheme.figmaYellowPastel.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        ...globalTheme.boxShadow,
        maxWidth: "770px",
      }}
    >
      {!showInfo && (
        <InfoIcon
          onClick={onInfoOpen}
          sx={{
            color: globalTheme.figmaPrimaryText.color,
            cursor: "pointer",
            position: "absolute",
            right: "6px",
            top: "6px",
            fontSize: "17px",
            "@media (min-width: 350px)": {
              fontSize: "18px",
            },
            "@media (min-width: 550px)": {
              fontSize: "19px",
            },
            "@media (min-width: 700px)": {
              fontSize: "20px",
            },
          }}
        />
      )}
      <div className="SearchAndExploreHeader">
        <p
          className="SearchAndExploreHeaderTitle"
          style={{ color: globalTheme.figmaPrimaryText.color, maxWidth: "95%" }}
        >
          PFP Creator
        </p>
        <p
          className="SearchAndExploreHeaderSub"
          style={{
            color: globalTheme.figmaPrimaryText.color,
            maxWidth: "95%",
            marginBottom: "-10px",
          }}
        >
          Create your own unique profile picture
        </p>
      </div>
      {showInfo && (
        <div
          className="ExploreInfo"
          style={{
            backgroundColor: globalTheme.figmaYellowAlt.color,
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            marginBottom: "5px",
          }}
        >
          <p
            className="ExploreInfoText"
            style={{ color: globalTheme.figmaPrimaryText.color }}
          >
            Create your own unique profile picture. Randomize the parts to
            create a unique look by clicking the randomize button for each
            layer.
          </p>
          <CancelIcon
            onClick={onInfoClose}
            sx={{
              color: globalTheme.figmaPrimaryText.color,
              cursor: "pointer",
              fontSize: "17px",
              "@media (min-width: 350px)": {
                fontSize: "18px",
              },
              "@media (min-width: 550px)": {
                fontSize: "19px",
              },
              "@media (min-width: 700px)": {
                fontSize: "20px",
              },
            }}
          />
        </div>
      )}
    </div>
  );
}
