// This file configures the global Material-UI theme and styles.

import { globalThemeLight } from "./globalTheme";

export const globalMuiTheme = {
  background: {
    backgroundColor: "#F5F5F5",
  },
};

export const materialListingFormStyles = {
  "& .MuiOutlinedInput-root": {
    color: "#39393c",
    borderColor: "#39393c",
    "& fieldset": {
      borderColor: "#39393c",
      color: "#39393c",
    },
    "&:hover fieldset": {
      borderColor: "#39393c",
      color: "#39393c",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#39393c",
      color: "#39393c",
    },
  },
  "& label.Mui-focused": {
    color: "#39393c",
  },
  "& label": {
    color: "#39393c",
  },
};

export const materialTabMenuStyles = {
  "& .MuiBottomNavigationAction-root.Mui-selected": {
    color: globalThemeLight.figmaPrimaryColor.color,
  },
  "& .MuiBottomNavigationAction-root": {
    color: globalThemeLight.figmaDashboardIcon.color,
  },
  "& .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label":
    {
      fontSize: "10px !important",
    },
  "& .MuiBottomNavigationAction-label": {
    fontSize: "10px !important",
  },
};

export const materialFormsStylesSearch = {
  "& .MuiOutlinedInput-root": {
    color: "#39393c",
    borderColor: "#ffffff",
    borderWidth: "2px",
    backgroundColor: "#fffbf3",
    boxShadow: "2px 2px 5px rgba(35, 35, 35, 0.2)",
    "& fieldset": {
      borderColor: "#ffffff",
      borderWidth: "2px",
      color: "#39393c",
    },
    "&:hover fieldset": {
      borderColor: "#ffffff",
      borderWidth: "2px",
      color: "#39393c",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ffffff",
      borderWidth: "2px",
      color: "#39393c",
    },
  },
  "& label.Mui-focused": {
    color: "#a0a0a0",
  },
  "& label": {
    color: "#a0a0a0",
  },
};

export const materialExploreSearch = {
  "& .MuiOutlinedInput-root": {
    color: globalThemeLight.figmaPrimaryText.color,
    borderColor: globalThemeLight.figmaExploreSearch.color,
    borderWidth: "2px",
    backgroundColor: "transparent",
    boxShadow: "1px 1px 5px rgba(35, 35, 35, 0.05)",
    "& fieldset": {
      borderColor: globalThemeLight.figmaExploreSearch.color,
      borderWidth: "2px",
      color: globalThemeLight.figmaPrimaryText.color,
    },
    "&:hover fieldset": {
      borderColor: globalThemeLight.figmaExploreSearch.color,
      borderWidth: "2px",
      color: globalThemeLight.figmaPrimaryText.color,
    },
    "&.Mui-focused fieldset": {
      borderColor: globalThemeLight.figmaExploreSearch.color,
      color: globalThemeLight.figmaPrimaryText.color,
    },
  },
  "& label.Mui-focused": {
    color: globalThemeLight.figmaExploreSearch.color,
  },
  "& label": {
    color: globalThemeLight.figmaExploreSearch.color,
  },
};

export const primaryColorTextField = {
  "& .MuiOutlinedInput-root": {
    color: globalThemeLight.figmaPrimaryText.color,
    borderColor: globalThemeLight.figmaPrimaryText.color,
    borderWidth: "1px",
    backgroundColor: "transparent",
    boxShadow: "1px 1px 5px rgba(35, 35, 35, 0.05)",
    "& fieldset": {
      borderColor: globalThemeLight.figmaPrimaryText.color,
      borderWidth: "1px",
      color: globalThemeLight.figmaPrimaryText.color,
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: globalThemeLight.figmaPrimaryText.color,
      borderRadius: "8px",
      borderWidth: "1px",
      color: globalThemeLight.figmaPrimaryText.color,
    },
    "&.Mui-focused fieldset": {
      borderColor: globalThemeLight.figmaPrimaryText.color,
      borderRadius: "8px",
      borderWidth: "2px",
      color: globalThemeLight.figmaPrimaryText.color,
    },
  },
  "& label.Mui-focused": {
    color: globalThemeLight.figmaPrimaryText.color,
  },
  "& label": {
    color: globalThemeLight.figmaPrimaryText.color,
  },
};

export const standardDialogRoot = {
  zIndex: 9999,
  backgroundColor: "rgba(253, 247, 234, 0.5)",
};
export const standardDialogPaper = {
  width: "94%",
  maxWidth: "800px",
  margin: 0,
  paddingBottom: "30px",
  paddingTop: "80px",
  position: "absolute",
  top: "-10px",
  zIndex: 9999,
   maxHeight: "80vh",
   borderRadius: "10px",
};

export const standardDialogTitle = {
  fontSize: "30px",
  margin: "0px",
  fontWeight: 600,
  textAlign: "left",
  "@media (max-width: 800px)": {
    fontSize: "28px",
  },
  "@media (max-width: 600px)": {
    fontSize: "24px",
  },
  "@media (max-width: 400px)": {
    fontSize: "20px",
  },
};

export const standardDialogText = {
  fontSize: "18px",
  margin: "0px",
  textAlign: "left",
  "@media (max-width: 800px)": {
    fontSize: "16px",
  },
  "@media (max-width: 600px)": {
    fontSize: "14px",
  },
  "@media (max-width: 400px)": {
    fontSize: "12px",
  },
};
