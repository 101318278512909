/**
 * Component to display the rating, review count, and views count for a project item.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.listItem - The project item containing rating, review count, and views count.
 * @param {boolean} [props.noViews] - Flag to determine whether to show views count or not.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <ProjectItemRating listItem={listItem} noViews={false} />
 *
 * @remarks
 * This component uses the `useThemeState` hook to get the global theme and applies it to the text and rating colors.
 * It also uses the `Skeleton` component from MUI to show loading states for rating, review count, and views count.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import Rating from "@mui/material/Rating";
import { useThemeState } from "../../../components/utils/globalStates";

import moment from "moment";
import "./ListingsComponents.css";

moment().format();

export default function ProjectItemRating({ listItem, noViews }: any) {
  const [reviewCountLoading, setReviewCountLoading] = useState(false);
  const [ratingCountLoading, setRatingCountLoading] = useState(false);
  const [ratingLoading, setRatingLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [ratingCount, setRatingCount] = useState(0);
  const [viewsCount, setViewsCount] = useState(0);
  const globalTheme = useThemeState((state) => state.globalTheme);

  // Get review count
  useEffect(() => {
    setReviewCountLoading(true);
    if (
      listItem.reviewsCount === null ||
      listItem.reviewsCount === undefined ||
      listItem.reviewsCount === ""
    ) {
      setReviewCount(0);
      setReviewCountLoading(false);
    } else {
      setReviewCount(listItem.reviewsCount);
      setReviewCountLoading(false);
    }
  }, [listItem.reviewsCount]);

  // Get rating count
  useEffect(() => {
    setRatingCountLoading(true);
    if (
      listItem.ratingCount === null ||
      listItem.ratingCount === undefined ||
      listItem.ratingCount === ""
    ) {
      setRatingCount(0);
      setRatingCountLoading(false);
    } else {
      setRatingCount(listItem.ratingCount);
      setRatingCountLoading(false);
    }
  }, [listItem.ratingCount]);

  // Get rating
  useEffect(() => {
    setRatingLoading(true);
    if (
      listItem.rating === null ||
      listItem.rating === undefined ||
      listItem.rating === ""
    ) {
      setRating(0);
      setRatingLoading(false);
    } else {
      setRating(listItem.rating);
      setRatingLoading(false);
    }
  }, [listItem.rating]);

  // Get views count
  useEffect(() => {
    setViewsCount(listItem.viewsTotal);
  }, [listItem.viewsTotal]);

  return (
    <div className="ProjectListRatingItem">
      <div className="ProjectListRatingItemInner">
        {ratingCountLoading === true ? (
          <Skeleton
            variant="text"
            sx={{
              width: "30%",
              fontSize: "11px",
              marginBottom: "0px",
              marginLeft: "0px",
            }}
          />
        ) : (
          rating > 0 && (
            <p
              className="ProjectListRatingItemRatingText"
              style={{
                color: globalTheme.primaryText.color,
                marginLeft: "1px",
                marginRight: "5px",
              }}
            >
              {ratingCount} Ratings
            </p>
          )
        )}
        {ratingLoading === true ? (
          <Skeleton
            variant="text"
            sx={{ width: "30%", fontSize: "11px", marginBottom: "0px" }}
          />
        ) : rating > 0 ? (
          <div className="ProjectListRatingItemRating">
            <Rating sx={{
                    "& svg": { color: globalTheme.primaryText.color },
                  }} value={rating} readOnly size="small" />
          </div>
        ) : (
          <div className="ProjectListRatingItemRating">
            <p
              className="ProjectListRatingItemRatingTextAlt"
              style={{ color: globalTheme.primaryText.color, marginLeft: "0px", }}
            >
              Be the first to rate or review
            </p>
          </div>
        )}
        {reviewCountLoading === true ? (
          <Skeleton
            variant="text"
            sx={{
              width: "30%",
              fontSize: "11px",
              marginBottom: "0px",
              marginLeft: "3px",
            }}
          />
        ) : (
          rating > 0 && (
            <p
              className="ProjectListRatingItemRatingText"
              style={{
                color: globalTheme.primaryText.color,
                marginLeft: "5px",
              }}
            >
              Reviews {reviewCount}
            </p>
          )
        )}
      </div>
      {/*noViews !== true && (
        <p
          className="ProjectListRatingItemRatingText"
          style={{
            color: globalTheme.primaryText.color,
            marginLeft: "3px",
            marginRight: "5px",
          }}
        >
          Views {viewsCount}
        </p>
      )*/}
    </div>
  );
}
