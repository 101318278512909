
/**
 * SnackbarListeners component is responsible for displaying snackbars for general notifications and error messages.
 * It uses global states to manage the snackbar's visibility, message, and action text.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 * 
 * @component
 * @example
 * return (
 *   <SnackbarListeners />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @remarks
 * This component uses the following global states:
 * - `useSnackbarState`: Manages the state for general notifications.
 * - `useSnackbarErrorState`: Manages the state for error notifications.
 * - `useThemeState`: Provides the global theme for styling the snackbars.
 *
 * The component renders two `Snackbar` components:
 * - One for general notifications.
 * - One for error notifications.
 *
 * Each `Snackbar` component has the following properties:
 * - `open`: Controls the visibility of the snackbar.
 * - `sx`: Custom styles for the snackbar content.
 * - `onClose`: Callback function to handle the snackbar close event.
 * - `autoHideDuration`: Duration before the snackbar auto-hides.
 * - `message`: The message to be displayed in the snackbar.
 * - `action`: The action button or icon to be displayed in the snackbar.
 *
 * The `Snackbar` components use the Material-UI library for styling and functionality.
 */

import React, { useEffect, useState } from "react";
import { useDeviceState } from "../utils/globalStates";
import Snackbar from "@mui/material/Snackbar";
import { useSnackbarState, useSnackbarErrorState, useThemeState } from "../utils/globalStates";
import { Button, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function SnackbarListeners() {
  const snackbarState = useSnackbarState((state) => state);
  const snackbarErrorState = useSnackbarErrorState((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);

  const onSnackbarClose = () => {
    if (snackbarState.isActionLoading) return;
    snackbarState.setIsOpen(false);
    snackbarState.setMessage("");
    snackbarState.setActionText("");
    snackbarState.setOpenLength(5000);
  };

  const onSnackbarErrorClose = () => {
    snackbarErrorState.setIsOpen(false);
    snackbarErrorState.setMessage("");
    snackbarErrorState.setActionText("");
    snackbarErrorState.setOpenLength(5000);
  };

  return (
    <>
      <Snackbar
        open={snackbarState.isOpen}
        sx={{
          zIndex: 99999,
          "& .MuiSnackbarContent-root": {
            backgroundColor: globalTheme.figmaBeigeDark.color,
            color: globalTheme.figmaPrimaryText.color,
            fontSize: "14px",
            fontWeight: 500,
            textAlign: "left",
            border: "0.5px solid #ffffff",
            zIndex: 99999,
            "@media (min-width: 600px)": {
              marginRight: "24px !important",
              maxWidth: "360px",
              zIndex: 99999,
            },
            "@media (min-width: 900px)": {
              maxWidth: "500px",
              zIndex: 99999,
            },
          },
        }}
        onClose={onSnackbarClose}
        autoHideDuration={snackbarState.openLength}
        message={snackbarState.message}
        action={
          <React.Fragment>
            {snackbarState.isActionLoading ? (
              <CircularProgress
                size={15}
                sx={{
                  color: globalTheme.figmaPrimaryTextSub.color,
                  marginRight: "10px",
                }}
              />
            ) : snackbarState.actionText !== "" ? (
              <Button variant="text" onClick={onSnackbarClose} color="inherit">
                {snackbarState.actionText}
              </Button>
            ) : (
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={onSnackbarClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          </React.Fragment>
        }
      />
      <Snackbar
        open={snackbarErrorState.isOpen}
        sx={{
          zIndex: 99999,
          "& .MuiSnackbarContent-root": {
            fontSize: "14px",
            fontWeight: 500,
            textAlign: "left",
            border: "0.5px solid #ffffff",
            zIndex: 99999,
            color: globalTheme.figmaWhite.color,
            backgroundColor: globalTheme.figmaWarningBackground.color,
            "@media (min-width: 600px)": {
              marginRight: "24px !important",
              maxWidth: "360px",
              zIndex: 99999,
            },
            "@media (min-width: 900px)": {
              maxWidth: "500px",
              zIndex: 99999,
            },
          },
        }}
        color="error"
        onClose={onSnackbarErrorClose}
        autoHideDuration={snackbarErrorState.openLength}
        message={snackbarErrorState.message}
        action={
          <React.Fragment>
            {snackbarErrorState.actionText !== "" ? (
              <Button
                variant="text"
                onClick={onSnackbarErrorClose}
                color="inherit"
              >
                {snackbarErrorState.actionText}
              </Button>
            ) : (
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={onSnackbarErrorClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          </React.Fragment>
        }
      />
    </>
  );
}
