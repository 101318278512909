/**
 * `TokensInfo` is a React functional component that displays information about cryptocurrencies and tokens.
 * It includes a search input for tokens and an optional informational message that can be toggled by the user.
 * The component uses local storage to remember if the user has seen the informational message.
 *
 * @component
 * @example
 * return (
 *   <TokensInfo />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @remarks
 * - The component uses the `useThemeState` hook to apply theme styles.
 * - The informational message can be toggled by clicking on the `InfoIcon` and `CancelIcon`.
 * - The visibility of the informational message is stored in local storage under the key `tokensInfo`.
 * 
 * @author 
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import SearchInputTokens from "../../search/SearchInputTokens";
import { useThemeState } from "../../utils/globalStates";
import "./TopBoxes.css";

export default function TokensInfo() {
  const [showInfo, setShowInfo] = useState(false);
  const globalTheme = useThemeState((state) => state.globalTheme);

  // Check local storage if user has seen the info
  useEffect(() => {
    if (localStorage.getItem("tokensInfo") === "true") {
      setShowInfo(false);
    } else {
      setShowInfo(true);
    }
  }, []);

  const onInfoClose = () => {
    setShowInfo(false);
    localStorage.setItem("tokensInfo", "true");
  };

  const onInfoOpen = () => {
    localStorage.removeItem("tokensInfo");
    setShowInfo(true);
  };
  return (
    <div
      className="SearchAndExplore"
      style={{
        backgroundColor: globalTheme.figmaGreenPastel.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        ...globalTheme.boxShadow,
        maxWidth: "770px",
      }}
    >
      {!showInfo && (
        <InfoIcon
          onClick={onInfoOpen}
          sx={{
            color: globalTheme.figmaPrimaryText.color,
            cursor: "pointer",
            position: "absolute",
            right: "6px",
            top: "6px",
            fontSize: "17px",
            "@media (min-width: 350px)": {
              fontSize: "18px",
            },
            "@media (min-width: 550px)": {
              fontSize: "19px",
            },
            "@media (min-width: 700px)": {
              fontSize: "20px",
            },
          }}
        />
      )}
      <div className="SearchAndExploreHeader">
        <p
          className="SearchAndExploreHeaderTitle"
          style={{ color: globalTheme.figmaPrimaryText.color, maxWidth: "95%" }}
        >
          Cryptocurrencies
        </p>
        <p
          className="SearchAndExploreHeaderSub"
          style={{
            color: globalTheme.figmaPrimaryText.color,
            maxWidth: "95%",
            marginBottom: "-10px",
          }}
        >
          Market price and data for all cryptocurrencies and tokens
        </p>
      </div>
      <SearchInputTokens />
      {showInfo && (
        <div
          className="ExploreInfo"
          style={{
            backgroundColor: globalTheme.figmaYellowAlt.color,
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            marginBottom: "5px",
          }}
        >
          <p
            className="ExploreInfoText"
            style={{ color: globalTheme.figmaPrimaryText.color }}
          >
            Real time market data for all cryptocurrencies and tokens. See the
            latest prices, market cap, volume, and more.
          </p>
          <CancelIcon
            onClick={onInfoClose}
            sx={{
              color: globalTheme.figmaPrimaryText.color,
              cursor: "pointer",
              fontSize: "17px",
              "@media (min-width: 350px)": {
                fontSize: "18px",
              },
              "@media (min-width: 550px)": {
                fontSize: "19px",
              },
              "@media (min-width: 700px)": {
                fontSize: "20px",
              },
            }}
          />
        </div>
      )}
    </div>
  );
}
