/**
 * Achievements component that sets up the routing for the achievements section of the application.
 * 
 * This component uses `react-router-dom` to define the routes for different views within the achievements section.
 * 
 * Routes:
 * - `/` renders the `AchievementsProfile` component.
 * - `/user/:userId` renders the `AchievementsUser` component.
 * - `/all` renders the `AchievementsList` component.
 * - Any other path (`*`) renders the `AchievementsList` component.
 * 
 * @returns {JSX.Element} The Achievements component with defined routes.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { Routes, Route} from "react-router-dom";
import AchievementsList from "./list/AchievementsList";
import AchievementsProfile from "./profile/AchievementsProfile";
import AchievementsUser from "./user/AchievementsUser";

import "./Achievements.css";

export default function Achievements() {
  return (
    <div className="Achievements">
      <Routes>
        <Route path="/" element={<AchievementsProfile />} />
        <Route path="/user/:userId" element={<AchievementsUser />} />
        <Route path="/all" element={<AchievementsList />} />
        <Route path="*" element={<AchievementsList />} />
      </Routes>
    </div>
  );
}
