/**
 * TutorialsSettings component provides a button to restore tutorial information
 * by clearing specific items from the local storage. It also displays a snackbar
 * notification to inform the user that the tutorials have been restored.
 *
 * @component
 * @returns {JSX.Element} The rendered TutorialsSettings component.
 *
 * @example
 * <TutorialsSettings />
 *
 * @remarks
 * This component uses the `useThemeState` and `useSnackbarState` hooks to access
 * global theme and snackbar state respectively.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { ButtonBase } from "@mui/material";

import {
  useThemeState,
  useSnackbarState,
} from "../../../../components/utils/globalStates";
import "./ProfileSettingsComp.css";

export default function TutorialsSettings() {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const snackbarState = useSnackbarState();


  // Delete all tutorials from local storage
  const restoreTutorials = async () => {
    localStorage.removeItem("achievementsInfo");
    localStorage.removeItem("achievementsProfileInfo");
    localStorage.removeItem("bookmarksInfo");
    localStorage.removeItem("dashboardInfo");
    localStorage.removeItem("dashboardInfoNew");
    localStorage.removeItem("pfpCreatorInfo");
    localStorage.removeItem("reviewSearchInfo");
    localStorage.removeItem("exploreInfo");
    localStorage.removeItem("settingsInfo");
    localStorage.removeItem("tokensInfo");
    localStorage.removeItem("userSearchInfo");
    localStorage.removeItem("walletsLatestInfo");
    localStorage.removeItem("walletsListInfo");
    localStorage.removeItem("issueReportInfo");
    snackbarState.setMessage("Tutorials restored.");
    snackbarState.setOpenLength(3000);
    snackbarState.setIsOpen(true);
    
  }

  const styles = {
    button: {
      color: globalTheme.figmaPrimaryText.color,
      backgroundColor: globalTheme.figmaAlt2.color,
      borderRadius: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  };

  return (
    <div className="ReferralsCount" style={{marginBottom: '40px'}}>
      <p className="ReferralsCountText">
        Click to restore tutorials on all pages.
      </p>
      <ButtonBase style={styles.button} onClick={restoreTutorials} >
        <p
          className="SocialConfigButtonText"
          style={{
            color: globalTheme.figmaPrimaryText.color,
          }}
        >
          Restore Tutorials
        </p>
      </ButtonBase>
    </div>
  );
}
