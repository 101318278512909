/**
 * Component for adding a review widget to the dashboard.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.addWidget - Function to add the widget.
 * @param {number} props.addwidgetRow - The row number to add the widget to.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <AddReviewWidget addWidget={addWidgetFunction} addwidgetRow={1} />
 *
 * @remarks
 * This component allows users to add a review widget to their dashboard. It includes a color picker for customizing the widget's background color and a button to confirm the addition of the widget.
 *
 * @see {@link useThemeState} for accessing global theme state.
 * @see {@link ReviewWidgetItem} for rendering review item within the widget.
 * @see {@link ColorPicker} for selecting widget background color.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState } from "react";

import ReviewWidgetItem from "../widgets/components/ReviewWidgetItem";
import { ButtonBase } from "@mui/material";
import ColorPicker from "./ColorPicker";
import { useThemeState } from "../../../components/utils/globalStates";
import "./DashboardAddWidget.css";

export default function AddReviewWidget({
  addWidget,
  addwidgetRow,
}: {
  addWidget: Function;
  addwidgetRow: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  
  const [isExpanded, setIsExpanded] = useState(false);
  const [widgetTitle, setWidgetTitle] = useState("New Reviews");
  const [pickedColor, setPickedColor] = useState(
    globalTheme.figmaGreenPastel.color
  );

  const onAddWidgetPress = () => {
    const widgetData = {
      bgColorCode: pickedColor,
      type: "reviews",
      rowLength: 4,
      uidRequired: false,
    };
    addWidget(widgetData, addwidgetRow);
  };

  return (
    <div
      className="AddWidget"
      style={{
        color: globalTheme.figmaPrimaryText.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        backgroundColor: globalTheme.figmaWhite.color,
      }}
    >
      <ButtonBase
        onClick={() => setIsExpanded(!isExpanded)}
        className="ListingsWidget"
        style={{
          color: globalTheme.figmaPrimaryText.color,
          border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          borderRadius: "8px",
          backgroundColor: pickedColor,
          width: "calc(100% + 2px)",
          marginTop: "-1px",
          marginBottom: "-1px",
        }}
      >
        <div
          className="ListingsWidgetHeader"
          style={{
            color: globalTheme.figmaPrimaryText.color,
            borderBottom: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          }}
        >
          <p className="ListingsWidgetTitle">{widgetTitle}</p>
        </div>
        <div className="ListingsWidgetInner">
          <ReviewWidgetItem
          isLink={false}
            dataToRender={{
              logo: "https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Flogo512.png?alt=media",
              name: "GATH3R",
              createdAt: Date.now() - 1000 * 60 * 60 * 2,
              rating: 5,
              category: 'tools',
              listingId: 'trust-wallet',
              listingRating: 4.9,
              reviewCount: 100,
              ratingCount: 100,
            }}
          />
        </div>
      </ButtonBase>
      <div
        className="AddWidgetSettingsOuter"
        style={{ maxHeight: isExpanded ? "500px" : "0px" }}
      >
        <div className="AddWidgetSettings">
          <ColorPicker
            setPickedColor={setPickedColor}
            pickedColor={pickedColor}
          />
        </div>
        <ButtonBase
          onClick={onAddWidgetPress}
          className="AddWidgetButton"
          style={{
            backgroundColor: globalTheme.figmaPrimaryText.color,
            color: globalTheme.figmaWhite.color,
            width: "100%",
            padding: "2px",
            borderRadius: "0px 0px 6px 6px",
            justifySelf: "flex-end",
          }}
        >
          <p>ADD WIDGET</p>
        </ButtonBase>
      </div>
    </div>
  );
}
