/**
 * ReferralGenerator component handles the generation, display, and management of referral codes for users.
 * 
 * @component
 * @returns {JSX.Element} The rendered ReferralGenerator component.
 * 
 * @example
 * <ReferralGenerator />
 * 
 * @remarks
 * This component uses Firebase functions to generate and manage referral codes. It also allows users to copy the referral code to the clipboard and download a QR code containing the referral link.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import React, { useState, useEffect } from "react";
import { CircularProgress, ButtonBase } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../../components/firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../../components/firebase/auth";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TextField from "@mui/material/TextField";
import QRCodeStyling from "qr-code-styling";
import wormLogo from "../../../../static/img/wormLogoYellow.png";
import {
  useThemeState,
  useAuthStates,
  useSnackbarErrorState,
  useSnackbarState,
} from "../../../../components/utils/globalStates";
import "./ProfileSettingsComp.css";

export default function ReferralGenerator() {
  const authStates = useAuthStates();
  const [user] = useAuthState(firebaseAuth);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [referralCodeError, setReferralCodeError] = useState(false);
  const [referralCodeHelperText, setReferralCodeHelperText] = useState("");
  const [referralCodeUpdating, setReferralCodeUpdating] = useState(false);

  const globalTheme = useThemeState((state) => state.globalTheme);
  const snackbarState = useSnackbarState();
  const snackbarErrorState = useSnackbarErrorState();

  useEffect(() => {
    if (referralCode.length === 0) {
      setReferralCodeHelperText("");
      setReferralCodeError(true);
    } else if (referralCode.length !== 12) {
      setReferralCodeHelperText("Referral code must be 12 characters long");
      setReferralCodeError(true);
    } else {
      setReferralCodeHelperText("");
      setReferralCodeError(false);
    }
  }, [referralCode]);

  // Check if referral code exists in user data

  useEffect(() => {
    const checkReferralCode = async () => {
      if (authStates.userData !== null) {
        console.log(authStates.userData);
        if (
          authStates.userData.referralCode !== null &&
          authStates.userData.referralCode !== undefined &&
          authStates.userData.referralCode !== ""
        ) {
          console.log("Referral code exists");
        } else {
          console.log("Referral code does not exist");
          if (user === null || user === undefined) {
            return;
          }
          const generateReferral = httpsCallable(
            firebaseFunctions,
            "referral-generate"
          );
          const tokenCheck = await user.getIdToken();
          try {
            await generateReferral({
              token: tokenCheck,
            });
          } catch (error) {
            console.error(error);
          }
        }
      }
    };
    checkReferralCode();
  }, [authStates.userData, user]);

  // Generate new referral code
  const regenerateReferral = async () => {
    if (user === null || user === undefined) {
      return;
    }
    setIsRegenerating(true);
    snackbarState.setMessage("Generating new referral code...");
    snackbarState.setOpenLength(null);
    snackbarState.setIsActionLoading(true);
    snackbarState.setIsOpen(true);
    const generateReferral = httpsCallable(
      firebaseFunctions,
      "referral-regenerate"
    );
    const tokenCheck = await user.getIdToken();
    try {
      await generateReferral({
        token: tokenCheck,
      });
      setIsRegenerating(false);
      snackbarState.setIsActionLoading(false);
      snackbarState.setOpenLength(3000);
      snackbarState.setMessage("New referral code generated!");
    } catch (error: any) {
      console.error(error);
      setIsRegenerating(false);
      snackbarState.setIsOpen(false);
      snackbarState.setIsActionLoading(false);
      snackbarState.setMessage("");
      snackbarErrorState.setMessage(error.message);
      snackbarErrorState.setOpenLength(4000);
      snackbarErrorState.setIsOpen(true);
    }
  };

  // Add referral code
  const addReferralCode = async () => {
    if (user === null || user === undefined) {
      return;
    }
    if (referralCode === "" || referralCodeError || referralCodeUpdating) {
      return;
    }
    setReferralCodeUpdating(true);
    snackbarState.setMessage("Adding referral code...");
    snackbarState.setOpenLength(null);
    snackbarState.setIsActionLoading(true);
    snackbarState.setIsOpen(true);
    const addReferral = httpsCallable(firebaseFunctions, "referral-add");
    const tokenCheck = await user.getIdToken();
    try {
      await addReferral({
        token: tokenCheck,
        referral: referralCode,
      });
      setReferralCodeUpdating(false);
      setReferralCode("");
      snackbarState.setIsActionLoading(false);
      snackbarState.setOpenLength(4000);
      snackbarState.setMessage(
        "Referral code added! Both you and the referrer will receive 100 points each!"
      );
    } catch (error: any) {
      console.error(error);
      setReferralCodeUpdating(false);
      snackbarState.setIsOpen(false);
      snackbarState.setIsActionLoading(false);
      snackbarState.setMessage("");
      snackbarErrorState.setMessage(error.message);
      snackbarErrorState.setOpenLength(4000);
      snackbarErrorState.setIsOpen(true);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        snackbarState.setMessage("Copied to clipboard!");
        snackbarState.setOpenLength(3000);
        snackbarState.setIsOpen(true);
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
        snackbarErrorState.setMessage(
          "Failed to copy to clipboard, please try again!"
        );
        snackbarErrorState.setOpenLength(3000);
        snackbarErrorState.setIsOpen(true);
      }
    );
  };

  const downloadQR = () => {
    let canvas = document.createElement("canvas");
    canvas.setAttribute("width", "200px");
    canvas.setAttribute("height", "240px");
    // @ts-ignore
    let ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    const image = new Image();
    image.src = wormLogo;
    image.onload = function () {
      ctx.drawImage(image, -28, -20);
      ctx.textAlign = "center";
      ctx.font = "bold 26px Poppins";
      ctx.fillText(
        authStates.userData.displayName,
        100,
        225,
        canvas.width - 10
      );
      const base64Url = canvas.toDataURL();
      const qr = new QRCodeStyling({
        width: 500,
        height: 500,
        data: `https://app.gath3r.co/auth/register?referral=${authStates.userData.referralCode}`,
        image: base64Url,
        dotsOptions: {
          color: "#000000",
          type: "square",
        },
        cornersDotOptions: {
          type: "dot",
          color: "#000000",
        },
        cornersSquareOptions: {
          type: "extra-rounded",
          color: "#FAEC72",
        },
        backgroundOptions: {
          color: "#ffffff",
        },
        imageOptions: {
          margin: 0,
        },
      });
      qr.download({
        name: "gath3r-referral-" + authStates.userData.displayName,
        extension: "png",
      });
    };
  };

  const styles = {
    textField: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        color: globalTheme.solidMainBlack.color,
        backgroundColor: globalTheme.solidMainWhite.color,
        boxShadow: "none",
        borderRadius: "5px",
        padding: "0px",
        fontSize: "14px",
        paddingLeft: "8px",
        paddingRight: "8px",
        border: "none",
        "& fieldset": {
          border: "none",
          color: globalTheme.solidMainBlack.color,
        },
        "&:hover fieldset": {
          border: "none",
          color: globalTheme.solidMainBlack.color,
        },
        "&.Mui-focused fieldset": {
          border: "none",
          color: globalTheme.solidMainBlack.color,
        },
      },
      "& label.Mui-focused": {
        border: "none",
        color: globalTheme.solidMainBlack.color,
      },
      "& label": {
        border: "none",
        color: globalTheme.solidMainBlack.color,
      },
    },
  };

  return authStates.userData !== null &&
    authStates.userData.referralCode !== null &&
    authStates.userData.referralCode !== undefined &&
    authStates.userData.referralCode !== "" ? (
    <>
      <div className="ReferralViewer">
        <div className="SocialConfig" style={{ width: "calc(100% - 80px)" }}>
          <div
            className="SocialConfigInner"
            style={{ marginBottom: "10px", alignItems: "center" }}
          >
            <p
              className="ProfileSettingsSubTitle"
              style={{
                margin: "0px",
                color: globalTheme.backgroundMainOffBeige.color,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "calc(100% - 60px)",
              }}
            >
              {`https://app.gath3r.co/auth/register?referral=${authStates.userData.referralCode}`}
            </p>
            <ButtonBase
              onClick={() =>
                copyToClipboard(
                  `https://app.gath3r.co/auth/register?referral=${authStates.userData.referralCode}`
                )
              }
              style={{
                color: globalTheme.figmaPrimaryText.color,
                backgroundColor: globalTheme.figmaAlt2.color,
                width: "50px",
                marginLeft: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                className="SocialConfigButtonText"
                style={{
                  color: globalTheme.figmaPrimaryText.color,
                }}
              >
                Copy
              </p>
            </ButtonBase>
          </div>
          <div className="SocialConfigInner" style={{ alignItems: "center" }}>
            <p
              className="ProfileSettingsSubTitle"
              style={{
                margin: "0px",
                color: globalTheme.backgroundMainOffBeige.color,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "calc(100% - 60px)",
              }}
            >
              {`${authStates.userData.referralCode}`}
            </p>
            <ButtonBase
              onClick={() =>
                copyToClipboard(`${authStates.userData.referralCode}`)
              }
              style={{
                color: globalTheme.figmaPrimaryText.color,
                backgroundColor: globalTheme.figmaAlt2.color,
                width: "50px",
                marginLeft: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                className="SocialConfigButtonText"
                style={{
                  color: globalTheme.figmaPrimaryText.color,
                }}
              >
                Copy
              </p>
            </ButtonBase>
          </div>
        </div>
        <ButtonBase
          onClick={() => downloadQR()}
          style={{
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.figmaGOrange.color,
            width: "70px",
            marginLeft: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            className="SocialConfigButtonText"
            style={{
              color: globalTheme.figmaPrimaryText.color,
              width: "90%",
            }}
          >
            Download QR
          </p>
        </ButtonBase>
      </div>
      <ButtonBase
        onClick={regenerateReferral}
        disabled={isRegenerating || authStates.userData.referralUsedCount < 5}
        style={{
          color: globalTheme.figmaPrimaryText.color,
          backgroundColor: globalTheme.figmaAlt2.color,
          width: "88%",
          maxWidth: "800px",
          borderRadius: "5px",
          marginTop: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <p
          className="SocialConfigButtonText"
          style={{
            color: globalTheme.figmaPrimaryText.color,
          }}
        >
          {isRegenerating ? (
            <CircularProgress
              size={12}
              color="inherit"
              sx={{ margin: 0, padding: 0 }}
            />
          ) : authStates.userData.referralUsedCount !== null &&
            authStates.userData.referralUsedCount !== undefined ? (
            authStates.userData.referralUsedCount < 5 ? (
              `${
                5 - authStates.userData.referralUsedCount
              } referrals left before you can generate a new code`
            ) : (
              "Generate new referral code"
            )
          ) : (
            "5 referrals left before you can generate a new code"
          )}
        </p>
      </ButtonBase>
      {authStates.userData.referrer !== null &&
      authStates.userData.referrer !== undefined &&
      authStates.userData.referrer !== "" ? null : (
        <div className="SocialConfig">
          <p
            className="ProfileSettingsSub"
            style={{
              color: globalTheme.textMainBlack.color,
              marginTop: "20px",
              marginLeft: "0px",
              width: "100%",
              marginBottom: "5px",
            }}
          >
            Forgot to use a referral code when creating a account? Add it here!
          </p>
          <div className="SocialConfigInner">
            <div className="SocialConfigTextField">
              <TextField
                sx={styles.textField}
                size="small"
                placeholder="......"
                value={referralCode}
                onChange={(event) => {
                  setReferralCode(event.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      {referralCode !== "" ? (
                        <CloseOutlinedIcon
                          onClick={() => {
                            setReferralCode("");
                          }}
                          color="inherit"
                          sx={{
                            fontSize: "20px",
                            marginLeft: "5px",
                            opacity: 0.4,
                            cursor: "pointer",
                          }}
                        />
                      ) : null}
                    </React.Fragment>
                  ),
                }}
              />
            </div>
            <ButtonBase
              onClick={addReferralCode}
              disabled={
                referralCode === "" || referralCodeError || referralCodeUpdating
              }
              style={{
                color: globalTheme.figmaPrimaryText.color,
                backgroundColor: globalTheme.figmaAlt2.color,
                width: "60px",
                marginLeft: "10px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {referralCodeUpdating ? (
                <CircularProgress
                  size={13}
                  color="inherit"
                  sx={{ color: globalTheme.figmaPrimaryText.color }}
                />
              ) : (
                <p
                  className="SocialConfigButtonText"
                  style={{
                    color:
                      referralCode === "" ||
                      referralCodeError ||
                      referralCodeUpdating
                        ? globalTheme.figmaPrimaryTextSub.color
                        : globalTheme.figmaPrimaryText.color,
                  }}
                >
                  ADD
                </p>
              )}
            </ButtonBase>
          </div>
          <p
            className="SocialConfigHelperText"
            style={{
              color: globalTheme.textMainBlack.color,
            }}
          >
            {referralCodeHelperText}
          </p>
        </div>
      )}
    </>
  ) : (
    <div className="SocialConfig">
      <CircularProgress
        size={30}
        color="inherit"
        sx={{
          color: globalTheme.backgroundMainOffYellow.color,
          marginTop: "15px",
        }}
      />
    </div>
  );
}
