/**
 * Component that fetches and displays a list of RSS feed items from Firestore.
 * 
 * @component
 * @param {ListingDialogProps} props - The props for the component.
 * @param {string} props.activeListing - The active listing ID to fetch RSS feed items for.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <RssDBList activeListing="exampleListingId" />
 * 
 * @remarks
 * This component uses Firebase Firestore to fetch RSS feed items and displays them in a list.
 * It shows a loading spinner while fetching data and handles errors gracefully.
 * The component also uses global state hooks to manage the fetched listings and loading state.
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import styles from "./news.module.css";
import { useRssState, useThemeState } from "../../../../components/utils/globalStates";
import {
  collection,
  query,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import { firestoreDb } from "../../../../components/firebase/firestore";
import RssDbItem from "./RssDbItem";

interface ListingDialogProps {
  activeListing: string;
}

moment().format();
let isFetching = false;

export default function RssDBList({ activeListing }: ListingDialogProps) {
  const { listings, setListings, isFetched, setIsFetched } = useRssState();
  const [loading, setLoading] = useState(false);
  const globalTheme = useThemeState((state) => state.globalTheme);

  useEffect(() => {
    const fetchListings = async () => {
      if (isFetching) return;
      if (activeListing === "") return;
      if (isFetched) return;
      try {
        setLoading(true);
        isFetching = true;
        let rssArray: any[] = [];

        let q = query(
          collection(firestoreDb, `listings/${activeListing}/rss`),
          orderBy("date", "desc"),
          limit(10)
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          let tempDoc = doc.data();
          let tempDate = tempDoc.date;
          rssArray.push({ ...doc.data(), timestamp: tempDate, proider: "RSS" });
        });

        setListings(rssArray);
        setIsFetched(true);
        isFetching = false;
        setLoading(false);
      } catch (error) {
        console.error(error);
        setIsFetched(true);
        isFetching = false;
        setLoading(false);
      }
    };
    fetchListings();
  }, [activeListing]);

  return !loading ? (
    listings.length > 0 ? (
      <div className={styles.newsListingsOuter}>
        {listings.map((listing: any, index: number) => {
          return (
            <div className={styles.outsideKeyListing} key={index}>
              <RssDbItem listing={listing} />
            </div>
          );
        })}
      </div>
    ) : (
      <div className={styles.loadingDialog}>
        <p className={styles.newsListingsText} style={{ color: globalTheme.backgroundMainOffBeige.color }}>No other articles found.</p>
      </div>
    )
  ) : (
    <div className={styles.loadingDialog}>
      <CircularProgress color="inherit" sx={{ color: globalTheme.backgroundMainOffBeige.color }}/>
    </div>
  );
}
