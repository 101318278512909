/**
 * Component for adding a new RSS link to the Firebase Realtime Database.
 *
 * This component includes a text field for entering the RSS link and a button to upload the link.
 * It uses Material-UI components for styling and Firebase for database operations.
 *
 * @component
 * @example
 * return (
 *   <AdminAddNewsLink />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @remarks
 * The component uses the `useThemeState` hook to access the global theme state for styling.
 * It also maintains local state for the RSS link input, loading status, and error message.
 *
 * @function
 * @name AdminAddNewsLink
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState } from "react";

import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { ref, set } from "firebase/database";
import { firebaseRlDb } from "../../../components/firebase/database";

import { useThemeState } from "../../../components/utils/globalStates";
import "./AdminComponents.css";

export default function AdminAddNewsLink() {
  const [rssLink, setRssLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("Add link");

  const globalTheme = useThemeState((state) => state.globalTheme);

  const onUpload = async () => {
    try {
      setLoading(true);
      const tempDate = Date.now();
      await set(ref(firebaseRlDb, "private/rssLinks/" + tempDate), rssLink);
      setRssLink("");
      setLoading(false);
    } catch (e) {
      console.error(e);
      setError("Error: Try again");
      setLoading(false);
    }
  };

  const materialListingFormStyles = {
  "& .MuiOutlinedInput-root": {
    color: globalTheme.backgroundMainOffBeige.color,
    borderColor: globalTheme.backgroundMainOffBeige.color,
    "& fieldset": {
      color: globalTheme.backgroundMainOffBeige.color,
    borderColor: globalTheme.backgroundMainOffBeige.color,
    },
    "&:hover fieldset": {
      color: globalTheme.backgroundMainOffBeige.color,
    borderColor: globalTheme.backgroundMainOffBeige.color,
    },
    "&.Mui-focused fieldset": {
      color: globalTheme.backgroundMainOffBeige.color,
    borderColor: globalTheme.backgroundMainOffBeige.color,
    },
  },
  "& label.Mui-focused": {
    color: globalTheme.backgroundMainOffBeige.color,
  },
  "& label": {
    color: globalTheme.backgroundMainOffBeige.color,
  },
};

  return (
    <div
      className="AdminNewsletterAddEmail"
      style={{
        borderBottom: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
        borderTop: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
        color: globalTheme.backgroundMainOffBeige.color,
      }}
    >
      <TextField
        label="Add new RSS link"
        disabled={loading}
        variant="outlined"
        size="small"
        sx={{ ...materialListingFormStyles, marginRight: "20px", flex: 1 }}
        value={rssLink}
        placeholder="RSS Link"
        onChange={(e) => setRssLink(e.target.value)}
      />
      <Button
        variant="outlined"
        color="inherit"
        sx={{ fontSize: "14px", minWidth: "30%" }}
        onClick={onUpload}
        disabled={rssLink === "" || loading}
      >
        {loading ? <CircularProgress color="inherit" size={"20px"} /> : error}
      </Button>
    </div>
  );
}
