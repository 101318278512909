/**
 * UserPageV2 component displays the user profile page with various user details and actions.
 *
 * @component
 * @returns {JSX.Element} The rendered UserPageV2 component.
 *
 * @example
 * <UserPageV2 />
 *
 * @remarks
 * This component fetches user data from Firestore and displays it. It allows the current user to follow/unfollow the displayed user,
 * report the user, and view various user-related information such as achievements, listings, reviews, and ratings.
 *
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import React, { useState, useEffect } from "react";
import { Skeleton } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { doc, onSnapshot, setDoc, deleteDoc } from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../components/firebase/functions";
import copy from "copy-to-clipboard";
import { Helmet } from "react-helmet-async";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

import Tooltip from "@mui/material/Tooltip";

// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import ButtonBase from "@mui/material/ButtonBase";

import ReportIcon from "@mui/icons-material/Report";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import {
  useSnackbarErrorState,
  useSnackbarState,
  useHeaderSettingsState,
  useTabSettingsState,
  useDeviceState,
  useThemeState,
  useAuthStates,
} from "../../../components/utils/globalStates";

import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "../../../components/firebase/analytics";

import { PfpViewer } from "../../../components/ui/img/PfpViewer";
import UserPageV2ReviewsPreview from "./components/UserPageV2ReviewsPreview";
import UserPageV2RatingsPreview from "./components/UserPageV2RatingsPreview";
import UserPageV2ListingsPreview from "./components/UserPageV2ListingsPreview";
import UserPageCountV2 from "./components/UserPageCountV2";
import UserPageAchievements from "./components/UserPageAchievements";
import UserVerifiedBadge from "../../../components/ui/img/badges/UserVerifiedBadge";

import XLogo from "../../../components/ui/img/socials/Xlogo";
import InstagramLogo from "../../../components/ui/img/socials/InstagramLogo";
import TelegramLogo from "../../../components/ui/img/socials/TelegramLogo";
import FacebookLogo from "../../../components/ui/img/socials/FacebookLogo";

import { removeTwitterPrefix } from "../../../components/utils/parsers";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import {
  standardDialogPaper,
  standardDialogRoot,
  standardDialogTitle,
} from "../../../components/theme/globalMuiTheme";
import { figmaColorVariables } from "../../../components/theme/globalTheme";
import "./UserPageV2.css";
import "../../profile/start/Profile.css";

moment().format();

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function UserPageV2() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { userId } = useParams();
  const navigate = useNavigate();
  const snackbarError = useSnackbarErrorState();
  const snackbarStates = useSnackbarState();
  const headerSettingsState = useHeaderSettingsState();
  const tabSettingsState = useTabSettingsState();
  const deviceState = useDeviceState();
  const authStates = useAuthStates((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [userData, setUserData] = useState<any>({});
  const [isFollowing, setIsFollowing] = useState(false);
  const [isFollowingLoading, setIsFollowingLoading] = useState(false);

  const [userReportDialog, setUserReportDialog] = useState(false);
  const [reasonString, setReasonString] = useState("");
  const [reasonArray, setReasonArray] = useState<string[]>([]);
  const [isSendingUserReport, setIsSendingUserReport] = useState(false);

  const onShareClick = () => {
    /*logEvent(firebaseAnalytics, "user_share", {
      user_id: user?.uid,
    });*/
    copy(`https://app.gath3r.co/users/page/${userId}`);
    snackbarStates.setMessage("Link copied to clipboard");
    snackbarStates.setOpenLength(3000);
    snackbarStates.setIsOpen(true);
  };

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setReasonArray([...reasonArray, event.target.value]);
    } else {
      setReasonArray(
        reasonArray.filter((reason) => reason !== event.target.value)
      );
    }
  };

  // Update reasonString based on reasonArray
  useEffect(() => {
    setReasonString(reasonArray.join(","));
  }, [reasonArray]);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle user report submit
  const onUserReportSubmit = async () => {
    if (isSendingUserReport) return;
    if (user?.uid === undefined || user?.uid === null) return;
    if (authStates.userData === null || authStates.userData === undefined)
      return;
    setIsSendingUserReport(true);
    if (reasonString === "") {
      snackbarError.setMessage("Reason is required");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      setIsSendingUserReport(false);
      return;
    }
    const callable = httpsCallable(firebaseFunctions, "user-new_report");
    try {
      await callable({
        reason: reasonString,
        id: userId,
        token: await user?.getIdToken(),
        reporterName: authStates.userData.displayName,
        name: userData.displayName,
      });
      setUserReportDialog(false);
      setIsSendingUserReport(false);
      snackbarStates.setMessage(
        userData.displayName === ""
          ? "Anonymous user"
          : userData.displayName + " reported"
      );
      snackbarStates.setOpenLength(3000);
      snackbarStates.setIsOpen(true);
    } catch (e) {
      console.error(e);
      setIsSendingUserReport(false);
      snackbarError.setMessage("Error reporting user, please try again");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
    }
  };

  // Set following or unfollowing
  const onFollowClick = async () => {
    if (user?.uid === undefined) return;
    if (isFollowingLoading === true) return;
    setIsFollowingLoading(true);
    if (isFollowing === true) {
      const followingRef = doc(
        firestoreDb,
        "follow",
        user?.uid,
        "userFollows",
        user?.uid + "-" + userId
      );
      try {
        await deleteDoc(followingRef);
        setIsFollowingLoading(false);
        snackbarStates.setMessage("Unfollowed");
        snackbarStates.setOpenLength(3000);
        snackbarStates.setIsOpen(true);
      } catch (e) {
        console.error("Error updating document: ", e);
        setIsFollowingLoading(false);
        snackbarError.setMessage("Error unfollowing, please try again");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
      }
    } else {
      const followingRef = doc(
        firestoreDb,
        "follow",
        user?.uid,
        "userFollows",
        user?.uid + "-" + userId
      );
      try {
        await setDoc(followingRef, {
          following: userId,
          follower: user?.uid,
          followedAt: Date.now(),
        });
        setIsFollowingLoading(false);
        snackbarStates.setMessage("Followed");
        snackbarStates.setOpenLength(3000);
        snackbarStates.setIsOpen(true);
      } catch (e) {
        console.error("Error updating document: ", e);
        setIsFollowingLoading(false);
        snackbarError.setMessage("Error following, please try again");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
      }
    }
  };

  // Fetch public user data from firestore
  useEffect(() => {
    if (userId === undefined) {
      snackbarError.setMessage("User not found");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return navigate("/users");
    }
    const userRef = doc(firestoreDb, "publicUserInfo", userId);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        setUserData(doc.data());
        setIsLoading(false);
        console.log(doc.data());
      } else {
        snackbarError.setMessage("User not found");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        return navigate("/users");
      }
    });
    return () => {
      unsubscribe();
    };
  }, [userId]);

  // Check if user is following
  useEffect(() => {
    if (user?.uid === undefined) return;
    const followingRef = doc(
      firestoreDb,
      "follow",
      user?.uid,
      "userFollows",
      user?.uid + "-" + userId
    );
    const unsubscribe = onSnapshot(followingRef, (doc) => {
      if (doc.exists()) {
        setIsFollowing(true);
      } else {
        setIsFollowing(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [userId, user?.uid]);

  useEffect(() => {
    headerSettingsState.setIsBackButtonOpen(true);
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.backgroundMainBeige.color);
    return () => {
      headerSettingsState.setIsBackButtonOpen(false);
      tabSettingsState.setColor(globalTheme.backgroundMainYellow.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      headerSettingsState.setIsLogoOpen(false);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  const onUserReportCancel = () => {
    if (isSendingUserReport) return;
    setReasonString("");
    setReasonArray([]);
    setUserReportDialog(false);
  };

  useEffect(() => {
    if (
      userData !== null &&
      userData !== undefined &&
      userData.id !== undefined &&
      userData.id !== null &&
      userData.id !== ""
    ) {
      let timeOutInit: any = null;

      const timeoutFunc = () => {
        timeOutInit = setTimeout(() => {
          logEvent(firebaseAnalytics, "user_visit", {
            user_uid: userData.id,
          });
        }, 3000);
      };
      timeoutFunc();
      return () => {
        clearTimeout(timeOutInit);
      };
    }
  }, [userData]);

  const styles = {
    dialogRoot: {
      zIndex: 9999,
      backgroundColor: "rgba(253, 247, 234, 0.5)",
      "& .MuiDialog-paper": {
        backgroundColor: globalTheme.primarySurface.color,
        color: globalTheme.backgroundMainOffBeige.color,
        width: "94%",
        maxWidth: "600px",
        margin: 0,
        paddingBottom: "30px",
        paddingTop: "100px",
        position: "absolute",
        top: "-10px",
        zIndex: 9999,
      },
    },
    dialogTitle: {
      color: globalTheme.backgroundMainOffBeige.color,
      textAlign: "center",
      fontSize: "16px",
      marginTop: "20px",
      fontWeight: 600,
    },
    formGroup: {
      color: globalTheme.solidMainBlack.color,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "0px",
    },
    checkBox: {
      "&.Mui-checked": {
        color: globalTheme.solidMainBlack.color,
      },
      color: globalTheme.solidMainBlack.color,
    },
    formControl: {
      color: globalTheme.solidMainBlack.color,
      justifyContent: "flex-start",
      margin: "0px",
      width: "33%",
      "@media (max-width: 800px)": {
        width: "50%",
      },

      "& .MuiTypography-root": {
        fontSize: "11px !important",
        "@media (min-width: 350px)": {
          fontSize: "12px !important",
        },
        "@media (min-width: 550px)": {
          fontSize: "13px !important",
        },
        "@media (min-width: 700px)": {
          fontSize: "14px !important",
        },
      },
    },
    menuPaper: {
      "& .MuiPaper-root": {
        borderRadius: "8px",
        backgroundColor: globalTheme.primarySurface.color,
        color: globalTheme.backgroundMainOffBeige.color,
      },
    },
  };

  const buttonStyles = {
    color: "#1A1A1A",
    backgroundColor: "#FFED8F",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    boxShadow: globalTheme.boxShadow,
    border: "1px solid #1A1A1A",
    flex: 1,
    margin: "5px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
  };

  return (
    <div
      className="UserPageV2"
      style={{ backgroundColor: globalTheme.primarySurface.color }}
    >
      {userData.name !== null && userData.name !== undefined && (
        <Helmet prioritizeSeoTags>
          <title>
            GATH3R -{" "}
            {userData.displayName === ""
              ? "Anonymous user"
              : userData.displayName}
          </title>
        </Helmet>
      )}
      <div
        className="UserPageV2Inner"
        style={{
          borderColor: globalTheme.textMainBlack.color,
        }}
      >
        <div
          className="UserPageV2InnerHeader"
          style={{
            borderColor: globalTheme.textMainBlack.color,
            position: "relative",
          }}
        >
          {userId !== user?.uid && (
            <IconButton
              sx={{
                top: "0px",
                right: "0px",
                position: "absolute",
                "@media (min-width: 550px)": {
                  top: "10px",
                  right: "3px",
                },
              }}
              size="small"
              color="inherit"
              onClick={handleMenuClick}
            >
              <MoreVertOutlinedIcon
                sx={{
                  fontSize: "20px",
                  color: globalTheme.backgroundMainOffBeige.color,
                  "@media (min-width: 350px)": {
                    fontSize: "22px",
                  },
                  "@media (min-width: 550px)": {
                    fontSize: "24px",
                  },
                  "@media (min-width: 700px)": {
                    fontSize: "26px",
                  },
                }}
              />
            </IconButton>
          )}
          <div
            className="UserPageV2InnerHeaderLogo"
            style={{
              border: `1px solid ${globalTheme.solidMainBlack.color}`,
            }}
          >
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                className="ProfileAvatarImg"
                sx={{
                  height: "100%",
                  width: "100%",
                  marginBottom: "0px",
                  borderRadius: "8px",
                }}
              />
            ) : userData.pfpString === null ||
              userData.pfpString === undefined ||
              userData.pfpString === "" ? (
              <PfpViewer pfpString={""} randomize={true} />
            ) : (
              <PfpViewer pfpString={userData.pfpString} randomize={false} />
            )}
          </div>
          {isLoading ? (
            <Skeleton
              variant="text"
              sx={{
                width: "50%",
                fontSize: "40px",
                "@media (max-width: 1200px)": {
                  fontSize: "30px",
                },

                "@media (max-width: 1000px)": {
                  fontSize: "24px",
                },
                "@media (max-width: 800px)": {
                  fontSize: "22px",
                },
                "@media (max-width: 600px)": {
                  fontSize: "20px",
                },

                "@media (max-width: 400px)": {
                  fontSize: "18px",
                },
              }}
            />
          ) : (
            <div className="UserPageV2InnerHeaderTitleOuter">
              <p
                className="UserPageV2InnerHeaderTitle"
                style={{
                  color: globalTheme.primaryText.color,
                }}
              >
                {userData.displayName === ""
                  ? "Anonymous user"
                  : userData.displayName}
              </p>
              {userData.verified === true && userData.disabled !== true && (
                <Tooltip title="Verified User">
                  <div className="UserPageV2InnerHeaderBadge">
                    <UserVerifiedBadge />
                  </div>
                </Tooltip>
              )}
            </div>
          )}
          {isLoading ? (
            <Skeleton
              variant="text"
              style={{ fontSize: "12px", marginTop: "0px", width: "60px" }}
            />
          ) : (
            <p
              className="UserPageV2InnerHeaderSub"
              style={{
                color: globalTheme.textMainGrey.color,
              }}
            >
              {" "}
              Active {moment(userData.lastActive).fromNow()}
            </p>
          )}
          {isLoading ? (
            <>
              <Skeleton
                variant="text"
                sx={{
                  width: "50%",
                  fontSize: "18px",
                  "@media (max-width: 800px)": {
                    fontSize: "15px",
                    width: "60%",
                  },
                  "@media (max-width: 400px)": {
                    fontSize: "12px",
                    width: "70%",
                  },
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  width: "50%",
                  fontSize: "18px",
                  "@media (max-width: 800px)": {
                    fontSize: "15px",
                    width: "60%",
                  },
                  "@media (max-width: 400px)": {
                    fontSize: "12px",
                    width: "70%",
                  },
                }}
              />
            </>
          ) : (
            userData.about !== null &&
            userData.about !== undefined &&
            userData.about !== "" && (
              <p
                className="UserPageV2InnerHeaderDescription"
                style={{ color: globalTheme.textMainBlack.color }}
              >
                {userData.about}
              </p>
            )
          )}

          {isLoading ? (
            <Skeleton
              variant="rectangular"
              sx={{
                marginTop: "10px",
                borderRadius: "8px",
                width: "40%",
                height: "20px",
                "@media (max-width: 800px)": {
                  width: "45%",
                },
                "@media (max-width: 400px)": {
                  width: "50%",
                },
              }}
            />
          ) : (
            <div className="UserPageV2InnerHeaderButtons">
              {userData !== null &&
                userData.xHandle !== "" &&
                userData.xHandle !== undefined &&
                userData.xHandle !== null && (
                  <a
                    href={`https://x.com/${removeTwitterPrefix(
                      userData.xHandle
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="UserPageV2InnerHeaderButtonsLinkSocial"
                  >
                    <XLogo customColor={figmaColorVariables.blue400} />
                  </a>
                )}
              {userData !== null &&
                userData.instagramHandle !== "" &&
                userData.instagramHandle !== undefined &&
                userData.instagramHandle !== null && (
                  <a
                    href={`https://instagram.com/${removeTwitterPrefix(
                      userData.instagramHandle
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="UserPageV2InnerHeaderButtonsLinkSocial"
                  >
                    <InstagramLogo customColor={figmaColorVariables.blue400} />
                  </a>
                )}
              {userData !== null &&
                userData.telegramHandle !== "" &&
                userData.telegramHandle !== undefined &&
                userData.telegramHandle !== null && (
                  <a
                    href={`https://t.me/${removeTwitterPrefix(
                      userData.telegramHandle
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="UserPageV2InnerHeaderButtonsLinkSocial"
                  >
                    <TelegramLogo customColor={figmaColorVariables.blue400} />
                  </a>
                )}
              {userData !== null &&
                userData.facebookUrl !== "" &&
                userData.facebookUrl !== undefined &&
                userData.facebookUrl !== null && (
                  <a
                    href={userData.facebookUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="UserPageV2InnerHeaderButtonsLinkSocial"
                  >
                    <FacebookLogo customColor={figmaColorVariables.blue400} />
                  </a>
                )}
              {user?.uid !== userId && (
                <ButtonBase
                  disabled={isFollowingLoading}
                  onClick={onFollowClick}
                  sx={{
                    borderRadius: "15px",
                    border: `1px solid ${figmaColorVariables.blue400}`,
                    padding: "2px 10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    flexDirection: "row",
                    marginBottom: "8px",
                  }}
                >
                  {isFollowing ? (
                    <p
                      className="UserPageV2InnerHeaderButtonsLink"
                      style={{ color: figmaColorVariables.blue400 }}
                    >
                      Unfollow
                    </p>
                  ) : (
                    <p
                      className="UserPageV2InnerHeaderButtonsLink"
                      style={{ color: figmaColorVariables.blue400 }}
                    >
                      Follow
                    </p>
                  )}
                  {isFollowing ? (
                    <PersonRemoveOutlinedIcon
                      sx={{
                        fontSize: "20px",
                        color: figmaColorVariables.blue400,
                        "@media (max-width: 800px)": {
                          fontSize: "17px",
                        },
                        "@media (max-width: 400px)": {
                          fontSize: "14px",
                        },
                      }}
                    />
                  ) : (
                    <PersonAddAlt1OutlinedIcon
                      sx={{
                        fontSize: "14px",
                        color: figmaColorVariables.blue400,
                      }}
                    />
                  )}
                </ButtonBase>
              )}
            </div>
          )}
          <div className="UserPageV2InnerHeaderCountOuter">
            <UserPageCountV2
              userId={userId}
              updateFollowers={isFollowingLoading}
              userPoints={userData.points}
              userListingCount={userData.listingCount}
              userReviewsCount={userData.reviewsCount}
              userRatingsCount={userData.ratingCount}
              followingCount={userData.followingCount}
              followersCount={userData.followersCount}
            />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "1px",
            margin: 0,
            backgroundColor: globalTheme.textMainBlack.color,
          }}
        ></div>
        <div
          className="UserPageV2Body"
          style={{ backgroundColor: globalTheme.secondarySurface.color }}
        >
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              sx={{
                width: "160px",
                height: "30px",
                borderRadius: "5px",
                marginTop: "20px",
              }}
            />
          ) : (
            <p
              className="UserPageV2BodyTitle"
              style={{ color: globalTheme.textMainBlack.color }}
            >
              GATH3R POINTS: {userData.points}
            </p>
          )}
          <div className="UserPageV2BodyAchievementsOuter">
            <UserPageAchievements userId={userData.id} />
          </div>
          <div className="UserPageV2BodyAchievementsOuter">
            <UserPageV2ListingsPreview userData={userData} />
          </div>
          <div className="UserPageV2BodyAchievementsOuter">
            <UserPageV2ReviewsPreview userData={userData} />
          </div>
          <div className="UserPageV2BodyAchievementsOuter">
            <UserPageV2RatingsPreview userData={userData} />
          </div>
        </div>
      </div>

      {/* 
      
      
      
      Meny & Dialogs
      
      
      
      */}
      <Menu
        elevation={1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        sx={styles.menuPaper}
      >
        <MenuItem
          dense
          onClick={() => {
            setUserReportDialog(true);
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <ReportIcon
              fontSize="small"
              sx={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
            />
          </ListItemIcon>
          <ListItemText>
            Report{" "}
            {userData.displayName === ""
              ? "Anonymous user"
              : userData.displayName}
          </ListItemText>
        </MenuItem>
      </Menu>
      <Dialog
        open={userReportDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={onUserReportCancel}
        sx={{
          ...standardDialogRoot,
          "& .MuiDialog-paper": {
            ...standardDialogPaper,
            backgroundColor: globalTheme.backgroundMainBeige.color,
            color: globalTheme.backgroundMainOffBeige.color,
            border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
          },
        }}
      >
        <IconButton
          sx={{
            color: globalTheme.textMainGrey.color,
            width: "30px",
            height: "30px",
            position: "absolute",
            top: "25px",
            right: "15px",
          }}
          size="small"
          onClick={onUserReportCancel}
        >
          <CloseOutlined />
        </IconButton>
        <DialogTitle
          sx={{
            ...standardDialogTitle,
            width: "calc(100% - 8px)",
            marginLeft: "4px",
            lineHeight: "110%",
          }}
        >
          Report {userData.displayName === "" ? "User" : userData.displayName}
          <br />
          <span style={{ fontSize: "55%", fontWeight: 400 }}>
            State at least one reason to continue
          </span>
        </DialogTitle>
        <DialogContent>
          <FormGroup sx={styles.formGroup} row>
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"spam"}
                  checked={reasonArray.includes("spam")}
                  onChange={handleReasonChange}
                />
              }
              label="Spam"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"content"}
                  checked={reasonArray.includes("content")}
                  onChange={handleReasonChange}
                />
              }
              label="Inappropriate content"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"harassment"}
                  checked={reasonArray.includes("harassment")}
                  onChange={handleReasonChange}
                />
              }
              label="Harassment"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"imperonate"}
                  checked={reasonArray.includes("imperonate")}
                  onChange={handleReasonChange}
                />
              }
              label="Impersonation"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"falseInfo"}
                  checked={reasonArray.includes("falseInfo")}
                  onChange={handleReasonChange}
                />
              }
              label="False information"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"hate"}
                  checked={reasonArray.includes("hate")}
                  onChange={handleReasonChange}
                />
              }
              label="Hate speech"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"violence"}
                  checked={reasonArray.includes("violence")}
                  onChange={handleReasonChange}
                />
              }
              label="Violence"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  size="small"
                  value={"other"}
                  checked={reasonArray.includes("other")}
                  onChange={handleReasonChange}
                />
              }
              label="Other"
            />
          </FormGroup>
          <div className="AdminListDialogEditButtons">
            <ButtonBase
              sx={{
                ...buttonStyles,
                backgroundColor: globalTheme.colorPaletteButtonRed.color,
                flex: "unset",
                minWidth: "90px",
              }}
              onClick={onUserReportCancel}
              disabled={isSendingUserReport}
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              sx={{
                ...buttonStyles,
                backgroundColor: globalTheme.colorPaletteButtonGreen.color,
                flex: "unset",
                marginLeft: "20px",
                minWidth: "90px",
              }}
              disabled={isSendingUserReport || reasonString === ""}
              onClick={onUserReportSubmit}
            >
              {isSendingUserReport ? "Submitting..." : "Submit"}
            </ButtonBase>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
