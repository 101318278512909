/**
 * AdminIssueReports component for viewing and handling issue reports.
 *
 * This component fetches and displays issue reports from the Firestore database.
 * It allows the user to filter between open and closed issues, view issue details,
 * and delete issue reports.
 *
 * @component
 * @example
 * return (
 *   <AdminIssueReports />
 * )
 *
 * @remarks
 * This component uses Firestore for database operations and Material-UI for UI components.
 * It also utilizes global state for theme and snackbar notifications.
 *
 * @returns {JSX.Element} The AdminIssueReports component.
 *
 * @hook
 * useEffect - Fetches open issues from Firestore when `issuesNewVisible` is true.
 * useEffect - Fetches closed issues from Firestore when `issuesClosedVisible` is true.
 * useEffect - Updates `collectedArray` whenever `issuesNewArray` or `issuesClosedArray` changes.
 *
 * @function
 * onIssueClick - Sets the active issue and opens the dialog.
 * onIssueDelete - Deletes the selected issue report from Firestore.
 * 
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useEffect, useState } from "react";
import {
  query,
  limit,
  collection,
  onSnapshot,
  where,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

import IconButton from "@mui/material/IconButton";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ButtonBase from "@mui/material/ButtonBase";
import Paper from "@mui/material/Paper";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import moment from "moment";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { CircularProgress } from "@mui/material";
import { firestoreDb } from "../../components/firebase/firestore";
import {
  useThemeState,
  useSnackbarErrorState,
  useSnackbarState,
} from "../../components/utils/globalStates";
import {
  standardDialogPaper,
  standardDialogRoot,
} from "../../components/theme/globalMuiTheme";
import "./Admin.css";
import { figmaColorVariables } from "../../components/theme/globalTheme";
import { set } from "firebase/database";
import { id } from "alchemy-sdk/dist/src/api/utils";

moment().format();

export default function AdminIssueReports() {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const snackbarStates = useSnackbarState((state) => state);
  const snackbarError = useSnackbarErrorState((state) => state);

  const [issuesNewVisible, setIssuesNewVisible] = useState(true);
  const [issuesNewArray, setIssueNewArray] = useState<any[]>([]);

  const [issuesClosedVisible, setIssueClosedVisible] = useState(false);
  const [issuesClosedArray, setIssueClosedArray] = useState<any[]>([]);

  const [collectedArray, setCollectedArray] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeIssue, setActiveIssue] = useState<any>(null);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);

  // Fetch issues with open status from issueReports collection if issueNewVisible is true
  useEffect(() => {
    if (issuesNewVisible) {
      setIsLoading(true);
      const q = query(
        collection(firestoreDb, "issueReports"),
        where("status", "==", "open"),
        limit(500)
      );
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const newReports: any[] = [];
        querySnapshot.forEach(async (doc) => {
          newReports.push({ ...doc.data(), type: "Issue", id: doc.id });
        });

        // Promise all in array and fetch reporter name and reported listing name if not in object
        const promises = newReports.map(async (report) => {
          const reporterRef = doc(
            firestoreDb,
            "privateUserInfo",
            report.userId
          );
          const reporterSnap = await getDoc(reporterRef);
          const reporterData = reporterSnap.data();
          report.reporterName = reporterData?.displayName;
        });
        await Promise.all(promises);
        setIssueNewArray(newReports);
        setIsLoading(false);
      });
      return () => unsubscribe();
    } else {
      setIssueNewArray([]);
    }
  }, [issuesNewVisible]);

  // Fetch issues with closed status from issueReports collection if issueClosedVisible is true
  useEffect(() => {
    if (issuesClosedVisible) {
      setIsLoading(true);
      const q = query(
        collection(firestoreDb, "issueReports"),
        where("status", "==", "closed"),
        limit(500)
      );
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const closedReports: any[] = [];
        querySnapshot.forEach(async (doc) => {
          closedReports.push({ ...doc.data(), type: "Issue", id: doc.id });
        });

        // Promise all in array and fetch reporter name and reported listing name if not in object
        const promises = closedReports.map(async (report) => {
          const reporterRef = doc(
            firestoreDb,
            "privateUserInfo",
            report.userId
          );
          const reporterSnap = await getDoc(reporterRef);
          const reporterData = reporterSnap.data();
          report.reporterName = reporterData?.displayName;
        });
        await Promise.all(promises);
        setIssueClosedArray(closedReports);
        setIsLoading(false);
      });
      return () => unsubscribe();
    } else {
      setIssueClosedArray([]);
    }
  }, [issuesClosedVisible]);

  // Listen for changes in all arrays and update collectedArray
  useEffect(() => {
    const allArrays = [...issuesNewArray, ...issuesClosedArray];
    const allArraysFlat = allArrays.flat();
    console.log("All arrays");
    console.log(allArraysFlat);
    setCollectedArray(allArraysFlat);
  }, [issuesNewArray, issuesClosedArray]);

  const onIssueClick = (issue: any) => {
    setActiveIssue(issue);
    setDialogOpen(true);
  };

  const onIssueDelete = async (issue: any) => {
    if (!issue) {
      snackbarError.setMessage("No active issue to delete");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }
    snackbarStates.setMessage(`Removing issue report...`);
    snackbarStates.setOpenLength(null);
    snackbarStates.setIsActionLoading(true);
    snackbarStates.setIsOpen(true);
    try {
      await deleteDoc(doc(firestoreDb, "issueReports", issue.id));
      setDeleteLoading(false);
      setDialogOpen(false);
      setConfirmDelete(false);
      snackbarStates.setMessage(
        `Removed issue reported by ${issue.reporterName}`
      );
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setOpenLength(4000);
    } catch (error) {
      console.error("Error deleting issue", error);
      setDeleteLoading(false);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("");
      snackbarError.setMessage("Error removing issue report");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
    }
  };

  const styles = {
    checkBox: {
      "&.Mui-checked": {
        color: globalTheme.solidMainBlack.color,
      },
      color: globalTheme.solidMainBlack.color,
    },
    formControl: {
      color: globalTheme.solidMainBlack.color,
      justifyContent: "center",
      marginLeft: "3px",
      marginRight: "3px",
      "& .MuiTypography-root": {
        fontSize: "11px !important",
        "@media (min-width: 350px)": {
          fontSize: "12px !important",
        },
        "@media (min-width: 550px)": {
          fontSize: "13px !important",
        },
        "@media (min-width: 700px)": {
          fontSize: "14px !important",
        },
      },
    },
    select: {
      backgroundColor: "transparent",
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      "& .MuiSelect-root": {
        "& fieldset": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
        "&:after": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
      },
      "&.Mui-focused fieldset": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      },
      "&.Mui-focused ": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      },
    },
    selectMenu: {
      color: globalTheme.backgroundMainOffBeige.color,
      "& .MuiPaper-root": {
        backgroundColor: globalTheme.backgroundMainBeige.color,
        color: globalTheme.backgroundMainOffBeige.color,
        "& .MuiMenuItem-root": {
          opacity: 0.6,
          "& .Mui-selected": {
            color: globalTheme.backgroundMainOffBeige.color,
            backgroundColor: globalTheme.backgroundMainBeige.color,
            opacity: 1,
          },
        },
        "& .Mui-selected": {
          color: globalTheme.backgroundMainOffBeige.color,
          backgroundColor: globalTheme.backgroundMainBeige.color,
          opacity: 1,
        },
      },
    },
    input: {
      backgroundColor: "transparent",
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      "& .MuiInputLabel-root": {
        color: globalTheme.backgroundMainOffBeige.color + " !important",
        "& .Mui-focused ": {
          color: globalTheme.backgroundMainOffBeige.color,
        },
      },
    },
  };

  const buttonStyles = {
    color: "#1A1A1A",
    backgroundColor: "#FFED8F",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    boxShadow: globalTheme.boxShadow,
    border: "1px solid #1A1A1A",
    flex: 1,
    margin: "5px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
  };

  return (
    <div className="AdminInner">
      <p
        className="AdminDescription"
        style={{ ...globalTheme.backgroundMainOffBeige }}
      >
        STILL IN PROGRESS
      </p>
      <p
        className="AdminDescription"
        style={{ ...globalTheme.backgroundMainOffBeige }}
      >
        This page is for viewing and handling issue reports. It is still in
        progress and will be updated with more features.
      </p>
      <div className="AdminReportsHeader">
        <div className="AdminReportsHeaderInner">
          <p className="AdminReportsHeaderInnerTitle">Issues</p>
          <div className="AdminReportsHeaderFilter">
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  checked={issuesNewVisible}
                  onChange={(e) => setIssuesNewVisible(e.target.checked)}
                  inputProps={{ "aria-label": "Open Issues" }}
                />
              }
              label="Open"
            />
            <FormControlLabel
              sx={styles.formControl}
              control={
                <Checkbox
                  sx={styles.checkBox}
                  checked={issuesClosedVisible}
                  onChange={(e) => setIssueClosedVisible(e.target.checked)}
                  inputProps={{ "aria-label": "Closed Issues" }}
                />
              }
              label="Closed"
            />
          </div>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="Reports table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Images</TableCell>
              <TableCell>Reporter</TableCell>
              <TableCell align="right">Created</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collectedArray.map((row) => (
              <TableRow
                key={row.title}
                sx={{
                  cursor: "pointer",
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  sx={{ minWidth: "120px" }}
                  onClick={() => onIssueClick(row)}
                >
                  <p>
                    {row.title.substring(0, 40)}
                    {row.title.length > 40 ? "..." : ""}
                  </p>
                </TableCell>
                <TableCell
                  sx={{ minWidth: "200px" }}
                  onClick={() => onIssueClick(row)}
                >
                  <p>
                    {row.description.substring(0, 150)}
                    {row.description.length > 150 ? "..." : ""}
                  </p>
                </TableCell>
                <TableCell onClick={() => onIssueClick(row)}>
                  <p>{row.images.length}</p>
                </TableCell>
                <TableCell>
                  <p
                    onClick={() => {
                      window.open(
                        "https://app.gath3r.co/users/page/" + row.userId,
                        "_blank"
                      );
                    }}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {row.reporterName}
                  </p>
                </TableCell>
                <TableCell align="right" onClick={() => onIssueClick(row)}>
                  {moment(row.created).format("LLL")}
                </TableCell>
                <TableCell align="right" onClick={() => onIssueClick(row)}>
                  <p>{row.status}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading && (
        <div
          style={{
            ...globalTheme.backgroundMainOffBeige,
            marginTop: "30px",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>Loading...</p>
          <CircularProgress
            size={15}
            color="inherit"
            sx={{ marginLeft: "10px" }}
          />
        </div>
      )}
      <Dialog
        sx={{
          ...standardDialogRoot,
          "& .MuiDialog-paper": {
            ...standardDialogPaper,
            backgroundColor: globalTheme.backgroundMainBeige.color,
            color: globalTheme.backgroundMainOffBeige.color,
            border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
          },
        }}
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
      >
        <IconButton
          sx={{
            color: globalTheme.textMainGrey.color,
            width: "30px",
            height: "30px",
            position: "absolute",
            top: "25px",
            right: "15px",
          }}
          size="small"
          onClick={() => setDialogOpen(false)}
        >
          <CloseOutlined />
        </IconButton>
        <DialogContent
          sx={{
            marginTop: "0px",
            paddingTop: "0px",
            paddingBottom: "50px",
          }}
        >
          {activeIssue && activeIssue.type === "Issue" && (
            <div className="AdminIssueReportsDialogOuter">
              <p
                className="AdminReportsHeaderInnerTitle"
                style={{ marginLeft: 0 }}
              >
                {activeIssue.title}
              </p>
              <p
                className="AdminReportsHeaderInnerTitle"
                style={{ marginLeft: 0, fontWeight: 500, marginTop: "0px" }}
              >
                <span style={{ fontSize: "80%" }}>
                  {activeIssue.description}
                </span>
              </p>
              {activeIssue.images.map((image: string, index: number) => (
                <img
                  key={index}
                  src={image}
                  alt={"Issue report image " + index}
                  className="AdminIssueReportsDialogImage"
                />
              ))}
              <p
                className="AdminReportsHeaderInnerTitle"
                style={{
                  marginLeft: 0,
                  fontWeight: 500,
                  marginTop: "0px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(
                    "https://app.gath3r.co/users/page/" + activeIssue.userId,
                    "_blank"
                  );
                }}
              >
                <span style={{ fontSize: "70%" }}>
                  Reporter: {activeIssue.reporterName}
                </span>
              </p>
              <p
                className="AdminReportsHeaderInnerTitle"
                style={{ marginLeft: 0, fontWeight: 500, marginTop: "0px" }}
              >
                <span style={{ fontSize: "70%" }}>
                  Created: {moment(activeIssue.created).format("LLL")}
                </span>
              </p>
              <p
                className="AdminReportsHeaderInnerTitle"
                style={{ marginLeft: 0, fontWeight: 500, marginTop: "0px" }}
              >
                <span style={{ fontSize: "70%" }}>
                  Status: {activeIssue.status}
                </span>
              </p>
              {confirmDelete ? (
                <div className="AdminListDialogEditButtons">
                  <p
                    className="AdminReportsHeaderInnerTitle"
                    style={{
                      marginLeft: 0,
                      fontWeight: 600,
                      marginTop: "0px",
                      marginBottom: "0px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flex: 1,
                    }}
                  >
                    <span style={{ fontSize: "80%" }}>
                      Are you sure you want to remove this issue report?
                    </span>
                  </p>
                  <ButtonBase
                    sx={{
                      ...buttonStyles,
                      backgroundColor: globalTheme.colorPaletteButtonRed.color,
                      flex: "unset",
                    }}
                    onClick={() => setConfirmDelete(false)}
                    disabled={deleteLoading}
                  >
                    Cancel
                  </ButtonBase>
                  <ButtonBase
                    sx={{
                      ...buttonStyles,
                      backgroundColor:
                        globalTheme.colorPaletteButtonGreen.color,
                      flex: "unset",
                    }}
                    onClick={() => {
                      onIssueDelete(activeIssue);
                    }}
                    disabled={deleteLoading}
                  >
                    Remove
                  </ButtonBase>
                </div>
              ) : (
                <div className="AdminListDialogEditButtons">
                  <ButtonBase
                    sx={buttonStyles}
                    onClick={() => setConfirmDelete(true)}
                  >
                    Remove without action
                  </ButtonBase>
                  <ButtonBase
                    sx={{
                      ...buttonStyles,
                      backgroundColor: figmaColorVariables.grey500,
                    }}
                    disabled
                    onClick={() => setConfirmClose(true)}
                  >
                    Close issue and assign points to reporter
                  </ButtonBase>
                </div>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
