/**
 * Component to display a preview of recent reviews for a specific project listing.
 *
 * @param {Object} props - The component props.
 * @param {any} props.listing - The listing object containing the project details.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * This component fetches and displays the most recent reviews for a given project listing.
 * It shows a loading skeleton while the reviews are being fetched and handles cases where
 * there are no reviews available. If there are more than 3 reviews, it provides a link to
 * view all reviews for the listing.
 *
 * @component
 * @example
 * const listing = { id: 'listingId' };
 * return <ProjectPageReviewsPreview listing={listing} />;
 *
 * @author
 * @name jimmybengtsson (itchy-fingers)
 */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReviewListItemPreview from "../../../../components/ui/listItems/ReviewListItemPreview";
import moment from "moment";
import {
  onSnapshot,
  query,
  collection,
  where,
  limit,
  orderBy,
} from "firebase/firestore";
import { firestoreDb } from "../../../../components/firebase/firestore";

import { useThemeState } from "../../../../components/utils/globalStates";
import "./ListingPageReviewPreviews.css";
import { Skeleton } from "@mui/material";

moment().format();

export default function ProjectPageReviewsPreview({
  listing,
}: {
  listing: any;
}) {
  const navigate = useNavigate();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [isLoading, setIsLoading] = useState(true);
  const [reviews, setReviews] = useState<any[]>([]);

  useEffect(() => {
    if (listing === null || listing.id === null || listing.id === undefined) {
      return;
    }
    setIsLoading(true);
    const reviewsQ = query(
      collection(firestoreDb, "reviews"),
      where("listing", "==", listing.id),
      where("review", "==", true),
      orderBy("createdAt", "desc"),
      limit(4)
    );
    const unsubscribe = onSnapshot(
      reviewsQ,
      (snapshot) => {
        const reviewsData: any[] = [];
        snapshot.forEach((doc) => {
          reviewsData.push(doc.data());
        });
        setReviews(reviewsData);
        setIsLoading(false);
      },
      (error) => {
        console.error(error);
        setIsLoading(false);
      }
    );
    return () => unsubscribe();
  }, [listing]);
  return (
    <div className="ProjectPageReviewsPreviewOuter">
      <p className="ProjectPageReviewsPreviewTitle" style={{ color: globalTheme.textMainBlack.color }}>Recent Reviews</p>
      {isLoading ? (
        <div className="ProjectPageReviewsPreviewInner">
          <Skeleton
            variant="rectangular"
            sx={{
              width: "calc(25% - 20px)",
              height: "100px",
              borderRadius: "8px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              width: "calc(25% - 20px)",
              height: "100px",
              borderRadius: "8px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              width: "calc(25% - 20px)",
              height: "100px",
              borderRadius: "8px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              width: "calc(25% - 20px)",
              height: "100px",
              borderRadius: "8px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
        </div>
      ) : reviews.length > 0 ? (
        <div className="ProjectPageReviewsPreviewInner">
          {reviews.map((review, index) => (
            <ReviewListItemPreview
              key={index}
              listItem={review}
              isListing={false}
            />
          ))}
          {reviews.length > 3 && (
            <p
            className="ProjectPageReviewsPreviewInnerLink"
            onClick={() => navigate(`/reviews/listing/${listing.id}`)}
            style={{ color: globalTheme.links.color }}
          >
            See all reviews
          </p>
          )}
        </div>
      ) : (
        <div className="ProjectPageReviewsPreviewInner">
          <p style={{ color: globalTheme.textMainBlack.color }}>No reviews yet</p>
        </div>
      )}
    </div>
  );
}
